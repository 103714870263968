import isEmpty from '../../../../../utils/is-empty'

const validateLocationData = (locationData) => {
  const { streetAndNumber, state, zipCode } = locationData
  const errors = {}

  if (isEmpty(streetAndNumber)) {
    errors.streetAndNumber = 'Please enter the street and number'
  }

  if (isEmpty(state)) {
    errors.state = 'State is required'
  }

  if (isEmpty(zipCode)) {
    errors.zipCode = 'Postal Code is required'
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

export default validateLocationData
