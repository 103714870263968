/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import { Box, Button, IconButton, Stack, Typography } from '@material-ui/core'
import { Fragment, useState } from 'react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import AddOrEditPerson from '../../../../_components/AddOrEditPerson'
import { Step, StepHead } from '../FormSteps.styles'
import Info from '../../../../../info'
import { MobileModalHeader } from '../../MobileMultiStep/MobileMultiStep.style'
import ConditionalWrapper from '../AddServices/components/ConditionalWrapper'
import SelectPeopleForm2 from './SelectPeopleForm2'

const AddPeople = ({
  isMobile,
  people,
  setPeople,
  settings,
  updateDefaultPeople,
  setStepSlug,
  isUsingIFrame,
}) => {
  const [open, setOpen] = useState(false)
  const [currentType, setCurrentType] = useState('')
  const [modalAction, setModalAction] = useState('add')
  const [personData, setPersonData] = useState(null)

  const showNewPeopleModal = (typeSlug) => {
    setCurrentType(typeSlug)
    setOpen(!open)
  }

  const closeNewPerson = () => {
    setCurrentType('')
    setOpen(false)
    setPersonData(null)
    setModalAction('add')
  }

  const onPeopleChange = (type, value) => {
    setPeople({ ...people, [type]: value })
  }

  const handlePersonAdded = (type, newPerson) => {
    const PEOPLE = {
      technicians: 'inspectorOptions',
      clients: 'clientOptions',
      agents: 'agentOptions',
      listingAgents: 'agentOptions',
      contractors: 'contractorOptions',
    }
    const fieldName = PEOPLE[`${type}`] || ''
    if (fieldName) {
      const list = settings[`${fieldName}`]
      const selected = people[`${type}`]

      if (modalAction === 'edit') {
        // Add the existing person to the list of options
        const index = list.findIndex((person) => person.id === newPerson.id)
        list[index] = newPerson
        // Auto-select the new person added
        const indexSelected = selected.findIndex((person) => person.id === newPerson.id)
        selected[indexSelected] = newPerson
      }
      if (modalAction === 'add') {
        // Add the new person to the list of options
        list.push(newPerson)
        // Auto-select the new person added
        selected.push(newPerson)
      }
      setPeople({ ...people, [type]: selected })
      updateDefaultPeople({ type: fieldName, newList: list })
    }
    
    // if (fieldName) {
    //   // Add the new person to the list of options
    //   const list = settings[`${fieldName}`]
    //   list.push(newPerson)
    //   updateDefaultPeople({ type: fieldName, newList: list })
    //   // Auto-select the new person added
    //   const selected = people[`${type}`]
    //   selected.push(newPerson)
    //   setPeople({ ...people, [type]: selected })
    // }
  }

  const backStep = () => {
    setStepSlug('setDateTime')
  }

  const nextStep = () => {
    setStepSlug('addInformation')
  }

  const editPerson = (type, person) => {
    setPersonData(person)
    setModalAction('edit')
    showNewPeopleModal(type)
  }

  return (
    <div>
      {isMobile && (
        <MobileModalHeader>
          <Box>
            <IconButton
              onClick={backStep}
              sx={{
                padding: '6px',
                marginLeft: '-8px',
                svg: { width: 28, height: 28 },
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="h6">Add People</Typography>
          </Box>
          <Box>
            <IconButton
              onClick={nextStep}
              sx={{
                padding: '6px',
                marginRight: '-8px',
                svg: { width: 28, height: 28 },
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          </Box>
        </MobileModalHeader>
      )}
      <ConditionalWrapper
        condition={!isMobile}
        wrapper={(children) => (
          <Step>
            <StepHead>
              <Typography variant="h6">People</Typography>
              <Info title="Select Agents, Clients and other people related to the appointment" />
            </StepHead>
            {children}
          </Step>
        )}
      >
        <Fragment>
          <Box>
            <Stack
              spacing={3}
              sx={{ ...(!isMobile && { p: '14px 14px 2px 14px' }) }}
            >
              {!settings.isUsingIFrame && (
                <SelectPeopleForm2
                  isMobile={isMobile}
                  type="technicians"
                  typeLabel="Technicians"
                  placeholderLabel="Select Technician"
                  options={settings.inspectorOptions}
                  onChange={onPeopleChange}
                  selected={people.technicians}
                  handleShowModal={showNewPeopleModal}
                  isUsingIFrame={isUsingIFrame}
                />
              )}
              <SelectPeopleForm2
                isMobile={isMobile}
                type="clients"
                typeLabel="Clients"
                placeholderLabel="Type to search for client"
                options={settings.clientOptions}
                onChange={onPeopleChange}
                selected={people.clients}
                handleShowModal={showNewPeopleModal}
                isUsingIFrame={isUsingIFrame}
                selectPersonBasicData={editPerson}
              />
              <SelectPeopleForm2
                isMobile={isMobile}
                type="agents"
                typeLabel="Agents"
                placeholderLabel="Type to search for agent"
                options={settings.agentOptions}
                onChange={onPeopleChange}
                selected={people.agents}
                handleShowModal={showNewPeopleModal}
                isUsingIFrame={isUsingIFrame}
                selectPersonBasicData={editPerson}
              />
              <SelectPeopleForm2
                isMobile={isMobile}
                type="listingAgents"
                typeLabel="Listing Agents"
                placeholderLabel="Type to search for listing agent"
                options={settings.agentOptions}
                onChange={onPeopleChange}
                selected={people.listingAgents}
                handleShowModal={showNewPeopleModal}
                isUsingIFrame={isUsingIFrame}
                selectPersonBasicData={editPerson}
              />
              <SelectPeopleForm2
                isMobile={isMobile}
                type="contractors"
                typeLabel="Contractors"
                placeholderLabel="Type to search for contractor"
                options={settings.contractorOptions}
                onChange={onPeopleChange}
                selected={people.contractors}
                handleShowModal={showNewPeopleModal}
                isUsingIFrame={isUsingIFrame}
                selectPersonBasicData={editPerson}
              />
            </Stack>
            <AddOrEditPerson
              action={modalAction}
              open={open}
              closeModal={closeNewPerson}
              type={currentType}
              pushOrUpdatePerson={handlePersonAdded}
              personBasicData={personData}
            />
          </Box>
          {!isMobile && (
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
              <Button variant="contained" color="inherit" onClick={backStep}>
                Previous
              </Button>
              {!isUsingIFrame && (
                <Button variant="contained" color="inherit" onClick={nextStep}>
                  Next
                </Button>
              )}
            </Stack>
          )}
        </Fragment>
      </ConditionalWrapper>
    </div>
  )
}

AddPeople.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  people: PropTypes.shape({
    technicians: PropTypes.array.isRequired,
    clients: PropTypes.array.isRequired,
    agents: PropTypes.array.isRequired,
    listingAgents: PropTypes.array.isRequired,
    contractors: PropTypes.array.isRequired,
  }).isRequired,
  setPeople: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    serviceOptions: PropTypes.array.isRequired,
    discountOptions: PropTypes.array.isRequired,
    agreementOptions: PropTypes.array.isRequired,
    templateOptions: PropTypes.array.isRequired,
    agentOptions: PropTypes.array.isRequired,
    clientOptions: PropTypes.array.isRequired,
    contractorOptions: PropTypes.array.isRequired,
    inspectorOptions: PropTypes.array.isRequired,
    timezone: PropTypes.string.isRequired,
    currencySymbol: PropTypes.string.isRequired,
    acceptEvents: PropTypes.bool.isRequired,
    defaultStartTime: PropTypes.string.isRequired,
    defaultDuration: PropTypes.number.isRequired,
    notifications: PropTypes.object,
  }).isRequired,
  updateDefaultPeople: PropTypes.func.isRequired,
  setStepSlug: PropTypes.func.isRequired,
  isUsingIFrame: PropTypes.bool,
}

export default AddPeople
