import { motion } from 'framer-motion'
import { Box, Container, Typography } from '@material-ui/core'
import { RootStyle } from '../shared/RootStyle'
import { MotionContainer, varBounceIn } from '../../components/animate'
import SentIcon from '../../assets/icon_sent'

export default function SuccessPage() {
  return (
    <RootStyle title="404 Page Not Found | Minimal-UI">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Success!
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Please close this window.
            </Typography>

            <motion.div variants={varBounceIn}>
              <SentIcon sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
            </motion.div>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  )
}
