import axiosApi from './_axiosApi'

export const createContractor = (formData) =>
  axiosApi
    .post('/v2/contractors', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data)

export const getContractorList = ({ subscriberId, cursor, perPage }) =>
  axiosApi
    .get('/v2/contractors', { params: { subscriberId, cursor, perPage } })
    .then((res) => res.data)

export const searchContractors = ({ subscriberId, cursor, perPage, text }) =>
  axiosApi
    .get('/v2/contractors', {
      params: { subscriberId, cursor, perPage, text },
    })
    .then((res) => res.data)

export const getContractor = ({ subscriberId, contractorId }) =>
  axiosApi
    .get(`/v2/contractors/${contractorId}`, { params: { subscriberId } })
    .then((res) => res.data)

export const updateContractor = ({ contractorId, formData }) =>
  axiosApi
    .put(`/v2/contractors/${contractorId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data)

export const changeContractorStatus = ({
  contractorId,
  subscriberId,
  newStatus,
}) =>
  axiosApi
    .post(`/v2/contractors/${contractorId}/status`, {
      subscriberId,
      newStatus,
    })
    .then((res) => res.data)

export const deleteContractor = ({ contractorId, subscriberId }) =>
  axiosApi
    .delete(`v2/contractors/${contractorId}`, { data: { subscriberId } })
    .then((res) => res.data)

export const exportContractors = (subscriberId) =>
  axiosApi
    .get(`/v2/contractors/export-file/${subscriberId}`, {
      responseType: 'blob',
    })
    .then((res) => {
      // Extract "filename" and "mime-type" from "header" response
      const contentDisposition = res.headers['content-disposition']
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1]
        : ''
      const mimeType = res.headers['content-type']

      return {
        blob: res.data,
        mimeType,
        filename,
      }
    })
