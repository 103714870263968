/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import { useState } from 'react'
import { MobileModal } from './MobileMultiStep.style'
import AddLocation from '../FormSteps/AddLocation'
import AddServices from '../FormSteps/AddServices/AddServices'
import SetDateTime from '../FormSteps/SetDateTime'
import AddPeople from '../FormSteps/AddPeople/AddPeople'
import AddInformation from '../FormSteps/AddInformation'
import SetDateTime2 from '../FormSteps/DateTime/SetDateTime2'

const MobileMultiStep = ({
  open,
  onClose,
  stepSlug,
  setStepSlug,
  data,
  settings,
  setLocation,
  setServices,
  setDateTime,
  setPeople,
  setInformation,
  updateDefaultPeople,
  selectedEvent,
  setSelectedEvent,
  SubmitButton,
  subscriberId,
}) => {
  return (
    <MobileModal open={open} anchor="bottom" onClose={onClose}>
      {(() => {
        switch (stepSlug) {
          // Step 1
          case 'addLocation':
            return (
              <AddLocation
                isMobile
                location={data.location}
                setLocation={setLocation}
                setStepSlug={setStepSlug}
                onClose={onClose}
                settings={settings}
              />
            )
          // Step 2
          case 'addServices':
            return (
              <AddServices
                isMobile
                services={data.services}
                setServices={setServices}
                location={data.location}
                settings={settings}
                setStepSlug={setStepSlug}
              />
            )
          // Step 3
          case 'setDateTime':
            return (
              <SetDateTime2
                isMobile
                setDateTime={setDateTime}
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
                setStepSlug={setStepSlug}
                isUsingIframe={settings.isUsingIframe}
                subscriberId={subscriberId}
              />
            )
          // Step 4
          case 'addPeople':
            return (
              <AddPeople
                isMobile
                people={data.people}
                setPeople={setPeople}
                settings={settings}
                updateDefaultPeople={updateDefaultPeople}
                setStepSlug={setStepSlug}
              />
            )
          // Step 5
          case 'addInformation':
            return (
              <AddInformation
                isMobile
                information={data.information}
                acceptActions={settings.acceptActions}
                actionGroups={settings.actionGroups}
                serviceOptions={settings.serviceOptions}
                setInformation={setInformation}
                setStepSlug={setStepSlug}
                SubmitButton={SubmitButton}
                services={data.services}
                setServices={setServices}
                settings={settings}
              />
            )
          default:
            return null
        }
      })()}
    </MobileModal>
  )
}

MobileMultiStep.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  stepSlug: PropTypes.string.isRequired,
  setStepSlug: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  setLocation: PropTypes.func.isRequired,
  setServices: PropTypes.func.isRequired,
  setDateTime: PropTypes.func.isRequired,
  setPeople: PropTypes.func.isRequired,
  setInformation: PropTypes.func.isRequired,
  updateDefaultPeople: PropTypes.func.isRequired,
  SubmitButton: PropTypes.node.isRequired,
  subscriberId: PropTypes.string,
}

export default MobileMultiStep
