import { experimentalStyled as styled } from '@material-ui/core/styles'
import Scrollbar from '../Scrollbar'

export const ListPicker = styled(Scrollbar)(({ theme }) => ({
  height: `358px`,
  width: `100%`,
  '& .simplebar-content': {
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'auto',
    alignItems: 'space-evenly',
    '& .MuiListItem-root': {
      borderRadius: 60,
      height: 'fit-content',
      width: '33.33%',
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        width: '30%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '30%', // 50%
      },
    },
    '& .Mui-selected': {
      background: '#f79023 !important',
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      justifyContent: 'flex-start !important',
      alignItems: 'flex-start !important',
    },
  },
}))
