const inspectionEmailFields = [
  {
    placement: 'bottom',
    text: 'Receiver Name',
    body: "This inserts code that will be replaced by the receiver's name. (i.e. 'John Doe')",
  },
  {
    placement: 'bottom',
    text: 'Receiver First Name',
    body: "This inserts code that will be replaced by the receiver's first name. (i.e. 'John')",
  },
  {
    placement: 'bottom',
    text: 'Appointment Date',
    body: "This inserts code that will be replaced by the scheduled appointment date. (i.e. '2/15/2017')",
  },
  {
    placement: 'bottom',
    text: 'Appointment Time',
    body: "This inserts code that will be replaced by the scheduled appointment time. (i.e. '9:00AM')",
  },
  {
    placement: 'bottom',
    text: 'Appointment End Time',
    body: "This inserts code that will be replaced by the appointment ending time. (i.e. '11:00AM')",
  },
  {
    placement: 'bottom',
    text: 'Property Address',
    body: "This inserts code that will be replaced by the property address. (i.e. '1234 Main St., Denver CO 80210')",
  },
  {
    placement: 'bottom',
    text: 'Street Address',
    body: "This inserts code that will be replaced by the property street address. (i.e. '1234 Main St.')",
  },
  {
    placement: 'bottom',
    text: 'Services',
    body: 'These buttons will insert placeholder code into your email. They will be replaced with the actual values when the email is sent. Hover over each for more details.	',
  },
  {
    placement: 'bottom',
    text: 'Fee Breakdown',
    body: "This inserts code that will be replaced by a comma-separated list of the fees for this appointment. (i.e. 'Residential Appointment $300, Radon Appointment $100')",
  },
  {
    placement: 'bottom',
    text: 'Company Name',
    body: "This inserts code that will be replaced by the company's name. (i.e. 'Sentry Inspecting')",
  },
  {
    placement: 'bottom',
    text: 'Client Name',
    body: "This inserts code that will be replaced by the client's name. (i.e. 'John Doe')",
  },
  {
    placement: 'bottom',
    text: 'Client First Name',
    body: "This inserts code that will be replaced by the client's first name. (i.e. 'John')",
  },
  {
    placement: 'bottom',
    text: 'Agent Name',
    body: "This inserts code that will be replaced by the client's agent's name. (i.e. 'John Smith')",
  },
  {
    placement: 'bottom',
    text: 'Agent First Name',
    body: "This inserts code that will be replaced by the client's agent's first name. (i.e. 'John')",
  },
  {
    placement: 'bottom',
    text: 'Listing Agent Name',
    body: "This inserts code that will be replaced by the listing agent's name. (i.e. 'John Smith')",
  },
  {
    placement: 'bottom',
    text: 'Listing Agent First Name',
    body: "This inserts code that will be replaced by the listing agent's first name. (i.e. 'John')",
  },
  {
    placement: 'bottom',
    text: 'Contractor Name',
    body: "This inserts code that will be replaced by the listing agent's name. (i.e. 'John Smith')",
  },
  {
    placement: 'bottom',
    text: 'Contractor First Name',
    body: "This inserts code that will be replaced by the listing agent's first name. (i.e. 'John')",
  },
  {
    placement: 'bottom',
    text: 'Technician Name',
    body: "This inserts code that will be replaced by the technician's name. (i.e. 'John Smith')",
  },
  {
    placement: 'bottom',
    text: 'Technician Phone',
    body: "This inserts code that will be replaced by the technician's phone.",
  },
  {
    placement: 'bottom',
    text: 'Technician Email',
    body: "This inserts code that will be replaced by the technician's email.",
  },
  {
    placement: 'bottom',
    text: 'Appointment Client Link',
    body: 'This will be replaced by a link to the appointment page, where clients can accept agreements, pay for appointments, and view reports.',
  },
  /*
  {
    placement: 'bottom',
    text: 'Invoice Link',
    body: 'This will be replaced by a direct link to the invoice.',
  },
  {
    placement: 'bottom',
    text: 'Report PDF',
    body: "This will be replaced by a direct link to a published report's PDF.",
  },
  {
    placement: 'bottom',
    text: 'Review PDF',
    body: "This will be replaced by a direct link to a report's Review PDF.",
  },
  */
]

export default inspectionEmailFields
