// ----------------------------------------------------------------------

export default function Switch(theme) {
  const isLight = theme.palette.mode === 'light'

  return {
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          boxShadow: theme.customShadows.z10,
          backgroundColor: theme.palette.background.paper,
          transform: 'scale(0.9)',
        },
        track: {
          opacity: `1 !important`,
          backgroundColor: theme.palette.grey[isLight ? 300 : 700],
          boxShadow: 'inset 0 0 4px 0 rgb(174 174 174 / 24%)',
          height: 20,
          borderRadius: 999,
          transform: 'scale(1.3)',
        },
        switchBase: {
          left: 0,
          right: 'auto',
          '&:not(:.Mui-checked)': {
            color: theme.palette.grey[isLight ? 100 : 300],
          },
          '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
            color: theme.palette.grey[isLight ? 400 : 600],
          },
          '&.Mui-disabled+.MuiSwitch-track': {
            opacity: 1,
            backgroundColor: `${theme.palette.action.disabledBackground} !important`,
          },
        },
      },
    },
  }
}
