import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Icon } from '@iconify/react'
import chevronLeft from '@iconify/icons-eva/chevron-left-fill'
import chevronRight from '@iconify/icons-eva/chevron-right-fill'
// material
import {
  experimentalStyled as styled,
  useTheme,
} from '@material-ui/core/styles'
import { Box, Drawer } from '@material-ui/core'
import SearchIcon from '@iconify/icons-eva/search-outline'
import { useSnackbar } from 'notistack'
import { PATH_DASHBOARD } from '../../routes/paths'

// components
import Logo from '../../assets/brand/logo_full.svg'
import LogoCompact from '../../assets/brand/logo_single.svg'
import Scrollbar from '../../components/Scrollbar'
import NavSection from '../../components/NavSection'
import NavSectionCompact from '../../components/NavSectionCompact'
import { MHidden } from '../../components/@material-extend'
//
import SvgIconStyle from '../../components/SvgIconStyle'
import sidebarConfig from './SidebarConfig'
import { AuthContext } from '../../contexts/AuthContext'

// ----------------------------------------------------------------------
import { Logocontainer, LogocontainerSingle, Switcher } from './sidebarStyle'

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: '100%', height: '100%' }}
  />
)

const getIconify = (name) => <Icon icon={name} width="25px" height="25px" />

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  business: getIcon('ic_business'),
  appointment: getIconify(SearchIcon),
  subscription: getIcon('ic_comms'),
  settings: getIcon('ic_settings'),
  calendar: getIcon('ic_calendar'),
  comms: getIcon('ic_comms'),
  people: getIcon('ic_people'),
  dashboard: getIcon('ic_dashboard'),
  template: getIcon('ic_template'),
}

// ----------------------------------------------------------------------
const navigationMenus = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    path: PATH_DASHBOARD.general.app,
    icon: ICONS.dashboard,
  },
  {
    id: 'inspections',
    title: 'Appointments',
    path: PATH_DASHBOARD.appointments.root,
    icon: ICONS.appointment,
  },
  {
    id: 'calendar',
    title: 'Calendar',
    path: PATH_DASHBOARD.calendar,
    icon: ICONS.calendar,
  },
  {
    id: 'templates',
    title: 'Templates',
    path: PATH_DASHBOARD.templates.root,
    icon: ICONS.template,
  },
  {
    id: 'people',
    title: 'People',
    path: PATH_DASHBOARD.people.root,
    icon: ICONS.people,
  },
  {
    id: 'communication',
    title: 'Communication',
    path: PATH_DASHBOARD.communication,
    icon: ICONS.comms,
  },
  {
    id: 'business',
    title: 'Business',
    path: PATH_DASHBOARD.business.root,
    icon: ICONS.business,
  },
]

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  isCompact: PropTypes.bool,
  setCompact: PropTypes.func,
}

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  isCompact,
  setCompact,
}) {
  const { user } = useContext(AuthContext)
  const theme = useTheme()
  const DRAWER_WIDTH = 230
  const DRAWER_WIDTH_COMPACT = 80
  const isAdmin = user?.user_type === 5
  const { pathname } = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const [sidebarData, setSidebarData] = useState([])

  const userLinks = user?.sidebar_nav_links || []

  function handleCompact() {
    setCompact(!isCompact)
  }

  const RootStyle = styled('div')(
    ({ theme }) => ({
      [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: `${isCompact === true ? DRAWER_WIDTH_COMPACT : DRAWER_WIDTH}px`,
        margin: `${isCompact === true && theme.spacing(2)}`,
      },
    }),
    { index: 999 },
  )

  useEffect(() => {
    if (userLinks && userLinks.length > 0) {
      const sideBarSettings = userLinks.map((r) => {
        if (r.id === 'metrics') return 'business'
        return r.id
      })

      // Many loops to get the menu items
      // TODO: Optimize this

      const sideBar = { items: [] }

      sideBarSettings.map((item) => {
        const menuItem = navigationMenus.find((op) => op.id === item)
        if (menuItem) sideBar.items.push(menuItem)
        return true
      })

      const extraMenus = navigationMenus.filter(
        (row) => sideBarSettings.indexOf(row.id) === -1,
      )

      const fullSideBar = { items: [...sideBar.items, ...extraMenus] }

      if (!user?.full_company_admin) {
        fullSideBar.items = fullSideBar.items.filter(
          (row) =>
            row.id !== 'subscriptions' &&
            row.id !== 'payments' &&
            row.id !== 'business' &&
            row.id !== 'Communication' &&
            row.id !== 'metrics',
        )
      }

      if (!user?.can_edit_template) {
        fullSideBar.items = fullSideBar.items.filter(
          (row) => row.id !== 'templates',
        )
      }

      setSidebarData([fullSideBar])
    }
  }, [userLinks])

  useEffect(() => {
    if (isOpenSidebar) onCloseSidebar()
  }, [pathname])

  if (!userLinks) {
    return null
  }

  const SidebarContent = isAdmin
    ? sidebarConfig.filter((x) => x.name === 'admin')
    : sidebarData
  const renderContent = (
    <>
      <Box sx={{ py: 1.5 }}>
        <Box
          component={RouterLink}
          to={isAdmin ? '/admin/' : '/'}
          sx={{ display: 'inline-flex' }}
        >
          <Logocontainer src={Logo} />
        </Box>
      </Box>
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <NavSection navConfig={SidebarContent} />
        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
    </>
  )

  const renderContentCompact = (
    <>
      <Box sx={{ py: 1.5 }}>
        <Box
          component={RouterLink}
          to={isAdmin ? '/admin/' : '/'}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <LogocontainerSingle src={LogoCompact} />
        </Box>
      </Box>
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <NavSectionCompact navConfig={SidebarContent} />

        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
    </>
  )

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      {/* sidebar for desktop */}
      <MHidden width="lgDown">
        <Drawer
          open={!isCompact}
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' },
          }}
        >
          {renderContent}

          <Switcher onClick={handleCompact}>
            <Icon
              className="chev-icon chev-left"
              width={30}
              height={30}
              icon={chevronLeft}
            />
            <Icon
              className="chev-icon chev-left"
              width={30}
              height={30}
              icon={chevronLeft}
            />
            <Icon
              className="chev-icon chev-left"
              width={30}
              height={30}
              icon={chevronLeft}
            />
          </Switcher>
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open={isCompact}
          variant="persistent"
          PaperProps={{
            elevation: 10,
            sx: {
              width: DRAWER_WIDTH_COMPACT,
              bgcolor: 'background.default',
              height: '95vh',
              top: theme.spacing(2),
              bottom: theme.spacing(2),
              left: theme.spacing(2),
              border: 'none !important',
              borderRadius: 2,
            },
          }}
        >
          {renderContentCompact}
          <Switcher onClick={handleCompact}>
            <Icon
              className="chev-icon chev-right"
              width={30}
              height={30}
              icon={chevronRight}
            />
            <Icon
              className="chev-icon chev-right"
              width={30}
              height={30}
              icon={chevronRight}
            />
            <Icon
              className="chev-icon chev-right"
              width={30}
              height={30}
              icon={chevronRight}
            />
          </Switcher>
        </Drawer>
      </MHidden>
    </RootStyle>
  )
}
