/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import {
  Autocomplete,
  Grid,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { Fragment, createRef } from 'react'
import QuillEditor from '../../../../../../../../common/QuillEditor/QuillEditor'
import { emailOptions as EMAIL_OPTIONS } from '../../../../../../../../../constants/action-variables'
import EMAIL_VARIABLES from '../../../../../../../../../constants/inspection_email_fields'

const EmailDetails = ({
  to_address,
  from_address,
  cc_address,
  bcc_address,
  subject,
  body_content,
  updateAction,
}) => {
  const editorRef = createRef()

  const onChange = (event) => {
    const { name, value } = event.target
    updateAction(name, value)
  }

  const onVaribleClicked = (value) => {
    const editor = editorRef.current.getEditor()
    const range = editor.getSelection()
    const position = range ? range.index : 0
    const variable = value.toUpperCase().split(' ').join('_')
    editor.insertText(position, `{{${variable}}}`)
  }

  return (
    <Fragment>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ mb: 2 }}>
        <TextField
          select
          fullWidth
          name="from_address"
          size="small"
          value={from_address}
          onChange={onChange}
          label="From"
        >
          <MenuItem value={1}>Company</MenuItem>
          <MenuItem value={2}>Technician</MenuItem>
        </TextField>
        <Autocomplete
          multiple
          fullWidth
          name="to_address"
          options={EMAIL_OPTIONS}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={to_address}
          filterSelectedOptions
          onChange={(_event, newValue) => updateAction('to_address', newValue)}
          // freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="To"
              placeholder={to_address ? '' : 'type a value or select one'}
            />
          )}
        />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ mb: 2 }}>
        <Autocomplete
          multiple
          fullWidth
          name="cc_address"
          options={EMAIL_OPTIONS}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={cc_address}
          filterSelectedOptions
          onChange={(_event, newValue) => updateAction('cc_address', newValue)}
          // freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Cc"
              placeholder={cc_address ? '' : 'type a value or select one'}
            />
          )}
        />
        <Autocomplete
          multiple
          fullWidth
          name="cc_address"
          options={EMAIL_OPTIONS}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={bcc_address}
          filterSelectedOptions
          onChange={(_event, newValue) => updateAction('bcc_address', newValue)}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Bcc"
              placeholder={bcc_address ? '' : 'type a value or select one'}
            />
          )}
        />
      </Stack>
      <Stack spacing={2} sx={{ mb: 2 }}>
        <TextField
          fullWidth
          name="subject"
          size="small"
          value={subject}
          onChange={onChange}
          label="Subject"
        />
        <Grid container>
          <Grid item xs={12} md={8} style={{ paddingTop: '0px' }}>
            <QuillEditor
              innerRef={editorRef}
              name="body_content"
              placeholder="Email body (template)"
              value={body_content}
              onChange={updateAction}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{ paddingTop: '0px', paddingLeft: '10px' }}
          >
            <MenuList
              sx={{
                padding: 0,
                height: '280px',
                overflowY: 'auto',
                border: '1px solid #dbe0e4',
                borderRadius: '8px',
              }}
            >
              {EMAIL_VARIABLES.map((item, i) => {
                return (
                  <Tooltip
                    // eslint-disable-next-line react/no-array-index-key
                    key={`email-variable-${i}`}
                    arrow
                    title={item.body}
                    placement="right"
                  >
                    <MenuItem
                      sx={{
                        fontSize: '12px',
                        borderRadius: '8px',
                        padding: '7px 15px',
                        justifyContent: 'center',
                      }}
                      onClick={() => onVaribleClicked(item.text)}
                    >
                      {item.text}
                    </MenuItem>
                  </Tooltip>
                )
              })}
            </MenuList>
          </Grid>
        </Grid>
      </Stack>
    </Fragment>
  )
}

EmailDetails.propTypes = {
  to_address: PropTypes.array,
  from_address: PropTypes.number,
  cc_address: PropTypes.array,
  bcc_address: PropTypes.array,
  subject: PropTypes.string,
  body_content: PropTypes.string,
  updateAction: PropTypes.func,
}

export default EmailDetails
