/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Card,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import Dialog from '../../../../../../animate/DialogAnimate'
import { QontoConnector, QontoStepIcon } from './AddOrEditAction.style'
import ChooseEvent from './Steps/ChooseEvent'
import AddRules from './Steps/AddRules'
import ChooseAction from './Steps/ChooseAction'
import AddDetails from './Steps/AddDetails/AddDetails'

const STEP_TABS = [
  { value: 1, slug: 'chooseEvent', label: 'Choose an Event' },
  { value: 2, slug: 'addRules', label: 'Add Rules' },
  { value: 3, slug: 'chooseAction', label: 'Choose an Action' },
  { value: 4, slug: 'addDetails', label: 'Add Details' },
]

const AddOrEditAction = ({
  isOpen,
  step,
  groupId,
  selected,
  closeModal,
  serviceOptions,
  serviceAddOnsOptions,
  addAction,
  updateAction,
}) => {
  const [activeStep, setActiveStep] = useState(1)
  const [action, setAction] = useState(newActionState)

  useEffect(() => {
    if (isOpen) {
      setActiveStep(step || 1)
      setAction(selected || newActionState)
    }
  }, [isOpen])

  const handleOnClose = () => {
    if (selected && step) {
      // Save changes before close modal
      // when we are editing an action
      updateAction(groupId, action)
    }
    closeModal()
  }

  const handleNext = () => {
    if (activeStep !== 4) {
      // Just go to next step
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    } else {
      // This means is the last step
      if (selected && step) {
        // We are editing the actions so...
        updateAction(groupId, action)
        return closeModal()
      }
      // Is a new action so... (validate, save & close)
      const { isValid, errors } = validateNewActionData(action)
      if (isValid) {
        addAction(groupId, action)
        closeModal()
      } else {
        // TODO: Show errors
        console.log('Hay errores')
      }
    }
    return null
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <Dialog open={isOpen} onClose={handleOnClose}>
      <Card sx={{ p: 2, maxWidth: 900, mx: 'auto', marginBottom: 2 }}>
        <Stepper
          alternativeLabel
          activeStep={activeStep - 1} // In <Stepper> the first value needs to be 0
          connector={<QontoConnector />}
        >
          {STEP_TABS.map((tab) => (
            <Step key={tab.slug}>
              <StepLabel
                sx={{
                  '.MuiStepLabel-label': {
                    marginTop: '8px !important',
                    lineHeight: 1,
                  },
                }}
                StepIconComponent={QontoStepIcon}
              >
                <Typography
                  variant="caption"
                  sx={{ cursor: 'pointer', fontWeight: 700, fontSize: '13px' }}
                  onClick={() => setActiveStep(tab.value)}
                >
                  {tab.label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Card>
      <Card sx={{ p: '20px', maxWidth: 900, mx: 'auto' }}>
        <Box>
          {(() => {
            switch (activeStep) {
              case 1:
                return <ChooseEvent action={action} setAction={setAction} />
              case 2:
                return (
                  <AddRules
                    action={action}
                    setAction={setAction}
                    serviceOptions={serviceOptions}
                    serviceAddOnsOptions={serviceAddOnsOptions}
                  />
                )
              case 3:
                return <ChooseAction action={action} setAction={setAction} />
              case 4:
                return <AddDetails action={action} setAction={setAction} />
              default:
                return 'Unknown step'
            }
          })()}
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          {activeStep !== 1 && (
            <Button
              onClick={handleBack}
              sx={{
                mr: 2,
                transform: 'scale(1) !important',
                minWidth: 95,
                background: '#d2d2d2',
                color: '#212b36 !important',
                boxShadow: `0 8px 16px 0 rgba(210,210,210,0.24)`,
                '&:hover': {
                  background: '#d2d2d2',
                  boxShadow: `0 8px 16px 0 rgba(210,210,210,0.24)`,
                },
              }}
            >
              Back
            </Button>
          )}
          <Button
            variant="contained"
            onClick={handleNext}
            sx={{ transform: 'scale(1) !important', minWidth: 95 }}
          >
            {activeStep === STEP_TABS.length
              ? selected && step
                ? 'Update'
                : 'Finish'
              : 'Next'}
          </Button>
        </Box>
      </Card>
    </Dialog>
  )
}

const validateNewActionData = (newAction) => {
  // TODO: validate new actionData before save/add it
  // console.log(JSON.stringify(newAction))
  return { isValid: true, errors: {} }
}

const newActionState = {
  // --- Choose an Event ---
  enable: true,
  duration: '0',
  duration_type: '0',
  trigger_type: 'after',
  event_type: 1,
  trigger_once: false,
  when_noti_disabled: false,
  is_certain_hours: false,
  certain_hours: [14, 34],
  no_weekends: false,
  // ------ Add Rules ------
  rules: [],
  // --- Choose an Action ---
  action_name: '',
  action_type: 1,
  // ------ Add Details ------
  to_address: [],
  from_address: 1,
  cc_address: [],
  bcc_address: [],
  subject: '',
  body_content: '',
  webhook_url: 'https://',
  webhook_data: [{ key: 1, value: 'inspection', label: 'Appointment' }],
}

AddOrEditAction.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  step: PropTypes.number,
  groupId: PropTypes.string,
  selected: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  serviceOptions: PropTypes.array.isRequired,
  serviceAddOnsOptions: PropTypes.array.isRequired,
  addAction: PropTypes.func.isRequired,
  updateAction: PropTypes.func.isRequired,
}

export default AddOrEditAction
