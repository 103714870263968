// ----------------------------------------------------------------------

export default function Pickers(theme) {
  return {
    PrivatePickersPopper: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.z24,
          borderRadius: theme.shape.borderRadiusMd,
        },
      },
    },

    PrivatePicker: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          border: 'none !important',
          borderRadius: theme.shape.borderRadiusMd,

          PrivatePickersCalendar: {
            '.MuiDialogActions-root': {
              display: 'flex',
              justifyContent: 'center',
            },
            minHeight: 190,
            maxHeight: 190,
          },
          '& .PrivatePickersToolbar-root': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
            '& .MuiTypography-root': {
              color: theme.palette.common.white,
              '&.Mui-selected': {
                color: `#fff !important`,
              },
            },
          },
          '& .MuiTabs-root': {
            margin: 0,
            border: 'none',
            background: theme.palette.background.paper,
            color: theme.palette.primary.main,
          },
          '& .MuiTabs-indicator': {
            width: '160px !important',
            backgroundColor: theme.palette.primary.dark,
          },
          '& span.Mui-selected': {
            color: '#fff',
          },
        },
        landscape: {
          border: `solid 1px ${theme.palette.divider}`,
        },
        PrivatePickersCalendarHeader: {
          MuiButton: {
            border: `ipx solid ${theme.palette.primary.main}`,
          },
        },
      },
    },

    MuiDateRangePickerViewDesktop: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadiusMd,
        },
      },
    },
    MuiPickersDay: {
      '&.Mui-selected': {
        color: '#fff !important',
      },
    },
  }
}
