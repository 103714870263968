export const themeColor = '#ff6600'
export const projectTitle = 'ReportON'
export const APP_NAME = 'ReportON'

/* ----- Google ----- */
// export const GOOGLE_API_KEY = 'AIzaSyB4cqZtcYF0naKyyL5sgVMbzGLLek2UvFA' // Live
export const GOOGLE_API_KEY = 'AIzaSyCajAivsbvUQyEAxVmQdWLDS4SSFWX5eN0' // Divelement (dev)
export const GOOGLE_CLIENT_ID =
  '1067738102615-flh8tdir7lc2ooh8tssoe07e73snnsds.apps.googleusercontent.com'
export const GOOGLE_CLIENT_SECRET = 'JxMsdU6VLkio-ChbzQYf6FDM'
export const barrerTocken = '8210accb-1adf-4f7b-b35b-421f4cf379be'
export const URLPattern = /^((http|https|ftp):\/\/)/
/* -------------- */

export const ENCRIPTION_PASSCODE = 'rep!o#rt@o$x72@9'
// export const apiURL = "https://reportondevstaging.sky-niche.website";
//  export const apiURL = "https://reporton.sky-niche.website";
// export const apiURL = 'http://localhost:4000'
// export const apiURL = "https://devapi.reporton.com";
// export const apiURL = "https://reporton.com";
export const apiURL =
  process.env.REACT_APP_API_URL === 'development'
    ? 'https://devapi.reporton.com'
    : 'https://api.reporton.com'

// Local BaseURL = "http://localhost:3000";
// Server BaseURL = "https://dev.reporton.com";
export const BaseURL =
  process.env.REACT_APP_API_URL === 'development'
    ? 'https://dev.reporton.com'
    : 'https://app.reporton.com'

export const isDev = process.env.NODE_ENV === 'development'

export const supportApiURL = 'https://reportoxsupport.skyniche.website'
export const CDNUploadURL =
  'https://storage.googleapis.com/upload/storage/v1/b/reportox-cdn/o'
export const CDNURL = 'https://reportoxsupport.skyniche.website'

/* Payment Gateway Mode */
export const GATEWAY_ENVIRONMENT = 'sandbox'

export const SQUARE_APP_ID =
  GATEWAY_ENVIRONMENT === 'sandbox'
    ? 'sandbox-sq0idb-TwuCw3McqUpKe0fOLVJhjA'
    : 'sq0idp-x0VHFvIZtAyK6s9xjq-wJg'
export const SQUARE_APP_SECRET =
  GATEWAY_ENVIRONMENT === 'sandbox'
    ? 'sandbox-sq0csb-Ni7Hzlx2QfMb-cICPUwF_xQ3T6uzZdyv0Ev45EjOmgw'
    : 'sq0csp-hVSMMmrS8FTArDQwoLS5Y_X68d1c3PL2Jdlns6j66cU'

export const PAYPAL_CLIENT_ID =
  GATEWAY_ENVIRONMENT === 'sandbox'
    ? 'AQfBKP2aIDXI6B2IKseIvui8wNjEQQZFPd89n4PEyzDEF5xSScpqRpJFHUPqYFJNPBAPhKADCzdP1_jP'
    : ''
export const PAYPAL_CLIENT_SECRET =
  GATEWAY_ENVIRONMENT === 'sandbox'
    ? 'EN4h7JwWd54qzzAmUQPMF6kIrnK_zpOjmnNz-15mj7NO26316pNYaLs18JQYO9MUxHMz8c3E9WD294Vk'
    : ''
