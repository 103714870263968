/* eslint-disable camelcase */
import axiosApi from './_axiosApi'

// * Queries & Mutations *

export const getReport = ({ userId, reportId }) =>
  axiosApi
    .get(`/v2/reports/${reportId}`, { params: { userId } })
    .then((res) => res.data)

export const downloadReport = ({ reportId, type }) =>
  axiosApi
    .get(`/v2/reports/${reportId}/download`, {
      params: { type },
      responseType: 'blob',
    })
    .then((res) => {
      // Extract "filename", "mime-type" and "size" from "header" response
      const contentDisposition = res.headers['content-disposition']
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1]
        : ''
      const mimeType = res.headers['content-type']
      const size = parseInt(res.headers['content-length'], 10)

      return {
        blob: res.data,
        mimeType,
        filename,
        size,
      }
    })

export const rebuildReportPdfs = ({ subscriberId, reportId }) =>
  axiosApi
    .post(`/v2/reports/${reportId}/rebuild-pdfs`, { subscriberId })
    .then((res) => res.data)

export const shareReport = ({
  reportId,
  recipients,
  extraMessage,
  sendFullReport,
  sendSummaryReport,
}) =>
  axiosApi
    .post(`/v2/reports/${reportId}/share`, {
      recipients,
      extraMessage,
      sendFullReport,
      sendSummaryReport,
    })
    .then((res) => res.data)

export const addReportAsSample = ({
  subscriberId,
  reportId,
  name,
  description,
}) =>
  axiosApi
    .post(`/v2/reports/${reportId}/as-sample`, {
      subscriberId,
      name,
      description,
    })
    .then((res) => res.data)

export const updateReportComment = ({
  reportId,
  commentId,
  subscriberId,
  name,
  tags,
  category,
  recommendation,
  default_location,
  default_text,
  min_cost,
  max_cost,
}) =>
  axiosApi
    .put(`/v2/reports/${reportId}/comment/${commentId}/update`, {
      subscriberId,
      name,
      tags,
      ...(category && { category }),
      ...(recommendation && { recommendation }),
      default_location: default_location || '',
      default_text: default_text || '',
      ...(min_cost && { min_cost }),
      ...(max_cost && { max_cost }),
    })
    .then((res) => res.data)
