export const durationTypes = [
  {
    id: '0',
    label: 'minutes',
    key: 0,
  },
  {
    id: '1',
    label: 'hours',
    key: 1,
  },
  {
    id: '2',
    label: 'days',
    key: 2,
  },
  {
    id: '3',
    label: 'weeks',
    key: 3,
  },
  {
    id: '4',
    label: 'months',
    key: 4,
  },
]

export const Reminders = [
  {
    id: '0',
    label: 'Only trigger once',
    key: 0,
    value: false,
  },
  {
    id: '1',
    label: 'Send even when notifications disabled',
    key: 1,
    value: false,
  },
  {
    id: '2',
    label: 'Send during certain hours only',
    key: 2,
    value: false,
  },
  {
    id: '3',
    label: 'Do not send on weekends',
    key: 3,
    value: false,
  },
]

export const before_after = [
  {
    id: '0',
    label: 'before',
    key: 0,
  },
  {
    id: '1',
    label: 'after',
    key: 1,
  },
]

export const actionTypes = [
  {
    id: 1,
    label: 'Send Email',
    key: 1,
  },
  {
    id: 2,
    label: 'Send Text',
    key: 2,
  },
  {
    id: 3,
    label: 'Call Webhook',
    key: 3,
  },
]

export const emailOptions = [
  {
    value: 'company_admins',
    label: 'Company admins',
    key: 1,
  },
  {
    value: 'clients',
    label: 'Clients',
    key: 2,
  },
  {
    value: 'agents',
    label: "Client's agents",
    key: 3,
  },
  {
    value: 'technicians',
    label: 'Technicians',
    key: 4,
  },
  {
    value: 'listing_agents',
    label: 'Listing agents',
    key: 5,
  },
  {
    value: 'contractors',
    label: 'Contractors',
    key: 6,
  },
]

export const webhookOptions = [
  {
    value: 'inspection',
    label: 'Appointment',
    key: 1,
  },
  {
    value: 'property',
    label: 'Property',
    key: 2,
  },
  {
    value: 'technicians',
    label: 'Technicians',
    key: 3,
  },
  {
    value: 'charges',
    label: 'Charges',
    key: 4,
  },
  {
    value: 'payments',
    label: 'Payments',
    key: 5,
  },
  {
    value: 'clients',
    label: 'Clients',
    key: 6,
  },
  {
    value: 'agents',
    label: "Client's agents",
    key: 7,
  },
  {
    value: 'listing_agents',
    label: 'Selling agents',
    key: 8,
  },
  {
    value: 'event_details',
    label: 'Event details',
    key: 9,
  },
]
