/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import {
  Box,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@material-ui/core'
import { Icon } from '@iconify/react'
import closeOutline from '@iconify/icons-eva/close-outline'

const NewFeeForm = ({ index, currencySymbol, fee, updateFee, deleteFee }) => {
  return (
    <Card sx={{ p: '28px 22px 20px 20px', mb: '20px', width: '100%' }}>
      <Stack>
        <Box
          xs={12}
          sx={{
            float: 'right',
            position: 'absolute',
            right: 3,
            top: 3,
            borderRadius: '14px',
          }}
        >
          <IconButton
            onClick={() => deleteFee(fee)}
            sx={{ margin: '0', p: `5px` }}
          >
            <Icon icon={closeOutline} width={18} height={18} />
          </IconButton>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Stack>
              <TextField
                fullWidth
                name={`line_item---${index}`}
                label="Line Item"
                size="small"
                sx={{ mb: 2 }}
                value={fee.line_item}
                onChange={updateFee}
              />
              <TextField
                rows={3}
                fullWidth
                multiline
                name={`pricing_details---${index}`}
                label="Pricing Details"
                value={fee.pricing_details}
                onChange={updateFee}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Stack>
              <TextField
                fullWidth
                type="number"
                name={`pretax_amount---${index}`}
                label="Pretax Amount"
                placeholder="PreTax Amount"
                size="small"
                sx={{ mb: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mr: 1 }}>
                      {currencySymbol}
                    </InputAdornment>
                  ),
                }}
                value={fee.pretax_amount}
                onChange={updateFee}
              />
              <TextField
                rows={3}
                fullWidth
                name={`tax_description---${index}`}
                multiline
                label="Tax Description"
                value={fee.tax_description}
                onChange={updateFee}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Stack>
              <TextField
                fullWidth
                type="number"
                name={`tax_amount---${index}`}
                label="Tax Amount"
                placeholder="Tax Amount"
                size="small"
                sx={{ mb: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mr: 1 }}>
                      {currencySymbol}
                    </InputAdornment>
                  ),
                }}
                value={fee.tax_amount}
                onChange={updateFee}
              />
              <TextField
                rows={3}
                fullWidth
                multiline
                name={`notes_for_client_invoice---${index}`}
                label="Notes for client invoice"
                value={fee?.notes_for_client_invoice}
                onChange={updateFee}
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  )
}

NewFeeForm.propTypes = {
  index: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string,
  fee: PropTypes.shape({
    id: PropTypes.string.isRequired,
    line_item: PropTypes.string.isRequired,
    pretax_amount: PropTypes.string.isRequired,
    tax_amount: PropTypes.string.isRequired,
    pricing_details: PropTypes.string.isRequired,
    tax_description: PropTypes.string.isRequired,
    notes_for_client_invoice: PropTypes.string,
  }).isRequired,
  updateFee: PropTypes.func.isRequired,
  deleteFee: PropTypes.func.isRequired,
}

export default NewFeeForm
