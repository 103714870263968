import PropTypes from 'prop-types'
import { useContext } from "react"
import {
  alpha,
  experimentalStyled as styled,
  useTheme,
} from '@material-ui/core/styles'
import { AppBar, Link, Typography } from '@material-ui/core'

const DRAWER_WIDTH = 230
const DRAWER_WIDTH_COMPACT = 95

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCompact: PropTypes.bool,
}

export default function DashboardNavbar({ onOpenSidebar, isCompact }) {
  const { user } = useContext(AuthContext)
  // const { isAdmin } = useSelector((state) => state.admin);
  const theme = useTheme()
  const isAdmin = false

  const RootStyle = styled(AppBar)(
    ({ theme }) => ({
      boxShadow: 'none',
      height: 30,
      backgroundColor: alpha(theme.palette.primary.main, 0.72),
      zIndex: 9999,
      width: `100%`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.common.white,
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${
          (isCompact ? DRAWER_WIDTH_COMPACT : DRAWER_WIDTH) + 1
        }px)`,
      },
    }),
    { index: 1 },
  )

  const loginAsUser = () => {
    localStorage.removeItem('is_admin')
    loginWithUserId(1, user.id)
  }

  return (
    <>
      {isAdmin ? (
        <RootStyle>
          <Typography variant="body2" textAlign="center" sx={{ fontSize: 10 }}>
            Logged in as{' '}
            {user ? `${user.first_name} ${user.last_name}` : ''}
            <Link
              onClick={loginAsUser}
              sx={{
                background: theme.palette.success.dark,
                padding: '5px',
                ml: 2,
                borderRadius: 9,
                color: '#fff',
                cursor: 'pointer',
              }}
            >
              Switch to admin
            </Link>
          </Typography>
        </RootStyle>
      ) : (
        ''
      )}
    </>
  )
}
