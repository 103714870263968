const actions = [
  {
    key: 1,
    label: '— Pre-Appointment —',
    options: [
      {
        id: 1,
        label: 'Appointment requested',
        description: `Triggered when unconfirmed appointment is created. Triggers once.`,
        type: 'after',
      },
      {
        id: 2,
        label: 'Appointment scheduled',
        description: `Triggered when a new appointment is created already confirmed or when a requested appointment becomes confirmed. Triggers once.`,
        type: 'after',
      },
      {
        id: 3, // before => "Service or Addon Added After Scheduling/Confirmation"
        label: 'Services Changed After Scheduling/Confirmation',
        description: `Triggers when some services change after the appointment is created. Triggers multiple times.`,
        type: 'after',
      },
      {
        id: 4, // before => "Service or Addon Removed After Scheduling/Confirmation"
        label: 'Fees Changed After Scheduling/Confirmation',
        description: `Triggers when some fees change after the appointment is created. Triggers multiple times.`,
        type: 'after',
      },
      {
        id: 5,
        label: 'Agreement Added After Scheduling/Confirmation',
        description: `Triggers when an agreement is added after the appointment is created. Triggers multiple times.`,
        type: 'after',
      },
      {
        id: 6,
        label: 'Agreement Removed After Scheduling/Confirmation',
        description: `Triggers when an agreement is removed after the appointment is created. Triggers multiple times.`,
        type: 'after',
      },
      {
        id: 7,
        label: 'Report Attachment Added After Scheduling/Confirmation',
        description: `Triggers when an attachment is added after the appointment is created. Triggers multiple times.`,
        type: 'after',
      },
      {
        id: 8,
        label: 'Report Attachment Removed After Scheduling/Confirmation',
        description: `Triggers when an attachment is removed after the appointment is created. Triggers multiple times.`,
        type: 'after',
      },
      {
        id: 9, // before => "Fee Added After Scheduling/Confirmation"
        label: 'Contractor assigned',
        description: `Triggered when an contractor is assigned to the appointment. Triggers multiple times.`,
        type: 'after',
      },
      {
        id: 10, // before => "Fee Removed After Scheduling/Confirmation"
        label: 'Contractor unassigned',
        description: `Triggered when an contractor is unassigned from an appointment. Triggers multiple times.`,
        type: 'after',
      },
      {
        id: 11,
        label: 'Technician assigned',
        description: `Triggered when an technician is assigned to the appointment. Triggers multiple times.`,
        type: 'after',
      },
      {
        id: 12,
        label: 'Technician unassigned',
        description: `Triggered when an technician is unassigned from an appointment. Triggers multiple times.`,
        type: 'after',
      },
      {
        id: 13,
        label: 'Appointment rescheduled',
        description: `Triggered when the date or time of the appointment change. Triggers multiple times.`,
        type: 'after',
      },
      {
        id: 14,
        label: 'Appointment canceled',
        description: `Triggers when the appointment is canceled. Triggers multiple times.`,
        type: 'after',
      },
    ],
  },
  {
    key: 2,
    label: '— Client Actions —',
    options: [
      {
        id: 15,
        label: 'All Agreements Signed',
        description: `Triggers when all agreements connected to the appointment are signed. Triggers multiple times.`,
        type: 'after',
      },
      {
        id: 16,
        label: 'Appointment paid for',
        description: `Triggered when the appointment is paid in full. Triggers multiple times.`,
        type: 'after',
      },
      {
        id: 17,
        label: 'Client has paid for Appointment and signed all Agreements',
        description: `Triggers when all agreements connected to the appointment are signed and appointment is marked as paid. Triggers multiple times.`,
        type: 'after',
      },
    ],
  },
  {
    key: 3,
    label: '— Day of Appointment —',
    options: [
      {
        id: 18,
        label: 'Appointment Start Time',
        description: `Triggered based on when the appointment is scheduled to take place. Triggers once.`,
        type: '',
      },
      {
        id: 19,
        label: 'Appointment End Time',
        description: `Triggered based on when the appointment is scheduled plus the appointment duration. Triggers once.`,
        type: '',
      },
      {
        id: 20,
        label: 'Report(s) Published',
        description: `Triggered when any report is published or when multiple reports are published. Triggers multiple times.`,
        type: 'after',
      },
    ],
  },
  {
    key: 4,
    label: '— Appointment Sub-Events —',
    options: [
      {
        id: 21,
        label: 'Appointment Sub-Event created',
        description: `Triggered when an appointment sub-event is created. Triggers multiple times.`,
        type: 'after',
      },
      {
        id: 22,
        label: 'Appointment Sub-Event updated',
        description: `Triggered when an appointment sub-event is updated. Triggers multiple times.`,
        type: 'after',
      },
      {
        id: 23,
        label: 'Appointment Sub-Event deleted',
        description: `Triggered when an appointment sub-event is deleted. Triggers multiple times.`,
        type: 'after',
      },
    ],
  },
  {
    key: 5,
    label: '— Post-Appointment —',
    options: [
      {
        id: 24,
        label: 'Report viewed by agent',
        description: 'Triggers once.',
        type: 'after',
      },
      {
        id: 25,
        label: 'Report viewed by client',
        description: 'Triggers once.',
        type: 'after',
      },
    ],
  },
]

export default actions
