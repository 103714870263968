/* eslint-disable react/forbid-prop-types */
import { useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom'
import {
  experimentalStyled as styled,
  useTheme,
} from '@material-ui/core/styles'
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@material-ui/core'
import { Icon } from '@iconify/react'
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill'
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill'

const ListItemStyle = styled((props) => (
  <ListItem button disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(2.5),
  paddingTop: theme.spacing(3.5),
  paddingBottom: theme.spacing(3.5),
  marginTop: 6,
  marginBottom: 6,
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    left: 0,
    width: 2,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
  },
  '&:hover': { color: 'inherit' },
}))

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
}

function NavItem({ item, active }) {
  const theme = useTheme()
  const isActiveRoot = active(item.path)
  const { title, path, icon, info, children } = item
  const [open, setOpen] = useState(isActiveRoot)

  const handleOpen = () => {
    setOpen((prev) => !prev)
  }
  const activeRootStyle = {
    color: theme.palette.text.primary,
    span: { color: 'primary.main' },
    svg: { color: 'primary.main' },
    background: `linear-gradient( to right, #feeddb , #ffffff)`,
    '&:before': { display: 'block' },
    '&:hover': { color: 'inherit' },
  }

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  }

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item, index) => {
              const { title, path } = item
              const isActiveSub = active(path)

              return (
                <ListItemStyle
                  key={`list-item-${index}`}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) =>
                          theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main',
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              )
            })}
          </List>
        </Collapse>
      </>
    )
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title && title} />
      {info && info}
    </ListItemStyle>
  )
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
}

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation()
  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false

  return (
    <Box {...other}>
      {navConfig.map((list, i) => {
        const { items } = list
        return (
          <List key={`nav-list-${i}`}>
            {items.map((item, j) => (
              <NavItem key={`nav-item-${j}`} item={item} active={match} />
            ))}
          </List>
        )
      })}
    </Box>
  )
}
