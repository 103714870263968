import { Icon } from '@iconify/react'
import { useSnackbar } from 'notistack'
import { useContext, useRef, useState } from 'react'
import downarrow from '@iconify/icons-eva/arrow-ios-downward-outline'
import { Link as RouterLink } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ReactComponent as Gear } from '../../assets/svgs/gear.svg'
import { ReactComponent as Profile } from '../../assets/svgs/user.svg'
import { PATH_DASHBOARD } from '../../routes/paths'
import useIsMountedRef from '../../hooks/useIsMountedRef'
import MyAvatar from '../../components/MyAvatar'
import MenuPopover from '../../components/MenuPopover'
import { AuthContext } from '../../contexts/AuthContext'
import SupportTicket from '../../features/SupportTicket/SupportTicket'

export default function AccountPopover() {
  const theme = useTheme()
  const mdu = useMediaQuery(theme.breakpoints.up('md'))
  const anchorRef = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const isMountedRef = useIsMountedRef()
  const [open, setOpen] = useState(false)
  const [chevronState, setChevState] = useState('0deg')
  const { user: userData, logout } = useContext(AuthContext)

  const handleOpen = () => {
    setOpen(true)
    setChevState('180deg')
  }
  const handleClose = () => {
    setOpen(false)
    setChevState('0deg')
  }

  const handleLogout = async () => {
    try {
      await logout()
      if (isMountedRef.current) handleClose()
    } catch (error) {
      enqueueSnackbar('Unable to logout', { variant: 'error' })
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={() => handleOpen()}
      >
        <IconButton
          ref={anchorRef}
          disableRipple
          sx={{
            padding: 0,
            width: 'auto',
            height: 44,
            '&:hover': {
              background: 'none !important',
            },
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
              },
              '&:hover': {
                background: `none !important`,
              },
            }),
          }}
        >
          <MyAvatar sx={{ marginRight: 2 }} />
          {mdu && (
            <Typography variant="body2" noWrap sx={{ fontSize: 12 }}>
              {userData.first_name} {userData.last_name}
            </Typography>
          )}
          {mdu && (
            <Icon
              icon={downarrow}
              style={{
                marginLeft: 10,
                color: '#d4d4d4',
                transform: `rotate(${chevronState})`,
                transition: theme.transitions.create(['transform']),
              }}
            />
          )}
        </IconButton>
      </Box>

      <MenuPopover
        open={open}
        onClose={() => handleClose()}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {userData.first_name} {userData.last_name}
          </Typography>
          {userData.role !== 'Administrator' ? (
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {userData.email}
            </Typography>
          ) : (
            ''
          )}
        </Box>

        <Divider sx={{ my: 1 }} />

        {userData.role !== 'Administrator' && [
          <MenuItem
            key="profile"
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
            to={PATH_DASHBOARD.user.edit}
            component={RouterLink}
            onClick={handleClose}
          >
            <ListItemIcon
              sx={{
                minWidth: '20px !important',
                justifyContent: 'center',
                margin: '0 12px 0 0',
              }}
            >
              <Profile height={18} style={{ fill: '#637481' }} />
            </ListItemIcon>
            <ListItemText
              primary="Profile"
              primaryTypographyProps={{
                variant: 'body2',
                color: 'text.secondary',
              }}
            />
          </MenuItem>,
          <MenuItem
            key="settings"
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
            to={PATH_DASHBOARD.settings.root}
            component={RouterLink}
            onClick={handleClose}
          >
            <ListItemIcon
              sx={{
                minWidth: '20px !important',
                justifyContent: 'center',
                margin: '0 12px 0 0',
              }}
            >
              <Gear height={18} style={{ fill: '#637481' }} />
            </ListItemIcon>
            <ListItemText
              primary="Settings"
              primaryTypographyProps={{
                variant: 'body2',
                color: 'text.secondary',
              }}
            />
          </MenuItem>,
        ]}
        {userData.role !== 'Administrator' && (
          <SupportTicket closeMenu={handleClose} />
        )}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  )
}
