import { Box, Card, Tab } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { GOOGLE_API_KEY } from '../../../../../../constants/defaultValues'
import { GoogleMapReact } from '../../../../../google-maps/GoogleMaps.styles'
import { Pin } from '../../../../../google-maps/Markers'

const STREET_VIEW_URL = `https://maps.googleapis.com/maps/api/streetview?size=600x600`
const Tabs = [
  { value: 'preview', label: 'Street View' },
  { value: 'location', label: 'Map' },
]

const LocationPreview = ({ isMobile, fullAddress, coordinates }) => {
  const [TabValue, setTabValue] = useState(Tabs[0].value)
  const theme = useTheme()

  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue)
  }

  const address = fullAddress?.replace(', ', ',') || ''

  return (
    <Card
      xs={12}
      sm={6}
      sx={{
        overflow: 'visible',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        ...(isMobile && { boxShadow: 'none' }),
      }}
    >
      <TabContext value={TabValue}>
        <Box>
          <TabList
            onChange={handleTabChange}
            sx={{
              '& .MuiTab-root:not(:last-child)': {
                marginRight: `0 !important`,
              },
              '& .MuiTabs-indicator': {
                bottom: '5px',
              },
            }}
          >
            {Tabs.map((tab) => (
              <Tab
                key={tab.value}
                disableRipple
                label={tab.label}
                value={tab.value}
                sx={{ p: theme.spacing(1, 5) }}
              />
            ))}
          </TabList>
        </Box>
        <Box sx={{ width: '100%' }}>
          <TabPanel value={Tabs[0].value} style={{ lineHeight: 0 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                pointerEvents: 'none',
                userSelect: 'none',
                ...(isMobile ? { p: '7px 0 0 0' } : { p: '3px 8px 8px 8px' }),
              }}
            >
              {address ? (
                <Box
                  component="img"
                  dev
                  style={{
                    width: 350,
                    height: 350,
                    borderRadius: 8,
                    pointerEvents: 'none',
                    userSelect: 'none',
                  }}
                  src={`${STREET_VIEW_URL}&location=${address}&key=${GOOGLE_API_KEY}`}
                />
              ) : (
                <div
                  style={{
                    width: 350,
                    height: 350,
                    borderRadius: 8,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 600,
                    color: '#637381',
                    backgroundColor: 'rgba(145, 158, 171, 0.24)',
                    opacity: '0.6',
                  }}
                >
                  <p style={{ marginBottom: '20px' }}>Search a location</p>
                </div>
              )}
            </Box>
          </TabPanel>
          <TabPanel value={Tabs[1].value}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                ...(isMobile ? { p: '7px 0 0 0' } : { p: '3px 8px 8px 8px' }),
              }}
            >
              {coordinates.lat !== 0 && coordinates.lng !== 0 ? (
                <div
                  id="map-container"
                  style={{
                    height: '350px',
                    width: '350px',
                    borderRadius: 8,
                    overflow: 'hidden',
                  }}
                >
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: GOOGLE_API_KEY, language: 'en' }}
                    yesIWantToUseGoogleMapApiInternals
                    defaultZoom={15}
                    defaultCenter={coordinates}
                    options={{
                      fullscreenControl: false,
                      zoomControl: false,
                    }}
                  >
                    <Pin
                      style={{ fill: '#ea4335' }}
                      lat={coordinates.lat}
                      lng={coordinates.lng}
                    />
                  </GoogleMapReact>
                </div>
              ) : (
                <div
                  style={{
                    width: 350,
                    height: 350,
                    borderRadius: 8,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 600,
                    color: '#637381',
                    backgroundColor: 'rgba(145, 158, 171, 0.24)',
                    opacity: '0.6',
                  }}
                >
                  <p style={{ marginBottom: '20px' }}>Search a location</p>
                </div>
              )}
            </Box>
          </TabPanel>
        </Box>
      </TabContext>
    </Card>
  )
}

// eslint-disable-next-line react/no-typos
LocationPreview.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  fullAddress: PropTypes.string.isRequired,
  coordinates: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
}

export default LocationPreview
