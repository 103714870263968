import EditorQuill from 'react-quill'

import styled from 'styled-components'

export const ReactQuill = styled(EditorQuill)`
  .ql-toolbar,
  .ql-container {
    border: 1px solid #dbe0e4;
  }
  .ql-toolbar {
    border-radius: 8px 8px 0 0;
  }
  .ql-container {
    font-family: Montserrat, sans-serif;
    font-size: 13px;
    border-radius: 0 0 8px 8px;
    .ql-editor {
      min-height: 236px;
      .ql-size-small {
        font-size: 13px;
      }
      .ql-size-large {
        font-size: 16px;
      }
      .ql-size-huge {
        font-size: 16px;
      }
      strong,
      b {
        font-weight: 600;
      }
      ul {
        padding-inline-start: 30px;
        li {
          padding: 0;
        }
      }
    }
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
    content: 'Normal';
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
    font-size: 13px;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
    content: 'Large';
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
    font-size: 16px;
  }
`
