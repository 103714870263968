/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
import { useContext, useEffect, useMemo, useState } from 'react'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Box, Card, Stack, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import isEmpty from '../../../../utils/is-empty'
import Dialog from '../../../animate/DialogAnimate'
import { AddSteps } from './MultiStepForm.styles'
import MobileAppointment from './MobileMultiStep'
// Form Sections
import AddLocation from './FormSteps/AddLocation'
import AddServices from './FormSteps/AddServices'
import AddPeople from './FormSteps/AddPeople'
import AddInformation from './FormSteps/AddInformation'
import SubmitAppointment from './FormSteps/SubmitAppointment'
import SetDateTime2 from './FormSteps/DateTime/SetDateTime2'
import { AuthContext } from '../../../../contexts/AuthContext'

const MultiStepForm = ({
  open,
  onClose,
  stepSlug,
  setStepSlug,
  data,
  setData,
  settings,
  setSettings,
}) => {
  const { user } = useContext(AuthContext)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const subscriberId = user?.subscriber_id || null
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (isEmpty(data.information.order_id)) {
      // Assign default data from the settings
      const { orderId: order_id, actionGroups: action_groups } = settings
      setData({
        ...data,
        information: { ...data.information, order_id, action_groups },
      })
    }
  }, [settings])

  useEffect(() => {
    if (selectedEvent) {
      setPeople({ ...data.people, technicians: [selectedEvent.user] })
    }
  }, [selectedEvent])

  const TABS_APPOINTMENT = useMemo(
    () => [
      {
        value: 'addLocation',
        label: 'Location',
        sublabel: !isEmpty(data?.location?.streetAndNumber)
          ? data?.location?.streetAndNumber
          : 'Add location',
      },
      {
        value: 'addServices',
        label: 'Services',
        sublabel: !isEmpty(data?.services.services)
          ? 'Services added'
          : 'Add Services',
      },
      {
        value: 'setDateTime',
        label: 'Date & Time',
        sublabel: !isEmpty(data?.dateTime)
          ? dayjs(data?.dateTime).format('MM/DD/YYYY hh:mm A')
          : 'Add Date & Time',
      },
      {
        value: 'addPeople',
        label: 'People',
        sublabel: arePeopleAdded(data?.people) ? 'People added' : 'Add People',
      },
      {
        value: 'addInformation',
        label: 'Information',
        sublabel: areInfoAdded(data.information)
          ? 'Information added'
          : 'Add Information',
      },
    ],
    [data],
  )

  const onStepClicked = (_event, slug) => {
    setStepSlug(slug)
  }

  const setLocation = (location) => {
    setData({ ...data, location })
  }

  const setServices = (services) => {
    setData({ ...data, services })
  }

  const setDateTime = (dateTime) => {
    setData({ ...data, dateTime })
  }

  const setPeople = (people) => {
    setData({ ...data, people })
  }

  const updateDefaultPeople = ({ type, newList }) => {
    setSettings({ ...settings, [type]: newList })
  }

  const setInformation = (information) => {
    setData({ ...data, information })
  }

  const SubmitButton = (
    <SubmitAppointment
      isMobile={isMobile}
      location={data.location}
      services={data.services}
      dateTime={data.dateTime}
      people={data.people}
      information={data.information}
      settings={settings}
      closeModal={onClose}
      setSelectedEvent={setSelectedEvent}
    />
  )

  return isMobile ? (
    <MobileAppointment
      open={open}
      onClose={onClose}
      stepSlug={stepSlug}
      setStepSlug={setStepSlug}
      data={data}
      settings={settings}
      setLocation={setLocation}
      setServices={setServices}
      setDateTime={setDateTime}
      setPeople={setPeople}
      setInformation={setInformation}
      updateDefaultPeople={updateDefaultPeople}
      SubmitButton={SubmitButton}
      selectedEvent={selectedEvent}
      setSelectedEvent={setSelectedEvent}
      subscriberId={subscriberId}
    />
  ) : (
    <Dialog open={open} onClose={onClose}>
      <TabContext value={stepSlug}>
        <Card
          style={{
            borderRadius: `60px`,
            maxWidth: 900,
            margin: 'auto',
          }}
        >
          <TabList
            onChange={onStepClicked}
            sx={{
              width: `100%`,
              display: 'flex',
              alignItems: 'center',
              padding: 0,
              '& .MuiTabs-scroller': {
                overflowX: 'auto !important',
              },
              '& .MuiTabs-flexContainer': {
                justifyContent: 'space-between',
              },
              '& .MuiTab-wrapper': {
                justifyContent: 'start',
              },
              '.MuiTabs-indicator': {
                backgroundColor: 'transparent',
              },
              backgroundColor: '#f0f0f0',
            }}
          >
            {TABS_APPOINTMENT.map((tab) => (
              <AddSteps
                key={tab.value}
                label={
                  <Stack style={{ maxWidth: '100%' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: 12,
                        textAlign: 'center',
                        width: '100%',
                      }}
                    >
                      {tab.label}
                      <Typography
                        style={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                        variant="caption"
                        sx={{ fontSize: 10 }}
                      >
                        {tab?.sublabel}
                      </Typography>
                    </Box>
                  </Stack>
                }
                value={tab.value}
              />
            ))}
            <Box
              style={{
                width: 'calc(100%/6)',
                margin: '8px',
                display: 'inline-flex',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {SubmitButton}
            </Box>
          </TabList>
        </Card>
        <Box sx={{ maxWidth: 900, mx: 'auto', mt: 2 }}>
          <TabPanel value="addLocation">
            <AddLocation
              isMobile={isMobile}
              location={data.location}
              setLocation={setLocation}
              setStepSlug={setStepSlug}
              settings={settings}
            />
          </TabPanel>
          <TabPanel value="addServices">
            <AddServices
              isMobile={isMobile}
              services={data.services}
              setServices={setServices}
              location={data.location}
              settings={settings}
              setStepSlug={setStepSlug}
            />
          </TabPanel>
          <TabPanel value="setDateTime">
            <SetDateTime2
              isMobile={isMobile}
              setDateTime={setDateTime}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
              setStepSlug={setStepSlug}
              isUsingIframe={settings.isUsingIframe}
              subscriberId={subscriberId}
            />
          </TabPanel>
          <TabPanel value="addPeople">
            <AddPeople
              isMobile={isMobile}
              people={data.people}
              setPeople={setPeople}
              settings={settings}
              updateDefaultPeople={updateDefaultPeople}
              setStepSlug={setStepSlug}
              isUsingIFrame={false}
            />
          </TabPanel>
          <TabPanel value="addInformation">
            <AddInformation
              isMobile={isMobile}
              information={data.information}
              acceptActions={settings.acceptActions}
              actionGroups={settings.actionGroups}
              serviceOptions={settings.serviceOptions}
              setInformation={setInformation}
              setStepSlug={setStepSlug}
              services={data.services}
              setServices={setServices}
              settings={settings}
            />
          </TabPanel>
        </Box>
      </TabContext>
    </Dialog>
  )
}

const arePeopleAdded = (people) => {
  // const fields = Object.keys(people)
  // return fields.every((group) => !isEmpty(people[group]))
  return !isEmpty(people.clients)
}

const areInfoAdded = (info) => {
  // const fields = ['order_id', 'square_feet']
  // return fields.every((key) => !isEmpty(info[key]))
  return !isEmpty(info.order_id)
}

MultiStepForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  stepSlug: PropTypes.string.isRequired,
  setStepSlug: PropTypes.func.isRequired,
  data: PropTypes.shape({
    location: PropTypes.shape({
      searchGoogleEnabled: PropTypes.bool.isRequired,
      fullAddress: PropTypes.string.isRequired,
      streetAndNumber: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zipCode: PropTypes.string.isRequired,
      square_feet: PropTypes.string.isRequired,
      year_built: PropTypes.string.isRequired,
      foundation: PropTypes.string.isRequired,
      coordinates: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      }),
    }).isRequired,
    services: PropTypes.shape({
      duration: PropTypes.string.isRequired,
      discountCode: PropTypes.string.isRequired,
      templates: PropTypes.array.isRequired,
      agreements: PropTypes.array.isRequired,
      services: PropTypes.array.isRequired,
      fees: PropTypes.array.isRequired,
      events: PropTypes.array.isRequired,
      newServices: PropTypes.shape({
        services: PropTypes.array,
        templates: PropTypes.array,
        agreements: PropTypes.array,
        fees: PropTypes.array,
        total: PropTypes.string,
        duration: PropTypes.string,
        manualDuration: PropTypes.string,
      }),
      isManualEdit: PropTypes.bool,
      manualData: PropTypes.shape({
        templates: PropTypes.array,
        agreements: PropTypes.array,
        fees: PropTypes.array,
        total: PropTypes.string,
        duration: PropTypes.string,
      }),
      autoData: PropTypes.shape({
        templates: PropTypes.array,
        agreements: PropTypes.array,
        services: PropTypes.array,
        fees: PropTypes.array,
        total: PropTypes.string,
        duration: PropTypes.string,
      }),
      requirePayment: PropTypes.bool.isRequired,
      paymentNotes: PropTypes.string.isRequired,
    }).isRequired,
    dateTime: PropTypes.string.isRequired,
    people: PropTypes.shape({
      technicians: PropTypes.array.isRequired,
      clients: PropTypes.array.isRequired,
      agents: PropTypes.array.isRequired,
      listingAgents: PropTypes.array.isRequired,
      contractors: PropTypes.array.isRequired,
    }).isRequired,
    information: PropTypes.shape({
      order_id: PropTypes.string.isRequired,
      confirm_inspection: PropTypes.bool.isRequired,
      disable_automated_notifications: PropTypes.bool.isRequired,
      occupied: PropTypes.bool.isRequired,
      utilities_on: PropTypes.bool.isRequired,
      action_groups: PropTypes.array,
    }).isRequired,
  }).isRequired,
  setData: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    companyName: PropTypes.string.isRequired,
    orderId: PropTypes.string.isRequired,
    serviceOptions: PropTypes.array.isRequired,
    discountOptions: PropTypes.array.isRequired,
    agreementOptions: PropTypes.array.isRequired,
    templateOptions: PropTypes.array.isRequired,
    agentOptions: PropTypes.array.isRequired,
    clientOptions: PropTypes.array.isRequired,
    contractorOptions: PropTypes.array.isRequired,
    inspectorOptions: PropTypes.array.isRequired,
    actionGroups: PropTypes.array.isRequired,
    isLimitReached: PropTypes.bool.isRequired,
    timezone: PropTypes.string.isRequired,
    currencySymbol: PropTypes.string.isRequired,
    acceptEvents: PropTypes.bool.isRequired,
    acceptActions: PropTypes.bool.isRequired,
    defaultStartTime: PropTypes.string.isRequired,
    defaultDuration: PropTypes.number,
    defaultPrice: PropTypes.number.isRequired,
    defaultAgreement: PropTypes.string.isRequired,
    notifications: PropTypes.object,
  }).isRequired,
  setSettings: PropTypes.func.isRequired,
}

export default MultiStepForm
