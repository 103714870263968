import { Box, Typography } from '@material-ui/core'
import { MCircularProgress } from './@material-extend'

export default function Loader({ message, height, smallLoad = true }) {
  return (
    <Box
      sx={{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: height || '80vh',
        width: '100%',
      }}
    >
      {message ? (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography>{message}</Typography>
          <MCircularProgress
            sx={{ mx: 'auto' }}
            size={height && smallLoad ? 30 : 60}
            color="primary"
          />
        </Box>
      ) : (
        <MCircularProgress
          size={height && smallLoad ? 30 : 60}
          color="primary"
        />
      )}
    </Box>
  )
}
