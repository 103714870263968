/* eslint-disable react/forbid-prop-types */
import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  alpha,
} from '@material-ui/core'
import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import { isEqual } from 'lodash'
import AddFeeForm from './AddFee/AddFee'
import { ReactComponent as RotateLeft } from '../../../../../../../assets/svgs/clock-rotate-left.svg'
import { ReactComponent as ArrowLeft } from '../../../../../../../assets/svgs/arrow-left.svg'

const ManualEditForm = ({
  newServices,
  templateOptions,
  agreementOptions,
  currencySymbol,
  handleManualEdit,
  saveManuelChanges,
}) => {
  const [fees, setFees] = useState(newServices.fees)
  const [services, setServices] = useState(newServices.services)
  const [templates, setTemplates] = useState(newServices.templates)
  const [agreements, setAgreements] = useState(newServices.agreements)
  const [duration] = useState(
    fees.reduce((acc, fee) => acc + Number(fee.duration), 0),
  )
  const [manualDuration, setManualDuration] = useState(
    newServices?.manualDuration || duration.toString(),
  )

  const total = useMemo(
    () =>
      fees.reduce((acc, fee) => {
        const amount = Number(fee.pretax_amount) + Number(fee.tax_amount)
        return acc + amount
      }, 0),
    [fees],
  )

  const changesToSave =
    !isEqual(fees, newServices.fees) ||
    !isEqual(services, newServices.services) ||
    !isEqual(templates, newServices.templates) ||
    !isEqual(agreements, newServices.agreements) ||
    manualDuration !== (newServices?.manualDuration || duration.toString())

  const removeServiceFee = (fee) => {
    const newServices = services.filter((s) => s.id !== fee.serviceId)
    const newFees = fees.filter((f) => f.serviceId !== fee.serviceId)
    setServices(newServices)
    setFees(newFees)
  }

  const removeAddOnFee = (fee) => {
    const newFees = fees.filter((f) => f.addOnId !== fee.addOnId)
    setFees(newFees)
    const serviceIndex = services.map((s) => s.id).indexOf(fee.serviceId)
    const service = services[serviceIndex]
    const addOnIndex = service.add_ons.map((a) => a.id).indexOf(fee.addOnId)
    const updatedServices = update(services, {
      [serviceIndex]: { add_ons: { $splice: [[addOnIndex, 1]] } },
    })
    setServices(updatedServices)
  }

  const handleRemoveFee = (fee) => {
    if (fee.addOnId) removeAddOnFee(fee)
    else removeServiceFee(fee)
  }

  const saveChanges = () => {
    const updatedServices = {
      ...newServices,
      services,
      templates,
      agreements,
      fees,
      ...(manualDuration &&
        manualDuration !== duration && {
          manualDuration: manualDuration,
        }),
    }
    saveManuelChanges(updatedServices)
  }

  return (
    <Stack>
      <Grid container spacing={2} sx={{ mb: '20px' }}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            Templates, Agreements and Duration
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            multiple
            fullWidth
            label="Templates"
            options={templateOptions}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            value={templates}
            filterSelectedOptions
            onChange={(_event, newArray) => setTemplates(newArray)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Templates"
                placeholder={!templates.length ? 'Search for' : ''}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            multiple
            fullWidth
            label="Agreements"
            options={agreementOptions}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            value={agreements}
            filterSelectedOptions
            onChange={(_event, newArray) => setAgreements(newArray)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Agreements"
                placeholder={!agreements.length ? 'Search for' : ''}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            // type="number"
            label="Duration"
            placeholder="Duration"
            size="small"
            name="duration"
            value={manualDuration}
            onChange={({ target: { value } }) => setManualDuration(value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">hrs</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <AddFeeForm
        fees={fees}
        setFees={setFees}
        currencySymbol={currencySymbol}
        deleteFee={handleRemoveFee}
      />
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'center', mb: '20px' }}
      >
        <Typography variant="subtitle2">
          <span style={{ marginRight: '15px' }}>Total:</span>
          <span>
            {currencySymbol}
            {total.toFixed(2)}
          </span>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          m: '0 auto 30px auto',
          width: '100%',
          maxWidth: '320px',
        }}
      >
        <Button
          variant="primary"
          startIcon={
            changesToSave ? (
              <RotateLeft width={15} style={{ fill: '#646464' }} />
            ) : (
              <ArrowLeft width={15} style={{ fill: '#646464' }} />
            )
          }
          onClick={handleManualEdit}
          sx={{
            width: '140px',
            padding: '8px 19px',
            background: '#d2d2d2',
            boxShadow: `0 8px 16px 0 ${alpha('#d2d2d2', 0.24)}`,
            '&:hover': {
              background: '#d2d2d2',
              boxShadow: `0 8px 16px 0 ${alpha('#d2d2d2', 0.24)}`,
            },
            textTransform: 'none',
            color: '#646464 !important',
          }}
        >
          {!changesToSave ? 'Back' : 'Revert'}
        </Button>
        <Button
          variant="primary"
          onClick={saveChanges}
          sx={{
            width: '140px',
            padding: '8px 19px',
            textTransform: 'none',
            background: '#1976d2',
            boxShadow: 'none',
            '&:hover': {
              background: '#1976d2',
              boxShadow: 'none',
            },
            '&:disabled': {
              background: 'rgb(25, 118, 210)',
              color: '#f3f3f3 !important',
              cursor: 'not-allowed',
              pointerEvents: 'auto',
            },
          }}
          disabled={!changesToSave}
        >
          Save Changes
        </Button>
      </Grid>
    </Stack>
  )
}

ManualEditForm.propTypes = {
  newServices: PropTypes.object.isRequired,
  templateOptions: PropTypes.array.isRequired,
  agreementOptions: PropTypes.array.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  handleManualEdit: PropTypes.func.isRequired,
  saveManuelChanges: PropTypes.func.isRequired,
}

export default ManualEditForm
