/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import { MenuItem, Stack, TextField } from '@material-ui/core'
import { actionTypes as ACTION_TYPES } from '../../../../../../../../constants/action-variables'

const ChooseAction = ({ action, setAction }) => {
  const handleChange = (e) => {
    const { value, name } = e.target
    setAction({
      ...action,
      [name]: value,
      // this avoids weird behavior with the shared variable (string & htmlString)
      ...(name === 'action_type' && { body_content: '' }),
    })
  }

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ mb: 2 }}>
      <TextField
        fullWidth
        name="action_name"
        size="small"
        value={action.action_name}
        label="Action Name (optional)"
        placeholder="Action Name"
        onChange={handleChange}
      />
      <TextField
        fullWidth
        select
        name="action_type"
        size="small"
        value={action.action_type}
        onChange={handleChange}
        label="Action"
      >
        {ACTION_TYPES.map((row) => {
          return (
            <MenuItem value={row.id} key={row.key}>
              {row.label}
            </MenuItem>
          )
        })}
      </TextField>
    </Stack>
  )
}

ChooseAction.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.string,
    enable: PropTypes.bool,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    duration_type: PropTypes.string,
    trigger_type: PropTypes.string,
    event_type: PropTypes.number,
    trigger_once: PropTypes.bool,
    when_noti_disabled: PropTypes.bool,
    is_certain_hours: PropTypes.bool,
    certain_hours: PropTypes.array,
    no_weekends: PropTypes.bool,
    rules: PropTypes.array,
    action_name: PropTypes.string,
    action_type: PropTypes.number,
    to_address: PropTypes.array,
    from_address: PropTypes.number,
    cc_address: PropTypes.array,
    bcc_address: PropTypes.array,
    subject: PropTypes.string,
    body_content: PropTypes.string,
    webhook_url: PropTypes.string,
    webhook_data: PropTypes.array,
  }).isRequired,
  setAction: PropTypes.func,
}

export default ChooseAction
