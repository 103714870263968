/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import { Box, Card, Typography } from '@material-ui/core'
import { ReactSortable } from 'react-sortablejs'
import { memo } from 'react'
import Dialog from '../../../../../../animate/DialogAnimate'
import ActionGroupAccordion from './ActionGroupAccordion/ActionGroupAccordion'

const PreviewActions = ({
  open,
  onClose,
  actionGroups,
  noSubtitle,
  openEditAction,
  cloneAction,
  updateGroups,
  updateActions,
  updateOneAction,
  serviceOptions,
  serviceAddOnsOptions,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Card sx={{ p: 2, maxWidth: 900, mx: 'auto' }}>
        <Box sx={{ pb: 2 }}>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Preview Actions
          </Typography>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
            Below are your actions that currently apply to this appointment.
          </Typography>
          {!noSubtitle && (
            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              Your other actions will still be available after this appointment
              is created.
            </Typography>
          )}
        </Box>

        <ReactSortable
          handle=".action-group-handler-wrapper"
          animation={200}
          list={actionGroups}
          setList={updateGroups}
        >
          {actionGroups.map((actionGroup) => (
            <ActionGroupAccordion
              key={actionGroup.id}
              actionGroup={actionGroup}
              openEditAction={openEditAction}
              cloneAction={cloneAction}
              updateActions={updateActions}
              updateOneAction={updateOneAction}
              serviceOptions={serviceOptions}
              serviceAddOnsOptions={serviceAddOnsOptions}
            />
          ))}
        </ReactSortable>
      </Card>
    </Dialog>
  )
}

PreviewActions.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  actionGroups: PropTypes.array,
  noSubtitle: PropTypes.bool,
  openEditAction: PropTypes.func,
  cloneAction: PropTypes.func,
  updateGroups: PropTypes.func,
  updateActions: PropTypes.func,
  updateOneAction: PropTypes.func,
  serviceOptions: PropTypes.array,
  serviceAddOnsOptions: PropTypes.array,
}

PreviewActions.defaultProps = {
  actionGroups: [],
}

export default memo(PreviewActions)
