import styled from 'styled-components'
import { Box, Drawer } from '@material-ui/core'

export const MobileModal = styled(Drawer)`
  .MuiModal-root.MuiDrawer-root {
    background-size: 150%;
    background-position: center center;
    border-radius: 0px !important;
  }
  & > .MuiPaper-root {
    height: auto;
    max-height: 90vh;
    padding: 14px 20px 16px 20px;
    border-radius: 24px 24px 0px 0px;
  }
`

export const MobileModalHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background: #fff;
`
