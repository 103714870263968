import { experimentalStyled as styled } from '@material-ui/core/styles'
import { Tab } from '@material-ui/core'

export const AddSteps = styled(Tab)(({ theme }) => ({
  width: 'calc(100%/6) !important',
  padding: theme.spacing(1, 2.5),
  minHeight: 'fit-content',
  fontSize: 11.3,
  color: theme.palette.text.primary,
  borderRadius: `60px`,
  '&.MuiButtonBase-root': {
    margin: 0,
    width: `19%`,
    height: '66px',
    padding: '8px 12px',
  },
  '&.Mui-selected': {
    boxShadow: theme.shadows[10],
    backgroundColor: 'white',
  },
}))
