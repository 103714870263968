/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-restricted-syntax */
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

const getAddonFee = ({ service, addOn, currencySymbol }) => {
  const { add_fee: addFee, name: addOnName } = addOn
  const serviceName = service.name

  // Get AddOn taxes
  let taxAcc = 0
  const taxDescription = []
  service.taxes.map((currentTax) => {
    const { add_percent: percentage, name: taxName } = currentTax
    const cost = (addFee + taxAcc).toFixed(2)
    const tax = (percentage * cost) / 100
    const taxAmount = tax.toFixed(2)
    const percent = percentage.toFixed(1)
    taxAcc += +taxAmount

    let description = `${currencySymbol}${taxAmount} ${taxName} for ${serviceName}`
    description += ` (${percent}% on ${currencySymbol}${cost})`
    taxDescription.push(description)
    return true
  })

  // Return fee
  return {
    id: uuidv4(),
    serviceId: service.id,
    addOnId: addOn.id,
    line_item: addOnName,
    pretax_amount: addFee.toFixed(2),
    tax_amount: taxAcc.toFixed(2),
    pricing_details: `${currencySymbol}${addFee.toFixed(2)} (Base)`,
    tax_description: taxDescription.join('\n'),
    duration: addOn?.add_hours || 0,
  }
}

getAddonFee.propTypes = {
  service: PropTypes.object,
  addOn: PropTypes.object,
  currencySymbol: PropTypes.string,
}

export default getAddonFee
