import { alpha } from '@material-ui/core/styles'

// ----------------------------------------------------------------------

export default function Tooltip(theme) {
  const isLight = theme.palette.mode === 'light'

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: isLight
            ? theme.palette.glass.glassLight
            : theme.palette.glass.glassDarkGrey,
          border: '1px solid',
          borderColor: alpha(theme.palette.grey[200], 0.3),
          backdropFilter: 'blur(2px)',
          WebkitBackdropFilter: 'blur(2px)', // Fix on Mobile
          zIndex: `999 !important`,
          '&.MuiTooltip-tooltip': {
            '&.MuiTooltip-tooltipPlacementBottom': {
              marginTop: '4px',
            },
            '&.MuiTooltip-tooltipPlacementTop': {
              marginBottom: '4px',
            },
            '&.MuiTooltip-tooltipPlacementLeft': {
              marginRight: '4px',
            },
            '&.MuiTooltip-tooltipPlacementRight': {
              marginLeft: '4px',
            },
          },
        },
        arrow: {
          color: alpha(theme.palette.grey[isLight ? 800 : 700], 0.6),
          backdropFilter: 'blur(4px)',
          WebkitBackdropFilter: 'blur(4px)', // Fix on Mobile
          zIndex: `999 !important`,
        },
      },
    },
  }
}
