import { alpha } from '@material-ui/core/styles'
// ----------------------------------------------------------------------

export default function ToggleButton(theme) {
  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary,
          border: `none`,
          fontWeight: 600,
          fontSize: 14,
          borderRadius: `8px !important`,
          '&.Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.background.paper,
            borderRadius: `8px !important`,
            boxShadow: theme.customShadows.primary,
            '&:hover': {
              backgroundColor: theme.palette.background.paper,
            },
          },
          '&.Mui-disabled': {
            color: theme.palette.grey[500_48],
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          height: 40,
          padding: 5,
          backgroundColor: alpha(theme.palette.button.grey, 0.4),
          boxShadow: 'inset 0 0 4px 0 rgb(174 174 174 / 24%)',
        },
      },
    },
  }
}
