// ----------------------------------------------------------------------

function path(root, sublink) {
  const rootIsSingleRoute = root === '/'
  return rootIsSingleRoute ? sublink : `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
const ROOTS_DOCS = '/docs'
const ROOTS_DASHBOARD = '/'
const ROOTS_ADMIN = '/admin'
const ROOTS_INTEGRATION = '/integrations'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  verify: path(ROOTS_AUTH, '/verify'),
}

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
  appointmentrequest: '/appointment-request',
  scheduleAppointment: '/schedule-appointment',
  invoice: '/invoice',
  technicianEdit: '/edit-technician',
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/dashboard'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
  },
  business: {
    root: path(ROOTS_DASHBOARD, '/business'),
  },
  availability: {
    root: path(ROOTS_DASHBOARD, '/availability'),
  },
  onlinescheduler: {
    root: path(ROOTS_DASHBOARD, '/schedule'),
  },
  inspectionrequested: {
    root: path(ROOTS_DASHBOARD, '/inspection-requested'),
  },
  requestinspection: {
    root: path(ROOTS_DASHBOARD, '/appointment-request'),
  },
  companyprofile: {
    root: path(ROOTS_DASHBOARD, '/company-profile'),
  },
  services: {
    root: path(ROOTS_DASHBOARD, '/services'),
  },
  discountcodes: {
    root: path(ROOTS_DASHBOARD, '/discount-codes'),
    admin: path(ROOTS_DASHBOARD, '/discounts'),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  supportTickets: path(ROOTS_DASHBOARD, '/support-tickets'),
  communication: path(ROOTS_DASHBOARD, '/Communication'),
  publicProfile: path(ROOTS_DASHBOARD, '/public-profile'),
  sampleReports: path(ROOTS_DASHBOARD, '/sample-reports'),
  subscription: {
    root: path(ROOTS_DASHBOARD, '/subscription'),
    pastPayments: path(ROOTS_DASHBOARD, '/subscription/past-Payments'),
    upgrade: path(ROOTS_DASHBOARD, '/subscription/upgrade'),
    messageBundle: path(ROOTS_DASHBOARD, '/subscription/message-bundle'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    edit: path(ROOTS_DASHBOARD, '/user/edit'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(
      ROOTS_DASHBOARD,
      '/e-commerce/product/nike-air-force-1-ndestrukt',
    ),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(
      ROOTS_DASHBOARD,
      '/e-commerce/product/nike-blazer-low-77-vintage/edit',
    ),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  },
  templates: {
    root: path(ROOTS_DASHBOARD, '/templates'),
    edit: path(ROOTS_DASHBOARD, '/templates/edit'),
    store: path(ROOTS_DASHBOARD, 'templates/store'),
  },
  reports: {
    view: path(ROOTS_DASHBOARD, '/view-report'),
    edit: path(ROOTS_DASHBOARD, '/edit-report'),
    list: path(ROOTS_DASHBOARD, '/report-view'),
  },

  people: {
    root: path(ROOTS_DASHBOARD, '/people'),
    import: path(ROOTS_DASHBOARD, '/import'),
  },
  appointments: {
    root: path(ROOTS_DASHBOARD, '/appointments'),
    details: path(ROOTS_DASHBOARD, '/appointments/details'),
    cientView: path(ROOTS_DASHBOARD, '/client-view'),
    printOrder: path(ROOTS_DASHBOARD, '/appointments/print-order'),
    viewLog: path(ROOTS_DASHBOARD, '/appointments/view-log'),
  },
  integrations: {
    success: path(ROOTS_INTEGRATION, '/success'),
    error: path(ROOTS_INTEGRATION, '/error'),
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings'),
    availability: path(ROOTS_DASHBOARD, '/settings/availability'),
    timeTracking: path(ROOTS_DASHBOARD, '/time-tracking'),
    actions: path(ROOTS_DASHBOARD, '/actions'),
  },
  paysplits: {
    root: path(ROOTS_DASHBOARD, '/paysplits'),
    payreports: path(ROOTS_DASHBOARD, '/pay-reports'),
  },
  subscribers: {
    root: path(ROOTS_DASHBOARD, '/subscribers'),
    details: path(ROOTS_DASHBOARD, '/subscribers/details'),
  },
  subscriptionPlans: {
    root: path(ROOTS_DASHBOARD, '/subscription-plans'),
    msgPlans: path(ROOTS_DASHBOARD, '/messaging-plans'),
  },
  merchant: {
    root: path(ROOTS_DASHBOARD, '/create-merchant'),
  },
  agreement: {
    details: path(ROOTS_DASHBOARD, '/agreement/agreement-detail'),
  },
}
export const PATH_ADMIN = {
  root: ROOTS_ADMIN,

  subscriptionPlans: {
    root: path(ROOTS_ADMIN, '/subscription-plans'),
    msgPlans: path(ROOTS_ADMIN, '/messaging-plans'),
  },
  dashboard: path(ROOTS_ADMIN, '/dashboard'),
  subscribers: {
    root: path(ROOTS_ADMIN, '/subscribers'),
    details: path(ROOTS_ADMIN, '/subscribers/details'),
  },
  paymentHistory: path(ROOTS_ADMIN, '/payment-history'),
  discounts: path(ROOTS_ADMIN, '/discounts'),
  reports: {
    root: path(ROOTS_ADMIN, '/reports'),
    subscriberReport: path(ROOTS_ADMIN, '/reports/subscriber-reports'),
    paymentReport: path(ROOTS_ADMIN, '/reports/payment-reports'),
    subsReport: path(ROOTS_ADMIN, '/reports/subscription-reports'),
  },
  emailMgmt: {
    root: path(ROOTS_ADMIN, '/email-management'),
    edit: path(ROOTS_ADMIN, '/email-management/edit'),
  },
  settings: {
    root: path(ROOTS_ADMIN, '/settings'),
  },
  updates: {
    root: path(ROOTS_ADMIN, '/product-updates'),
  },
  trackEmail: {
    root: path(ROOTS_ADMIN, '/track-email'),
  },
}
export const PATH_DOCS = {
  root: ROOTS_DOCS,
  introduction: path(ROOTS_DOCS, '/introduction'),
  quickstart: path(ROOTS_DOCS, '/quick-start'),
  package: path(ROOTS_DOCS, '/package'),

  // Theme UI
  color: path(ROOTS_DOCS, '/color'),
  typography: path(ROOTS_DOCS, '/typography'),
  icon: path(ROOTS_DOCS, '/icon'),
  shadows: path(ROOTS_DOCS, '/shadows'),
  components: path(ROOTS_DOCS, '/components'),
  settings: path(ROOTS_DOCS, '/settings'),
  tips: path(ROOTS_DOCS, '/tips'),

  // Development
  routing: path(ROOTS_DOCS, '/routing'),
  environmentVariables: path(ROOTS_DOCS, '/environment-variables'),
  stateManagement: path(ROOTS_DOCS, '/state-management'),
  apiCalls: path(ROOTS_DOCS, '/api-calls'),
  analytics: path(ROOTS_DOCS, '/analytics'),
  authentication: path(ROOTS_DOCS, '/authentication'),
  multiLanguage: path(ROOTS_DOCS, '/multi-language'),
  lazyload: path(ROOTS_DOCS, '/lazyload-image'),
  formHelper: path(ROOTS_DOCS, '/form-helper'),

  // Changelog
  support: path(ROOTS_DOCS, '/support'),
  changelog: path(ROOTS_DOCS, '/changelog'),
}
