import { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'
import DashboardLayout from '../layouts/dashboard'

function AuthGuard() {
  const { user } = useContext(AuthContext)

  if (!user?.id) return <Navigate to="/auth/login" />

  return user?.user_type !== 5 ? (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  ) : (
    <Navigate to="/admin" /> // Super Admin (ReportON's owner)
  )
}

export default AuthGuard
