/* eslint-disable camelcase */
import getCurrencySymbol from '../utils/getCurrencySymbol'
import axiosApi from './_axiosApi'

// * Queries & Mutations *

// export const

export const getIsnInspectorsAndTemplates = (subscriberId) =>
  axiosApi
    .post('/users/get-inspectors-isn-by-id', {
      subscriber_id: subscriberId,
    })
    .then(transformISNData)

export const updateInspectorsIsn = (inspectorsData) =>
  axiosApi
    .post('/users/update-inspectors-isn', {
      inspectors_isn: inspectorsData,
    })
    .then((resp) => resp.data)

export const updateIsnTemplateMap = (subscriberId, IsnTemplateMap) =>
  axiosApi
    .post(`/users/update-user/${subscriberId}`, {
      integration_isn_template_map: IsnTemplateMap,
    })
    .then((res) => res.data)

export const updateIsnCredentials = (userId, credentials) =>
  axiosApi
    .post(`/users/update-user/${userId}`, {
      integration_isn: { enabled: true, credentials },
    })
    .then((res) => res.data)

/**
 * Get user appointment settings
 * @param {string} userId - User ID.
 */
export const fetchUserAppointmentSettings = (userId) =>
  axiosApi
    .post(`/users/get-by-id`, { user_id: userId })
    .then(transformUserSettings)

export const getUserPublicData = (subscriberId) => {
  return axiosApi
    .get(`/v2/company/${subscriberId}/profile`)
    .then((res) => res.data)
}

// * Transformers *

const transformISNData = (res) => {
  const { inspectors_isn: inspectors, templates } = res.data

  return {
    inspectors,
    templates: templates.map(({ id, name }) => ({
      value: id,
      key: id,
      label: name,
    })),
  }
}

const transformUserSettings = (res) => {
  const {
    local_timezone: timezone,
    local_currency: currencyCode,
    enable_inspection_events: acceptEvents,
    enable_actions: acceptActions,
    scheduling_email_settings: schedulingEmailSettings,
    report_email_settings: reportEmailSettings,
    pre_inspection_client_email_settings: clientPreNotifications,
    post_inspection_client_email_settings: clientPostNotifications,
    pre_inspection_agent_email_settings: agentPreNotifications,
    post_inspection_agent_email_settings: agentPostNotifications,
    pre_inspection_listing_agent_email_settings: listingAgentPreNotifications,
    post_inspection_listing_agent_email_settings: listingAgentPostNotifications,
    pre_inspection_contractors_email_settings: contractorPreNotifications,
    post_inspection_contractors_email_settings: contractorPostNotifications,
    inspection_agreements_fees_email_settings: agreementsFees,
    address_lat_long: addressLatLong,
    service_radius: serviceRadius,
    companyAddress,
    email_settings: emailSettings,
    company_name: companyName,
    first_name: firstName,
    last_name: lastName,
    phone,
    payment_type: paymentType,
    payment_credentials: paymentCredentials,
    square_credentials: squareCredentials,
    stripe_credentials: stripeCredentials,
    paypal_credentials: paypalCredentials,
    notified_when_payments_are_received,
    send_client_receipt_when_payment_is_received,
    payment_invoice_text,
    /* Scheduling/Scheduling options */
    capture_buyer_address,
    capture_agent_address,
    starting_appointment_id,
    /* Scheduling/Online Scheduler */
    enable_online_scheduling,
    dont_allow_scheduling_within,
    allow_choice_of_technicians,
    show_client_pricing_details,
    require_confirmation,
    confirmation_text,
    conversion_url,
    google_analytics_number,
    receive_an_email_for_completed_bookings,
    email_for_completed_bookings,
    receive_an_email_booking_is_in_progress,
    receive_text_message_for_completed_bookings,
    phone_for_completed_bookings,
    receive_text_message_booking_is_in_progress,
    /* Scheduling/Appointment Request Form */
    default_request_inspection_agreement,
    enable_inspection_request_form,
    default_request_inspection_name,
    default_request_inspection_price,
    default_request_inspection_start_time,
    default_request_inspection_duration,
    request_inspection_note_to_client,
    request_received_text,
    receive_email_for_new_inspection_request,
    email_for_new_inspection_request,
    receive_text_message_for_new_inspection_request,
    phone_for_new_inspection_request,
    /* Reporting/Default Location Tags */
    location_tags,
    /* Reporting/Default Recommendation List */
    template_recommendations,
    photo_quality,
    enable_cost_estimator,
    require_completion_to_publish,
    /* Reporting/Sample Reports */
    sample_reports,
    /* Reporting/Co-Branding */
    display_client_agent,
    display_listing_agent,
    display_contractor,
    /* User Settings/Calendar View */
    dashboard_settings,
    /* User Settings/Integration */
    google_calender,
    google_calender_id,
    google_calender_custom_text,
    /* Pages/Calendar */
    local_date_format /* Company Settings/Localization */,
    /* Company Settings/Social Links */
    social_links,
    /* Company Settings/Localization */
    country_code,
    /* Company Settings/Integrations */
    integration_google_drive,
    integration_quick_book,
    integration_mailchimp,
    integration_simple_solutions,
    integration_isn,
    integration_isn_template_map,
    /* Company Settings/Employee Tools & Options */
    enable_advance_metrics,
    enable_pay_splits,
    enable_hourly_tracking,
    enable_service_limitations,
    enable_service_areas,
    /* Public Profile */
    header_logo,
    signature,
    video_url,
    company_logo: companyLogo,
    profile_pic: profilePic,
  } = res.data

  return {
    timezone,
    currencySymbol: getCurrencySymbol(currencyCode),
    acceptEvents,
    acceptActions,
    addressLatLong,
    companyAddress,
    emailSettings,
    /* ToDo: Temporary, this was worked before, maybe some changes in the backend */
    defaultStartTime: default_request_inspection_start_time,
    defaultDuration: default_request_inspection_duration,
    defaultPrice: default_request_inspection_price,
    defaultAgreement: default_request_inspection_agreement,
    companyName,
    firstName,
    lastName,
    phone,
    companyLogo,
    profilePic,
    paymentType,
    paymentCredentials,
    squareCredentials,
    stripeCredentials,
    paypalCredentials,
    notified_when_payments_are_received,
    send_client_receipt_when_payment_is_received,
    payment_invoice_text,
    /* Scheduling/Scheduling options */
    capture_buyer_address,
    capture_agent_address,
    starting_appointment_id,
    /* Scheduling/Online Scheduler */
    enable_online_scheduling,
    dont_allow_scheduling_within,
    allow_choice_of_technicians,
    show_client_pricing_details,
    require_confirmation,
    confirmation_text,
    conversion_url,
    google_analytics_number,
    receive_an_email_for_completed_bookings,
    email_for_completed_bookings,
    receive_an_email_booking_is_in_progress,
    receive_text_message_for_completed_bookings,
    phone_for_completed_bookings,
    receive_text_message_booking_is_in_progress,
    /* Scheduling/Appointment Request Form */
    default_request_inspection_agreement,
    enable_inspection_request_form,
    default_request_inspection_name,
    default_request_inspection_price,
    default_request_inspection_start_time,
    default_request_inspection_duration,
    request_inspection_note_to_client,
    request_received_text,
    receive_email_for_new_inspection_request,
    email_for_new_inspection_request,
    receive_text_message_for_new_inspection_request,
    phone_for_new_inspection_request,
    /* Reporting/Default Location Tags */
    location_tags,
    /* Reporting/Default Recommendation List */
    template_recommendations,
    photo_quality,
    enable_cost_estimator,
    require_completion_to_publish,
    /* Reporting/Sample Reports */
    sample_reports,
    /* Reporting/Co-Branding */
    display_client_agent,
    display_listing_agent,
    display_contractor,
    /* User Settings/Calendar View */
    dashboard_settings,
    /* User Settings/Integration */
    google_calender,
    google_calender_id,
    google_calender_custom_text,
    /* Pages/Calendar */
    local_date_format,
    /* Company Settings/Social Links */
    social_links,
    /* Company Settings/Localization */
    local_timezone: timezone,
    local_currency: currencyCode,
    country_code,
    /* Company Settings/Integrations */
    integration_google_drive,
    integration_quick_book,
    integration_mailchimp,
    integration_simple_solutions,
    integration_isn,
    integration_isn_template_map,
    /* Company Settings/Employee Tools & Options */
    enable_actions: acceptActions,
    enable_inspection_events: acceptEvents,
    enable_advance_metrics,
    enable_pay_splits,
    enable_hourly_tracking,
    enable_service_limitations,
    enable_service_areas,
    /* Public Profile */
    header_logo,
    company_logo: companyLogo,
    profile_pic: profilePic,
    signature,
    video_url,
    notifications: {
      reportEmailSettings,
      schedulingEmailSettings,
      clientPreNotifications,
      clientPostNotifications,
      agentPreNotifications,
      agentPostNotifications,
      listingAgentPreNotifications,
      listingAgentPostNotifications,
      contractorPreNotifications,
      contractorPostNotifications,
      agreementsFees,
    },
  }
}
