/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import { Fragment, useMemo } from 'react'
import { Box, Typography } from '@material-ui/core'
import { actionTypes as ACTION_TYPES } from '../../../../../../../../../constants/action-variables'
import EmailDetails from './EmailDetails'
import TextDetails from './TextDetails'
import WebhookDetails from './WebhookDetails'

const AddDetails = ({ action, setAction }) => {
  const actionName = useMemo(
    () => ACTION_TYPES.find((type) => action.action_type === type.id).label,
    [action.action_type],
  )

  const updateAction = (fieldName, value) => {
    setAction({ ...action, [fieldName]: value })
  }

  return (
    <Fragment>
      <Typography variant="h6" sx={{ textAlign: 'center' }}>
        Provide details necessary to <strong>{actionName}</strong>
      </Typography>
      <Box sx={{ my: 2 }}>
        {(() => {
          switch (action.action_type) {
            // Send Email
            case 1:
              return (
                <EmailDetails
                  to_address={action.to_address}
                  from_address={action.from_address}
                  cc_address={action.cc_address}
                  bcc_address={action.bcc_address}
                  subject={action.subject}
                  body_content={action.body_content}
                  updateAction={updateAction}
                />
              )
            // Send Text
            case 2:
              return (
                <TextDetails
                  to_address={action.to_address}
                  body_content={action.body_content}
                  updateAction={updateAction}
                />
              )
            // Call Webhook
            case 3:
              return (
                <WebhookDetails
                  webhook_url={action.webhook_url}
                  webhook_data={action.webhook_data}
                  updateAction={updateAction}
                />
              )
            default:
              return 'Error handler => Unknown step'
          }
        })()}
      </Box>
    </Fragment>
  )
}

AddDetails.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.string,
    enable: PropTypes.bool,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    duration_type: PropTypes.string,
    trigger_type: PropTypes.string,
    event_type: PropTypes.number,
    trigger_once: PropTypes.bool,
    when_noti_disabled: PropTypes.bool,
    is_certain_hours: PropTypes.bool,
    certain_hours: PropTypes.array,
    no_weekends: PropTypes.bool,
    rules: PropTypes.array,
    action_name: PropTypes.string,
    action_type: PropTypes.number,
    to_address: PropTypes.array,
    from_address: PropTypes.number,
    cc_address: PropTypes.array,
    bcc_address: PropTypes.array,
    subject: PropTypes.string,
    body_content: PropTypes.string,
    webhook_url: PropTypes.string,
    webhook_data: PropTypes.array,
  }).isRequired,
  setAction: PropTypes.func,
}

export default AddDetails
