import React, { Fragment, useState } from 'react'
import { Tooltip } from '@material-ui/core'

import { TButton } from './Common'

const TooltipItem = (props) => {
  const [hover, setHover] = useState(null)
  const handleHoverOpen = (event) => {
    setHover(event.currentTarget)
  }

  const handleHoverClose = () => {
    setHover(null)
  }

  return (
    <Fragment>
      <Tooltip arrow title={props.item.body}>
        <TButton
          fullWidth
          size="small"
          onMouseEnter={handleHoverOpen}
          onMouseLeave={handleHoverClose}
          onClick={props.onClick}
        >
          {props.item.text}
        </TButton>
      </Tooltip>
    </Fragment>
  )
}

export default TooltipItem
