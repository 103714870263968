import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'
// utils

const initialState = {
  isAdmin: false,
  error: false,
  positions: [
    { id: uuid(), title: 'Metrics', md: 12 },
    { id: uuid(), title: 'Maps', md: 6 },
    { id: uuid(), title: 'Calendar', md: 6 },
    { id: uuid(), title: 'Appointments', md: 12 },
    { id: uuid(), title: 'Subscriptions', md: 6 },
    { id: uuid(), title: 'Product Updates', md: 6 },
  ],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
}

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // SwitchAdmin
    isAdministrator(state, action) {
      state.isAdmin = true
    },
    sortPosition(state, action) {
    },

    isActiveUser(state, action) {
      state.isAdmin = false
    },
  },
})

// Reducer
export default slice.reducer
// Actions
export const { sortPosition } = slice.actions
