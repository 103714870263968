/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import { memo } from 'react'
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@material-ui/core'
import { Icon } from '@iconify/react'
import dragHandle from '@iconify/icons-fa-solid/grip-lines-vertical'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ReactSortable } from 'react-sortablejs'
import update from 'immutability-helper'
import { AccordionHead, CenterGrid } from './ActionGroupAccordion.style'
import Label from '../../../../../../../Label'
import ActionDetails from './ActionDetails'

const ActionGroupAccordion = ({
  actionGroup,
  openEditAction,
  cloneAction,
  updateActions,
  updateOneAction,
  serviceOptions,
  serviceAddOnsOptions,
}) => {
  const theme = useTheme()
  const mdd = useMediaQuery(theme.breakpoints.down('md'))

  const { id: groupId, name, actions } = actionGroup

  const updateCurrentActions = (newActions) => {
    updateActions(groupId, newActions)
  }

  return (
    <Box sx={{ mb: 1 }}>
      <Accordion>
        <AccordionHead
          expandIcon={
            <Label sx={{ float: 'right', cursor: 'pointer' }}>
              {actions && actions.length
                ? `${actions.length} Actions`
                : 'No Actions'}
            </Label>
          }
        >
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                [theme.breakpoints.down('md')]: {
                  justifyContent: 'flex-end',
                },
              }}
            >
              <IconButton
                className="action-group-handler-wrapper"
                sx={{
                  cursor: 'move',
                  color: theme.palette.background.border,
                  marginRight: '8px',
                }}
              >
                <Icon icon={dragHandle} width={15} height={15} />
              </IconButton>
            </Box>
            <Typography variant="subtitle1">{name}</Typography>
          </Stack>
        </AccordionHead>
        <AccordionDetails sx={{ padding: '8px' }}>
          <Box sx={{ display: `${mdd ? 'none' : 'block'}`, ml: '41px' }}>
            <Grid container>
              <CenterGrid item xs={12} sm={1} />
              <CenterGrid item xs={12} sm={2}>
                <Typography variant="subtitle2">Type/Name</Typography>
              </CenterGrid>
              <CenterGrid item xs={12} sm={2}>
                <Typography variant="subtitle2">To</Typography>
              </CenterGrid>
              <CenterGrid item xs={12} sm={2}>
                <Typography variant="subtitle2">Event</Typography>
              </CenterGrid>
              <CenterGrid item xs={12} sm={2}>
                <Typography variant="subtitle2">Rules</Typography>
              </CenterGrid>
              <CenterGrid item xs={12} sm={2}>
                <Typography variant="subtitle2">Details</Typography>
              </CenterGrid>
            </Grid>
          </Box>
          <ReactSortable
            handle=".action-item-handler-wrapper"
            group="share"
            animation={200}
            list={actions || []}
            setList={updateCurrentActions}
          >
            {(actions || []).map((action, index) => (
              <ActionDetails
                // eslint-disable-next-line react/no-array-index-key
                key={`${action.id}--${index}`}
                groupId={groupId}
                action={action}
                updateOneAction={updateOneAction}
                openEditAction={openEditAction}
                cloneAction={cloneAction}
                serviceOptions={serviceOptions}
                serviceAddOnsOptions={serviceAddOnsOptions}
              />
            ))}
          </ReactSortable>
          <Box sx={{ mt: 2, mx: 'auto', textAlign: 'center' }}>
            <Button onClick={() => openEditAction(groupId, null, null)}>
              Add Action
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

ActionGroupAccordion.propTypes = {
  actionGroup: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    enable: PropTypes.bool,
    actions: PropTypes.array,
  }),
  openEditAction: PropTypes.func,
  cloneAction: PropTypes.func,
  updateActions: PropTypes.func,
  updateOneAction: PropTypes.func,
  serviceOptions: PropTypes.array,
  serviceAddOnsOptions: PropTypes.array,
}

export default memo(ActionGroupAccordion)
