import { Icon } from '@iconify/react'
import infoOutline from '@iconify/icons-eva/info-outline'
import Question from '@iconify-icons/radix-icons/question-mark-circled'
import { Tooltip } from '@material-ui/core'
import { useState } from 'react'
import { MIconButton } from './@material-extend'
import Support from './_support/ReportOnSupport'

function Info(props) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Tooltip title={props.title}>
        <MIconButton>
          <Icon
            icon={props.dash ? Question : infoOutline}
            onClick={props.dash && (() => setOpen(true))}
            width={15}
            height={15}
          />
        </MIconButton>
      </Tooltip>
      {props.dash && <Support open={open} handleClose={() => setOpen(false)} />}
    </>
  )
}
export default Info
