/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
import {
  Box,
  Card,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  Stack,
  Switch,
  Typography,
} from '@material-ui/core'
import { Icon } from '@iconify/react'
import DelIcon from '@iconify/icons-ic/delete'
import copyIcon from '@iconify-icons/akar-icons/copy'
import GripIcon from '@iconify/icons-fa-solid/grip-lines-vertical'
import { useTheme } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Fragment, useMemo } from 'react'
import { CenterGrid, DetailContainer } from './ActionGroupAccordion.style'
import {
  actionTypes as ACTION_TYPES,
  durationTypes as DURATION_TYPES,
} from '../../../../../../../../constants/action-variables'
import ACTIONS from '../../../../../../../../constants/actions'
import RULE_ACTIONS from '../../../../../../../../constants/rule_actions'

const ActionDetails = ({
  groupId,
  action,
  updateOneAction,
  hideDelete,
  openEditAction,
  cloneAction,
  serviceOptions,
  serviceAddOnsOptions,
}) => {
  const { action_type, duration_type, duration, trigger_type, event_type } =
    action

  const theme = useTheme()
  const mdd = useMediaQuery(theme.breakpoints.down('md'))

  const ACTION_OPTIONS = useMemo(
    () =>
      ACTIONS.reduce((accumulator, data) => {
        return [...accumulator, ...data.options]
      }, []),
    [],
  )

  const RULE_OPTIONS = useMemo(
    () =>
      RULE_ACTIONS.reduce((accumulator, data) => {
        return [...accumulator, ...data.options]
      }, []),
    [],
  )

  const values = useMemo(() => {
    return {
      actionType:
        ACTION_TYPES.find((type) => action_type === type.id).label || '',
      actionString:
        ACTION_OPTIONS.find(
          (action) => event_type === action.id,
        ).label.toLowerCase() || '',
      durationType:
        DURATION_TYPES.find((type) => duration_type === type.id).label || '',
      rulesString: RulesToStringArray(
        action.rules,
        RULE_OPTIONS,
        serviceOptions,
        serviceAddOnsOptions,
      ),
    }
  }, [action])

  const enableOrDisabelAction = () => {
    const newAction = { ...action, enable: !action.enable }
    updateOneAction(groupId, newAction)
  }

  return (
    <Card sx={{ my: 1, display: 'flex' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 0 0 10px',
          [theme.breakpoints.down('md')]: { justifyContent: 'flex-end' },
        }}
      >
        <IconButton
          className="action-item-handler-wrapper"
          sx={{ cursor: 'move' }}
        >
          <Icon
            icon={GripIcon}
            width={15}
            height={15}
            color={theme.palette.background.border}
          />
        </IconButton>
      </Box>
      <Grid container sx={{ my: 1 }}>
        <Grid
          item
          xs={12}
          md={1}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FormControlLabel
            labelPlacement="bottom"
            control={
              <Switch
                checked={action.enable}
                onChange={enableOrDisabelAction}
              />
            }
            label={
              <Typography variant="caption">
                {action.enable ? 'Enabled' : 'Disabled'}
              </Typography>
            }
            sx={{
              margin: '0px',
              [theme.breakpoints.down('md')]: { float: 'right' },
            }}
          />
        </Grid>
        <CenterGrid item xs={12} md={2}>
          <DetailContainer onClick={() => openEditAction(groupId, action, 3)}>
            {/* Action Type */}
            <Typography variant="caption">{values.actionType}</Typography>
          </DetailContainer>
        </CenterGrid>
        <CenterGrid item xs={12} md={2}>
          <DetailContainer onClick={() => openEditAction(groupId, action, 4)}>
            {/* Send To */}
            <List
              sx={{
                width: '100%',
                padding: '0',
                textAlign: 'left',
              }}
            >
              {action.to_address.map(({ value }) => {
                if (!value) return null

                return (
                  <li key={`to-address-${value}`}>
                    <Typography
                      variant="caption"
                      sx={{
                        display: 'flex',
                        lineHeight: '1.2',
                        paddingLeft: '7px',
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          position: 'absolute',
                          left: 0,
                          lineHeight: '1.2',
                          fontWeight: 500,
                        }}
                      >
                        •
                      </Typography>
                      {/* Capitalize the first letter of the value (string) */}
                      {value.charAt(0).toUpperCase() +
                        value.slice(1).replace('_', ' ')}
                    </Typography>
                  </li>
                )
              })}
            </List>
          </DetailContainer>
        </CenterGrid>
        <CenterGrid item xs={12} md={2}>
          <DetailContainer onClick={() => openEditAction(groupId, action, 1)}>
            {/* Event String */}
            <Typography
              variant="caption"
              sx={{ lineHeight: '1.2', textAlign: 'left' }}
            >
              {`${duration} ${values.durationType} ${trigger_type} ${values.actionString}`}
            </Typography>
          </DetailContainer>
        </CenterGrid>
        <CenterGrid item xs={12} md={2}>
          <DetailContainer onClick={() => openEditAction(groupId, action, 2)}>
            {/* Rules in string */}
            {!action.rules.length ? (
              <Typography variant="caption">On all appointments</Typography>
            ) : (
              <List
                sx={{
                  width: '100%',
                  padding: '0',
                  textAlign: 'left',
                  lineHeight: '1.2',
                }}
              >
                {values?.rulesString?.map((ruleString) => (
                  <li key={ruleString}>
                    <Typography
                      variant="caption"
                      sx={{
                        display: 'flex',
                        lineHeight: '1.2',
                        paddingLeft: '7px',
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          position: 'absolute',
                          left: 0,
                          lineHeight: '1.2',
                          fontWeight: 500,
                        }}
                      >
                        •
                      </Typography>
                      {ruleString}
                    </Typography>
                  </li>
                ))}
              </List>
            )}
          </DetailContainer>
        </CenterGrid>
        <CenterGrid item xs={12} md={2}>
          <DetailContainer
            onClick={() => openEditAction(groupId, action, 4)}
            sx={{ justifyContent: 'flex-start' }}
          >
            <Typography
              variant="caption"
              sx={{ lineHeight: '1.2', textAlign: 'left' }}
            >
              {(() => {
                switch (action_type) {
                  case 1:
                    return (
                      <Fragment>
                        <em style={{ fontWeight: 500 }}>Subject: </em>
                        <span>{action.subject}</span>
                      </Fragment>
                    )
                  case 2:
                    return (
                      <Fragment>
                        <em style={{ fontWeight: 500 }}>SMS: </em>
                        <span>Click here to see the text to send</span>
                      </Fragment>
                    )
                  case 3:
                    return (
                      <Fragment>
                        <em style={{ fontWeight: 500 }}>URL: </em>
                        <span>{action.webhook_url}</span>
                      </Fragment>
                    )
                  default:
                    return 'Unknown action_type'
                }
              })()}
            </Typography>
          </DetailContainer>
        </CenterGrid>
        <Grid item xs={12} md={1}>
          <Stack
            direction={{ xs: 'row' }}
            sx={{
              justifyContent: 'center',
              mr: '10px',
              alignItems: 'center',
              height: `100%`,
              [theme.breakpoints.down('md')]: {
                justifyContent: 'flex-end',
              },
            }}
          >
            <IconButton onClick={() => cloneAction(groupId, action)}>
              <Icon icon={copyIcon} width={15} height={15} />
            </IconButton>
            {!hideDelete && (
              <IconButton
                onClick={() => {
                  // del('delete', actionData)
                }}
              >
                <Icon icon={DelIcon} width={15} height={15} />
              </IconButton>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Card>
  )
}

ActionDetails.propTypes = {
  groupId: PropTypes.any,
  action: PropTypes.shape({
    id: PropTypes.string,
    enable: PropTypes.bool,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    duration_type: PropTypes.string,
    trigger_type: PropTypes.string,
    event_type: PropTypes.number,
    trigger_once: PropTypes.bool,
    when_noti_disabled: PropTypes.bool,
    is_certain_hours: PropTypes.bool,
    certain_hours: PropTypes.array,
    no_weekends: PropTypes.bool,
    rules: PropTypes.array,
    action_name: PropTypes.string,
    action_type: PropTypes.number,
    to_address: PropTypes.array,
    from_address: PropTypes.number,
    cc_address: PropTypes.array,
    bcc_address: PropTypes.array,
    subject: PropTypes.string,
    body_content: PropTypes.string,
    webhook_url: PropTypes.string,
    webhook_data: PropTypes.array,
  }).isRequired,
  updateOneAction: PropTypes.func,
  hideDelete: PropTypes.bool,
  openEditAction: PropTypes.func,
  cloneAction: PropTypes.func,
  serviceOptions: PropTypes.array,
  serviceAddOnsOptions: PropTypes.array,
}

ActionDetails.defaultProps = {
  hideDelete: true,
}

export default ActionDetails

const RulesToStringArray = (
  rules,
  RULE_OPTIONS,
  serviceOptions,
  addOnsOptions,
) => {
  return rules.map((rule) => {
    const { trigger_type, event_type, report_name } = rule

    const action = RULE_OPTIONS.find((option) => option.id === event_type)

    const ruleString = []
    ruleString.push(trigger_type === 'has' ? 'Has' : "Doesn't have")

    switch (event_type) {
      case 1:
      case 2: {
        ruleString.push(action.label.toLowerCase())
        break
      }
      case 3: {
        const fieldType = action.label.toLowerCase()
        // Get field name
        const field = action.options[0].options.find(
          (opt) => opt.id === rule.field,
        )
        const fieldName = field.label.toLowerCase()
        // Get operator
        let operator = field.options[0].options.find(
          (opt) => opt.id === rule.operator,
        )
        operator = operator.label.toLowerCase()
        // Get value
        let value = ''
        if (field.id === 1) {
          // Value from option list
          const option = field.options[1].options.find(
            (opt) => opt.id === rule.numeric,
          )
          value = option?.label?.toLowerCase() || '_unknown_'
        } else {
          // Custom value (manually set)
          value = rule.numeric
        }
        ruleString.push(`${fieldType} "${fieldName}" ${operator} ${value}`)
        break
      }
      case 4:
      case 6: {
        const field = event_type === 4 ? 'services' : 'changed_services'
        const value = rule[field]
        const service = serviceOptions.find((s) => s.id === value)
        if (service) {
          const serviceName = service.label.toLowerCase()
          const type = event_type === 4 ? 'service' : 'new service'
          ruleString.push(`${type} "${serviceName}"`)
        }
        break
      }
      case 5:
      case 7: {
        const field =
          event_type === 5 ? 'service_addons' : 'changed_service_addons'
        const value = rule[field]
        const addOn = addOnsOptions.find((a) => a.id === value)
        if (addOn) {
          const addOnName = addOn.label.toLowerCase()
          const type =
            event_type === 5 ? 'service add on' : 'new service add on'
          ruleString.push(`${type} "${addOnName}"`)
        }
        break
      }
      case 8: {
        const word = (report_name || '').toLowerCase()
        ruleString.push(`report attachment name contains "${word}"`)
        break
      }
      default: {
        ruleString.push(`__unknown__`)
        break
      }
    }

    // Convert and return array into string
    return `${ruleString.join(' ')}.`
  })
}
