import PropTypes from 'prop-types'
import { useState } from 'react'
import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import SupportForm from '../../components/_support/ReportOnSupport'
import { ReactComponent as Support } from '../../assets/svgs/headset.svg'

function SupportTicket({ closeMenu }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <MenuItem
        sx={{ typography: 'body2', py: 1, px: 2.5 }}
        onClick={() => {
          setOpen(true)
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: '20px !important',
            justifyContent: 'center',
            margin: '0 12px 0 0',
          }}
        >
          <Support height={18} style={{ fill: '#637481' }} />
        </ListItemIcon>
        <ListItemText
          primary="Support"
          primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
        />
      </MenuItem>
      <SupportForm
        open={open}
        handleClose={() => {
          closeMenu()
          setOpen(false)
        }}
      />
    </>
  )
}

SupportTicket.propTypes = {
  closeMenu: PropTypes.func.isRequired,
}

export default SupportTicket
