import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isEmpty from '../../../../../utils/is-empty'

dayjs.extend(utc)
dayjs.extend(timezone)

const validateDateTime = (dateTime, timezone) => {
  const errors = {}

  if (isEmpty(dateTime)) {
    errors.dateTime = 'Date & time are required'
  }

  if (!dayjs(dateTime).isValid()) {
    errors.dateTime = 'Date needs to be valid'
  }

  if (
    !dayjs
      .tz(dateTime, timezone)
      .utc()
      .isAfter(dayjs.tz(new Date(), timezone).utc())
  ) {
    errors.dateTime = 'Date & Time needs to be future date'
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

export default validateDateTime
