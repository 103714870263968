import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

const useDebounce = ({ val, delay }) => {
  const [debounceVal, setDebounceVal] = useState(val)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceVal(val)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [val])

  return debounceVal
}

useDebounce.propTypes = {
  val: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired,
}

export default useDebounce
