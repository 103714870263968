import { alpha } from '@material-ui/core/styles'

// ----------------------------------------------------------------------

export default function Backdrop(theme) {
  const varHigh = alpha(theme.palette.grey[900], 0.7)

  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: varHigh,
          '&.MuiBackdrop-invisible': {
            background: 'transparent',
          },
        },
      },
    },
  }
}
