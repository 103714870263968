/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import { useContext, useState, useEffect, Fragment } from 'react'
import InputMask from 'react-input-mask'
import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import {
  getTechnician,
  createTechnician,
  updateTechnician,
} from '../../../../services/technician.service'
import { AuthContext } from '../../../../contexts/AuthContext'

const TechnicianForm = ({
  action,
  profilePic,
  setAvatarSrc,
  personBasicData,
  closeModal,
  setBlocking,
  handleSuccess,
}) => {
  const { user } = useContext(AuthContext)
  const [data, setData] = useState(initialValues[action])
  const [errors, setErrors] = useState({})
  const { enqueueSnackbar } = useSnackbar()
  const subscriberId = user?.subscriber_id || ''

  useEffect(() => {
    if (action === 'edit') {
      const { id: technicianId, profile_pic } = personBasicData
      if (profile_pic) setAvatarSrc(profile_pic)
      getTechnician({ subscriberId, technicianId }).then((resp) => {
        if (resp.success) {
          const { status, id, profile_pic, ...technician } = resp.data
          setData(technician)
          if (profile_pic) setAvatarSrc(profile_pic)
        }
      })
    }
  }, [])

  const onChange = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }

  const onPastePhone = (e) => {
    e.preventDefault()
    const value = e.clipboardData.getData('text')
    const digits = value.replace(/\D+/g, '')
    const phone = digits.slice(digits.length - 10)
    setData({ ...data, phone })
  }

  const onBooleanChange = (e) => {
    const { name, checked } = e.target
    setData({ ...data, [name]: checked })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setBlocking(true)

    // Convert data into multipart/form-data content type
    const formData = new FormData()
    formData.append('subscriberId', subscriberId)
    if (profilePic) formData.append('profile_pic', profilePic)
    Object.keys(initialValues[action]).forEach((key) => {
      const value = key === 'phone' ? data[key].replace(/\D/g, '') : data[key]
      formData.append(key, value)
    })

    // Get technician full name
    const { first_name: firstname, last_name: lastname } = data
    const fullName = lastname ? `${firstname} ${lastname}`.trim() : firstname

    if (action === 'edit') {
      // Try to update existing technician
      try {
        const query = { technicianId: personBasicData.id, formData }
        const { success, data: tech, error } = await updateTechnician(query)
        if (success) {
          const successMsg = `The technician "${fullName}" has been updated successfully!`
          enqueueSnackbar(successMsg, { variant: 'success' })
          handleSuccess(tech) // Update single technician
          setData(initialValues.edit) // reset form
          closeModal()
        } else {
          const { type, details, message } = error
          if (type === 'BAD_USER_INPUT') setErrors(details)
          else enqueueSnackbar(message, { variant: 'error' })
        }
      } catch (_err) {
        const errMessage = `Something went wrong! Please try it later or contact us for support.`
        enqueueSnackbar(errMessage, { variant: 'error' })
      } finally {
        setBlocking(false)
      }
    } else {
      // Try to save new technician
      try {
        const { success, data: tech, error } = await createTechnician(formData)
        if (success) {
          const successMsg = `The technician "${fullName}" has been added successfully!`
          enqueueSnackbar(successMsg, { variant: 'success' })
          handleSuccess(tech) // Push new technician
          setData(initialValues.add) // reset form
          closeModal()
        } else {
          const { type, details, message } = error
          if (type === 'BAD_USER_INPUT') setErrors(details)
          else enqueueSnackbar(message, { variant: 'error' })
        }
      } catch (_err) {
        const errMessage = `Something went wrong! Please try it later or contact us for support.`
        enqueueSnackbar(errMessage, { variant: 'error' })
      } finally {
        setBlocking(false)
      }
    }
  }

  return (
    <Fragment>
      <Typography
        variant="h6"
        sx={{ pb: 3, textAlign: 'center', lineHeight: 1 }}
      >
        {action === 'add' ? 'New' : 'Edit'} Technician
      </Typography>
      <form noValidate onSubmit={onSubmit}>
        <Stack spacing={3}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 3, sm: 2 }}
          >
            <TextField
              fullWidth
              name="first_name"
              label="First Name"
              size="small"
              value={data.first_name}
              onChange={onChange}
              error={Boolean(errors.first_name)}
              helperText={errors.first_name}
            />
            <TextField
              fullWidth
              name="last_name"
              label="Last Name"
              size="small"
              value={data.last_name}
              onChange={onChange}
              error={Boolean(errors.last_name)}
              helperText={errors.last_name}
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 3, sm: 2 }}
          >
            <TextField
              fullWidth
              type="email"
              name="email"
              label="Email Address"
              size="small"
              value={data.email}
              onChange={onChange}
              error={Boolean(errors.email)}
              helperText={errors.email}
              inputProps={{ inputMode: 'email' }}
            />
            <InputMask
              fullWidth
              name="phone"
              label="Phone Number"
              size="small"
              mask="(999) 999-9999"
              maskChar=" "
              alwaysShowMask={false}
              value={data.phone}
              onPaste={onPastePhone}
              onChange={onChange}
              error={Boolean(errors.phone)}
              helperText={errors.phone}
              inputProps={{ inputMode: 'numeric' }}
            >
              {(props) => <TextField {...props} />}
            </InputMask>
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 3, sm: 2 }}
          >
            <TextField
              type="color"
              name="color"
              label="Color"
              size="small"
              value={data.color}
              onChange={onChange}
              style={{
                width: '64px',
              }}
            />
          </Stack>
          {action === 'add' && (
            <Fragment>
              <Stack
                sx={{ mt: '32px !important' }}
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 3, sm: 2 }}
              >
                <FormControlLabel
                  sx={{
                    ml: 0,
                    width: '100%',
                    borderBottom: '1px solid #dbe0e4',
                    paddingBottom: '8px',
                    '.MuiFormControlLabel-label': {
                      width: 'calc(100% - 58px)',
                      userSelect: 'none',
                    },
                    '.MuiSwitch-root': {
                      marginLeft: '6px',
                      marginRight: '-6px',
                    },
                  }}
                  control={
                    <Switch
                      color="primary"
                      name="can_schedule_new"
                      checked={data.can_schedule_new}
                      onChange={onBooleanChange}
                    />
                  }
                  label={
                    <Box width="100%">
                      <Typography variant="subtitle2">
                        Schedule appointments
                      </Typography>
                      <p
                        style={{
                          fontSize: 11.5,
                          lineHeight: 1.2,
                          color: 'gray',
                        }}
                      >
                        Allow this technician to schedule new appointments.
                      </p>
                    </Box>
                  }
                  labelPlacement="start"
                />
              </Stack>
              <Stack
                sx={{ mt: '8px !important' }}
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 3, sm: 2 }}
              >
                <FormControlLabel
                  sx={{
                    ml: 0,
                    width: '100%',
                    borderBottom: '1px solid #dbe0e4',
                    paddingBottom: '8px',
                    '.MuiFormControlLabel-label': {
                      width: 'calc(100% - 58px)',
                      userSelect: 'none',
                    },
                    '.MuiSwitch-root': {
                      marginLeft: '6px',
                      marginRight: '-6px',
                    },
                  }}
                  control={
                    <Switch
                      color="primary"
                      name="can_publish_report"
                      checked={data.can_publish_report}
                      onChange={onBooleanChange}
                    />
                  }
                  label={
                    <Box width="100%">
                      <Typography variant="subtitle2">
                        Publish reports
                      </Typography>
                      <p
                        style={{
                          fontSize: 11.5,
                          lineHeight: 1.2,
                          color: 'gray',
                        }}
                      >
                        Allow this technician to publish reports without
                        approval from a company admin.
                      </p>
                    </Box>
                  }
                  labelPlacement="start"
                />
              </Stack>
              <Stack
                sx={{ mt: '8px !important' }}
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 3, sm: 2 }}
              >
                <FormControlLabel
                  sx={{
                    ml: 0,
                    width: '100%',
                    borderBottom: '1px solid #dbe0e4',
                    paddingBottom: '8px',
                    '.MuiFormControlLabel-label': {
                      width: 'calc(100% - 58px)',
                      userSelect: 'none',
                    },
                    '.MuiSwitch-root': {
                      marginLeft: '6px',
                      marginRight: '-6px',
                    },
                  }}
                  control={
                    <Switch
                      color="primary"
                      name="can_edit_template"
                      checked={data.can_edit_template}
                      onChange={onBooleanChange}
                    />
                  }
                  label={
                    <Box width="100%">
                      <Typography variant="subtitle2">
                        Edit templates
                      </Typography>
                      <p
                        style={{
                          fontSize: 11.5,
                          lineHeight: 1.2,
                          color: 'gray',
                        }}
                      >
                        Allow this technician to have access to the template
                        editor.
                      </p>
                    </Box>
                  }
                  labelPlacement="start"
                />
              </Stack>
              <Stack
                sx={{ mt: '8px !important' }}
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 3, sm: 2 }}
              >
                <FormControlLabel
                  sx={{
                    ml: 0,
                    width: '100%',
                    borderBottom: '1px solid #dbe0e4',
                    paddingBottom: '8px',
                    '.MuiFormControlLabel-label': {
                      width: 'calc(100% - 58px)',
                      userSelect: 'none',
                    },
                    '.MuiSwitch-root': {
                      marginLeft: '6px',
                      marginRight: '-6px',
                    },
                  }}
                  control={
                    <Switch
                      color="primary"
                      name="can_add_new_comment"
                      checked={data.can_add_new_comment}
                      onChange={onBooleanChange}
                    />
                  }
                  label={
                    <Box width="100%">
                      <Typography variant="subtitle2">
                        New content to templates
                      </Typography>
                      <p
                        style={{
                          fontSize: 11.5,
                          lineHeight: 1.2,
                          color: 'gray',
                        }}
                      >
                        Allow this technician to add new sections, items and
                        comments to templates through the editor.
                      </p>
                    </Box>
                  }
                  labelPlacement="start"
                />
              </Stack>
              <Stack
                sx={{ mt: '8px !important' }}
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 3, sm: 2 }}
              >
                <FormControlLabel
                  sx={{
                    ml: 0,
                    width: '100%',
                    borderBottom: '1px solid #dbe0e4',
                    paddingBottom: '8px',
                    '.MuiFormControlLabel-label': {
                      width: 'calc(100% - 58px)',
                      userSelect: 'none',
                    },
                    '.MuiSwitch-root': {
                      marginLeft: '6px',
                      marginRight: '-6px',
                    },
                  }}
                  value="start"
                  control={
                    <Switch
                      color="primary"
                      name="full_company_admin"
                      checked={data.full_company_admin}
                      onChange={onBooleanChange}
                    />
                  }
                  label={
                    <Box width="100%">
                      <Typography variant="subtitle2">Admin access</Typography>
                      <p
                        style={{
                          fontSize: 11.5,
                          lineHeight: 1.2,
                          color: 'gray',
                        }}
                      >
                        Give full privileges to publish reports, add/remove team
                        members, view metrics, modify email templates, and
                        modify subscriptions.
                      </p>
                    </Box>
                  }
                  labelPlacement="start"
                />
              </Stack>
              <Stack
                sx={{ mt: '8px !important' }}
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 3, sm: 2 }}
              >
                <FormControlLabel
                  sx={{
                    ml: 0,
                    width: '100%',
                    // borderBottom: '1px solid #dbe0e4',
                    paddingBottom: '8px',
                    '.MuiFormControlLabel-label': {
                      width: 'calc(100% - 58px)',
                      userSelect: 'none',
                    },
                    '.MuiSwitch-root': {
                      marginLeft: '6px',
                      marginRight: '-6px',
                    },
                  }}
                  value="start"
                  control={
                    <Switch
                      color="primary"
                      name="show_appointment_price"
                      checked={data.show_appointment_price}
                      onChange={onBooleanChange}
                    />
                  }
                  label={
                    <Box width="100%">
                      <Typography variant="subtitle2">
                        Prices on mobile app
                      </Typography>
                      <p
                        style={{
                          fontSize: 11.5,
                          lineHeight: 1.2,
                          color: 'gray',
                        }}
                      >
                        Shows prices on appointment details in the mobile app.
                      </p>
                    </Box>
                  }
                  labelPlacement="start"
                />
              </Stack>
            </Fragment>
          )}
        </Stack>
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <Button type="submit" variant="contained">
            {action === 'add' ? 'Add' : 'Update'} Technician
          </Button>
        </Box>
      </form>
    </Fragment>
  )
}

const initialValues = {
  add: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    can_schedule_new: true,
    can_publish_report: true,
    can_edit_template: true,
    can_add_new_comment: true,
    full_company_admin: true,
    show_appointment_price: true,
  },
  edit: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    color: '#B9DDFFFF',
  },
}

TechnicianForm.defaultProps = {
  setAvatarSrc: () => {},
}

TechnicianForm.propTypes = {
  action: PropTypes.oneOf(['add', 'edit']).isRequired,
  profilePic: PropTypes.object,
  setAvatarSrc: PropTypes.func,
  personBasicData: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  setBlocking: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
}

export default TechnicianForm
