import axiosApi from './_axiosApi'

export const createStaff = (formData) =>
  axiosApi
    .post('/v2/staffs', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data)

export const getStaffList = ({ subscriberId, cursor, perPage }) =>
  axiosApi
    .get('/v2/staffs', { params: { subscriberId, cursor, perPage } })
    .then((res) => res.data)

export const searchStaffs = ({ subscriberId, cursor, perPage, text }) =>
  axiosApi
    .get('/v2/staffs', { params: { subscriberId, cursor, perPage, text } })
    .then((res) => res.data)

export const getStaff = ({ subscriberId, staffId }) =>
  axiosApi
    .get(`/v2/staffs/${staffId}`, { params: { subscriberId } })
    .then((res) => res.data)

export const getStaffPerms = ({ subscriberId, staffId }) =>
  axiosApi
    .get(`/v2/staffs/${staffId}/perms`, { params: { subscriberId } })
    .then((res) => res.data)

export const updateStaff = ({ staffId, formData }) =>
  axiosApi
    .put(`/v2/staffs/${staffId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data)

export const changeStaffStatus = ({ staffId, subscriberId, newStatus }) =>
  axiosApi
    .post(`/v2/staffs/${staffId}/status`, { subscriberId, newStatus })
    .then((res) => res.data)

export const updateStaffPerms = ({ staffId, ...newData }) =>
  axiosApi.put(`/v2/staffs/${staffId}/perms`, newData).then((res) => res.data)

export const deleteStaff = ({ staffId, subscriberId }) =>
  axiosApi
    .delete(`v2/staffs/${staffId}`, { data: { subscriberId } })
    .then((res) => res.data)

export const exportStaffs = (subscriberId) =>
  axiosApi
    .get('/v2/staffs/export-file', {
      params: { subscriberId },
      responseType: 'blob',
    })
    .then((res) => {
      // Extract "filename" and "mime-type" from "header" response
      const contentDisposition = res.headers['content-disposition']
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1]
        : ''
      const mimeType = res.headers['content-type']

      return {
        blob: res.data,
        mimeType,
        filename,
      }
    })
