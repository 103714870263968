const CURRENCY_RELATION = {
  1: '$',
  2: 'C$',
  3: 'A$',
  4: 'NZ$',
}

const getCurrencySymbol = (currencyCode) =>
  CURRENCY_RELATION[currencyCode] || '$'

module.exports = getCurrencySymbol
