/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  Button,
  Card,
  Grid,
  IconButton,
  ListSubheader,
  MenuItem,
  Stack,
  TextField,
} from '@material-ui/core'
import { v4 as uuid } from 'uuid'
import update from 'immutability-helper'
import { Icon } from '@iconify/react'
import closeOutline from '@iconify/icons-eva/close-outline'
import RULE_ACTIONS from '../../../../../../../../constants/rule_actions'
import isEmpty from '../../../../../../../../utils/is-empty'

const SERVICE_TYPES = {
  services: 'service',
  changed_services: 'service',
  service_addons: 'serviceAddons',
  changed_service_addons: 'serviceAddons',
}

const AddRules = ({
  action,
  setAction,
  serviceOptions,
  serviceAddOnsOptions,
}) => {
  const RULE_OPTIONS = useMemo(
    () =>
      RULE_ACTIONS.reduce((accumulator, data) => {
        return [...accumulator, ...data.options]
      }, []),
    [],
  )

  const addRule = () => {
    setAction({
      ...action,
      rules: update(action.rules, {
        $push: [{ ...newRuleState, id: uuid() }],
      }),
    })
  }

  const updateRule = (e) => {
    const { value, name } = e.target
    const [field, index] = name.split('---')
    setAction({
      ...action,
      rules: update(action.rules, {
        [index]: { $merge: { [field]: value } },
      }),
    })
  }

  const removeRule = (index) => {
    setAction({
      ...action,
      rules: update(action.rules, { $splice: [[index, 1]] }),
    })
  }

  const handleExtraFieldChange = (e, currentNumeric) => {
    const { value, name } = e.target
    const [field, index] = name.split('---')
    /**
     * This next logic is from the original code.
     * I really don't know what means "numeric" value
     */
    let numeric = currentNumeric
    if (field === 'field') {
      numeric = value === 1 ? value : ''
    }
    setAction({
      ...action,
      rules: update(action.rules, {
        [index]: { $merge: { [field]: value, numeric } },
      }),
    })
  }

  return (
    <Card
      sx={{
        mb: '20px',
        overflow: 'visible',
        boxShadow: 'none',
        borderRadius: '0px',
      }}
    >
      {action.rules.length ? (
        action.rules.map((rule, index1) => {
          // Get action options
          const actionOptions = RULE_ACTIONS.filter((item) => {
            const eventType = parseInt(action.event_type, 2)
            const itemEventTypes = item.event_type
            return [eventType, 0].some((val) => itemEventTypes.includes(val))
          })

          // Get current rule extra options
          const ruleExtraOptions =
            RULE_OPTIONS.find((item) => item.id === rule.event_type)?.options ||
            []

          // For each rule added
          return (
            <Card key={rule.id} sx={{ p: 1, mb: 2, borderRadius: '10px' }}>
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                sx={{ mb: 1 }}
                spacing={1}
              >
                <TextField
                  select
                  fullWidth
                  name={`trigger_type---${index1}`}
                  size="small"
                  value={rule.trigger_type}
                  onChange={updateRule}
                >
                  <MenuItem value="has">Has</MenuItem>
                  <MenuItem value="has_not">Has Not</MenuItem>
                </TextField>
                <TextField
                  select
                  fullWidth
                  name={`event_type---${index1}`}
                  size="small"
                  value={rule.event_type}
                  onChange={updateRule}
                >
                  {actionOptions.map((row) => {
                    return [
                      <ListSubheader key={row.key}>
                        {[4, 8].includes(action.event_type)
                          ? row.label.replace('Added', 'Removed')
                          : row.label}
                      </ListSubheader>,
                      row.options.map((r) => (
                        <MenuItem value={r.id} key={r.id}>
                          {r.label}
                        </MenuItem>
                      )),
                    ]
                  })}
                </TextField>
                <IconButton
                  sx={{ height: '36px', width: '36px' }}
                  onClick={() => removeRule(index1)}
                >
                  <Icon
                    icon={closeOutline}
                    widh={20}
                    height={20}
                    color="#c80303"
                  />
                </IconButton>
              </Stack>
              <Grid container spacing={1}>
                {/* For each rule extra option (field) */}
                {ruleExtraOptions.map((extraField) => {
                  const { id, value: fieldName, type, options } = extraField

                  const serviceType = SERVICE_TYPES[fieldName] || 'other'

                  const ruleSubExtraOptions =
                    options.find((opt) => opt.id === rule[fieldName])
                      ?.options || []

                  return (
                    <Fragment key={`ruleaction-options-option-${id}`}>
                      <Grid item xs={12} md={4}>
                        {type === 'select' ? (
                          <TextField
                            select
                            fullWidth
                            name={`${fieldName}---${index1}`}
                            size="small"
                            value={rule[fieldName]}
                            onChange={(e) =>
                              handleExtraFieldChange(e, rule.numeric)
                            }
                          >
                            {options.map((row) => {
                              return (
                                !isEmpty(row.label) && (
                                  <MenuItem key={row.id} value={row.id}>
                                    {row.label}
                                  </MenuItem>
                                )
                              )
                            })}
                            {serviceType === 'service' &&
                              serviceOptions.map((row) => {
                                return (
                                  <MenuItem key={row.id} value={row.id}>
                                    {row.label}
                                  </MenuItem>
                                )
                              })}
                            {serviceType === 'serviceAddons' &&
                              serviceAddOnsOptions.map((row) => {
                                return (
                                  <MenuItem key={row.id} value={row.id}>
                                    {row.label}
                                  </MenuItem>
                                )
                              })}
                          </TextField>
                        ) : (
                          <TextField
                            fullWidth
                            name={`${fieldName}---${index1}`}
                            type={type}
                            size="small"
                            value={rule[fieldName]}
                            onChange={(e) =>
                              handleExtraFieldChange(e, rule.numeric)
                            }
                          />
                        )}
                      </Grid>
                      {/* For each rule sub extra option (field) */}
                      {ruleSubExtraOptions.map((subExtraField) => {
                        const {
                          id,
                          value: fieldName,
                          type,
                          options,
                        } = subExtraField

                        if (type === 'select') {
                          return (
                            <Grid
                              item
                              xs={12}
                              md={4}
                              key={`subExtraField-${id}`}
                            >
                              <TextField
                                select
                                fullWidth
                                name={`${fieldName}---${index1}`}
                                size="small"
                                value={rule[fieldName]}
                                onChange={updateRule}
                              >
                                {options.map((row) => {
                                  return (
                                    <MenuItem value={row.id} key={row.id}>
                                      {row.label}
                                    </MenuItem>
                                  )
                                })}
                              </TextField>
                            </Grid>
                          )
                        }
                        return (
                          <Grid item xs={12} md={4} key={`subExtraField-${id}`}>
                            <TextField
                              fullWidth
                              name={`${fieldName}---${index1}`}
                              type={type}
                              size="small"
                              value={rule[fieldName]}
                              onChange={updateRule}
                            />
                          </Grid>
                        )
                      })}
                    </Fragment>
                  )
                })}
              </Grid>
            </Card>
          )
        })
      ) : (
        <Alert
          severity="info"
          sx={{ justifyContent: 'center', mb: 2, padding: '9px 16px' }}
        >
          Without rules, this action will happen for <b>EVERY</b> appointment!
        </Alert>
      )}
      <Button sx={{ transform: 'scale(1) !important' }} onClick={addRule}>
        Add Rules
      </Button>
    </Card>
  )
}

const newRuleState = {
  id: '',
  trigger_type: 'has',
  event_type: 1,
  field: 1,
  operator: 1,
  numeric: 1,
  services: '',
  service_addons: '',
}

AddRules.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.string,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    duration_type: PropTypes.string,
    trigger_type: PropTypes.string,
    event_type: PropTypes.number,
    trigger_once: PropTypes.bool,
    when_noti_disabled: PropTypes.bool,
    is_certain_hours: PropTypes.bool,
    certain_hours: PropTypes.array,
    no_weekends: PropTypes.bool,
    rules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        trigger_type: PropTypes.string,
        event_type: PropTypes.number,
        field: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        operator: PropTypes.number,
        numeric: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        services: PropTypes.string,
        service_addons: PropTypes.string,
      }),
    ).isRequired,
    action_name: PropTypes.string,
    action_type: PropTypes.number,
    to_address: PropTypes.array,
    from_address: PropTypes.number,
    cc_address: PropTypes.array,
    bcc_address: PropTypes.array,
    subject: PropTypes.string,
    body_content: PropTypes.string,
    webhook_url: PropTypes.string,
    webhook_data: PropTypes.array,
  }).isRequired,
  setAction: PropTypes.func,
  serviceOptions: PropTypes.array,
  serviceAddOnsOptions: PropTypes.array,
}

export default AddRules
