import { useState } from 'react'
import { Icon } from '@iconify/react'
import roundFullscreen from '@iconify/icons-ic/round-fullscreen'
import roundFullscreenExit from '@iconify/icons-ic/round-fullscreen-exit'
// material
import { alpha } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'

// ----------------------------------------------------------------------

export default function SettingFullscreen() {
  const [fullscreen, setFullscreen] = useState(false)

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
      setFullscreen(true)
    } else if (document.exitFullscreen) {
      document.exitFullscreen()
      setFullscreen(false)
    }
  }

  return (
    <IconButton
      onClick={toggleFullScreen}
      sx={{
        fontSize: 14,
        ...(fullscreen && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.selectedOpacity,
            ),
        }),
      }}
    >
      <Icon
        icon={fullscreen ? roundFullscreenExit : roundFullscreen}
        height={18}
        width={18}
      />
    </IconButton>
  )
}
