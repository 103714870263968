// ----------------------------------------------------------------------

import { alpha } from '@material-ui/core'

export default function Button(theme) {
  const isLight = theme.palette.mode === 'light'
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          background: isLight
            ? alpha(theme.palette.primary.main, 0.9)
            : theme.palette.glass.glassDark,
          border: isLight ? 'none' : '1px solid',
          borderColor: alpha(theme.palette.grey[100], 0.2),
          color: `#fff !important`,
          padding: theme.spacing(1, 3),
          boxShadow: isLight
            ? theme.customShadows.primary
            : theme.customShadows.z16,
          borderRadius: `10px`,
          fontWeight: '600',
          transition: '0.8s',
          '&:hover': {
            background: isLight
              ? alpha(theme.palette.primary.main, 0.9)
              : theme.palette.glass.glassDark,
            color: '#fff',
            boxShadow: theme.customShadows.primary,
          },
          '&:disabled': {
            background: theme.palette.action.disabledBackground,
            color: `${theme.palette.text.secondary} !important`,
            opacity: 0.6,
          },
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: `${theme.palette.primary.main} !important`,
          background: `transparent !important`,
          boxShadow: `none`,
          border: 'none',
          '&:hover': {
            background: alpha(theme.palette.primary.light, 0.3),
            boxShadow: `none`,
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          background: isLight
            ? alpha(theme.palette.secondary.main, 0.9)
            : theme.palette.glass.glassDarkSecondary,
          boxShadow: isLight
            ? theme.customShadows.secondary
            : theme.customShadows.z16,
          '&:hover': {
            background: isLight
              ? alpha(theme.palette.secondary.main, 0.9)
              : theme.palette.glass.glassDarkSecondary,
            boxShadow: theme.customShadows.secondary,
          },
        },
        containedError: {
          background: isLight
            ? alpha(theme.palette.error.main, 0.9)
            : theme.palette.glass.glassDarkError,
          boxShadow: isLight
            ? theme.customShadows.error
            : theme.customShadows.z16,
          '&:hover': {
            background: isLight
              ? alpha(theme.palette.error.main, 0.9)
              : theme.palette.glass.glassDarkError,
            boxShadow: theme.customShadows.error,
          },
        },
        containedInfo: {
          background: isLight
            ? alpha(theme.palette.info.main, 0.9)
            : theme.palette.glass.glassDarkInfo,
          boxShadow: isLight
            ? theme.customShadows.info
            : theme.customShadows.z16,
          '&:hover': {
            background: isLight
              ? alpha(theme.palette.info.main, 0.9)
              : theme.palette.glass.glassDarkInfo,
            boxShadow: theme.customShadows.info,
          },
        },
        containedGrey: {
          background: isLight
            ? alpha(theme.palette.button.grey, 0.7)
            : theme.palette.glass.glassDarkGrey,
          color: `${theme.palette.text.primary} !important`,
          boxShadow: isLight
            ? theme.customShadows.z24
            : theme.customShadows.z16,
          '&:hover': {
            background: isLight
              ? alpha(theme.palette.button.grey, 0.9)
              : theme.palette.glass.glassDarkGrey,
            boxShadow: theme.shadows[10],
          },
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            background: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            background: theme.palette.action.hover,
          },
        },
      },
    },
  }
}
