/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import isEmpty from '../../../../../utils/is-empty'
import validateLocationData from './validateLocationData'
import validateDateTime from './validateDateTime'

const validateData = ({
  location: locationData,
  dateTime: date,
  people: peopleData,
  information: informationData,
  services: servicesData,
  userTimeZone,
}) => {
  // TODO: Improve validator

  let accErrors = {}

  // Location - validator
  const location = validateLocationData(locationData)
  if (!location.isValid && !locationData.square_feet) {
    // const msg = `Street, street number and postal code are required`
    const msg = `Location: Please enter a location (address) and square feets`
    accErrors = { ...accErrors, location: msg }
  }

  // Date & Time - validator
  const dateTime = validateDateTime(date, userTimeZone)
  if (!dateTime.isValid) {
    const msg = `Date & Time: Please select a future date`
    accErrors = { ...accErrors, dateTime: msg }
  }

  // People - Validator
  const { clients } = peopleData
  if (!clients.length) {
    const msg = 'People: Please select at least one client'
    accErrors = { ...accErrors, people: msg }
  }

  // Information - validator
  const { order_id } = informationData
  if (!order_id) {
    const msg = 'Information: order Id is required'
    accErrors = { ...accErrors, information: msg }
  }

  // Services - validator
  const { newServices } = servicesData
  const servicesList = newServices.fees
  if (!servicesList.length) {
    const msg = `Services: no service(s) has been chosen`
    accErrors = { ...accErrors, services: msg }
  }

  return {
    errors: accErrors,
    isValid: isEmpty(accErrors),
  }
}

validateData.propTypes = {
  location: PropTypes.object.isRequired,
  dateTime: PropTypes.string.isRequired,
  people: PropTypes.object.isRequired,
  information: PropTypes.object.isRequired,
  services: PropTypes.object.isRequired,
}

export default validateData
