import PropTypes from 'prop-types'
// material
import { Box } from '@material-ui/core'

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
}

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="0 0 300 300"
      >
        <g>
          <polygon
            fill="#747779"
            points="160.604,0 25,78.291 25,234.88 61.741,256.096 60.383,101.554 198.439,21.85 	"
          />
          <polygon
            fill="#747779"
            points="156.865,158.954 216.322,124.627 216.322,136.239 156.865,170.566 	"
          />
          <polygon
            fill="#747779"
            points="156.865,183.861 216.322,149.533 216.322,161.137 156.865,195.472 	"
          />
          <polygon
            fill="#747779"
            points="156.865,208.759 216.322,174.432 216.322,186.043 156.865,220.37 	"
          />
          <path
            fill="#F26822"
            d="M140.69,301.352l135.604-78.291V66.48l-42.042-24.271L96.893,121.508v154.565L140.69,301.352
		L140.69,301.352z M132.475,265.008V142.057l108.237-62.496v122.96L132.475,265.008L132.475,265.008z"
          />
        </g>
      </svg>
    </Box>
  )
}
