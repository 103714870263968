export const getUserLastPageVisited = (state) => {
  return state.userv2.lastPageVisited
}

export const getUserLoading = (state) => {
  return state.userv2.isLoading
}

export const getUserData = (state) => {
  return state.userv2
}

export const getUserSettings = (state) => {
  return state.userv2.userSettings
}

export const getUserAppointmentSettings = (state) => {
  return state.userv2.userAppointmentSettings
}

export const getUserUserDataFetched = (state) => {
  return state.userv2.fetchUserData
}
export const getUserUserDataFetchedSuccess = (state) => {
  return state.userv2.fetchUserDataSuccess
}
