import { experimentalStyled as styled } from '@material-ui/core/styles'
import { AccordionSummary, Grid } from '@material-ui/core'

export const CenterGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const AccordionHead = styled(AccordionSummary)(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'none !important',
  },
  '& .Mui-expanded': {
    '& span': {
      color: 'white !important',
    },
  },
}))

export const DetailContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  height: `100%`,
  width: `100%`,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.background.border}`,
  margin: '0 5px',
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  textAlign: 'center',
  fontSize: 12,
  padding: theme.spacing(1),
  flexWrap: 'wrap',
  wordBreak: 'break-all',
  alignContent: 'center',
}))
