import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { experimentalStyled as styled } from '@material-ui/core/styles'
import DashboardNavbar from './DashboardNavbar'
import DashboardSidebar from './DashboardSidebar'
import AdminSub from './AdminSub'

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 64

const RootStyle = styled('div')(
  {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
  },
  { index: 99 },
)

const MainStyle = styled('div')(
  ({ theme }) => ({
    flexGrow: 1,
    overflowX: 'auto',
    height: '100%',
    paddingTop: APP_BAR_MOBILE + 0,
    [theme.breakpoints.up('lg')]: {
      paddingTop: APP_BAR_DESKTOP + 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }),
  { index: 1 },
)

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false)
  const [isCompact, setCompact] = useState(false)
  const [isAdmin] = useState(false)

  return (
    <RootStyle sx={{ paddingTop: isAdmin && `30px` }}>
      {isAdmin && <AdminSub />}
      <DashboardNavbar
        onOpenSidebar={() => setOpen(true)}
        isCompact={isCompact}
      />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
        isCompact={isCompact}
        setCompact={setCompact}
      />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  )
}
