import * as Yup from 'yup'
import { useContext, useState } from 'react'
import {
  Box,
  Card,
  FormControlLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  experimentalStyled as styled,
  useTheme,
} from '@material-ui/core/styles'
// utils
import { Form, FormikProvider, useFormik } from 'formik'
import swal from 'sweetalert'
import BlockUi from 'react-block-ui'
import 'react-block-ui/style.css'
import { useSnackbar } from 'notistack'
import Loader from '../Loader'
import { projectTitle } from '../../constants/defaultValues'
import DialogAnimate from '../animate/DialogAnimate'
import { MButton } from '../@material-extend'
import { createSupportTicket } from '../../services/support.service'
import { AuthContext } from '../../contexts/AuthContext'

const StyledUpload = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: 8,
  border: `1px solid #dfdfdf`,
}))

const severityOptions = [
  {
    label: 'Minor Issue',
    value: 1,
  },
  {
    label: 'Major Issue',
    value: 2,
  },
  {
    label: 'Text change',
    value: 3,
  },
  {
    label: 'Tweak Needed',
    value: 4,
  },
  {
    label: 'Feature / Function request',
    value: 5,
  },
]

export default function AddImage({ open, handleClose }) {
  const theme = useTheme()
  // fileData upload states and handlers
  const [file, setFile] = useState(null)
  const [blocking, setblocking] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const UpdateUserSchema = Yup.object().shape({
    description: Yup.string().required('description is required'),
    title: Yup.string().required('Title is required'),
    severityValue: Yup.string().required('severity is required'),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: '',
      title: '',
      severityValue: '',
    },

    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      setblocking(true)

      // Convert data into multipart/form-data content type
      const formData = new FormData()
      Object.keys(values).forEach((key) => formData.append(key, values[key]))
      formData.append('file', file)

      createSupportTicket(formData)
        .then((res) => {
          if (res.success) {
            swal({
              title: 'Ticket sent!',
              icon: 'success',
            })
            handleClose()
            resetForm()
            setFile(null)
          } else {
            swal({
              title: 'Error while sending Ticket',
              icon: 'error',
            })
          }
        })
        .catch((error) => {
          swal({
            title: 'Error while sending Ticket',
            icon: 'error',
          })
        })
        .finally(() => {
          setblocking(false)
        })
    },
  })

  const { errors, touched, handleSubmit, getFieldProps } = formik

  const filehandler = ({ target: { files } }) => {
    const newFile = files[0]
    if (!newFile) return
    setFile(newFile)
  }

  return (
    <>
      <DialogAnimate open={open} onClose={handleClose}>
        <FormikProvider value={formik}>
          <Form
            autoComplete="off"
            id="supportTicketForm"
            noValidate
            onSubmit={handleSubmit}
          >
            <Card sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
              <BlockUi
                tag="div"
                blocking={blocking}
                loader={<Loader />}
                message="Please wait"
                keepInView
              >
                <Typography
                  variant="h6"
                  sx={{ mx: 'auto', width: 'fit-content', mb: 1 }}
                >
                  {projectTitle} Support
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  sx={{ mx: 'auto', width: 'fit-content', mb: 1 }}
                >
                  Submit New Ticket
                </Typography>
                <Stack sx={{ my: 3 }} spacing={2}>
                  <TextField
                    fullWidth
                    name="name"
                    label="Issue Title *"
                    placeholder="Issue Title"
                    size="small"
                    {...getFieldProps('title')}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                  />
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    name="description"
                    label="Issue Description *"
                    placeholder="Issue Description"
                    size="small"
                    {...getFieldProps('description')}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                  <TextField
                    fullWidth
                    select
                    label="Severity *"
                    size="small"
                    placeholder="choose"
                    {...getFieldProps('severityValue')}
                    error={Boolean(
                      touched.severityValue && errors.severityValue,
                    )}
                    helperText={touched.severityValue && errors.severityValue}
                  >
                    {severityOptions.map((option) => (
                      <MenuItem
                        key={`severity-${option.value}`}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Stack spacing={1}>
                    <Typography variant="subtitle2">
                      Please upload any supporting files/pictures.
                    </Typography>
                    <StyledUpload container>
                      <Grid item xs={8}>
                        <Box sx={{ overflow: 'hidden' }}>
                          <Typography
                            variant="body1"
                            sx={{ whiteSpace: 'pre' }}
                            color="#a4a4a4"
                          >
                            {file ? file.name : 'Click Browse to Choose File'}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControlLabel
                          fullWidth
                          name="files"
                          id="files"
                          {...getFieldProps('files')}
                          control={
                            <OutlinedInput
                              type="file"
                              sx={{ display: 'none' }}
                            />
                          }
                          label={
                            <Typography variant="body1">Browse</Typography>
                          }
                          sx={{
                            background: '#fff',
                            color: theme.palette.primary.main,
                            paddingLeft: `10px`,
                            float: 'right',
                            borderLeft: `1px solid ${theme.palette.text.secondary}`,
                          }}
                          onChange={filehandler}
                        />
                      </Grid>
                    </StyledUpload>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    pt: 3,
                    mx: 'auto',
                    maxWidth: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <MButton sx={{ width: `50%` }} type="submit">
                    Save
                  </MButton>
                  {/* <MButton
                                        sx={{ width: `50%` }}
                                        variant='contained'
                                        color='secondary'
                                        onClick={() => navigateTo(PATH_DASHBOARD.supportTickets)}
                                    >Support Tickets</MButton> */}
                </Stack>
              </BlockUi>
            </Card>
          </Form>
        </FormikProvider>
      </DialogAnimate>
    </>
  )
}
