import { useReducer, createContext } from 'react'
import { useDispatch } from 'react-redux'
import { setAccessToken } from '../accessToken'
import { getMe, logOut } from '../services/auth.service'
import { fetchUserAppointmentSettingsAsyncThunk } from '../redux/reducers/user'

const initialState = {
  user: null,
}

const AuthContext = createContext({
  user: null,
  login: () => {},
  logout: () => {},
})

function authReducer(state, action) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: action.payload,
      }
    case 'LOGOUT':
      return {
        ...state,
        user: null,
      }
    default:
      return state
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState)
  const dispatch2 = useDispatch()

  const login = async (accessToken) => {
    let userData = null
    try {
      setAccessToken(accessToken)
      const { data } = await getMe()
      const { subscriber_id: subscriberId } = data
      // Temporal
      dispatch2(fetchUserAppointmentSettingsAsyncThunk(subscriberId))
      userData = data
    } catch (_err) {
      // In case token is null or invalid...
      userData = null
    }

    dispatch({ type: 'LOGIN', payload: userData })
  }

  const updateSession = async () => {
    const { data: userData } = await getMe()
    dispatch({ type: 'LOGIN', payload: userData })
  }

  const logout = async () => {
    await logOut()
    setAccessToken('')
    dispatch({ type: 'LOGOUT' })
  }

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        login,
        logout,
        updateSession,
      }}
      {...props}
    />
  )
}

export { AuthContext, AuthProvider }
