import PropTypes from 'prop-types'
import { useContext, useState, useRef, useEffect } from 'react'
import {
  Button,
  Card,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Datepicker } from '@mobiscroll/react'
import { getTechnicianList } from '../../../../../../services/technician.service'
import { getCalendarSelectedDateTime } from '../../../../../../redux/selectors/calendar'
import { setSelectedDateTime } from '../../../../../../redux/reducers/calendar'
import { setAppointmentSelectedTechnician } from '../../../../../../redux/reducers/appointments'
import { AuthContext } from '../../../../../../contexts/AuthContext'

dayjs.extend(customParseFormat)
const AddNewTimeSlot = ({
  selectedDate,
  setDateTime,
  selectedEvent,
  setSelectedEvent,
  nextStep,
  setMySelectedDate,
}) => {
  const { user } = useContext(AuthContext)
  const [technicians, setTechnicians] = useState([])
  const [addForm, setAddForm] = useState({
    timeSlotStart: '',
    technician: null,
  })
  const [errors, setErrors] = useState({
    timeSlotStart: false,
    technician: false,
  })
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const interviewDateRef = useRef()
  const selectedDateStore = useSelector(getCalendarSelectedDateTime)
  const dispatch = useDispatch()
  const subscriberId = user?.subscriber_id || null

  useEffect(() => {
    getTechnicianList({
      subscriberId,
      cursor: null,
      limit: 100,
    })
      .then((res) => {
        const tmpTechnicians = res.data.pageData
        setTechnicians(tmpTechnicians)

        if (selectedEvent) {
          const tmpIndex = tmpTechnicians.findIndex(
            (item) => selectedEvent && item.id === selectedEvent?.user?.id,
          )
          setSelectedIndex(tmpIndex)
        }
      })
      .catch((err) => {
        console.log(err)
      })

    if (selectedEvent) {
      setAddForm({
        ...addForm,
        technician: selectedEvent.user,
        timeSlotStart: dayjs(selectedEvent.selectedDate).format('HH:mm'),
      })
      setMySelectedDate(dayjs(selectedEvent.selectedDate).toDate())
    }
  }, [])

  const handleAddTimeSlot = () => {
    setErrors({
      timeSlotStart: false,
      technicianId: false,
    })
    if (!addForm.timeSlotStart || addForm.timeSlotStart.trim().length === 0) {
      setErrors({ ...errors, timeSlotStart: true })
      return
    }
    if (!addForm.technician || !addForm.technician.id) {
      setErrors({ ...errors, technician: true })
      return
    }

    const newDay = dayjs(selectedDate).format('YYYY/MM/DD')
    const time = dayjs(
      `${newDay} ${addForm.timeSlotStart}`,
      'YYYY/MM/DD HH:mm',
    ).format('hh:mm A')
    setDateTime(dayjs(`${newDay} ${time}`).format())
    setSelectedEvent({
      user: addForm.technician,
      selectedDate: dayjs(`${newDay} ${time}`).format(),
    })
    dispatch(setAppointmentSelectedTechnician(addForm.technician.id))
    nextStep()
  }

  useEffect(() => {
    if (!!selectedDateStore) {
      setAddForm({
        ...addForm,
        timeSlotStart: dayjs(selectedDateStore).format('HH:mm'),
      })
      setMySelectedDate(dayjs(selectedDateStore).toDate())
      dispatch(setSelectedDateTime({ selectedDateTime: null }))
    }
  }, [selectedDateStore])

  const onChangeTimePickerRange = (event) => {
    setAddForm({
      ...addForm,
      timeSlotStart: dayjs(event.value).format('HH:mm'),
    })
  }

  return (
    <Card
      sx={{
        padding: '32px 32px 32px 24px',
      }}
    >
      <FormControl fullWidth sx={{ m: 1 }} error={errors.timeSlotStart}>
        <Datepicker
          theme="ios"
          themeVariant="light"
          controls={['time']}
          returnFormat="jsdate"
          label="Time Slot Start"
          placeholder="Select the date and time for the event"
          dataTimezone="utc"
          displayTimezone="local"
          style={{ cursor: 'pointer' }}
          startInput={null}
          endInput={null}
          stepMinute={15}
          onChange={onChangeTimePickerRange}
        />
      </FormControl>
      <FormControl fullWidth sx={{ m: 1 }} error={errors.technician}>
        <Typography variant="caption">Select Technician</Typography>
        <Select
          label="Technician"
          placeholder="Select Technician"
          sx={{ color: '#000' }}
          onChange={(e) => {
            setAddForm({ ...addForm, technician: technicians[e.target.value] })
            setSelectedIndex(+e.target.value)
          }}
          value={selectedIndex}
        >
          {technicians.map((technician, index) => (
            <MenuItem
              key={technician.id}
              value={index}
              placeholder={`${technician.first_name} ${technician.last_name}`}
            >{`${technician.first_name} ${technician.last_name}`}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 2 }}>
        <Button onClick={() => handleAddTimeSlot()}>Use this timeslot</Button>
      </FormControl>
    </Card>
  )
}
AddNewTimeSlot.propTypes = {
  selectedDate: PropTypes.string,
  setDateTime: PropTypes.func.isRequired,
  selectedEvent: PropTypes.oneOfType([PropTypes.object]),
  setSelectedEvent: PropTypes.func.isRequired,
  nextStep: PropTypes.func,
  setMySelectedDate: PropTypes.func,
}
export default AddNewTimeSlot
