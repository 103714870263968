import axiosApi from './_axiosApi'

export const addTimeSlot = (
  subscriberId,
  technicianId,
  userId,
  startTime,
  days,
) =>
  axiosApi
    .post(`/v2/availability`, {
      subscriber_id: subscriberId,
      team_id: technicianId,
      added_by: userId,
      start_time: startTime,
      days,
    })
    .then((res) => res.data)
export const deleteTimeSlots = (subscriberId, timeSlotsIds) =>
  axiosApi
    .delete(`/v2/availability`, {
      data: {
        subscriberId,
        timeSlotsIds,
      },
    })
    .then((res) => res.data)

export const getAvailabilityFromSubscriber = (subscriberId) =>
  axiosApi.get(`/v2/availability/${subscriberId}`).then((res) => res.data)
