import { useContext, useState, useEffect, lazy } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Load from './utils/loadable2'
import { refreshToken } from './services/auth.service'
import { AuthContext } from './contexts/AuthContext'
import AuthRedirect from './routes/AuthRedirect'
import AuthGuard from './routes/AuthGuard'
import AdminGuard from './routes/AdminGuard'
import LoadingScreen from './components/LoadingScreen'
import SuccessPage from './pages/Statuses/SuccessPage'
import ErrorPage from './pages/Statuses/ErrorPage'

// * Auth Routes *
const Login = Load(lazy(() => import('./pages/auth/Login')))
// * Public Routes *
const ComingSoon = Load(lazy(() => import('./pages/ComingSoon')))
const Maintenance = Load(lazy(() => import('./pages/Maintenance')))
const Register = Load(lazy(() => import('./pages/auth/Register')))
const ForgotPass = Load(lazy(() => import('./pages/auth/ForgotPassword')))
const ResetPassword = Load(lazy(() => import('./pages/auth/ResetPassword')))
const VerifyCode = Load(lazy(() => import('./pages/auth/VerifyCode')))
const VerifyEmail = Load(
  lazy(() => import('./pages/authentication/ActivatePage')),
)
const DashboardLayout = Load(lazy(() => import('./layouts/dashboard')))
const FullScreenLayout = Load(lazy(() => import('./layouts/fullScreenLayout')))
const ViewReport = Load(lazy(() => import('./pages/Reports/ViewReport')))
const NewViewReport = Load(lazy(() => import('./pages/Reports/ViewReport2')))
const ReportList = Load(lazy(() => import('./pages/Reports/ReportListView')))
const ForceResetPassword = Load(
  lazy(() => import('./pages/auth/ForceResetPassword')),
)
const AppointmentRequest = Load(
  lazy(() => import('./pages/AppoinmentRequest/myAppointmentRequest')),
)
const ScheduleAppointments = Load(
  lazy(() => import('./pages/OnlineScheduler/ScheduleAppointment')),
)
const AgreementDetail = Load(
  lazy(() => import('./pages/Agreements/Agreements')),
)
const AppointmentClient = Load(
  lazy(() => import('./pages/Appointments/AppointmentClientView')),
)
const AppointmentClient1 = Load(
  lazy(() => import('./features/Appointments/ClientView/ClientView')),
)
const PublicCompanyProfile = Load(
  lazy(() => import('./pages/companyProfile/publicProfile')),
)
const PublicSampleReport = Load(
  lazy(() => import('./pages/companyProfile/sampleReports')),
)
const ReportPdf = Load(lazy(() => import('./pages/Reports/ReportPdf')))
const ReviewPdf = Load(lazy(() => import('./pages/Reports/ReviewPdf')))
const PrivacyPolicy = Load(lazy(() => import('./pages/public/PrivacyPolicy')))
const TermsOfUse = Load(lazy(() => import('./pages/public/TermsOfUse')))
const ReporVideoPlayer = Load(lazy(() => import('./pages/ReporVideoPlayer')))
const ShortenedUrl = Load(lazy(() => import('./components/ShortenedUrl')))
// * Private Routes (Main Views) *
const Dashboard = Load(lazy(() => import('./pages/dashboard/GeneralApp2')))
// const Templates = Load(lazy(() => import('./pages/Templates/template')))
const TemplatesV2 = Load(lazy(() => import('./pages/Templates')))
const EditTemplates = Load(lazy(() => import('./pages/Templates/editTemplate')))
const Business = Load(lazy(() => import('./pages/Business/Business-v2')))
const CompanyProfile = Load(
  lazy(() => import('./pages/companyProfile/CompanyProfile')),
)
const People = Load(lazy(() => import('./pages/people')))
const Communication = Load(
  lazy(() => import('./pages/Communication/Communication')),
)
const Appointments = Load(
  lazy(() => import('./pages/Appointments/Appointments3')),
)
const Settings = Load(lazy(() => import('./pages/Settings/settings')))
const EditTechnician = Load(lazy(() => import('./pages/User/EditTechnician')))
const TimeTracking = Load(lazy(() => import('./pages/Settings/Timetracking')))
const Calendar = Load(lazy(() => import('./pages/Calendar/Calendar')))
const Subscription = Load(
  lazy(() => import('./pages/subscription/subscription')),
)
const CreateMerchant = Load(
  lazy(() => import('./components/CreateMerchant/CreateMerchant')),
)
const Services = Load(lazy(() => import('./pages/services/services')))
const DiscountCodes = Load(
  lazy(() => import('./pages/discountCodes/discountCodes')),
)
const AvailabilityPage = Load(
  lazy(() => import('./pages/Availability/AvailbilityPage')),
)
const SupportTickets = Load(
  lazy(() => import('./pages/SupportTickets/TicketListing')),
)
// const PaySplits = Load(lazy(() => import('./pages/paysplits/paysplits')))
const PaySplitsV2 = Load(
  lazy(() => import('./components/_new/settings/PaySplits2')),
)
const Actions = Load(lazy(() => import('./pages/Actions/Actions')))
const Automations = Load(
  lazy(() => import('./components/_new/settings/Automations/Automations')),
)
const AppointmentDetails = Load(
  lazy(() => import('./pages/Appointments/AppointmentDetails2')),
)
const PrintOrder = Load(lazy(() => import('./pages/Appointments/PrintOrder')))
const ViewLog = Load(lazy(() => import('./pages/Appointments/ViewLog')))
const PastPay = Load(lazy(() => import('./pages/subscription/pastPayments')))
const UpgradePlan = Load(lazy(() => import('./pages/subscription/UpgradePlan')))
const MessageBundle = Load(
  lazy(() => import('./pages/subscription/MessageBundle/UpgradePlan')),
)
const EditReport = Load(lazy(() => import('./pages/Reports/EditReport')))
const ImportPeople = Load(lazy(() => import('./pages/import/importAgents')))
// * Private Routes (Sub Views) *
const UserEdit = Load(lazy(() => import('./pages/User/EditUser')))
const TemplateStore = Load(
  lazy(() => import('./pages/Templates/TemplateStore')),
)
const EditTemplate = Load(lazy(() => import('./pages/Templates/editTemplate')))
const ApptDetails = Load(
  lazy(() => import('./pages/Appointments/AppointmentDetails2')),
)
const ApptDetails3 = Load(lazy(() => import('./pages/_appointments/details')))
const NotFound = Load(lazy(() => import('./pages/Page404')))
const Invoice = Load(lazy(() => import('./pages/Invoice/Invoice')))
//
// * Admin Routes *
const AdminDashboard = Load(
  lazy(() => import('./pages/dashboard/AdminDashboard')),
)
const SubPlans = Load(lazy(() => import('./pages/AdminSpecific/Subscriptions')))
const MsgPlans = Load(
  lazy(() => import('./pages/AdminSpecific/MessageBundles')),
)
const DiscountAdmin = Load(
  lazy(() => import('./pages/AdminSpecific/Discounts')),
)
const PaymentHistory = Load(
  lazy(() => import('./pages/AdminSpecific/Payments')),
)
const SettingsAdmin = Load(lazy(() => import('./pages/AdminSpecific/Settings')))
const Updates = Load(lazy(() => import('./pages/AdminSpecific/ProductUpdates')))
const TrackEmail = Load(lazy(() => import('./pages/AdminSpecific/TrackEmail')))
const SubscriberReport = Load(
  lazy(() => import('./pages/AdminSpecific/SubscriberReports')),
)
const AdminSubs = Load(lazy(() => import('./pages/AdminSpecific/Subscribers')))
const EmailManagementList = Load(
  lazy(() => import('./pages/AdminSpecific/Emailmgmt')),
)
const PaymentReport = Load(
  lazy(() => import('./pages/AdminSpecific/PaymentReports')),
)
const SubsReport = Load(
  lazy(() => import('./pages/AdminSpecific/SubscriptionReports')),
)
const AdminSubDetails = Load(
  lazy(() => import('./pages/AdminSpecific/SubscriberDetails')),
)
const EmailEdit = Load(lazy(() => import('./pages/AdminSpecific/EmailEdit')))

// IFrame Wrapper
const NewAppointmentIFrame = Load(
  lazy(() => import('./components/iframe/NewAppointmentIFrame')),
)

const PaymentSelector = Load(
  lazy(() => import('./pages/Payments/PaymentSelector')),
)

function App() {
  const context = useContext(AuthContext)
  const [loading, setLoading] = useState(true)

  const recoverAccessToken = async () => {
    try {
      const { success, accessToken } = await refreshToken()
      if (success) await context.login(accessToken)
    } finally {
      setLoading(false)
    }
  }

  // Try to recover the session (accessToken)
  useEffect(() => recoverAccessToken(), [])

  if (loading) return <LoadingScreen />

  return (
    <Routes>
      {/* Auth Route */}
      <Route path="/" element={<AuthRedirect />}>
        <Route exact path="/auth/login" element={<Login />} />
      </Route>
      {/* Public Routes */}
      <Route path="/auth/register" element={<Register />} />
      <Route path="/auth/verify" element={<VerifyCode />} />
      <Route path="/auth/forgot-password" element={<ForgotPass />} />
      <Route path="/auth/reset-password/:code" element={<ResetPassword />} />
      <Route path="/auth/account-verified/:passed" element={<VerifyEmail />} />
      <Route path="iframe-appointment/:id" element={<NewAppointmentIFrame />} />
      <Route
        path="create-payment/:appointmentId"
        element={<PaymentSelector />}
      />
      <Route path="view-report2/:report_id" element={<FullScreenLayout />}>
        <Route index element={<ViewReport />} />
      </Route>
      <Route path="view-report/:id" element={<NewViewReport />} />
      <Route path="report-views/:view_id" element={<FullScreenLayout />}>
        <Route index element={<ReportList />} />
      </Route>
      <Route path="force-reset-password" element={<FullScreenLayout />}>
        <Route index element={<ForceResetPassword />} />
      </Route>
      <Route path="sample-report/:report_id" element={<FullScreenLayout />}>
        <Route index element={<ViewReport />} />
      </Route>
      <Route
        path="appointment-request/:request_id"
        element={<FullScreenLayout />}
      >
        <Route index element={<AppointmentRequest />} />
      </Route>
      <Route path="schedule/:request_id" element={<FullScreenLayout />}>
        <Route index element={<ScheduleAppointments />} />
      </Route>
      <Route
        path="invoice/:id"
        element={context.user ? <DashboardLayout /> : <FullScreenLayout />}
      >
        <Route index element={<Invoice />} />
      </Route>
      <Route
        path="agreement"
        element={context.user ? <DashboardLayout /> : <FullScreenLayout />}
      >
        <Route
          index
          path="agreement-detail/:id/:agreement_id"
          element={<AgreementDetail />}
        />
      </Route>
      <Route
        path="client-view"
        element={context.user ? <DashboardLayout /> : <FullScreenLayout />}
      >
        <Route index path=":id" element={<AppointmentClient />} />
      </Route>
      <Route path="client-view1" element={<FullScreenLayout />}>
        <Route index path=":id" element={<AppointmentClient1 />} />
      </Route>
      <Route path="public-profile" element={<FullScreenLayout />}>
        <Route index path=":request_id" element={<PublicCompanyProfile />} />
      </Route>
      <Route path="sample-reports" element={<FullScreenLayout />}>
        <Route index path=":request_id" element={<PublicSampleReport />} />
      </Route>
      <Route path="report-pdf" element={<FullScreenLayout />}>
        <Route index path=":inspection_id" element={<ReportPdf />} />
      </Route>
      <Route path="review-pdf" element={<FullScreenLayout />}>
        <Route index path=":inspection_id" element={<ReviewPdf />} />
      </Route>
      <Route path="public" element={<FullScreenLayout />}>
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-of-use" element={<TermsOfUse />} />
      </Route>
      <Route path="watch/:commentId" element={<ReporVideoPlayer />} />
      <Route path="s/:uniqId" element={<ShortenedUrl />} />
      <Route exact path="integrations/success" element={<SuccessPage />} />
      <Route exact path="integrations/error" element={<ErrorPage />} />
      {/* Private Routes */}
      <Route path="/" element={<AuthGuard />}>
        {/* Main Views (Side Menu) */}
        <Route index element={<Navigate to="/dashboard" replace />} />
        <Route exact path="coming-soon" element={<ComingSoon />} />
        <Route exact path="maintenance" element={<Maintenance />} />
        <Route exact path="dashboard" element={<Dashboard />} />
        <Route exact path="templates" element={<TemplatesV2 />} />
        <Route exact path="business" element={<Business />} />
        <Route exact path="people" element={<People />} />
        <Route exact path="communication" element={<Communication />} />
        <Route path="company-profile" element={<CompanyProfile />} />
        <Route exact path="appointments" element={<Appointments />} />
        <Route exact path="time-tracking" element={<TimeTracking />} />
        <Route exact path="calendar" element={<Calendar />} />
        <Route exact path="subscription" element={<Subscription />} />
        <Route exact path="create-merchant" element={<CreateMerchant />} />
        <Route exact path="services" element={<Services />} />
        <Route exact path="discount-codes" element={<DiscountCodes />} />
        <Route exact path="availability" element={<AvailabilityPage />} />
        <Route exact path="time-tracking" element={<TimeTracking />} />
        <Route exact path="support-tickets" element={<SupportTickets />} />
        <Route exact path="pay-splits" element={<PaySplitsV2 />} />
        <Route exact path="actions" element={<Actions />} />
        <Route exact path="settings/automations" element={<Automations />} />
        <Route exact path="/settings" element={<Settings />} />
        {/* Sub Routes */}
        <Route exact path="/user/edit" element={<UserEdit />} />
        {/* Templates */}
        <Route path="templates/edit/:template_id" element={<EditTemplates />} />
        <Route path="templates/store" element={<TemplateStore />} />
        {/* People */}
        <Route exact path="/people/import" element={<ImportPeople />} />
        {/* Appointments */}
        <Route path="/appointments/:id" element={<ApptDetails3 />} />
        <Route path="/appointments/details/:id" element={<ApptDetails />} />
        <Route exact path="appointments/print-order" element={<PrintOrder />} />
        <Route
          exact
          path="appointments/view-log/:report_id"
          element={<ViewLog />}
        />
        {/* Subscription */}
        <Route exact path="subscription/past-payments" element={<PastPay />} />
        <Route exact path="subscription/upgrade" element={<UpgradePlan />} />
        <Route
          exact
          path="subscription/message-bundle"
          element={<MessageBundle />}
        />
        {/* Edit Report */}
        <Route exact path="edit-report/:report_id" element={<EditReport />} />
        {/* edit-technician */}
        <Route path="edit-technician/:type/:id" element={<EditTechnician />} />
      </Route>
      {/* Admin Routes */}
      <Route path="admin" element={<AdminGuard />}>
        {/* Main Views (Side Menu) */}
        <Route index element={<Navigate to="/admin/dashboard" replace />} />
        <Route exact path="dashboard" element={<AdminDashboard />} />
        <Route exact path="subscription-plans" element={<SubPlans />} />
        <Route exact path="messaging-plans" element={<MsgPlans />} />
        <Route exact path="discounts" element={<DiscountAdmin />} />
        <Route exact path="payment-history" element={<PaymentHistory />} />
        <Route exact path="settings" element={<SettingsAdmin />} />
        <Route exact path="product-updates" element={<Updates />} />
        <Route exact path="track-email" element={<TrackEmail />} />
        <Route exact path="reports" element={<SubscriberReport />} />
        <Route exact path="subscribers" element={<AdminSubs />} />
        <Route
          exact
          path="email-management"
          element={<EmailManagementList />}
        />
        {/* Sub Routes */}
        {/* Reports */}
        <Route
          exact
          path="reports/subscriber-reports"
          element={<SubscriberReport />}
        />
        <Route
          exact
          path="reports/payment-reports"
          element={<PaymentReport />}
        />
        <Route
          exact
          path="reports/subscription-reports"
          element={<SubsReport />}
        />
        {/* subscribers */}
        <Route
          exact
          path="subscribers/details/:id"
          element={<AdminSubDetails />}
        />
        {/* email-management */}
        <Route exact path="email-management/edit/:id" element={<EmailEdit />} />
      </Route>
      {/* Not Found */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default App
