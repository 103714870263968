/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import {
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { experimentalStyled as styled } from '@material-ui/core/styles'
import { MSwitch } from '../../../../../../@material-extend'

const Switchlabel = styled(FormControlLabel)(({ theme }) => ({
  padding: theme.spacing(1, 1),
  fontSize: 12,
  '& .MuiFormControlLabel-label': {
    fontSize: 12,
    fontWeight: 700,
  },
}))

const PaymentSection = ({
  requirePayment,
  paymentNotes,
  onPaymentDataChange,
}) => {
  return (
    <Grid container spacing={2} sx={{ my: 2 }}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Payment</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Switchlabel
          control={
            <MSwitch
              name="requirePayment"
              color="primary"
              checked={requirePayment}
              onChange={onPaymentDataChange}
            />
          }
          label="Require payment to release report(s)"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          multiline
          name="paymentNotes"
          label="Appointment notes"
          placeholder="i.e. 'Client will pay on-site', 'Received in cash', etc"
          rows={4}
          value={paymentNotes}
          onChange={onPaymentDataChange}
        />
      </Grid>
    </Grid>
  )
}

PaymentSection.propTypes = {
  requirePayment: PropTypes.bool.isRequired,
  paymentNotes: PropTypes.string.isRequired,
  onPaymentDataChange: PropTypes.func.isRequired,
}

export default PaymentSection
