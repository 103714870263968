import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getInuitQuickBooksUserData, getUserGoogleCalendars } from '../../services/integrations'

const initialState = {
  isLoading: false,
  dataLoaded: false,
  error: false,
  inuitQuickBooksData: null,
  googleCalendars: [],
}

export const getInuitQuickBooksData = createAsyncThunk(
  'integrations/fetchInuitQuickBooksData',
  async (userId) => {
    try {
      const res = await getInuitQuickBooksUserData(userId)
      return res.data
    } catch (e) {
      return Promise.reject()
    }
  },
)

export const getGoogleCalendars = createAsyncThunk(
  'integrations/fetchGoogleCalendars',
  async (userId) => {
    try {
      const res = await getUserGoogleCalendars(userId)
      return res.data
    } catch (e) {
      return Promise.reject()
    }
  },
)

const slice = createSlice({
  name: 'availability',
  initialState,
  reducers: {
    setUriInuitQuickBooksData: (state, action) => {
      state.inuitQuickBooksData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInuitQuickBooksData.pending, (state) => {
        state.isLoading = true
        state.dataLoaded = false
        state.error = false
      })
      .addCase(getInuitQuickBooksData.fulfilled, (state, action) => {
        state.isLoading = false
        state.dataLoaded = true
        state.error = false
        state.inuitQuickBooksData = action.payload
      })
      .addCase(getInuitQuickBooksData.rejected, (state) => {
        state.isLoading = false
        state.dataLoaded = false
        state.error = true
      })
      .addCase(getGoogleCalendars.pending, (state) => {
        state.isLoading = true
        state.dataLoaded = false
        state.error = false
      })
      .addCase(getGoogleCalendars.fulfilled, (state, action) => {
        state.isLoading = false
        state.dataLoaded = true
        state.error = false
        state.googleCalendars = action.payload
      })
      .addCase(getGoogleCalendars.rejected, (state) => {
        state.isLoading = false
        state.dataLoaded = false
        state.error = true
      })
  },
})

export const { setUriInuitQuickBooksData } = slice.actions

export default slice.reducer
