import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { group } from 'radash'
import { getAvailabilityFromSubscriber } from '../../services/availability.service'

const initialState = {
  isLoading: false,
  dataLoaded: false,
  error: false,
  selectedAvailabilitiesIds: [],
  availabilities: [],
  byStartTime: null,
  byTimeDate: null,
  timeSlots: [],
}

export const fetchAvailabilities = createAsyncThunk(
  'content/fetchAvailabilities',
  async (subscriberId) => {
    const res = await getAvailabilityFromSubscriber(subscriberId)
    return res
  },
)

const slice = createSlice({
  name: 'availability',
  initialState,
  reducers: {
    setSelectedAvailabilitiesIds: (state, action) => {
      state.selectedAvailabilitiesIds = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAvailabilities.pending, (state) => {
      state.isLoading = true
      state.selectedAvailabilitiesIds = []
    })
    builder.addCase(fetchAvailabilities.fulfilled, (state, action) => {
      state.isLoading = false
      state.availabilities = action.payload
      state.byStartTime = group(action.payload, (data) => data.start_time)
      state.timeSlots = Object.keys(state.byStartTime).sort((a, b) => {
        if (a < b) return -1
        if (a > b) return 1
        return 0
      })

      if (state.timeSlots.length > 0) {
        const mNewObj = {}
        // group by date
        for (let i = 0; i < state.timeSlots.length; i += 1) {
          mNewObj[state.timeSlots[i]] = group(
            state.byStartTime[state.timeSlots[i]],
            (data) => data.day,
          )
        }
        state.byTimeDate = mNewObj
      }
    })
    builder.addCase(fetchAvailabilities.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    })
  },
})

export const { setSelectedAvailabilitiesIds } = slice.actions

export default slice.reducer
