// ----------------------------------------------------------------------

export default function Menu(theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            '& .MuiIconButton-label': {
              color: '#fff',
            },
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              '& .MuiIconButton-label': {
                color: theme.palette.text.secondary,
              },
            },
            '&.sections-items': {
              '& .MuiIconButton-label': {
                color: theme.palette.common.white,
              },
            },
          },
          [theme.breakpoints.down('sm')]: {
            minHeight: 'fit-content',
            py: 0,
          },
        },
      },
    },
  }
}
