import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  settings: [],
}


const slice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setSettingsAction: (state, action) => {
      state.settings = action.payload.settings
    },
  },
})

export const {
  setSettingsAction,
} = slice.actions
export default slice.reducer