import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getCalendarEvents } from '../../services/calendar'
import { getUserGoogleCalendars } from '../../services/integrations'

const initialState = {
  isLoading: false,
  dataLoaded: false,
  error: false,
  selectedDateTime: null,
  selectedAppointmentId: null,
  events: [],
  technicians: [],
  userCalendars: [],
  fetchUserCalendarsFinish: false,
  calendarIsSelected: false,
}

export const getCalendarUserEvents = createAsyncThunk(
  'calendar/fetchCalendarEvents',
  async (datesSelected) => {
    try {
      const res = await getCalendarEvents({
        startDate: datesSelected.fromDate,
        endDate: datesSelected.toDate,
      })
      return res.data.data
    } catch (e) {
      return Promise.reject()
    }
  },
)

export const fetchUserCalendars = createAsyncThunk(
  'user/fetchUserCalendars',
  async (userId) => {
    const res = await getUserGoogleCalendars(userId)
    if (res.status === 200 && res.data) {
      return {
        success: true,
        data: res.data,
      }
    }
    return Promise.reject()
  },
)

const slice = createSlice({
  name: 'calendarv2',
  initialState,
  reducers: {
    setSelectedDateTime: (state, action) => {
      state.selectedDateTime = action.payload.selectedDateTime
      state.selectedAppointmentId = action.payload.selectedAppointmentId
    },
    setCalendarIsLoading: (state, action) => {
      state.isLoading = true
    },
    setCalendarIsDoneLoading: (state, action) => {
      state.isLoading = false
    },
    removeEvents: (state) => {
      state.events = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserCalendars.pending, (state) => {
      state.isLoading = true
      state.userCalendars = []
      state.fetchUserCalendarsFinish = false
    })
    builder.addCase(fetchUserCalendars.fulfilled, (state, action) => {
      state.userCalendars = action.payload.data
      state.calendarIsSelected = !!action.payload.data.find(
        (calendar) => calendar.active,
      )
      state.isLoading = false
      state.fetchUserCalendarsFinish = true
    })
    builder.addCase(fetchUserCalendars.rejected, (state) => {
      state.isLoading = false
      state.userCalendars = []
      state.fetchUserCalendarsFinish = true
    })
    builder
      .addCase(getCalendarUserEvents.pending, (state) => {
        state.isLoading = true
        state.dataLoaded = false
        state.error = false
      })
      .addCase(getCalendarUserEvents.fulfilled, (state, action) => {
        state.isLoading = false
        state.dataLoaded = true
        state.error = false
        state.events = action.payload.events
        state.technicians = action.payload.technicians
      })
      .addCase(getCalendarUserEvents.rejected, (state) => {
        state.isLoading = false
        state.dataLoaded = false
        state.error = true
      })
  },
})

export const {
  removeEvents,
  setSelectedDateTime,
  setCalendarIsLoading,
  setCalendarIsDoneLoading,
} = slice.actions
export default slice.reducer
