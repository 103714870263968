/* eslint-disable react/forbid-prop-types */
import { Box, Button, IconButton, Stack, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ManualEditForm from './components/ManualEditForm'
import SelectServices from './components/SelectServices'
import AddEvents from './components/AddEvents/AddEvents'
import { Step, StepHead } from '../FormSteps.styles'
import PaymentSection from './components/PaymentSection'
import Info from '../../../../../info'
import { MobileModalHeader } from '../../MobileMultiStep/MobileMultiStep.style'
import ConditionalWrapper from './components/ConditionalWrapper'
// import { servicesInitialState } from '../../../utils/initialStates'

const AddServices = ({
  isMobile,
  services,
  setServices,
  location,
  settings,
  setStepSlug,
}) => {
  const { isManualEdit, newServices } = services

  const updateServiceField = (field, value) => {
    setServices({ ...services, [field]: value })
  }

  /*
  const updateTextField = (field, value) => {
    setServices({ ...services, [field]: value })
  }
  */

  const onInputChange = (event) => {
    const { value, name, checked } = event.target
    const newValue = name === 'requirePayment' ? checked : value
    setServices({ ...services, [name]: newValue })
  }

  const handleManualEdit = () => {
    setServices({ ...services, isManualEdit: !isManualEdit })
  }

  const saveManuelChanges = (updatedServices) => {
    setServices({
      ...services,
      isManualEdit: false,
      newServices: updatedServices,
    })
  }

  const setEvents = (events) => {
    setServices({ ...services, events: events })
  }

  const backStep = () => {
    setStepSlug('addLocation')
  }

  const nextStep = () => {
    setStepSlug('setDateTime')
  }

  const formik = useFormik({
    initialValues: {
      notes: '',
    },
  })

  return (
    <Fragment>
      {isMobile && (
        <MobileModalHeader>
          <Box>
            <IconButton
              onClick={backStep}
              sx={{
                padding: '6px',
                marginLeft: '-8px',
                svg: { width: 28, height: 28 },
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="h6">Add Services</Typography>
          </Box>
          <Box>
            <IconButton
              onClick={nextStep}
              sx={{
                padding: '6px',
                marginRight: '-8px',
                svg: { width: 28, height: 28 },
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          </Box>
        </MobileModalHeader>
      )}
      <ConditionalWrapper
        condition={!isMobile}
        wrapper={(children) => (
          <Step>
            <StepHead>
              <Typography variant="h6">Services</Typography>
              <Info title="Select Services in this step" />
            </StepHead>
            {children}
          </Step>
        )}
      >
        <Fragment>
          <Box sx={{ ...(!isMobile && { margin: `24px 0px`, px: 2 }) }}>
            <FormikProvider value={formik}>
              <Form>
                {isManualEdit ? (
                  <ManualEditForm
                    newServices={newServices}
                    templateOptions={settings.templateOptions}
                    agreementOptions={settings.agreementOptions}
                    currencySymbol={settings.currencySymbol}
                    handleManualEdit={handleManualEdit}
                    saveManuelChanges={saveManuelChanges}
                  />
                ) : (
                  <SelectServices
                    newServices={newServices}
                    location={location}
                    isMobile={isMobile}
                    isUsingIFrame={settings.isUsingIFrame}
                    serviceOptions={settings.serviceOptions}
                    discountCode={services.discountCode}
                    currencySymbol={settings.currencySymbol}
                    updateServiceField={updateServiceField}
                    onInputChange={onInputChange}
                    handleManualEdit={handleManualEdit}
                  />
                )}
                {/*{!settings.isUsingIFrame && settings.acceptEvents && (*/}
                {/*  <AddEvents*/}
                {/*    events={services.events}*/}
                {/*    defaultStartTime={settings.defaultStartTime}*/}
                {/*    defaultDuration={settings.defaultDuration}*/}
                {/*    inspectorOptions={settings.inspectorOptions}*/}
                {/*    setEvents={setEvents}*/}
                {/*  />*/}
                {/*)}*/}
                {/*{!settings.isUsingIFrame && (*/}
                {/*    <PaymentSection*/}
                {/*        requirePayment={services.requirePayment}*/}
                {/*        paymentNotes={services.paymentNotes}*/}
                {/*        onPaymentDataChange={onInputChange}*/}
                {/*    />*/}
                {/*)}*/}
              </Form>
            </FormikProvider>
          </Box>
          {!isMobile && (
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
              <Button variant="contained" color="inherit" onClick={backStep}>
                Previous
              </Button>
              <Button variant="contained" color="inherit" onClick={nextStep}>
                Next
              </Button>
            </Stack>
          )}
        </Fragment>
      </ConditionalWrapper>
    </Fragment>
  )
}

AddServices.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  services: PropTypes.shape({
    // manualEdit: PropTypes.bool.isRequired,
    // duration: PropTypes.string.isRequired,
    discountCode: PropTypes.string.isRequired,
    // templates: PropTypes.array.isRequired,
    // agreements: PropTypes.array.isRequired,
    // services: PropTypes.array.isRequired,
    // fees: PropTypes.array.isRequired,
    newServices: PropTypes.shape({
      services: PropTypes.array,
      templates: PropTypes.array,
      agreements: PropTypes.array,
      fees: PropTypes.array,
      total: PropTypes.string,
      duration: PropTypes.string,
      manualDuration: PropTypes.string,
    }),
    isManualEdit: PropTypes.bool,
    manualData: PropTypes.shape({
      templates: PropTypes.array,
      agreements: PropTypes.array,
      fees: PropTypes.array,
      total: PropTypes.string,
      duration: PropTypes.string,
    }),
    autoData: PropTypes.shape({
      templates: PropTypes.array,
      agreements: PropTypes.array,
      services: PropTypes.array,
      fees: PropTypes.array,
      total: PropTypes.string,
      duration: PropTypes.string,
    }),
    events: PropTypes.array.isRequired,
    requirePayment: PropTypes.bool.isRequired,
    paymentNotes: PropTypes.string.isRequired,
  }).isRequired,
  setServices: PropTypes.func.isRequired,
  location: PropTypes.shape({
    searchGoogleEnabled: PropTypes.bool.isRequired,
    fullAddress: PropTypes.string.isRequired,
    streetAndNumber: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    square_feet: PropTypes.string.isRequired,
    year_built: PropTypes.string.isRequired,
    foundation: PropTypes.string.isRequired,
    coordinates: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }),
  }).isRequired,
  settings: PropTypes.shape({
    serviceOptions: PropTypes.array.isRequired,
    discountOptions: PropTypes.array.isRequired,
    agreementOptions: PropTypes.array.isRequired,
    templateOptions: PropTypes.array.isRequired,
    agentOptions: PropTypes.array.isRequired,
    clientOptions: PropTypes.array.isRequired,
    contractorOptions: PropTypes.array.isRequired,
    inspectorOptions: PropTypes.array.isRequired,
    timezone: PropTypes.string.isRequired,
    currencySymbol: PropTypes.string.isRequired,
    acceptEvents: PropTypes.bool.isRequired,
    defaultStartTime: PropTypes.string.isRequired,
    defaultDuration: PropTypes.number.isRequired,
    notifications: PropTypes.object,
  }).isRequired,
  setStepSlug: PropTypes.func.isRequired,
}

export default AddServices
