import axiosApi from './_axiosApi'

export const createAgent = (formData) =>
  axiosApi
    .post('/v2/agents', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data)

export const getAgentList = ({ subscriberId, cursor, perPage }) =>
  axiosApi
    .get('/v2/agents', { params: { subscriberId, cursor, perPage } })
    .then((res) => res.data)

export const searchAgents = ({ subscriberId, cursor, perPage, text }) =>
  axiosApi
    .get('/v2/agents', { params: { subscriberId, cursor, perPage, text } })
    .then((res) => res.data)

export const getAgent = ({ subscriberId, agentId }) =>
  axiosApi
    .get(`/v2/agents/${agentId}`, { params: { subscriberId } })
    .then((res) => res.data)

export const updateAgent = ({ agentId, formData }) =>
  axiosApi
    .put(`/v2/agents/${agentId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data)

export const changeAgentStatus = ({ agentId, subscriberId, newStatus }) =>
  axiosApi
    .post(`/v2/agents/${agentId}/status`, { subscriberId, newStatus })
    .then((res) => res.data)

export const deleteAgent = ({ agentId, subscriberId }) =>
  axiosApi
    .delete(`v2/agents/${agentId}`, { data: { subscriberId } })
    .then((res) => res.data)

export const importAgents = (formData) =>
  axiosApi
    .post('/v2/agents/import', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data)

export const exportAgents = (subscriberId) =>
  axiosApi
    .get(`/v2/agents/export-file/${subscriberId}`, {
      responseType: 'blob',
    })
    .then((res) => {
      // Extract "filename" and "mime-type" from "header" response
      const contentDisposition = res.headers['content-disposition']
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1]
        : ''
      const mimeType = res.headers['content-type']

      return {
        blob: res.data,
        mimeType,
        filename,
      }
    })
