export const getAppointmentTimeSlots = (state) => {
  return state.appointments.timeSlots
}

export const getAppointmentLoading = (state) => {
  return state.appointments.isLoading
}

export const getAppointmentOpenModal = (state) => {
  return state.appointments.openModal
}

export const getAppointmentSelectedTechnician = (state) => {
  return state.appointments.currentAppointmentState.selectedTechnicianId
}
