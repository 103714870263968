/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import { Box, Card, Grid, Typography } from '@material-ui/core'
import BlockUi from 'react-block-ui'
import numeral from 'numeral'
import { useEffect, useState } from 'react'
import Dialog from '../../../animate/DialogAnimate'
import AvatarInputFile from '../../../common/AvatarInputFile/AvatarInputFile'
import ClientForm from './ClientForm'
import AgentForm from './AgentForm'
import ContractorForm from './ContractorForm'
import TechnicianForm from './TechnicianForm'
import StaffForm from './StaffForm'
import Loader from '../../../Loader'

const AddOrEditPerson = ({
  action,
  open,
  closeModal,
  type,
  personBasicData,
  pushOrUpdatePerson,
  isUsingIframe,
  subscriber,
}) => {
  const [maxFileSize] = useState(5242880) // 5,242,880 bytes => 5.2 MB
  const [avatar, setAvatar] = useState({ file: null, preview: '' })
  const [blocking, setBlocking] = useState(false)

  useEffect(() => {
    if (!open) {
      // Set initial value of avatar
      setAvatar({ file: null, preview: '' })
    }
  }, [open])

  const onAvatarChange = (_name, avatar) => {
    setAvatar(avatar)
  }

  const setAvatarSrc = (src) => {
    setAvatar({ ...avatar, preview: src })
  }

  const handleClose = () => {
    if (!blocking) closeModal('')
  }

  const handleSuccess = (data) => {
    pushOrUpdatePerson(type, data)
  }

  return (
    <Dialog open={open} onClose={handleClose} isUsingIframe={isUsingIframe}>
      <Box
        sx={{
          maxWidth: 900,
          mx: 'auto',
          '.block-ui-overlay': { backgroundColor: 'transparent' },
        }}
      >
        <BlockUi
          tag="div"
          blocking={blocking}
          loader={<Loader />}
          message=""
          keepInView
          style={{ maxWidth: 900 }}
        >
          <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
            <Grid item xs={12} md={3}>
              <Card sx={{ maxHeight: '100%', position: 'reltive' }}>
                {blocking && (
                  <Box
                    sx={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      width: '100%',
                      height: '100%',
                      zIndex: 1,
                      backgroundColor: '#FFF',
                      opacity: 0.5,
                    }}
                  />
                )}
                <Box
                  sx={{
                    marginTop: '28px',
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <AvatarInputFile
                    name="avatar"
                    src={avatar.preview}
                    diameter={126}
                    maxSize={maxFileSize}
                    onChange={onAvatarChange}
                  />
                </Box>
                <Box sx={{ marginBottom: '28px' }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      marginBottom: '16px',
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.primary',
                    }}
                  >
                    Profile Picture
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Allowed: *.jpeg, *.jpg, *.png, *.gif
                    <span style={{ display: 'block', marginTop: '5px' }}>
                      max size: {numeral(maxFileSize).format('0.0 b')}
                    </span>
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={8}>
              <Card sx={{ p: 3, overflow: 'hidden', position: 'reltive' }}>
                {blocking && (
                  <Box
                    sx={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      width: '100%',
                      height: '100%',
                      zIndex: 1,
                      backgroundColor: '#FFF',
                      opacity: 0.5,
                    }}
                  />
                )}
                {(() => {
                  switch (type) {
                    // Add or Update Client form
                    case 'clients':
                      return (
                        <ClientForm
                          action={action}
                          profilePic={avatar.file}
                          setAvatarSrc={setAvatarSrc}
                          personBasicData={personBasicData}
                          closeModal={closeModal}
                          setBlocking={setBlocking}
                          handleSuccess={handleSuccess}
                          subscriber={subscriber}
                        />
                      )
                    // Add or Update Agent form
                    case 'agents':
                      return (
                        <AgentForm
                          type="agents"
                          action={action}
                          profilePic={avatar.file}
                          setAvatarSrc={setAvatarSrc}
                          personBasicData={personBasicData}
                          closeModal={closeModal}
                          setBlocking={setBlocking}
                          handleSuccess={handleSuccess}
                          subscriber={subscriber}
                        />
                      )
                    // Add or Update Listing Agent form
                    case 'listingAgents':
                      return (
                        <AgentForm
                          type="listingAgents"
                          action={action}
                          profilePic={avatar.file}
                          setAvatarSrc={setAvatarSrc}
                          personBasicData={personBasicData}
                          closeModal={closeModal}
                          setBlocking={setBlocking}
                          handleSuccess={handleSuccess}
                          subscriber={subscriber}
                        />
                      )
                    // Add or Update Contractor form
                    case 'contractors':
                      return (
                        <ContractorForm
                          action={action}
                          profilePic={avatar.file}
                          setAvatarSrc={setAvatarSrc}
                          personBasicData={personBasicData}
                          closeModal={closeModal}
                          setBlocking={setBlocking}
                          handleSuccess={handleSuccess}
                          subscriber={subscriber}
                        />
                      )
                    // Add or Update Technician form
                    case 'technicians':
                      return (
                        <TechnicianForm
                          action={action}
                          profilePic={avatar.file}
                          setAvatarSrc={setAvatarSrc}
                          personBasicData={personBasicData}
                          closeModal={closeModal}
                          setBlocking={setBlocking}
                          handleSuccess={handleSuccess}
                        />
                      )
                    // Add or Update Staff form
                    case 'staffs':
                      return (
                        <StaffForm
                          action={action}
                          profilePic={avatar.file}
                          setAvatarSrc={setAvatarSrc}
                          personBasicData={personBasicData}
                          closeModal={closeModal}
                          setBlocking={setBlocking}
                          handleSuccess={handleSuccess}
                        />
                      )
                    default:
                      return null
                  }
                })()}
              </Card>
            </Grid>
          </Grid>
        </BlockUi>
      </Box>
    </Dialog>
  )
}

AddOrEditPerson.defaultProps = {
  action: 'add',
  isUsingIframe: false,
}

AddOrEditPerson.propTypes = {
  action: PropTypes.oneOf(['add', 'edit']),
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  type: PropTypes.oneOf([
    '',
    'technicians',
    'clients',
    'agents',
    'listingAgents',
    'contractors',
    'staffs',
  ]),
  personBasicData: PropTypes.object,
  pushOrUpdatePerson: PropTypes.func.isRequired,
  isUsingIframe: PropTypes.bool,
  subscriber: PropTypes.string,
}

export default AddOrEditPerson
