import { experimentalStyled as styled } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'

export const Logocontainer = styled('img')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `10px 25px 0 25px`,
}))

export const LogocontainerSingle = styled('img')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 60,
  width: 60,
  padding: theme.spacing(1),
  margin: 'auto',
}))
export const Switcher = styled(IconButton)(({ theme }) => ({
  marginBottom: 2,
  background: theme.palette.primary.main,
  borderRadius: `6px`,
  padding: `0px`,
  height: 40,
  width: 40,
  margin: theme.spacing(2.45),
  boxShadow: `0px 5px 18px 0px #f7902385 `,
  '&:hover': {
    background: theme.palette.primary.main,
    '& .chev-left': {
      '&:hover': {
        transform: 'scale3d(0.5, 0.5, 0.5)',
        animation: ' move-left 1s ease-out infinite',
        '&:nth-of type(1)': {
          opacity: 0,
          animation: 'move-left 1s ease-out 1s infinite',
        },
        '&:nth-of type(2)': {
          opacity: 0,
          animation: 'move-left 1s ease-out 2s infinite',
        },
      },
    },

    '& .chev-right': {
      '&:hover': {
        animation: ' move-right 1s ease-out infinite',
        '&:nth-of type(1)': {
          opacity: 0,
          animation: 'move-right 1s ease-out 1s infinite',
        },
        '&:nth-of type(2)': {
          opacity: 0,
          animation: 'move-right 1s ease-out 2s infinite',
        },
      },
    },
  },
  '& .chev-icon': {
    color: theme.palette.text.primary,
    position: 'absolute',
  },

  '@keyframes move-left': {
    '25%': {
      opacity: 0.6,
    },
    '33% ': {
      transform: `translateX(${'-4px'})`,
    },
    '67%': {
      opacity: 0.6,
      transform: `translateX(${'-5px'})`,
    },
    '100% ': {
      opacity: 0,
      transform: `translateX(${'-6.5px'}) scale3d(0.5, 0.5, 0.5)`,
    },
  },

  '@keyframes move-right': {
    '25%': {
      opacity: 0.6,
    },
    '33% ': {
      transform: `translateX(${'4px'})`,
    },
    '67%': {
      opacity: 0.6,
      transform: `translateX(${'5px'})`,
    },
    '100% ': {
      opacity: 0,
      transform: `translateX(${'6.5px'}) scale3d(0.5, 0.5, 0.5)`,
    },
  },
}))
