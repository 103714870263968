import styled from 'styled-components'

export const AvatarField = styled.div`
  text-align: center;
  display: inline-table;
`

export const Img = styled.img`
  cursor: pointer;
  object-fit: cover;
  box-sizing: border-box;
  border-radius: 100%;
  border: 2px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  transition: all ease-in-out 0.3s;
  &:hover {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }
`

export const FigCaption = styled.figcaption`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: all ease-in-out 0.3s;
  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }
  img {
    height: 40px;
    width: 40px;
  }
`
