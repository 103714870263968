import { Fragment, useEffect, useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'

const SearchGoogleMapsPlace = ({
  name,
  placeholder,
  value: address,
  onChange,
  latitude,
  longitude,
  radius,
  label,
  usingLongName,
  size,
  margin,
}) => {
  const [value, setValue] = useState(address || '')
  // miles to meters
  const [searchOptions, setSearchOptions] = useState({
    radius: (radius || 10) * 1609.34,
    rankBy: google.maps.places.RankBy.DISTANCE,
    types: ['address'],
  })

  const handleOnChange = (value) => {
    setValue(value)
  }
  const handleSelect = async (fullAddress) => {
    const results = await geocodeByAddress(fullAddress)
    const { geometry, address_components: addressArray } = results[0]
    const address = {
      fullAddress,
      ...getFormattedAddress(addressArray, geometry, usingLongName),
    }
    setValue(fullAddress)
    onChange(name, address)
  }

  const getPosition = async () => {
    const unknown = [0, '0']
    if (
      latitude &&
      longitude &&
      !unknown.includes(latitude) &&
      !unknown.includes(longitude)
    ) {
      setSearchOptions({
        location: new google.maps.LatLng(+latitude, +longitude),
        ...searchOptions,
      })
    } else {
      await navigator.geolocation.getCurrentPosition(
        (position) => {
          setSearchOptions({
            location: new google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude,
            ),
            ...searchOptions,
          })
        },
        (err) => console.log(err),
      )
    }
  }

  useEffect(() => {
    getPosition()
  }, [])

  return (
    <Fragment>
      <PlacesAutocomplete
        value={value}
        onChange={handleOnChange}
        onSelect={handleSelect}
        debounce={250}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <div id="search-container">
              <TextField
                fullWidth
                label={label || ''}
                name={name}
                {...getInputProps({
                  placeholder: placeholder || 'Search place...',
                  className: 'location-search-input',
                  size: size || 'small',
                  margin: margin || '',
                })}
              />
              <div
                className="autocomplete-dropdown-container"
                style={{ position: 'relative' }}
              >
                {suggestions.length > 0 && (
                  <div
                    style={{
                      width: '100%',
                      position: 'absolute',
                      zIndex: 999999,
                      // padding: '8px',
                      padding: '8px 0',
                      borderRadius: '8px',
                      backgroundColor: '#FFFFFF',
                      boxShadow: `0 0 2px 0 rgb(174 174 174 / 24%), 0 12px 24px 0 rgb(174 174 174 / 24%)`,
                    }}
                  >
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? 'row suggestion-item--active'
                        : 'row suggestion-item'

                      const normalStyles = {
                        display: 'flex',
                        cursor: 'pointer',
                        padding: '0 14px',
                        minHeight: '36px',
                        alignItems: 'center',
                        // borderRadius: '12px',
                      }

                      const activeStyles = {
                        ...normalStyles,
                        backgroundColor: 'rgba(145, 158, 171, 0.08)',
                      }

                      return (
                        <div
                          key={suggestion.placeId}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style: suggestion.active
                              ? activeStyles
                              : normalStyles,
                          })}
                        >
                          <span
                            style={{
                              fontSize: '14px',
                              height: '100%',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {suggestion.description}
                          </span>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          )
        }}
      </PlacesAutocomplete>
    </Fragment>
  )
}

SearchGoogleMapsPlace.defaultProps = {
  usingLongName: false,
}

SearchGoogleMapsPlace.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  radius: PropTypes.number,
  label: PropTypes.string,
  usingLongName: PropTypes.bool,
  size: PropTypes.string,
  margin: PropTypes.string,
}

export default SearchGoogleMapsPlace

// Helpers ----v

const getFormattedAddress = (addressArray, geometry, usingLongName) => {
  return {
    street: extractor(addressArray, 'route', usingLongName),
    streetNumber: extractor(addressArray, 'street_number', usingLongName),
    neighborhood: getNeighborhood(addressArray),
    city: extractor(addressArray, 'locality', usingLongName),
    state: extractor(
      addressArray,
      'administrative_area_level_1',
      usingLongName,
    ),
    cp: extractor(addressArray, 'postal_code', usingLongName),
    country: extractor(addressArray, 'country', usingLongName),
    coordinates: {
      lat: geometry.location.lat(),
      lng: geometry.location.lng(),
    },
  }
}

const extractor = (addressArray, slug, usingLongName) => {
  const value = addressArray.find(({ types }) => types.includes(slug))
  if (!value) return ''
  if (slug === 'locality') return value.long_name // city
  return usingLongName ? value.long_name : value.short_name
}

const getNeighborhood = (addressArray) => {
  const neighborhood = extractor(addressArray, 'neighborhood')
  if (neighborhood) return neighborhood
  const sublocality = extractor(addressArray, 'sublocality')
  if (sublocality) return sublocality
  return extractor(addressArray, 'sublocality_level_1')
}
