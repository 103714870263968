import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  error: false,
  userSettings: null,
}

const slice = createSlice({
  name: 'iframeAppointment',
  initialState,
  reducers: {
    setUserSettings: (state, action) => {
      state.userSettings = action.payload
    },
  },
})

export const { setUserSettings } = slice.actions

export default slice.reducer
