/* eslint-disable react/forbid-prop-types */
import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom'
// material
import {
  alpha,
  experimentalStyled as styled,
  useTheme,
} from '@material-ui/core/styles'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@material-ui/core'

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItem button disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  position: 'relative',
  textTransform: 'capitalize',
  padding: theme.spacing(1.5, 1.7),
  margin: '5px auto !important',
  color: theme.palette.text.secondary,
  borderRadius: 8,
}))

const ListItemIconStyle = styled(ListItemIcon)({
  width: 20,
  height: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
  isCompact: PropTypes.bool,
}

function NavItem({ item, active, isCompact }) {
  const theme = useTheme()
  const isActiveRoot = active(item.path)
  const { title, path, icon, info, children } = item
  const [open, setOpen] = useState(isCompact && isActiveRoot)
  const MenuRef = useRef(null)

  const handleOpen = () => {
    setOpen((prev) => !prev)
  }

  const activeRootStyle = {
    color: theme.palette.text.primary,
    span: { color: '#fff' },
    // background: `linear-gradient( to right, #feeddb , #ffffff)`,
    bgcolor: theme.palette.primary.main,
    '&:hover': {
      bgcolor: theme.palette.primary.main,
    },
    '&:before': { display: 'none' },
    svg: {
      color: '#fff',
    },
  }

  const activeSubStyle = {
    color: '#fff',
    fontWeight: 'fontWeightMedium',
    span: { color: '#fff' },
    // background: `linear-gradient( to right, #feeddb , #ffffff)`,
    bgcolor: theme.palette.primary.main,
    '&:hover': {
      bgcolor: theme.palette.primary.main,
    },
    '&:before': { display: 'none' },
    svg: {
      color: '#fff',
    },
  }

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <Box ref={MenuRef}>
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          </Box>
        </ListItemStyle>

        <Menu
          anchorEl={MenuRef.current}
          open={open}
          onClose={handleOpen}
          unmountOnExit
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'center', horizontal: 'left' }}
          PaperProps={{
            sx: {
              ml: 2,
            },
          }}
        >
          <List component="div">
            {children.map((item) => {
              const { title, path } = item
              const isActiveSub = active(path)
              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              )
            })}
          </List>
        </Menu>
      </>
    )
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
    </ListItemStyle>
  )
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
  isCompact: PropTypes.bool,
}

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation()
  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false

  return (
    <Box {...other}>
      {navConfig.map((list, index) => {
        const { items } = list
        return (
          <List key={`${index  }subheader`} sx={{ p: `7px 16px` }}>
            {items.map((item) => (
              <NavItem key={index + item.id} item={item} active={match} />
            ))}
          </List>
        )
      })}
    </Box>
  )
}
