import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import update from 'immutability-helper'

import { getMe, logOut } from '../../services/auth.service'
import { fetchUserAppointmentSettings } from '../../services/user.services'
import { PATH_AUTH } from '../../routes/paths'

const initialState = {
  isLoading: false,
  dataLoaded: false,
  isAuthenticated: false,
  fetchUserData: false,
  fetchUserDataSuccess: false,
  error: false,
  accessToken: null,
  refreshToken: null,
  email: null,
  first_name: null,
  id: null,
  last_name: null,
  can_edit_template: false,
  full_company_admin: false,
  phone: null,
  profile_pic: null,
  role: null,
  sidebar_nav_links: [],
  isFullAdmin: false,
  userType: null,
  userSettings: null,
  lastPageVisited: null,
  userAppointmentSettings: null,
  fetchUserAppointmentSettingsSuccess: false,
  fetchUserAppointmentSettingsFinish: false,
  enable_online_scheduling: false,
}

export const fetchUserData = createAsyncThunk(
  'user/fetchUserData',
  async () => {
    const res = await getMe()
    if (res.success) {
      return {
        success: res.success,
        ...res.data,
      }
    }
    return Promise.reject()
  },
)

export const fetchUserAppointmentSettingsAsyncThunk = createAsyncThunk(
  'user/fetchUserAppointmentsSettings',
  async (userId) => {
    const res = await fetchUserAppointmentSettings(userId)
    return res
  },
)

export const fetchLogOut = createAsyncThunk(
  'user/fetchUserLogOut',
  async () => {
    const res = await logOut()
    return res
  },
)

const slice = createSlice({
  name: 'userv2',
  initialState,
  reducers: {
    setTokens: (state, action) => {
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
    },
    setSubscriberId: (state, action) => {
      state.subscriber_id = action.payload.subscriber_id
    },
    setUserLastPageVisited: (state, action) => {
      state.lastPageVisited = action.payload.lastPageVisited
    },
    setUserCalendars: (state, action) => {
      state.calendars = action.payload.data
      state.calendarIsSelected = !!action.payload.data.find(
        (calendar) => calendar.active,
      )
      state.isLoading = false
      state.fetchUserCalendarsFinish = true
    },
    updateUserAppointmentSettings: (state, action) => {
      if (action.payload.keyConfiguration) {
        state.userAppointmentSettings = update(state.userAppointmentSettings, {
          [action.payload.keyConfiguration]: {
            [action.payload.key]: { $set: action.payload.value },
          },
        })
      } else {
        state.userAppointmentSettings = update(state.userAppointmentSettings, {
          [action.payload.key]: { $set: action.payload.value },
        })
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.pending, (state) => {
      state.isLoading = true
      state.fetchUserData = false
      state.fetchUserDataSuccess = false
    })
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      state.email = action.payload.email
      state.first_name = action.payload.first_name
      state.id = action.payload.id
      state.subscriber_id = action.payload.subscriber_id || action.payload.id
      state.last_name = action.payload.last_name
      state.phone = action.payload.phone
      state.profile_pic = action.payload.profile_pic
      state.role = action.payload.role
      state.sidebar_nav_links = action.payload.sidebar_nav_links
      state.isAuthenticated = true
      state.userType = action.payload.user_type
      state.isFullAdmin = action.payload.user_type === 5
      state.can_edit_template = action.payload.can_edit_template
      state.full_company_admin = action.payload.full_company_admin
      state.enable_online_scheduling = action.payload.enable_online_scheduling

      state.isLoading = false
      state.fetchUserData = true
      state.fetchUserDataSuccess = true
    })
    builder.addCase(fetchUserData.rejected, (state, action) => {
      state.isLoading = false
      state.fetchUserData = true
      state.fetchUserDataSuccess = false
    })
    builder.addCase(fetchUserAppointmentSettingsAsyncThunk.pending, (state) => {
      state.isLoading = true
      state.fetchUserAppointmentSettingsSuccess = false
    })
    builder.addCase(
      fetchUserAppointmentSettingsAsyncThunk.fulfilled,
      (state, action) => {
        if (!state.fetchUserAppointmentSettingsFinish) {
          state.userAppointmentSettings = { ...action.payload }
          if (!state.userAppointmentSettings.emailSettings) {
            state.userAppointmentSettings.emailSettings = {
              point_of_contact: '1',
              bcc_address: '',
              header_type: '1',
              custom_header: '',
              footer_type: '1',
              custom_footer: '',
            }
          }
        }
        // ToDo: Maybe review backend in order to do not this validation in FE
        if (!action.payload.paymentCredentials) {
          state.userAppointmentSettings.paymentCredentials = {
            api_login_id: '',
            api_transaction_key: '',
            public_client_key: '',
          }
        }
        if (!action.payload.squareCredentials) {
          state.userAppointmentSettings.squareCredentials = {
            applicationId: '',
            locationId: '',
            accessToken: '',
          }
        }
        if (!action.payload.stripeCredentials) {
          state.userAppointmentSettings.stripeCredentials = {
            publishable_key: '',
            secret_key: '',
          }
        }
        if (!action.payload.paypalCredentials) {
          state.userAppointmentSettings.paypalCredentials = {
            client_secret: '',
          }
        }

        if (action.payload.google_calender_id) {
          state.calendarIsSelected =
            action.payload.google_calender_id.length > 0 || false
        }
        state.isLoading = false
        state.fetchUserAppointmentSettingsSuccess = true
        state.fetchUserAppointmentSettingsFinish = true
      },
    )
    builder.addCase(
      fetchUserAppointmentSettingsAsyncThunk.rejected,
      (state, action) => {
        state.isLoading = false
        state.fetchUserAppointmentSettingsSuccess = false
        state.fetchUserAppointmentSettingsFinish = true
        state.lastPageVisited = PATH_AUTH.login
      },
    )
    builder.addCase(fetchLogOut.fulfilled, (state) => {
      return {
        ...initialState,
        isAuthenticated: false,
        fetchUserData: false,
        fetchUserDataSuccess: false,
      }
    })
  },
})

export const {
  setTokens,
  updateUserAppointmentSettings,
  setSubscriberId,
  setUserLastPageVisited,
  resetUserState,
  setUserCalendars,
} = slice.actions

export default slice.reducer
