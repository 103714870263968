const Times = [
  { label: '00:00 AM', value: 0 },
  { label: '00:30 AM', value: 30 },
  { label: '01:00 AM', value: 100 },
  { label: '01:30 AM', value: 130 },
  { label: '02:00 AM', value: 200 },
  { label: '02:30 AM', value: 230 },
  { label: '03:00 AM', value: 300 },
  { label: '03:30 AM', value: 330 },
  { label: '04:00 AM', value: 400 },
  { label: '04:30 AM', value: 430 },
  { label: '05:00 AM', value: 500 },
  { label: '05:30 AM', value: 530 },
  { label: '06:00 AM', value: 600 },
  { label: '06:30 AM', value: 630 },
  { label: '07:00 AM', value: 700 },
  { label: '07:30 AM', value: 730 },
  { label: '08:00 AM', value: 800 },
  { label: '08:30 AM', value: 830 },
  { label: '09:00 AM', value: 900 },
  { label: '09:30 AM', value: 930 },
  { label: '10:00 AM', value: 1000 },
  { label: '10:30 AM', value: 1030 },
  { label: '11:00 AM', value: 1100 },
  { label: '11:30 AM', value: 1130 },
  { label: '12:00 PM', value: 1200 },
  { label: '12:30 PM', value: 1230 },
  { label: '01:00 PM', value: 1300 },
  { label: '01:30 PM', value: 1330 },
  { label: '02:00 PM', value: 1400 },
  { label: '02:30 PM', value: 1430 },
  { label: '03:00 PM', value: 1500 },
  { label: '03:30 PM', value: 1530 },
  { label: '04:00 PM', value: 1600 },
  { label: '04:30 PM', value: 1630 },
  { label: '05:00 PM', value: 1700 },
  { label: '05:30 PM', value: 1730 },
  { label: '06:00 PM', value: 1800 },
  { label: '06:30 PM', value: 1830 },
  { label: '07:00 PM', value: 1900 },
  { label: '07:30 PM', value: 1930 },
  { label: '08:00 PM', value: 2000 },
  { label: '08:30 PM', value: 2030 },
  { label: '09:00 PM', value: 2100 },
  { label: '09:30 PM', value: 2130 },
  { label: '10:00 PM', value: 2200 },
  { label: '10:30 PM', value: 2230 },
  { label: '11:00 PM', value: 2300 },
  { label: '11:30 PM', value: 2330 },
]

export default Times
