/* eslint-disable react/forbid-prop-types */
import { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  ListSubheader,
  MenuItem,
  Slider,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { DURATION_OPTIONS } from '../../../../../../../../constants/new-appointment'
import ACTION_TYPES from '../../../../../../../../constants/actions'

const ChooseEvent = ({ action, setAction }) => {
  const theme = useTheme()

  const ACTION_OPTIONS = useMemo(
    () =>
      ACTION_TYPES.reduce((accumulator, data) => {
        return [...accumulator, ...data.options]
      }, []),
    [],
  )

  const handleChange = (e) => {
    const { value, name, checked } = e.target
    setAction({ ...action, [name]: checked || value })
  }

  const updateOnlyNumbers = (e) => {
    const onlyNumbers = e.target.value.replace(/[^\d.]/g, '')
    e.target.value = onlyNumbers
    handleChange(e)
  }

  return (
    <Fragment>
      <Card sx={{ mb: '20px', boxShadow: 'none', borderRadius: '0px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              name="duration"
              size="small"
              value={action?.duration.toString() || ''}
              onChange={updateOnlyNumbers}
              inputProps={{ minLength: 4, maxLength: 4 }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              select
              fullWidth
              name="duration_type"
              size="small"
              value={action.duration_type}
              onChange={handleChange}
            >
              {DURATION_OPTIONS.map((option) => (
                <MenuItem key={option.key} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              select
              fullWidth
              type="number"
              name="trigger_type"
              size="small"
              value={action.trigger_type}
              onChange={handleChange}
            >
              <MenuItem
                value="before"
                disabled={![18, 19].includes(action.event_type)}
              >
                before
              </MenuItem>
              <MenuItem value="after">after</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              type="number"
              name="event_type"
              size="small"
              value={action.event_type}
              onChange={handleChange}
            >
              {ACTION_TYPES.map((type) => [
                <ListSubheader>{type.label}</ListSubheader>,
                type.options.map((option) => (
                  <MenuItem
                    key={`action-option-${option.id}`}
                    value={option.id}
                  >
                    {option.label}
                  </MenuItem>
                )),
              ])}
            </TextField>
            <Typography variant="caption" sx={{ color: '#a4a4a4', mt: 1 }}>
              {
                ACTION_OPTIONS.find((item) => action.event_type === item.id)
                  .description
              }
            </Typography>
          </Grid>
        </Grid>
      </Card>

      <Stack sx={{ mb: '20px' }}>
        <FormControlLabel
          sx={{ marginRight: 'auto' }}
          name="trigger_once"
          control={
            <Checkbox checked={action.trigger_once} onClick={handleChange} />
          }
          label="Only trigger once"
        />
        <FormControlLabel
          sx={{ marginRight: 'auto' }}
          name="when_noti_disabled"
          control={
            <Checkbox
              checked={action.when_noti_disabled}
              onClick={handleChange}
            />
          }
          label="Send even when notifications disabled"
        />
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              sx={{ marginRight: 'auto' }}
              name="is_certain_hours"
              control={
                <Checkbox
                  checked={action.is_certain_hours}
                  onClick={handleChange}
                />
              }
              label="Send during certain hours only"
            />
          </Grid>
          {action.is_certain_hours && (
            <Grid
              item
              container
              xs={12}
              md={6}
              sx={{ [theme.breakpoints.down('md')]: { mt: 10 } }}
            >
              <Slider
                step={1}
                min={1}
                max={47}
                value={action.certain_hours}
                sx={{
                  '& .MuiSlider-valueLabelOpen': {
                    top: '-15px',
                    borderRadius: '4px',
                    padding: '4px 0px',
                  },
                  '& .MuiSlider-valueLabelCircle': {
                    height: `22px !important`,
                    width: `72px !important`,
                    display: 'flex',
                    justifyContent: 'center',
                  },
                }}
                onChange={(_e, values) => {
                  setAction({ ...action, certain_hours: values })
                }}
                valueLabelDisplay="on"
                valueLabelFormat={sliderValueFormat}
              />
            </Grid>
          )}
        </Grid>
        <FormControlLabel
          sx={{ marginRight: 'auto' }}
          name="no_weekends"
          control={
            <Checkbox checked={action.no_weekends} onClick={handleChange} />
          }
          label="Do not send on weekends"
        />
      </Stack>
    </Fragment>
  )
}

const TIME_SLOTS = {
  1: '12:30 am',
  2: '1:00 am',
  3: '1:30 am',
  4: '2:00 am',
  5: '2:30 am',
  6: '3:00 am',
  7: '3:30 am',
  8: '4:00 am',
  9: '4:30 am',
  10: '5:00 am',
  11: '5:30 am',
  12: '6:00 am',
  13: '6:30 am',
  14: '7:00 am',
  15: '7:30 am',
  16: '8:00 am',
  17: '8:30 am',
  18: '9:00 am',
  19: '9:30 am',
  20: '10:00 am',
  21: '10:30 am',
  22: '11:00 am',
  23: '11:30 am',
  24: '12:00 pm',
  25: '12:30 pm',
  26: '1:00 pm',
  27: '1:30 pm',
  28: '2:00 pm',
  29: '2:30 pm',
  30: '3:00 pm',
  31: '3:30 pm',
  32: '4:00 pm',
  33: '4:30 pm',
  34: '5:00 pm',
  35: '5:30 pm',
  36: '6:00 pm',
  37: '6:30 pm',
  38: '7:00 pm',
  39: '7:30 pm',
  40: '8:00 pm',
  41: '8:30 pm',
  42: '9:00 pm',
  43: '9:30 pm',
  44: '10:00 pm',
  45: '10:30 pm',
  46: '11:00 pm',
  47: '11:30 pm',
}

function sliderValueFormat(value) {
  return value > 0 ? TIME_SLOTS[value.toString()] : value
}

ChooseEvent.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.string,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    duration_type: PropTypes.string,
    trigger_type: PropTypes.string,
    event_type: PropTypes.number,
    trigger_once: PropTypes.bool,
    when_noti_disabled: PropTypes.bool,
    is_certain_hours: PropTypes.bool,
    certain_hours: PropTypes.array,
    no_weekends: PropTypes.bool,
    rules: PropTypes.array,
    action_name: PropTypes.string,
    action_type: PropTypes.number,
    to_address: PropTypes.array,
    from_address: PropTypes.number,
    cc_address: PropTypes.array,
    bcc_address: PropTypes.array,
    subject: PropTypes.string,
    body_content: PropTypes.string,
    webhook_url: PropTypes.string,
    webhook_data: PropTypes.array,
  }).isRequired,
  setAction: PropTypes.func,
}

export default ChooseEvent
