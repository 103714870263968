import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
// slices
import userReducer from './slices/user'
import calendarReducer from './slices/calendar'
import AdminReducer from './slices/Admin'
import DashboardReducer from './slices/dashboard'
import reducerAvailability from './reducers/availability'
import reducerUser from './reducers/user'
import reducerPublicProfile from './reducers/public-profile'
import reducerIntegrations from './reducers/integrations'
import reducerReports from './reducers/reports'
import reducerAppointments from './reducers/appointments'
import reducerCalendar from './reducers/calendar'
import reducerEvents from './reducers/events'
import reducerNavigation from './reducers/navigation'
import iframeAppointment from './reducers/iframeAppointment'
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
}

const rootReducer = combineReducers({
  user: userReducer,
  calendar: calendarReducer,
  admin: AdminReducer,
  dashboard: DashboardReducer,
  availability: reducerAvailability,
  userv2: reducerUser,
  publicProfile: reducerPublicProfile,
  reports: reducerReports,
  integrations: reducerIntegrations,
  appointments: reducerAppointments,
  calendarv2: reducerCalendar,
  events: reducerEvents,
  navigation: reducerNavigation,
  iframeAppointment,
})

export { rootPersistConfig, rootReducer }
