import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import numeral from 'numeral'
import { AvatarField, FigCaption, Img } from './AvatarInputFile.style'

const DEFAULT_IMG = '/assets/img/user_male.svg'

const AvatarInputFile = ({
  name,
  src,
  diameter,
  maxSize,
  accept,
  onChange,
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const onFileSelected = ({ target: { files } }) => {
    const file = files[0]
    if (!file) return

    // Validate file type
    if (!accept.includes(file.type)) {
      const errorMsg = 'File type not accepted'
      enqueueSnackbar(errorMsg, { variant: 'error', autoHideDuration: 3500 })
      return
    }

    // Validate file size
    if (file.size > maxSize) {
      const limitSize = formatBytes(maxSize)
      const errorMsg = `The image is too large, max size ${limitSize}`
      enqueueSnackbar(errorMsg, { variant: 'error', autoHideDuration: 4500 })
      return
    }

    onChange(name, { file, preview: URL.createObjectURL(file) })
  }

  return (
    <AvatarField>
      <label htmlFor={name}>
        <input
          hidden
          type="file"
          id={name}
          onChange={onFileSelected}
          onClick={(e) => {
            // This allows to re-select the previously selected file
            e.currentTarget.value = ''
          }}
          accept={accept.join(', ')}
        />
        <figure className="avatar-container" style={{ position: 'relative' }}>
          <Img
            alt={name}
            width={diameter}
            height={diameter}
            src={src || DEFAULT_IMG}
          />
          <FigCaption>
            <img alt="img-icon" src="/assets/img/camera-white.png" />
          </FigCaption>
        </figure>
      </label>
    </AvatarField>
  )
}

const formatBytes = (value) => {
  return numeral(value).format('0.0 b')
}

AvatarInputFile.propTypes = {
  name: PropTypes.string.isRequired,
  src: PropTypes.string,
  diameter: PropTypes.number,
  maxSize: PropTypes.number,
  accept: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
}

AvatarInputFile.defaultProps = {
  diameter: 120,
  maxSize: 5242880, // 5,242,880 Bytes => 5.2 MB
  accept: ['image/png', 'image/jpeg', 'image/gif'],
}

export default AvatarInputFile
