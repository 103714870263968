import { withStyles } from '@material-ui/styles'

// ----------------------------------------------------------------------

const GlobalStyles = withStyles(
  (theme) => ({
    '@global': {
      '*': {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
      },
      '.MuiFormHelperText-root': {
        margin: '0px 5px 0 5px',
      },
      html: {
        width: '100%',
        height: '100%',
        '-ms-text-size-adjust': '100%',
        '-webkit-overflow-scrolling': 'touch',

        '& .MuiSlider-valueLabelLabel': {
          color: 'white !important',
        },
        '& .MuiSlider-valueLabelCircle': {
          height: `42px !important`,
          width: `42px !important`,
        },
        '& .MuiSlider-valueLabelOpen': {
          top: `-45px`,
        },
        '& .fc-bgevent': {
          opacity: `1 !important`,
        },
        '& .appointment-img': {
          minWidth: 150,
        },
        '& .mbsc-material .mbsc-selected .mbsc-calendar-cell-text': {
          background: `#f79023 !important`,
          borderColor: `#f79023 !important`,
        },
        '& .MuiSwitch-root': {
          alignItems: 'center',
        },
        '& .MuiPickersDay-root.Mui-selected': {
          color: theme.palette.common.white,
        },
        '& .ql-tooltip.ql-editing': {
          transform: 'translate(0px,-10px) !important',
          left: '0 !important',
        },
        '& .category-box': {
          '& .comment-box:last-of-type .MuiDivider-root': {
            display: 'none !important',
            opacity: 0,
          },
          '&:last-of-type': {
            marginBottom: `0 !important`,
          },
        },
      },
      body: {
        width: '100%',
        height: '100% ',
        '& .MuiButton-root': {
          [theme.breakpoints.up('xl')]: {
            // transform: 'scale(1.1)',
          },
        },
      },
      '#root': {
        width: '100%',
        height: '100%',
        '& > div': {
          height: '100%',
        },
      },
      input: {
        '&[type=number]': {
          MozAppearance: 'textfield',
          '&::-webkit-outer-spin-button': {
            margin: 0,
            WebkitAppearance: 'none',
          },
          '&::-webkit-inner-spin-button': {
            margin: 0,
            WebkitAppearance: 'none',
          },
        },
      },
      textarea: {
        '&::-webkit-input-placeholder': { color: theme.palette.text.disabled },
        '&::-moz-placeholder': {
          opacity: 1,
          color: theme.palette.text.disabled,
        },
        '&:-ms-input-placeholder': { color: theme.palette.text.disabled },
        '&::placeholder': { color: theme.palette.text.disabled },
      },
      a: { color: theme.palette.primary.main },
      img: { display: 'block', maxWidth: '100%' },

      '.MuiGrid-item .react-multi-email > input': {
        width: '100% !important',
      },
      '.print-display': {
        display: 'none',
      },
      '@media print': {
        '.print-display': {
          display: 'block !important',
        },
      },

      // Lazy Load Img
      '.blur-up': {
        WebkitFilter: 'blur(5px)',
        filter: 'blur(5px)',
        transition: 'filter 400ms, -webkit-filter 400ms',
      },
      '.blur-up.lazyloaded ': {
        WebkitFilter: 'blur(0)',
        filter: 'blur(0)',
      },
      '.pac-container': {
        zIndex: 2000,
      },
      '.pac-logo:after': {
        backgroundImage: 'none',
      },
      '.comment-block': {
        '&:hover .quick-edit': {
          display: 'inline-flex',
        },
      },
      '.swal-text': {
        textAlign: 'center !important',
      },
    },
  }),
  { index: 999 },
)(() => null)

export default GlobalStyles
