import { alpha , experimentalStyled as styled } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

export const LabelCalendar = styled(Box)(
  ({ backgroundColor, hoverColor, textColor }) => ({
    background: backgroundColor,
    color: textColor || '#000',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '4px 0',
    marginBottom: '4px',
    textAlign: 'center',
    borderRadius: '2px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    ':hover': {
      color: hoverColor || '#ecb184',
      cursor: 'pointer',
    },
  }),
)
