import { createSlice } from '@reduxjs/toolkit'
// utils

const initialState = {
  isAdmin: false,
  error: false,
  events: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
}

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    // SwitchAdmin
    isAdministrator(state, action) {
      state.isAdmin = true
    },

    isActiveUser(state, action) {
      state.isAdmin = false
    },
  },
})

// Reducer
export default slice.reducer
// Actions
export const { isAdministrator, isActiveUser } = slice.actions
