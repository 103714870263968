import _axiosApi from './_axiosApi'

export const register = (registerData) =>
  _axiosApi.post('/v1/auth/register', registerData)

/**
 * Check if user can do some action
 * @param {{ user: string, action: string }} data
 * @returns {Boolean}
 */
export const canUser = (data) => {
  const { user, action } = data
  return _axiosApi
    .post('/users/check-permission', {
      user_id: user,
      permission: action,
    })
    .then((res) => res.data.permission)
}

export const login = (email, password) =>
  _axiosApi
    .post(`/v2/auth/login`, { email, password }, { withCredentials: true })
    .then((res) => res.data)

export const getMe = () => _axiosApi.get('/v2/auth/me').then((res) => res.data)

export const refreshToken = () =>
  _axiosApi
    .post(`/v2/auth/refresh-token`, null, { withCredentials: true })
    .then((res) => res.data)

export const logOut = () =>
  _axiosApi.post(`/v2/auth/logout`, null, { withCredentials: true })

export const changePassword = (data) =>
  _axiosApi.post('/v2/auth/change-password', data)

export const signUp = ({
  first_name: firstName,
  last_name: lastName,
  company_name: companyName,
  email,
  password,
  phone,
  terms_of_policy: termsOfPolicy,
  receive_communication: receiveCommunication,
}) =>
  _axiosApi
    .post('/v2/auth/sign-up', {
      first_name: firstName,
      last_name: lastName,
      company_name: companyName,
      email,
      password,
      phone,
      terms_of_policy: termsOfPolicy,
      receive_communication: receiveCommunication,
    })
    .then((res) => res.data)

export const forgotPassword = ({ email }) =>
  _axiosApi.post('/v2/auth/forgot-password', { email })

export const resetPassword = ({ email, password, code }) =>
  _axiosApi
    .post('/v2/auth/reset-password', {
      email,
      password,
      code,
    })
    .then((res) => res.data)
