import _axiosApi from './_axiosApi'

// *** QuickBooks ***

export const getInuitQuickBooksUserData = (userId) =>
  _axiosApi.get(`/v2/integration/inuit-quickbooks/${userId}`)

export const connectInuitQuickBooks = (data) =>
  _axiosApi.post('/v2/integration/inuit-quickbooks', data)

export const isConnectedToInuitQuickBooks = (subscriberId) =>
  _axiosApi.get(`/v2/integration/inuit-quickbooks/${subscriberId}`)

// *** Google Calendar ***

export const getUserGoogleCalendars = (userId) =>
  _axiosApi.get(`/integration/google-calendar/${userId}/calendars`)

export const getUserGoogleCalendarEvent = (
  userId,
  calendarId,
  startDate,
  endDate,
) =>
  _axiosApi.get(
    `/integration/google-calendar/${userId}/calendars/${calendarId}/events`,
    { params: { startDate, endDate } },
  )
