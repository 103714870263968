/* eslint-disable react/forbid-prop-types */
import { Box, Button, Grid, Stack, Typography } from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import { useState } from 'react'
import dayjs from 'dayjs'
import TooltipItem from '../../../../../../../_settings/TooltipItem'
import NewEventForm from './NewEventForm'

const AddEvents = ({
  events,
  defaultStartTime,
  defaultDuration,
  inspectorOptions,
  setEvents,
}) => {
  const [today] = useState(dayjs().format('YYYY-MM-DD'))
  const [startDate] = useState(dayjs(`${today} ${defaultStartTime}`).toDate())
  const [endDate] = useState(
    dayjs(startDate).add(defaultDuration, 'hour').toDate(),
  )

  const addEmptyEvent = () => {
    const updatedEvents = events
    updatedEvents.push({
      ...newEventObject,
      id: uuidv4(),
      start_date: startDate,
      end_date: endDate,
    })
    setEvents(updatedEvents)
  }

  const updateEvent = (e) => {
    const { value, name } = e.target
    const [field, index] = name.split('---')
    setEvents(update(events, { [index]: { $merge: { [field]: value } } }))
  }

  const onSelectChange = (value, name) => {
    const [field, index] = name.split('---')
    setEvents(update(events, { [index]: { $merge: { [field]: value } } }))
  }

  const deleteEvent = (index) => {
    setEvents(update(events, { $splice: [[index, 1]] }))
  }

  return (
    <Box fullWidth sx={{ py: 2 }}>
      <Typography variant="subtitle1">
        Sub-Events (reminders)
        <TooltipItem
          item={{
            text: '?',
            body: 'These are calendar events like radon pick-up/drop-off that will be associated with this appointment. They will show up in appointment Details and the calendar entry will link to this appointment.',
          }}
        />
      </Typography>
      <Stack spacing={2} sx={{ my: 2 }}>
        {events?.map((event, index) => (
          <NewEventForm
            key={event.id}
            index={index}
            event={event}
            updateEvent={updateEvent}
            onSelectChange={onSelectChange}
            deleteEvent={deleteEvent}
            inspectorOptions={inspectorOptions}
          />
        ))}
      </Stack>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'center', my: 2 }}
      >
        <Button variant="primary" onClick={addEmptyEvent}>
          Add Event
        </Button>
      </Grid>
    </Box>
  )
}

const newEventObject = {
  id: '',
  name: '',
  description: '',
  inspector: null,
  start_date: null,
  end_date: null,
}

AddEvents.propTypes = {
  events: PropTypes.array.isRequired,
  defaultStartTime: PropTypes.string.isRequired,
  defaultDuration: PropTypes.number.isRequired,
  inspectorOptions: PropTypes.array.isRequired,
  setEvents: PropTypes.func.isRequired,
}

export default AddEvents
