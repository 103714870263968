/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import { Autocomplete, Stack, TextField } from '@material-ui/core'
import { webhookOptions as WEBHOOK_OPTIONS } from '../../../../../../../../../constants/action-variables'

const WebhookDetails = ({ webhook_url, webhook_data, updateAction }) => {
  const onChange = (event) => {
    const { name, value } = event.target
    updateAction(name, value)
  }

  return (
    <Stack spacing={2} sx={{ my: 2 }}>
      <TextField
        fullWidth
        name="webhook_url"
        label="Webhook URL"
        size="small"
        value={webhook_url}
        onChange={onChange}
      />
      <Autocomplete
        multiple
        fullWidth
        name="webhook_data"
        options={WEBHOOK_OPTIONS}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        value={webhook_data}
        filterSelectedOptions
        onChange={(_event, newValue) => updateAction('webhook_data', newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label="Payload"
            // placeholder="Payload"
          />
        )}
      />
    </Stack>
  )
}

WebhookDetails.propTypes = {
  webhook_url: PropTypes.string,
  webhook_data: PropTypes.array,
  updateAction: PropTypes.func,
}

export default WebhookDetails
