import axiosApi from './_axiosApi'

export const createTechnician = (formData) =>
  axiosApi
    .post('/v2/technicians', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data)

export const getTechnicianList = ({ subscriberId, cursor, perPage }) =>
  axiosApi
    .get('/v2/technicians', { params: { subscriberId, cursor, perPage } })
    .then((res) => res.data)

export const searchTechnicians = ({ subscriberId, cursor, perPage, text }) =>
  axiosApi
    .get('/v2/technicians', {
      params: { subscriberId, cursor, perPage, text },
    })
    .then((res) => res.data)

export const searchTechniciansAvailability = ({
  subscriberId,
  fromDate,
  toDate,
}) =>
  axiosApi
    .get('/v2/technicians/availability', {
      params: { subscriberId, fromDate, toDate },
    })
    .then((res) => res.data)

export const getTechnician = ({ subscriberId, technicianId }) =>
  axiosApi
    .get(`/v2/technicians/${technicianId}`, { params: { subscriberId } })
    .then((res) => res.data)

export const getTechnicianPerms = ({ subscriberId, technicianId }) =>
  axiosApi
    .get(`/v2/technicians/${technicianId}/perms`, { params: { subscriberId } })
    .then((res) => res.data)

export const updateTechnician = ({ technicianId, formData }) =>
  axiosApi
    .put(`/v2/technicians/${technicianId}`, formData)
    .then((res) => res.data)

export const changeTechnicianStatus = ({
  technicianId,
  subscriberId,
  newStatus,
}) =>
  axiosApi
    .post(`/v2/technicians/${technicianId}/status`, { subscriberId, newStatus })
    .then((res) => res.data)

export const updateTechnicianPerms = ({ technicianId, ...newData }) =>
  axiosApi
    .put(`/v2/technicians/${technicianId}/perms`, newData)
    .then((res) => res.data)

export const deleteTechnician = ({ technicianId, subscriberId }) =>
  axiosApi
    .delete(`v2/technicians/${technicianId}`, { data: { subscriberId } })
    .then((res) => res.data)

export const exportTechnicians = (subscriberId) =>
  axiosApi
    .get('/v2/technicians/export-file', {
      params: { subscriberId },
      responseType: 'blob',
    })
    .then((res) => {
      // Extract "filename" and "mime-type" from "header" response
      const contentDisposition = res.headers['content-disposition']
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1]
        : ''
      const mimeType = res.headers['content-type']

      return {
        blob: res.data,
        mimeType,
        filename,
      }
    })

export const searchGlobalTechnicians = (text) =>
  axiosApi
    .get(`/v2/technicians/global-search`, {
      params: { text },
    })
    .then((res) => res.data)
