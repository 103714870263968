/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import { Fragment, useMemo, useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import update from 'immutability-helper'
import { v4 as uuid } from 'uuid'
import PreviewActionsModal from './PreviewActions/PreviewActions'
import AddOrEditAction from './AddOrEditAction/AddOrEditAction'

const ActionsSection = ({
  isMobile,
  actionGroups,
  noSubtitle,
  updateGroups,
  updateActions,
  serviceOptions,
}) => {
  const [previewModalOpen, setPreviewModalOpen] = useState(false)
  const [actionModal, setActionModal] = useState({
    isOpen: false,
    groupId: null,
    action: null,
    step: null,
  })

  // Extract "services" and "addOns" from serviceOptions
  const OPTIONS = useMemo(() =>
    serviceOptions.reduce(
      (acc, currentService) => {
        const { id, name, add_ons } = currentService
        const newService = { id, label: name }
        const newAddOns = add_ons.map((item) => ({
          id: item.id,
          label: `${name} > ${item.name}`,
        }))
        return {
          services: [...acc.services, newService],
          addOns: [...acc.addOns, ...newAddOns],
        }
      },
      { services: [], addOns: [] },
    ),
  )

  const openPreview = () => {
    setPreviewModalOpen(true)
  }

  const closePreview = () => {
    setPreviewModalOpen(false)
  }

  const openAddOrEdit = (groupId, action, step) => {
    setActionModal({
      isOpen: true,
      groupId: groupId || null,
      action: action || null,
      step: step || null,
    })
  }

  const closeAddOrEdit = () => {
    setActionModal({ isOpen: false, groupId: null, action: null, step: null })
  }

  const updateOneAction = (groupId, action) => {
    const groupIndex = actionGroups.findIndex((group) => group.id === groupId)
    // Get a clone of current actions
    const currentActions = actionGroups[groupIndex].actions
    const actionIndex = currentActions.map((a) => a.id).indexOf(action.id)
    // Generate new actions array
    const newActions = update(currentActions, {
      [actionIndex]: { $set: action },
    })
    // Generate the new actionGroups array
    const newActionGroups = update(actionGroups, {
      [groupIndex]: { $merge: { actions: newActions } },
    })
    updateGroups(newActionGroups)
  }

  const addNewAction = (groupId, newAction) => {
    const groupIndex = actionGroups.map((a) => a.id).indexOf(groupId)
    // Get a clone of current actions and push the new one
    const updatedActions = actionGroups[groupIndex].actions
    updatedActions.push(newAction)
    // Generate the new actionGroups array
    const newActionGroups = update(actionGroups, {
      [groupIndex]: { $merge: { actions: updatedActions } },
    })
    updateGroups(newActionGroups)
  }

  const cloneAction = (groupId, action) => {
    // Create action clone
    const { _id, ...actionWithoutId } = action
    const newAction = { ...actionWithoutId, id: uuid() }
    addNewAction(groupId, newAction)
  }

  return (
    <Fragment>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '15px',
          justifyContent: 'center',
          ...(isMobile && { marginBottom: '7px' }),
        }}
      >
        Actions enabled on this appointment!
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ ...(isMobile && { display: 'flex', justifyContent: 'center' }) }}
      >
        <Button
          variant="primary"
          sx={{ transform: 'scale(1)' }}
          onClick={openPreview}
        >
          Preview Actions
        </Button>

        {/* Preview Actions (modal) */}
        <PreviewActionsModal
          open={previewModalOpen}
          onClose={closePreview}
          actionGroups={actionGroups}
          noSubtitle={noSubtitle}
          openEditAction={openAddOrEdit}
          cloneAction={cloneAction}
          updateGroups={updateGroups}
          updateActions={updateActions}
          updateOneAction={updateOneAction}
          serviceOptions={OPTIONS.services}
          serviceAddOnsOptions={OPTIONS.addOns}
        />

        {/* Add or Edit Action (modal) */}
        <AddOrEditAction
          isOpen={actionModal.isOpen}
          step={actionModal.step}
          groupId={actionModal.groupId}
          selected={actionModal.action}
          closeModal={closeAddOrEdit}
          serviceOptions={OPTIONS.services}
          serviceAddOnsOptions={OPTIONS.addOns}
          addAction={addNewAction}
          updateAction={updateOneAction}
        />
      </Grid>
    </Fragment>
  )
}

ActionsSection.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  actionGroups: PropTypes.array.isRequired,
  noSubtitle: PropTypes.bool,
  updateGroups: PropTypes.func,
  updateActions: PropTypes.func,
  serviceOptions: PropTypes.array,
}

export default ActionsSection
