export const getCalendarSelectedDateTime = (state) => {
  return state.calendarv2.selectedDateTime
}

export const getCalendarSelectedAppointmentId = (state) => {
  return state.calendarv2.selectedAppointmentId
}

export const getCalendarStoreTechnicians = (state) => {
  return state.calendarv2.technicians
}

export const getCalendarStoreEvents = (state) => {
  return state.calendarv2.events
}

export const getCalendarLoading = (state) => {
  return state.calendarv2.isLoading
}

export const getUserCalendars = (state) => {
  return state.calendarv2.userCalendars
}

export const getUserCalendarIsSelected = (state) => {
  return state.calendarv2.calendarIsSelected
}
