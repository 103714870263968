import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  dataLoaded: false,
  selectedEvent: null,
  selectedAppointment: null,
  isEdit: false,
  timeZone: null,
  type: '',
}

const slice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload.selectedEvent
      state.isEdit = true
      state.type = 'edit'
    },
    setSelectedAppointmentNewEvent: (state, action) => {
      state.selectedAppointment = action.payload.selectedAppointment
      state.isEdit = false
      state.type = 'new'
    },
    setSelectedAppointment: (state, action) => {
      state.selectedAppointment = action.payload.selectedAppointment
      state.isEdit = true
    },
    setTypeActionEvent: (state, action) => {
      state.type = action.payload.type
    },
    clearSelectedAppointment: (state, action) => {
      state.selectedAppointment = null
      state.isEdit = false
    },
    clearSelectedEvent: (state, action) => {
      state.selectedEvent = null
      state.isEdit = false
    },
    setTimeZone: (state, action) => {
      state.timeZone = action.payload.timeZone
    },
  },
})

export const {
  setSelectedAppointment,
  setSelectedAppointmentEditEvent,
  setSelectedAppointmentNewEvent,
  clearSelectedAppointment,
  setSelectedEvent,
  clearSelectedEvent,
  setTimeZone,
  setTypeActionEvent,
} = slice.actions
export default slice.reducer
