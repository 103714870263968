/* eslint-disable camelcase */
import axiosApi from './_axiosApi'
import isEmpty from '../utils/is-empty'
import { Gateways } from '../constants/payments'
import getGatewaySlug from '../utils/getGatewaySlug'
import { barrerTocken } from '../constants/defaultValues'

// * Queries & Mutations *

/**
 * Get team appointment settings
 * @param {string} subscriberId - User ID.
 */
export const getTeamAppointmentSettings = (subscriberId) =>
  axiosApi
    .get(`/inspections/people-data/${subscriberId}/1`)
    .then(transformSelectOptions)

export const createAppointment = (newAppointment) =>
  axiosApi.post('/inspections', newAppointment).then((res) => res.data)

export const getAppointmentData = (appointmentId, user, subscriber) =>
  axiosApi
    .post('/inspections/get-by-id', {
      inspection_id: appointmentId,
      user_id: user || subscriber,
      u_id: user,
    })
    .then(transformAppointmentData)

export const areInspectorsAvailable = ({
  subscriberId,
  inspectors,
  dateTime,
  appointmentId,
}) =>
  axiosApi
    .post(`/inspections/check-inspection-time/${subscriberId}`, {
      date_time: dateTime,
      inspectors,
      inspections_id: appointmentId,
    })
    .then((res) => res.data)

export const getAppointmentOptions = (subscriberId) =>
  axiosApi
    .get(`/inspections/people-data/${subscriberId}/1`)
    .then(transformAppointmentOptions)

export const getInspectionList = (
  subscriberId,
  userId,
  rowsPerPage,
  page,
  selectedOrderOption,
  searchs,
) => {
  return axiosApi.get(
    `/inspections-list/${subscriberId}/${userId}?pageSize=${rowsPerPage}&currentPage=${
      page + 1
    }&orderBy=${selectedOrderOption.column}&search=${searchs || ''}`,
    { headers: { Authorization: `Bearer ${barrerTocken}` } },
  )
}

// * Transformers *

const transformSelectOptions = ({ data }) => {
  const { subscription_data: subscriptionData } = data

  const activeServices = data.services.filter(
    (services) => services.status === 1,
  )

  const discountOptions = data.discounts.map(({ id, name }) => ({
    keys: id,
    label: name,
    value: id,
  }))

  const agreementOptions = data.agreements.map(({ id, name, content }) => ({
    keys: id,
    label: name,
    value: id,
    content,
  }))

  const templateOptions = data.templates.map(({ id, name, attachments }) => ({
    keys: id,
    label: name,
    value: id,
    attachments,
  }))

  const isLimitReached = isEmpty(subscriptionData?.is_inspection_limit_reached)
    ? false
    : subscriptionData.is_inspection_limit_reached

  return {
    orderId: String(data?.order_id) || '',
    serviceOptions: activeServices,
    discountOptions,
    agreementOptions,
    templateOptions,
    agentOptions: data?.agents || [],
    clientOptions: data?.clients || [],
    contractorOptions: data?.contractors || [],
    inspectorOptions: data?.inspectors || [],
    actionGroups: data?.actiongroups || [],
    isLimitReached,
  }
}

const transformAppointmentData = ({ data: resp }) => {
  const { status, data, user_settings, local_date_format } = resp
  const settings = {}

  if (status === 1) {
    // format attachments (filter falsy values)
    data.attachments = data.attachments?.filter((x) => !!x)
    // format settings
    settings.timezone = user_settings.local_timezone
    settings.dateFormat = local_date_format
    settings.enableActions = user_settings.enable_actions
    settings.schedulingEmailSettings = user_settings.scheduling_email_settings
    settings.canUserPublishReport = resp.can_publish_report
    settings.headerLogo = user_settings.header_logo
    // Get active gateway credentials
    const gatewaySlug = getGatewaySlug(user_settings.payment_type)
    settings.activeGateway = gatewaySlug
    switch (gatewaySlug) {
      case Gateways.AuthorizeNet:
        settings.pc = user_settings.payment_credentials || {}
        break
      case Gateways.Stripe:
        settings.pc = user_settings.stripe_credentials || {}
        break
      case Gateways.PayPal:
        settings.pc = user_settings.paypal_credentials || {}
        break
      case Gateways.Square:
        settings.pc = user_settings.square_credentials || {}
        break
      case Gateways.Fortis:
        settings.pc = user_settings.fortis_credentials || {}
        break
      default:
        settings.pc = {}
    }
  }

  return { status, data, settings }
}

const transformAppointmentOptions = ({ data }) => {
  const agreementOptions = data.agreements.map(({ id, name }) => ({
    keys: id,
    label: name,
    value: id,
  }))

  const templateOptions = data.templates.map(({ id, name, attachments }) => ({
    key: id,
    label: name,
    value: id,
    attachments,
  }))

  const activeServices = data.services.filter(
    (services) => services.status === 1,
  )

  return {
    agreementOptions,
    templateOptions,
    serviceOptions: activeServices,
    technicianOptions: data?.inspectors || [],
    clientOptions: data?.clients || [],
    agentOptions: data?.agents || [],
    listingAgentOptions: data?.agents || [],
    contractorOptions: data?.contractors || [],
  }
}

export const searchAppointments = ({ subscriberId, cursor, perPage, text }) =>
  axiosApi
    .get('/v2/appointments/list', {
      params: { subscriberId, cursor, perPage, text },
    })
    .then((res) => res.data)
