/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'

const AGREEMENT_FIELDS = [
  '{{CLIENT_NAME}}',
  '{{PROPERTY_ADDRESS}}',
  '{{APPOINTMENT_DATE}}',
  '{{APPOINTMENT_TIME}}',
  '{{APPOINTMENT_PRICE}}',
  '{{TECHNICIAN_SIGNATURE}}',
  '{{CURRENT_DATE}}',
  '{{SERVICES}}',
  '{{FEES}}',
]

const agreementsBuilder = ({
  companyName,
  fullAddress,
  dateTime,
  clients,
  services,
  fees,
  agreements,
  // settings,
  currencySymbol,
  total,
}) => {
  const values = {}

  AGREEMENT_FIELDS.map((field) => {
    switch (field) {
      case '{{COMPANY_NAME}}':
        values[field] = companyName
        break
      case '{{CLIENT_NAME}}': {
        const clientNames = clients.map((client) =>
          `${client.first_name} ${client.last_name}`.trim(),
        )
        values[field] = arrayToTextList(clientNames)
        break
      }
      case '{{PROPERTY_ADDRESS}}':
        values[field] = fullAddress
        break
      case '{{APPOINTMENT_DATE}}':
        values[field] = dayjs(dateTime).format('MM/DD/YYYY')
        break
      case '{{APPOINTMENT_TIME}}':
        values[field] = dayjs(dateTime).format('hh:mm a')
        break
      case '{{APPOINTMENT_PRICE}}':
        values[field] = `${currencySymbol}${total}`
        break
      case '{{TECHNICIAN_SIGNATURE}}': {
        values[field] = '-------' // TODO: Get technician signature
        break
      }
      case '{{CURRENT_DATE}}':
        values[field] = dayjs().format('MM/DD/YYYY')
        break
      case '{{SERVICES}}': {
        const serviceNames = services.map((service) => service.name.trim())
        values[field] = arrayToTextList(serviceNames)
        break
      }
      case '{{FEES}}': {
        const feesData = fees.map((fee) => {
          const { line_item, pretax_amount, tax_amount } = fee
          const total = (Number(pretax_amount) + Number(tax_amount)).toFixed(2)
          return `${line_item} (${currencySymbol}${total})`
        })
        values[field] = arrayToTextList(feesData)
        break
      }
      default:
        values[field] = ''
    }
    return true
  })

  return agreements.map((agreement) => {
    const { value, label, content: real_content } = agreement
    let content = real_content

    const variables = real_content.match(/{{[{]?(.*?)[}]?}}/g) || []
    variables.map((variable) => {
      content = content.replace(variable, values[variable])
      return true
    })

    return {
      id: uuidv4(),
      agreementId: value,
      name: label,
      real_content,
      content,
      signed: false,
    }
  })
}

// Join all elements of the array with "," except the last one with "and"
export const arrayToTextList = (stringArray) => {
  // e.g. ["Tom", "Dick", "Dany", "John"] --> "Tom, Dick, Dany and John"
  return stringArray.join(', ').replace(/, ([^,]*)$/, ' and $1')
}

agreementsBuilder.propTypes = {
  fullAddress: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  clients: PropTypes.array.isRequired,
  services: PropTypes.array.isRequired,
  fees: PropTypes.array.isRequired,
  agreements: PropTypes.array.isRequired,
  // settings: PropTypes.object.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
}

export default agreementsBuilder
