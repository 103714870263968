import { alpha } from '@material-ui/core/styles'

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to right, ${color1}, ${color2})`
}
function createGlass(color1, color2) {
  return `linear-gradient(to top left, ${color1}, ${color2})`
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#aeaeae',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
}

export const PRIMARY = {
  lighter: '#FEF4D4',
  light: '#FED680',
  main: '#f79023',
  dark: '#B86B1A',
  darker: '#784611',
  contrastText: '#eee',
  specialText: '#e86f31',
}
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff',
}
export const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
}
export const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
}
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
}
export const ERROR = {
  lighter: '#FFDED3',
  light: '#FAA09B',
  main: '#EB6B63',
  dark: '#D4554E',
  darker: '#A6322F',
  contrastText: '#fff',
}

const GRADIENTS = {
  primary: createGradient(ERROR.dark, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
}
const GLASS = {
  glassDark: createGlass(alpha(PRIMARY.main, 0.45), alpha(GREY[600], 0.45)),
  glassDarkSecondary: createGlass(
    alpha(SECONDARY.main, 0.45),
    alpha(GREY[700], 0.45),
  ),
  glassDarkError: createGlass(alpha(ERROR.main, 0.45), alpha(GREY[700], 0.45)),
  glassDarkInfo: createGlass(alpha(INFO.main, 0.45), alpha(GREY[700], 0.45)),
  glassDarkGrey: createGlass(alpha(GREY[600], 0.45), alpha(GREY[700], 0.45)),
  glassLight: createGlass(alpha(GREY[900], 0.7), alpha(GREY[800], 0.7)),
}

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  bgGrey: '#ececec',
  grey: GREY,
  gradients: GRADIENTS,
  glass: GLASS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
}

const palette = {
  light: {
    ...COMMON,
    text: { primary: GREY[800], secondary: GREY[600], disabled: '#b2b2b2' },
    background: {
      paper: '#fff',
      default: '#fff',
      neutral: GREY[200],
      customGrey: '#f5f8fb',
      border: GREY[400],
      gradient: '#feeddb',
    },
    button: { grey: '#d2d2d2' },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    text: { primary: '#fff', secondary: GREY[500], disabled: '#b2b2b2' },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: GREY[500_16],
      customGrey: '#f5f8fb',
      border: GREY[400],
      gradient: '#AD670B',
    },
    button: { grey: GREY[700] },
    action: { active: GREY[500], ...COMMON.action },
  },
}

export default palette
