// eslint-disable-next-line no-var
var accessToken = ''

export const setAccessToken = (value) => {
  accessToken = value
}

export const getAccessToken = () => {
  return accessToken
}

export const getAuth = () => {
  // TODO: Get data from service
  return {}
}
