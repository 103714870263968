import { useTheme } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import { Box, StepConnector } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp'

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#f79023',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#f79023',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)

const QontoStepIcon = ({ active, completed }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        height: 22,
        display: 'flex',
        color: '#eaeaf0',
        alignItems: 'center',
        ...(active && { color: theme.palette.primary.main }),
      }}
    >
      {completed ? (
        <CheckCircleSharpIcon
          sx={{ color: theme.palette.primary.main, zIndex: 1, fontSize: 18 }}
        />
      ) : (
        <Box
          sx={{
            width: 9,
            height: 9,
            borderRadius: '50%',
            bgcolor: 'currentColor',
          }}
        />
      )}
    </Box>
  )
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
}

export { QontoConnector, QontoStepIcon }
