import axios from 'axios'
import { apiURL } from '../constants/defaultValues'
import { getAccessToken, setAccessToken } from '../accessToken'

const BASE_URL = `${apiURL}/api`

// Helper: Renew the short-term token
const refreshToken = () =>
  axios
    .post(`${BASE_URL}/v2/auth/refresh-token`, null, { withCredentials: true })
    .then((res) => res.data)

// Create an Axios instance for the ReportON API with custom config
const axiosApi = axios.create({
  withCredentials: false,
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

// Global request interceptor
axiosApi.interceptors.request.use(
  (config) => {
    // Set current "accessToken" to all request
    config.headers.Authorization = `Bearer ${getAccessToken()}`
    return config
  },
  (error) => Promise.reject(error),
)

// Global response interceptor
axiosApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    // accessToken expired or invalid ?
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true // Mark the request as retried to avoid infinite loops.
      try {
        // Try to refresh the "accessToken"
        const { success, accessToken } = await refreshToken()
        if (success) {
          setAccessToken(accessToken)
          // Update the request headers to add the new accessToken
          originalRequest.headers.Authorization = `Bearer ${accessToken}`
          // Retry the original request
          return axiosApi(originalRequest)
        }
        // TODO: Do log out, clear storage & redirect to the login page
      } catch (err) {
        const errMessage = `We were unable to connect to the server. Please try again later or contact us for assistance.`
        // eslint-disable-next-line no-console
        console.log(errMessage)
        throw err
      }
    }

    // For all other errors, return the error as is.
    return Promise.reject(error)
  },
)

export default axiosApi
