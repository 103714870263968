/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useSnackbar } from 'notistack'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import tzPlugin from 'dayjs/plugin/timezone'
import { v4 as uuid } from 'uuid'
import { AddButton } from './SubmitAppointment.styles'
import validateData from '../../../utils/validators/validator'
import agreementsBuilder from '../../../utils/agreementsBuilder'
import emailSettingsBuilder from '../../../utils/emailSettingsBuilder'
import { createAppointment } from '../../../../../../services/inspections.service'
import { AuthContext } from '../../../../../../contexts/AuthContext'

dayjs.extend(utc)
dayjs.extend(tzPlugin)

function Submit(props) {
  const { user } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const subscriberId = user?.subscriber_id || props?.subscriberId || null
  const { timezone } = props.isIFrame ? user.companySettings : props.settings
  const userId = user?.id || null

  const {
    location,
    services,
    dateTime,
    people,
    information,
    settings,
    isMobile,
    closeModal,
    setSelectedEvent,
  } = props

  const onSubmit = async () => {
    setLoading(true)

    let apptDate = null
    if (dateTime) {
      const cleanDateTime = dayjs(dateTime).format('YYYY-MM-DD HH:mm')
      apptDate = dayjs.tz(cleanDateTime, timezone).utc()
    }

    // Validator
    const incomingData = { ...props, dateTime: apptDate, timezone }
    const { isValid, errors } = validateData(incomingData)
    if (!isValid) {
      const error =
        errors.location ||
        errors.services ||
        errors.dateTime ||
        errors.people ||
        errors.information

      setLoading(false)
      return enqueueSnackbar(error, {
        autoHideDuration: 5000,
        variant: 'error',
      })
    }

    // Destructuring as helpers
    const { coordinates } = location
    const { newServices } = services
    const { technicians, clients, agents, listingAgents, contractors } = people
    const { disable_automated_notifications } = information
    const { acceptEvents, acceptActions, notifications } = settings

    const sum = newServices.fees.reduce(
      (acc, fee) => {
        const amount = Number(fee.pretax_amount) + Number(fee.tax_amount)
        return {
          total: acc.total + amount,
          duration: acc.duration + Number(fee.duration),
        }
      },
      { total: 0, duration: 0 },
    )

    // Builders
    const servicesList = cleanServices(newServices.services)
    const serviceEvents = acceptEvents ? services.events : []
    const inspectionEvents = serviceEvents
      .filter((event) => !!event.inspector && event.inspector.id)
      .map((ev) => {
        const startDate = dayjs(ev.start_date).format('YYYY-MM-DD HH:mm')
        const endDate = dayjs(ev.end_date).format('YYYY-MM-DD HH:mm')
        return {
          ...ev,
          inspector: ev.inspector.id,
          start_date: dayjs.tz(startDate, timezone).utc(),
          end_date: dayjs.tz(endDate, timezone).utc(),
        }
      })
    const agreements = agreementsBuilder({
      companyName: settings.companyName,
      fullAddress: location.fullAddress,
      dateTime: apptDate,
      clients,
      services: servicesList,
      fees: newServices.fees,
      agreements: newServices.agreements,
      currencySymbol: settings.currencySymbol,
      total: Number(sum.total),
    })
    const attachments =
      newServices.templates.map(({ attachments }) => attachments).flat(1) || []
    const emailSettings = emailSettingsBuilder({ notifications })

    const newAppointment = {
      // * location (done)
      address: location.streetAndNumber,
      city: location.city,
      state: location.state,
      zip_code: location.zipCode,
      square_feet: location.square_feet,
      year_built: location.year_built,
      foundation: Number(location.foundation),
      address_lat_long: `${coordinates.lat},${coordinates.lng}`,

      // * services (done)
      duration: Number(newServices.manualDuration || sum.duration),
      discount_code: services.discountCode,
      templates: newServices.templates.map((template) => template.value),
      attachments,
      agreements,
      agreement_signed: !newServices.agreements.length,
      services: servicesList,
      fees: newServices.fees,
      inspection_events: inspectionEvents,
      paid: Number(sum.total) === 0,
      payment_notes: '',
      total_fee: Number(sum.total),
      outstanding: Number(sum.total),
      require_payment: services.requirePayment,

      // * dateTime (done)
      date_time: apptDate,

      // * people (done)
      inspectors: technicians.map((technician) => technician.user_id),
      clients: clients.map((client) => client.id),
      agents: agents.map((agent) => agent.id),
      listing_agents: listingAgents.map((listingAgent) => listingAgent.id),
      contractors: contractors.map((contractor) => contractor.id),
      invisible_inspectors: [],

      // * information (done)
      order_id: Number(information.order_id),
      confirm_inspection: information.confirm_inspection,
      disable_automated_notifications: disable_automated_notifications,
      send_notifications: !disable_automated_notifications,
      occupied: information.occupied,
      utilities_on: information.utilities_on,
      inspection_notes: '', // is commented in the form so empty string
      action_groups: acceptActions ? information.action_groups : [],
      internal_notes: services.paymentNotes
        ? [
            {
              id: uuid(),
              text: services.paymentNotes,
              isEdited: false,
              createdBy: userId,
              createdAt: dayjs.utc().format(),
            },
          ]
        : [],

      // * session (done)
      added_by: userId,
      subscriber_id: subscriberId,

      // * settings (done)
      ...emailSettings,
    }

    // Try to save new appointment
    try {
      const result = await createAppointment(newAppointment)
      if (result._id) {
        const succMsg = `Appointment has been added successfully!`
        enqueueSnackbar(succMsg, { autoHideDuration: 7000, variant: 'success' })
        closeModal()
      } else {
        const error = 'Error while creating appointment'
        enqueueSnackbar(error, { autoHideDuration: 5000, variant: 'error' })
      }
    } catch (_err) {
      const error = `Failed to add appointment! Please try after sometime`
      enqueueSnackbar(error, { autoHideDuration: 5000, variant: 'error' })
    } finally {
      if (setSelectedEvent) setSelectedEvent(null)
      setLoading(false)
    }
    return null
  }

  return (
    <AddButton
      variant="contained"
      id="add-inspection"
      onClick={onSubmit}
      sx={{
        width: '100%',
        padding: '0',
        maxWidth: '100%',
        transform: 'scale(1)',
        borderRadius: 10,
        ...(isMobile
          ? {
              fontSize: '13px',
              height: '40px',
              right: '-8px',
              width: '80px',
            }
          : { height: '50px' }),
      }}
      disabled={loading}
    >
      Submit
    </AddButton>
  )
}

const cleanServices = (services) =>
  services.map((service) => {
    // Convert templates & agreements to array of IDs
    return {
      ...service,
      templates: service.templates.map((template) => template.id),
      agreements: service.agreements.map((agreement) => agreement.id),
    }
  })

Submit.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  services: PropTypes.oneOfType([PropTypes.object]).isRequired,
  dateTime: PropTypes.oneOfType([PropTypes.any]).isRequired,
  people: PropTypes.oneOfType([PropTypes.object]).isRequired,
  information: PropTypes.oneOfType([PropTypes.object]).isRequired,
  settings: PropTypes.oneOfType([PropTypes.object]).isRequired,
  closeModal: PropTypes.func.isRequired,
  setSelectedEvent: PropTypes.func,
  isIFrame: PropTypes.bool,
  subscriberId: PropTypes.string,
}

export default Submit
