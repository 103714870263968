// mock api
// import './_apis_'

// i18n
import './locales/i18n'

// highlight
import './utils/highlight'

// scroll bar
import 'simplebar/src/simplebar.css'

// map
import 'mapbox-gl/dist/mapbox-gl.css'

// lightbox
import 'react-image-lightbox/style.css'

// editor
import 'react-quill/dist/quill.snow.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

// slick-carousel
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// lazy image
import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'
import 'lazysizes/plugins/object-fit/ls.object-fit'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'

import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'
// material
import AdapterDateFns from '@material-ui/lab/AdapterDateFns'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
// redux
import { store } from './redux/store'
// contexts
import { SettingsProvider } from './contexts/SettingsContext'
// theme
import ThemeConfig from './theme'
import ThemePrimaryColor from './components/ThemePrimaryColor'
import NotistackProvider from './components/NotistackProvider'

//
import App from './App'
import * as serviceWorker from './serviceWorker'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './contexts/AuthContext'

// ----------------------------------------------------------------------

ReactDOM.render(
  <BrowserRouter>
    <ReduxProvider store={store}>
      <AuthProvider>
        <HelmetProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <ThemeConfig>
                <ThemePrimaryColor>
                  <NotistackProvider>
                    <App />
                  </NotistackProvider>
                </ThemePrimaryColor>
              </ThemeConfig>
            </SettingsProvider>
          </LocalizationProvider>    
        </HelmetProvider>
      </AuthProvider>
    </ReduxProvider>
  </BrowserRouter>,
  document.getElementById('root'),
)

// If you want to enable client cache, register instead.
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
