import { useContext } from 'react'
import { MAvatar } from './@material-extend'
import createAvatar from '../utils/createAvatar'
import { AuthContext } from '../contexts/AuthContext'

export default function MyAvatar({ ...other }) {
  const { user } = useContext(AuthContext)

  return (
    <MAvatar
      src={user?.profile_pic}
      alt={user?.first_name}
      color={
        user?.profile_pic ? 'default' : createAvatar(user?.first_name).color
      }
      {...other}
    >
      {createAvatar(user?.first_name).name}
    </MAvatar>
  )
}
