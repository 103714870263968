/* eslint-disable react/forbid-prop-types */
import 'react-quill/dist/quill.snow.css'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { ReactQuill } from './QuillEditor.style'

const QuillEditor = forwardRef(
  ({ innerRef, name, value, placeholder, onChange, ...props }) => {
    const handleChange = (html) => {
      onChange(name, html)
    }

    return (
      <ReactQuill
        {...props}
        name={name}
        ref={innerRef}
        theme="snow"
        onChange={handleChange}
        value={value}
        modules={QuillEditor.modules}
        formats={QuillEditor.formats}
        // bounds=".app"
        placeholder={placeholder}
      />
    )
  },
)

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
QuillEditor.modules = {
  toolbar: [
    [{ size: ['small', 'large'] }],
    [
      'bold',
      'italic',
      'underline',
      'strike',
      { color: [] },
      { background: [] },
    ],
    [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
    // ['link', 'image', 'video'],
    ['link', 'image'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
}

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
QuillEditor.formats = [
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'color',
  'background',
  'list',
  'ordered',
  'bullet',
  'align',
  'link',
  'image',
  'video',
  'clean',
]

QuillEditor.propTypes = {
  innerRef: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default QuillEditor
