import dayjs from 'dayjs'
import { alpha } from '@material-ui/core/styles'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import _axiosApi from './_axiosApi'
import { CALENDAR_VIEW } from '../constants/LocalStorageKeys'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)

export const getCalendarEvents = ({ startDate, endDate }) =>
  _axiosApi.get(`/v2/calendar`, {
    params: {
      startDate,
      endDate,
    },
  })

// POST => /api/v2/calendar/events
export const createEventFromCalendar = (payload) =>
  _axiosApi.post(`/v2/calendar/events`, payload).then((res) => res.data)

// PUT => /api/v2/calendar/events
export const updateEventFromCalendar = (requestPayload) =>
  requestPayload.type === 'inspSubEvent'
    ? _axiosApi
        .put(`/v2/calendar/events`, {
          ...requestPayload,
        })
        .then((response) => response.data)
    : _axiosApi
        .put(`/v2/calendar/events`, requestPayload)
        .then((response) => response.data)

// PUT => /api/v2/calendar/events
export const updateRescheduleAppointmentCalendar = ({
  appointmentId,
  type,
  dateTime,
}) =>
  _axiosApi
    .put(`/v2/calendar/events`, {
      appointmentId,
      type,
      dateTime,
    })
    .then((response) => response.data)
// en el caso de “inspection event”  se envia => {appointmentId, type, dateTime}

// PUT => /api/v2/calendar/events
export const calendarEventShare = (requestPayload) =>
  _axiosApi
    .post(`/v2/calendar/events/share`, requestPayload)
    .then((response) => response.data)

// DELETE => /api/v2/calendar/events
export const calendarEventDelete = ({ type, eventId }) =>
  _axiosApi
    .delete(`/v2/calendar/events`, {
      params: {
        type,
        eventId,
      },
    })
    .then((response) => response.data)

export const mapCalendarStoreEvents = (calendarStoreEvents, userTimeZone) => {
  return calendarStoreEvents.map((event) => {
    if (!!event.systemRef && event.systemRef.type === 'inspEvent') {
      return {
        id: event.systemRef.appointmentId,
        appointmentId: event.systemRef.appointmentId,
        googleEventId: event.googleEventId || '',
        title: event.title,
        title1: event.title,
        desc: event.apptData.address || event.description,
        description: event.description || '',
        start: dayjs(event.start?.dateTime).tz(userTimeZone).toDate(),
        end: dayjs(event.end?.dateTime).tz(userTimeZone).toDate(),
        timezone: userTimeZone,
        color: alpha(event.technician.eventColor, 0.9),
        realColor: event.technician.eventColor,
        resource: event.technician.id,
        paid: event.apptData.isPaid,
        agreement_signed: event.apptData.agreementSigned,
        published: event.apptData.isPublished,
        isEvent: false,
        systemRef: event.systemRef,
        dragBetweenSlots: true,
        dragInTime: true,
        editable: true,
        profile_pic: event.technician.profile_pic || '',
      }
    }
    if (
      !!event.systemRef &&
      (event.systemRef.type === 'inspSubEvent' ||
        event.systemRef.type === 'regularEvent')
    ) {
      return {
        id: event.systemRef.eventId,
        appointmentId: event.systemRef.appointmentId || '',
        googleEventId: event.googleEventId || '',
        title: event.title,
        title1: event.title,
        desc: event.description,
        description: event.description || '',
        start: dayjs(event.start?.dateTime).tz(userTimeZone).toDate(),
        end: dayjs(event.end?.dateTime).tz(userTimeZone).toDate(),
        timezone: event.start?.timezone || event.end?.timezone,
        color:
          event.systemRef.type === 'inspSubEvent'
            ? alpha(event.technician.eventColor, 0.4)
            : '#BDBDBD',
        realColor:
          event.systemRef.type === 'inspSubEvent'
            ? event.technician.eventColor
            : '#777676',
        resource: event.technician.id,
        isEvent: true,
        systemRef: event.systemRef,
        dragBetweenSlots: true,
        dragInTime: true,
        editable: true,
        profile_pic: event.technician.profile_pic || '',
      }
    }
    if (!!event.systemRef && event.systemRef.type === 'externalEvent') {
      return {
        id: event.googleEventId,
        appointmentId: event.systemRef.appointmentId || '',
        googleEventId: event.googleEventId || '',
        title: event.title,
        title1: event.title,
        desc: event.description,
        description: event.description || '',
        start: dayjs(event.start?.dateTime).tz(userTimeZone).toDate(),
        end: dayjs(event.end?.dateTime).tz(userTimeZone).toDate(),
        timezone: event.start?.timezone || event.end?.timezone,
        textColor: '#188FFDFF',
        color: '#BDBDBD',
        realColor: '#777676',
        resource: event.technician.id,
        isEvent: true,
        systemRef: event.systemRef,
        dragBetweenSlots: false,
        dragInTime: false,
        editable: false,
        profile_pic: event.technician.profile_pic || '',
      }
    }
    return null
  })
}

const unDoDragAndDropEvent = ({
  calendarEvents,
  storeSelectedEvent,
  setCalendarEvents,
  swal,
}) => {
  const tmpEvents = calendarEvents
  const tmpIndex = tmpEvents.findIndex((item) => {
    return item.id === storeSelectedEvent.id
  })
  tmpEvents[tmpIndex] = {
    ...tmpEvents[tmpIndex],
    title: storeSelectedEvent.name,
    desc: storeSelectedEvent.description,
    description: storeSelectedEvent.description,
    resource: storeSelectedEvent.inspector,
    start: storeSelectedEvent.start_date,
    end: storeSelectedEvent.end_date,
  }
  setCalendarEvents(tmpEvents)
  swal({
    title: 'Error when updating Event!',
    icon: 'error',
    dangerMode: true,
  })
}

const unDoDragAndDropAppointment = ({
  calendarEvents,
  storeSelectedAppointment,
  setCalendarEvents,
  swal,
}) => {
  const tmpEvents = calendarEvents
  const tmpIndex = tmpEvents.findIndex((item) => {
    return item.id === storeSelectedAppointment.appointmentId
  })
  tmpEvents[tmpIndex] = {
    ...tmpEvents[tmpIndex],
    start: storeSelectedAppointment.start_date,
    end: storeSelectedAppointment.end_date,
    resource: storeSelectedAppointment.technicianId,
  }
  setCalendarEvents(tmpEvents)
  swal({
    title: 'Error when updating Event!',
    icon: 'error',
    dangerMode: true,
  })
}

export const changeViewHelper = ({
  setView,
  setCalView,
  event,
  isDashboardComponent = false,
}) => {
  // ToDo: Timezones is not working properly according MobiScroll Docs
  // https://mobiscroll.com/docs/react/eventcalendar/scheduler
  // ...(!!timezone ? { timezones: [timezone] } : {}),
  let tmpCalView
  switch (event.target.value) {
    case 'week':
      tmpCalView = {
        schedule: {
          type: 'week',
          currentTimeIndicator: true,
          timeCellStep: 60,
          timeLabelStep: 60,
        },
      }
      break
    case 'day':
      tmpCalView = {
        schedule: {
          type: 'day',
          currentTimeIndicator: true,
          timeCellStep: 60,
          timeLabelStep: 60,
        },
      }
      break
    case 'month':
      tmpCalView = {
        calendar: {
          type: 'month',
          size: 1,
          labels: true,
          labels: 'all',
        },
      }
      break
    case '2months':
      tmpCalView = {
        calendar: {
          type: 'month',
          size: 2,
          labels: true,
          labels: 'all',
        },
      }
      break
    case 'dashboard':
      tmpCalView = {
        calendar: { type: 'week' },
        agenda: { type: 'day' },
      }
      break
    default:
      tmpCalView = {
        schedule: {
          type: 'week',
          currentTimeIndicator: true,
          timeCellStep: 60,
          timeLabelStep: 60,
        },
      }
  }

  if (!isDashboardComponent) {
    localStorage.setItem(CALENDAR_VIEW, event.target.value)
  }

  setView(event.target.value)
  setCalView(tmpCalView)
}

export const onEventRightClickHelper = ({
  dispatch,
  setDateRef,
  args,
  clearSelectedEvent,
  clearSelectedAppointment,
  setMenuOpen,
  setSelectedDateTime,
  setSelectedEvent,
  setSelectedAppointment,
  setOpen,
}) => {
  dispatch(clearSelectedEvent())
  dispatch(clearSelectedAppointment())

  const cleanDateTime = dayjs(args.date).format('YYYY-MM-DD HH:mm')
  dispatch(
    setSelectedDateTime({
      selectedDateTime: cleanDateTime,
      selectedAppointmentId:
        args.event.id !== args.event.googleEventId ? args.event.id : null,
    }),
  )
  setDateRef(args.domEvent.target)

  // Only if eventType is
  if (args.event.systemRef.type === 'inspSubEvent') {
    dispatch(
      setSelectedEvent({
        selectedEvent: {
          id: args.event.id,
          appointmentId: args.event.systemRef.appointmentId,
          eventId: args.event.systemRef.eventId,
          name: args.event.title,
          description: args.event.description,
          inspector: args.resource,
          start_date: args.event.start,
          end_date: args.event.end,
          type: 'inspSubEvent',
        },
      }),
    )
  } else if (args.event.systemRef.type === 'inspEvent') {
    dispatch(
      setSelectedAppointment({
        selectedAppointment: {
          appointmentId: args.event.id,
          type: 'inspEvent',
          start_date: args.event.start,
          end_date: args.event.end,
          technicianId: args.resource,
        },
      }),
    )
  } else if (args.event.systemRef.type === 'regularEvent') {
    dispatch(
      setSelectedEvent({
        selectedEvent: {
          id: args.event.id,
          eventId: args.event.systemRef.eventId,
          name: args.event.title,
          description: args.event.description,
          inspector: args.resource,
          start_date: args.event.start,
          end_date: args.event.end,
          type: 'regularEvent',
        },
      }),
    )
  }

  setOpen(false)
  setMenuOpen(true)
}

export const updateEventDragAndDropHelper = ({
  dispatch,
  setCalendarIsLoading,
  storeSelectedEvent,
  event,
  clearSelectedEvent,
  clearSelectedAppointment,
  setCalendarIsDoneLoading,
  userTimeZone,
  calendarEvents,
  setCalendarEvents,
  swal,
}) => {
  dispatch(setCalendarIsLoading())
  updateEventFromCalendar({
    appointmentId: storeSelectedEvent.appointmentId || null,
    eventId: storeSelectedEvent.eventId,
    type: event.event.systemRef.type,
    inspector: event.resource,
    startDate: dayjs
      .tz(dayjs(event.event.start).format('YYYY-MM-DD HH:mm'), userTimeZone)
      .format(),
    endDate: dayjs
      .tz(dayjs(event.event.end).format('YYYY-MM-DD HH:mm'), userTimeZone)
      .format(),
    title: storeSelectedEvent.name,
  })
    .then((res) => {
      if (!res.success) {
        unDoDragAndDropEvent({
          calendarEvents,
          storeSelectedEvent,
          setCalendarEvents,
          swal,
        })
      }
    })
    .catch((err) => {
      unDoDragAndDropEvent({
        calendarEvents,
        storeSelectedEvent,
        setCalendarEvents,
        swal,
      })
    })
    .finally(() => {
      dispatch(clearSelectedEvent())
      dispatch(setCalendarIsDoneLoading())
    })
}

export const onEventDragAndDropHelperStart = ({
  event,
  dispatch,
  setSelectedAppointment,
  setSelectedEvent,
  swal,
}) => {
  if (event.event.systemRef.type === 'externalEvent') {
    swal({
      title: 'Not Supported',
      icon: 'error',
      dangerMode: true,
    })
  } else if (event.event.systemRef.type === 'inspEvent') {
    dispatch(
      setSelectedAppointment({
        selectedAppointment: {
          appointmentId: event.event.id,
          type: 'inspEvent',
          start_date: event.event.start,
          end_date: event.event.end,
          technicianId: event.resource,
        },
      }),
    )
  } else {
    dispatch(
      setSelectedEvent({
        selectedEvent: {
          id: event.event.id,
          appointmentId: event.event.systemRef.appointmentId,
          eventId: event.event.systemRef.eventId,
          name: event.event.title,
          description: event.event.description,
          inspector: event.resource,
          start_date: event.event.start,
          end_date: event.event.end,
        },
      }),
    )
  }
}

export const onEventDragAndDropHelperEnd = ({
  event,
  dispatch,
  setCalendarIsLoading,
  storeSelectedEvent,
  clearSelectedEvent,
  clearSelectedAppointment,
  setCalendarIsDoneLoading,
  userTimeZone,
  storeSelectedAppointment,
  setCalendarEvents,
  swal,
  calendarEvents,
}) => {
  if (event.event.systemRef.type === 'externalEvent') {
    unDoDragAndDropEvent({
      calendarEvents,
      storeSelectedEvent,
      setCalendarEvents,
      swal,
    })
    dispatch(clearSelectedEvent())
    return
  }
  if (storeSelectedEvent && event.event.systemRef.type === 'regularEvent') {
    updateEventDragAndDropHelper({
      dispatch,
      setCalendarIsLoading,
      storeSelectedEvent,
      event,
      clearSelectedEvent,
      clearSelectedAppointment,
      setCalendarIsDoneLoading,
      userTimeZone,
      calendarEvents,
      setCalendarEvents,
      swal,
    })
  } else if (
    storeSelectedEvent &&
    event.event.systemRef.type === 'inspSubEvent'
  ) {
    updateEventDragAndDropHelper({
      dispatch,
      setCalendarIsLoading,
      storeSelectedEvent,
      event,
      clearSelectedEvent,
      clearSelectedAppointment,
      setCalendarIsDoneLoading,
      userTimeZone,
      calendarEvents,
      setCalendarEvents,
      swal,
    })
  } else if (storeSelectedAppointment) {
    if (storeSelectedAppointment.technicianId !== event.resource) {
      unDoDragAndDropAppointment({
        calendarEvents,
        storeSelectedAppointment,
        setCalendarEvents,
        swal,
      })
      dispatch(clearSelectedAppointment())
      return
    }
    dispatch(setCalendarIsLoading())
    updateRescheduleAppointmentCalendar({
      appointmentId: storeSelectedAppointment.appointmentId,
      type: storeSelectedAppointment.type,
      dateTime: dayjs
        .tz(dayjs(event.event.start).format('YYYY-MM-DD HH:mm'), userTimeZone)
        .format(),
    })
      .then((res) => {
        if (!res.success) {
          unDoDragAndDropAppointment({
            calendarEvents,
            storeSelectedAppointment,
            setCalendarEvents,
            swal,
          })
        }
      })
      .catch((err) => {
        unDoDragAndDropAppointment({
          calendarEvents,
          storeSelectedAppointment,
          setCalendarEvents,
          swal,
        })
      })
      .finally(() => {
        dispatch(clearSelectedAppointment())
        dispatch(setCalendarIsDoneLoading())
      })
  }
}

export const onEventCellRightClickHelper = ({
  dispatch,
  setDateRef,
  args,
  clearSelectedEvent,
  setIsAllowedToAdd,
  setMenuOpen,
  setSelectedDateTime,
}) => {
  dispatch(clearSelectedEvent())
  if (dayjs(args.date).isSameOrAfter(dayjs())) {
    setIsAllowedToAdd(true)
  } else {
    setIsAllowedToAdd(false)
  }

  const cleanDateTime = dayjs(args.date).format('YYYY-MM-DD HH:mm')
  dispatch(
    setSelectedDateTime({
      selectedDateTime: cleanDateTime,
      selectedAppointmentId: null,
    }),
  )
  setDateRef(args.domEvent.target)

  setMenuOpen(true)
}
