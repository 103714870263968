import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { searchTechniciansAvailability } from '../../services/technician.service'

export const fetchTimeSlotsAppointments = createAsyncThunk(
  'appointments/fetchTimeSlotsAppointments',
  async ({
    subscriberId,
    fromDate,
    toDate
  }) => {
    try {
      const res = await searchTechniciansAvailability({
        subscriberId,
        fromDate,
        toDate
      })
      return res.data.timeSlots
    } catch (e) {
      return Promise.reject()
    }
  },
)

const initialState = {
  isLoading: false,
  dataLoaded: false,
  error: false,
  timeSlots: [],
  currentAppointmentState: {
    selectedTechnicianId: '',
  },
  openModal: false,
}

const slice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    setAppointmentSelectedTechnician: (state, action) => {
      state.currentAppointmentState.selectedTechnicianId = action.payload
    },
    setOpenNewAppointment: (state, action) => {
      state.openModal = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimeSlotsAppointments.pending, (state) => {
        state.isLoading = true
        state.dataLoaded = false
        state.error = false
      })
      .addCase(fetchTimeSlotsAppointments.fulfilled, (state, action) => {
        state.isLoading = false
        state.dataLoaded = true
        state.error = false
        state.timeSlots = action.payload
      })
      .addCase(fetchTimeSlotsAppointments.rejected, (state) => {
        state.isLoading = false
        state.dataLoaded = false
        state.error = true
      })
  },
})
export const { setAppointmentSelectedTechnician, setOpenNewAppointment } =
  slice.actions
export default slice.reducer
