import { experimentalStyled as styled } from '@material-ui/core/styles'
import Page from '../../components/Page'

export const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}))
