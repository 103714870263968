import axiosApi from './_axiosApi'

export const createClient = (formData) =>
  axiosApi
    .post('/v2/clients', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data)

export const getClientList = ({ subscriberId, cursor, perPage }) =>
  axiosApi
    .get('/v2/clients', { params: { subscriberId, cursor, perPage } })
    .then((res) => res.data)

export const searchClients = ({ subscriberId, cursor, perPage, text }) =>
  axiosApi
    .get('/v2/clients', { params: { subscriberId, cursor, perPage, text } })
    .then((res) => res.data)

export const getClient = ({ subscriberId, clientId }) =>
  axiosApi
    .get(`/v2/clients/${clientId}`, { params: { subscriberId } })
    .then((res) => res.data)

export const updateClient = ({ clientId, formData }) =>
  axiosApi
    .put(`/v2/clients/${clientId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data)

export const changeClientStatus = ({ clientId, subscriberId, newStatus }) =>
  axiosApi
    .post(`/v2/clients/${clientId}/status`, { subscriberId, newStatus })
    .then((res) => res.data)

export const deleteClient = ({ clientId, subscriberId }) =>
  axiosApi
    .delete(`v2/clients/${clientId}`, { data: { subscriberId } })
    .then((res) => res.data)

export const importClients = (formData) =>
  axiosApi
    .post('/v2/clients/import', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data)

export const exportClients = (subscriberId) =>
  axiosApi
    .get(`/v2/clients/export-file/${subscriberId}`, {
      responseType: 'blob',
    })
    .then((res) => {
      // Extract "filename" and "mime-type" from "header" response
      const contentDisposition = res.headers['content-disposition']
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1]
        : ''
      const mimeType = res.headers['content-type']

      return {
        blob: res.data,
        mimeType,
        filename,
      }
    })
