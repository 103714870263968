import { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'

function AuthRedirect() {
  const { user } = useContext(AuthContext)

  if (!user?.id) return <Outlet />

  return user?.user_type !== 5 ? (
    <Navigate to="/dashboard" /> // Normal Login (Owners, Technicians & Staff)
  ) : (
    <Navigate to="/admin" /> // Super Admin (ReportON's owner)
  )
}

export default AuthRedirect
