/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core'
import update from 'immutability-helper'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { Fragment } from 'react'
import { MSwitch } from '../../../../../@material-extend'
import { Step, StepHead, Switchlabel } from '../FormSteps.styles'
import Info from '../../../../../info'
import ActionsSection from './ActionsSection'
import { MobileModalHeader } from '../../MobileMultiStep/MobileMultiStep.style'
import ConditionalWrapper from '../AddServices/components/ConditionalWrapper'
import AddEvents from '../AddServices/components/AddEvents/AddEvents'
import PaymentSection from '../AddServices/components/PaymentSection'
import { Form, FormikProvider, useFormik } from 'formik'

const AddInformation = ({
  isMobile,
  information,
  acceptActions,
  actionGroups,
  serviceOptions,
  setInformation,
  setStepSlug,
  SubmitButton,
  settings,
  services,
  setServices,
}) => {
  const onInputChange = (event) => {
    const { value, name } = event.target
    setInformation({ ...information, [name]: value })
  }

  const onCheckboxChange = (event) => {
    const { name, checked } = event.target
    setInformation({
      ...information,
      [name]: checked,
      // Special behavior required by the client
      ...(name === 'confirm_inspection' &&
        information.confirm_inspection === true && {
          disable_automated_notifications: true,
        }),
    })
  }

  const updateGroups = (newActionGroup) => {
    setInformation({ ...information, action_groups: newActionGroup })
  }

  const updateActions = (groupId, actionsArray) => {
    const groupIndex = actionGroups.map((a) => a.id).indexOf(groupId)
    // Generate new actionGroups array
    const newActionGroups = update(actionGroups, {
      [groupIndex]: { $merge: { actions: actionsArray } },
    })
    setInformation({ ...information, action_groups: newActionGroups })
  }

  const formik = useFormik({
    initialValues: {
      notes: '',
    },
  })

  const setEvents = (events) => {
    setServices({ ...services, events: events })
  }

  const onInputChangePayment = (event) => {
    const { value, name, checked } = event.target
    const newValue = name === 'requirePayment' ? checked : value
    setServices({ ...services, [name]: newValue })
  }

  const backStep = () => {
    setStepSlug('addPeople')
  }

  return (
    <div>
      {isMobile && (
        <MobileModalHeader>
          <Box>
            <IconButton
              onClick={backStep}
              sx={{
                padding: '6px',
                marginLeft: '-8px',
                svg: { width: 28, height: 28 },
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="h6">Add Information</Typography>
          </Box>
          <Box>{SubmitButton}</Box>
        </MobileModalHeader>
      )}
      <ConditionalWrapper
        condition={!isMobile}
        wrapper={(children) => (
          <Step sx={{ maxWidth: 700 }}>
            <StepHead>
              <Typography variant="h6">Information</Typography>
              <Info title="Select additional information for the appointment" />
            </StepHead>
            {children}
          </Step>
        )}
      >
        <Fragment>
          <Box sx={{ ...(!isMobile && { p: '20px' }) }}>
            <Grid container>
              <Grid item xs={12} sm={12} sx={{ marginBottom: '16px' }}>
                <TextField
                  fullWidth
                  type="text"
                  name="order_id"
                  label="Order Id"
                  autoComplete="OrderId"
                  size="small"
                  value={information.order_id}
                  onChange={onInputChange}
                />
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={12} sm={6}>
                  <Stack>
                    <Switchlabel
                      sx={{ marginRight: 'auto' }}
                      name="confirm_inspection"
                      label="Confirmed Appointment"
                      control={
                        <MSwitch
                          color="primary"
                          checked={information.confirm_inspection}
                          onChange={onCheckboxChange}
                        />
                      }
                    />
                    <Switchlabel
                      sx={{ marginRight: 'auto' }}
                      name="occupied"
                      label="Occupied? "
                      control={
                        <MSwitch
                          color="primary"
                          checked={information.occupied}
                          onChange={onCheckboxChange}
                        />
                      }
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ marginBottom: '16px' }}>
                  <Stack>
                    <Switchlabel
                      sx={{ marginRight: 'auto' }}
                      name="disable_automated_notifications"
                      label="Disable Automated Notifications"
                      control={
                        <MSwitch
                          color="primary"
                          checked={information.disable_automated_notifications}
                          onChange={onCheckboxChange}
                          disabled={!information.confirm_inspection}
                        />
                      }
                    />

                    <Switchlabel
                      sx={{ marginRight: 'auto' }}
                      name="utilities_on"
                      label="Utilities On?"
                      control={
                        <MSwitch
                          color="primary"
                          checked={information.utilities_on}
                          onChange={onCheckboxChange}
                        />
                      }
                    />
                  </Stack>
                </Grid>
                {/*
                    ** This was commented in the original code/logic **

                    I'm going to leave this here in case it's ever needed again or if there is doubt about this field
                    This field "inspection_notes" is currently sent empty to the endpoint.

                    <Grid item xs={12} sm={12}>
                      <TextField
                        rows={4}
                        fullWidth
                        multiline
                        label="Appointment Notes"
                        placeholder='These notes are private and only visible by you - use for access notes, entry codes, personal notes etc'
                        value={props.appointmentData.inspection_notes}
                        onChange={(e)=>{
                          props.setAppointmentData({...props.appointmentData, ...{inspection_notes:e.target.value} });
                        }}
                      />
                    </Grid>
                */}
                {acceptActions && (
                  <ActionsSection
                    isMobile={isMobile}
                    actionGroups={information.action_groups}
                    updateGroups={updateGroups}
                    updateActions={updateActions}
                    serviceOptions={serviceOptions}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={12} sx={{ marginBottom: '16px' }}>
                <FormikProvider value={formik}>
                  <Form>
                    {!settings.isUsingIFrame && settings.acceptEvents && (
                      <AddEvents
                        events={services.events}
                        defaultStartTime={settings.defaultStartTime}
                        defaultDuration={settings.defaultDuration}
                        inspectorOptions={settings.inspectorOptions}
                        setEvents={setEvents}
                      />
                    )}
                    {!settings.isUsingIFrame && (
                      <PaymentSection
                        requirePayment={services.requirePayment}
                        paymentNotes={services.paymentNotes}
                        onPaymentDataChange={onInputChangePayment}
                      />
                    )}
                  </Form>
                </FormikProvider>
              </Grid>
            </Grid>
          </Box>
          {!isMobile && (
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
              <Button variant="contained" color="inherit" onClick={backStep}>
                Previous
              </Button>
            </Stack>
          )}
        </Fragment>
      </ConditionalWrapper>
    </div>
  )
}

AddInformation.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  information: PropTypes.shape({
    order_id: PropTypes.string.isRequired,
    confirm_inspection: PropTypes.bool.isRequired,
    occupied: PropTypes.bool.isRequired,
    disable_automated_notifications: PropTypes.bool.isRequired,
    utilities_on: PropTypes.bool.isRequired,
    action_groups: PropTypes.array,
  }).isRequired,
  acceptActions: PropTypes.bool.isRequired,
  actionGroups: PropTypes.array.isRequired,
  serviceOptions: PropTypes.array.isRequired,
  setInformation: PropTypes.func.isRequired,
  setStepSlug: PropTypes.func.isRequired,
  SubmitButton: PropTypes.node,
  settings: PropTypes.shape({
    acceptEvents: PropTypes.bool.isRequired,
    isUsingIFrame: PropTypes.bool.isRequired,
    defaultStartTime: PropTypes.string.isRequired,
    defaultDuration: PropTypes.number.isRequired,
    inspectorOptions: PropTypes.array.isRequired,
  }).isRequired,
  services: PropTypes.shape({
    events: PropTypes.array.isRequired,
    requirePayment: PropTypes.bool.isRequired,
    paymentNotes: PropTypes.string.isRequired,
  }).isRequired,
  setServices: PropTypes.func.isRequired,
}

export default AddInformation
