import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getUserPublicData } from '../../services/user.services'

const initialState = {
  isLoading: false,
  dataLoaded: false,
  first_name: null,
  last_name: null,
  header_logo: null,
  company_logo: null,
  profile_pic: null,
  signature: null,
  address: null,
  city: null,
  state: null,
  zip_code: null,
  social_links: null,
  address_lat_long: null,
  video_url: null,
  service_area: null,
  about_company: null,
}

export const fetchPublicUserData = createAsyncThunk(
  'user/fetchPublicProfileData',
  async (subscriberId) => {
    const res = await getUserPublicData(subscriberId)
    if (res.success) {
      return { ...res.data }
    }
    return Promise.reject()
  },
)

const slice = createSlice({
  name: 'publicProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublicUserData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPublicUserData.fulfilled, (state, action) => {
        state.isLoading = false
        state.dataLoaded = true

        state.header_logo = action.payload.header_logo
        state.company_logo = action.payload.company_logo
        state.profile_pic = action.payload.profile_pic
        state.signature = action.payload.signature
        state.address = action.payload.address
        state.city = action.payload.city
        state.state = action.payload.state
        state.zip_code = action.payload.zip_code
        state.social_links = action.payload.social_links
        state.address_lat_long = action.payload.address_lat_long
        state.video_url = action.payload.video_url
        state.service_area = action.payload.service_area
        state.about_company = action.payload.about_company
        state.first_name = action.payload.first_name
        state.last_name = action.payload.last_name
      })
      .addCase(fetchPublicUserData.rejected, (state) => {
        state.isLoading = false
        state.dataLoaded = false
      })
  },
})

export default slice.reducer
