/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'

const emailSettingsBuilder = ({ notifications }) => {
  const {
    reportEmailSettings,
    schedulingEmailSettings,
    clientPreNotifications,
    clientPostNotifications,
    agentPreNotifications,
    agentPostNotifications,
    listingAgentPreNotifications,
    listingAgentPostNotifications,
    contractorPreNotifications,
    contractorPostNotifications,
    agreementsFees,
  } = notifications

  const {
    notify_me_when_agent_views_report,
    notify_me_when_client_views_report,
    report_ready_client_email,
    report_ready_client_text,
    report_ready_agent_email,
    report_ready_agent_text,
    report_ready_listing_agent_email,
    report_ready_listing_agent_text,
    report_ready_contractors_email,
    report_ready_contractors_text,
  } = reportEmailSettings

  const {
    inspection_confirmation_email_for_clients,
    inspection_confirmation_email_for_agents,
    inspection_confirmation_email_for_listing_agents,
    inspection_confirmation_email_for_contractors,
    send_client_a_confirmation_email_on_reschedule,
    send_agent_a_confirmation_email_on_reschedule,
    send_listing_agent_a_confirmation_email_on_reschedule,
    send_contractor_a_confirmation_email_on_reschedule,
  } = schedulingEmailSettings

  const {
    when_a_new_fee_is_added,
    when_an_inspection_agreement_is_added_later,
  } = agreementsFees

  // * Inspectors email settings
  const inspectorsEmailSettings = {
    agent_views_the_report: notify_me_when_agent_views_report.enabled,
    client_views_the_report: notify_me_when_client_views_report.enabled,
    contractor_views_the_report: false, // always false
  }

  // * Clients email settings
  let preReminders = buildReminders('pre', clientPreNotifications)
  let postReminders = buildReminders('post', clientPostNotifications)

  const clientsEmailSettings = {
    confirmation_email_for_clients:
      inspection_confirmation_email_for_clients.enabled,
    confirmation_email_on_reschedule:
      send_client_a_confirmation_email_on_reschedule.enabled,
    ...preReminders,
    ...postReminders,
    report_ready_client_email: report_ready_client_email.enabled,
    report_ready_client_text: report_ready_client_text.enabled,
    send_client_an_email_when_a_new_fee_is_added:
      when_a_new_fee_is_added.enabled,
    send_client_an_email_when_an_inspection_agreement:
      when_an_inspection_agreement_is_added_later.enabled,
  }

  // * Agents email settings
  preReminders = buildReminders('pre', agentPreNotifications)
  postReminders = buildReminders('post', agentPostNotifications)

  const agentsEmailSettings = {
    confirmation_email_for_agents:
      inspection_confirmation_email_for_agents.enabled,
    confirmation_email_on_reschedule:
      send_agent_a_confirmation_email_on_reschedule.enabled,
    ...preReminders,
    ...postReminders,
    report_ready_agent_email: report_ready_agent_email.enabled,
    report_ready_agent_text: report_ready_agent_text.enabled,
    send_agent_an_email_when_a_new_fee_is_added:
      when_a_new_fee_is_added.enabled,
    send_agent_an_email_when_an_inspection_agreement:
      when_an_inspection_agreement_is_added_later.enabled,
  }

  // * Listing Agents email settings
  preReminders = buildReminders('pre', listingAgentPreNotifications)
  postReminders = buildReminders('post', listingAgentPostNotifications)

  const listingAgentsEmailSettings = {
    confirmation_email_for_agents:
      inspection_confirmation_email_for_listing_agents.enabled,
    confirmation_email_on_reschedule:
      send_listing_agent_a_confirmation_email_on_reschedule.enabled,
    ...preReminders,
    ...postReminders,
    report_ready_agent_email: report_ready_listing_agent_email.enabled,
    report_ready_agent_text: report_ready_listing_agent_text.enabled,
    send_agent_an_email_when_a_new_fee_is_added:
      when_a_new_fee_is_added.enabled,
    send_agent_an_email_when_an_inspection_agreement:
      when_an_inspection_agreement_is_added_later.enabled,
  }

  // * Contractors email settings
  preReminders = buildReminders('pre', contractorPreNotifications)
  postReminders = buildReminders('post', contractorPostNotifications)

  const contractorsEmailSettings = {
    confirmation_email_for_contractors:
      inspection_confirmation_email_for_contractors.enabled,
    confirmation_email_on_reschedule:
      send_contractor_a_confirmation_email_on_reschedule.enabled,
    ...preReminders,
    ...postReminders,
    report_ready_contractors_email: report_ready_contractors_email.enabled,
    report_ready_contractors_text: report_ready_contractors_text.enabled,
    send_contractors_an_email_when_a_new_fee_is_added:
      when_a_new_fee_is_added.enabled,
    send_contractors_an_email_when_an_inspection_agreement:
      when_an_inspection_agreement_is_added_later.enabled,
  }

  // * Response
  const emailSettings = {
    inspectors_email_settings: inspectorsEmailSettings,
    clients_email_settings: clientsEmailSettings,
    agents_email_settings: agentsEmailSettings,
    listing_agents_email_settings: listingAgentsEmailSettings,
    contractors_email_settings: contractorsEmailSettings,
  }

  return emailSettings
}

const buildReminders = (type, settings) => {
  const {
    email_remainder_one,
    email_remainder_two,
    email_remainder_three,
    text_remainder_one,
    text_remainder_two,
  } = settings

  const emailReminderOne = {
    value: email_remainder_one.enabled,
    duration: email_remainder_one.period,
    duration_type: email_remainder_one.period_type,
  }
  const emailReminderTwo = {
    value: email_remainder_two.enabled,
    duration: email_remainder_two.period,
    duration_type: email_remainder_two.period_type,
  }
  const emailReminderThree = {
    value: email_remainder_three.enabled,
    duration: email_remainder_three.period,
    duration_type: email_remainder_three.period_type,
  }
  const smsReminderOne = {
    value: text_remainder_one.enabled,
    duration: text_remainder_one.period,
    duration_type: text_remainder_one.period_type,
  }
  const smsReminderTwo = {
    value: text_remainder_two.enabled,
    duration: text_remainder_two.period,
    duration_type: text_remainder_two.period_type,
  }

  const data = {}
  data[`${type}_inspection_reminder_one`] = emailReminderOne
  data[`${type}_inspection_reminder_two`] = emailReminderTwo
  data[`${type}_inspection_reminder_three`] = emailReminderThree
  data[`${type}_inspection_text_reminder_one`] = smsReminderOne
  data[`${type}_inspection_text_reminder_two`] = smsReminderTwo
  return data
}

emailSettingsBuilder.propTypes = {
  notifications: PropTypes.object,
}

export default emailSettingsBuilder
