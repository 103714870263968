/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import { useContext, useState, useEffect, useMemo, Fragment } from 'react'
import InputMask from 'react-input-mask'
import { Box, Button, Stack, TextField, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import {
  getAgent,
  createAgent,
  updateAgent,
} from '../../../../services/agent.service'
import { AuthContext } from '../../../../contexts/AuthContext'

const AgentForm = ({
  type,
  action,
  profilePic,
  setAvatarSrc,
  personBasicData,
  closeModal,
  setBlocking,
  handleSuccess,
  subscriber,
}) => {
  const { user } = useContext(AuthContext)
  const [data, setData] = useState(initialValues)
  const [errors, setErrors] = useState({})
  const { enqueueSnackbar } = useSnackbar()

  // Subcriber from session (token) or from props (iframe)
  const subscriberId = user?.subscriber_id || subscriber

  useEffect(() => {
    if (action === 'edit') {
      const { id: agentId, profile_pic } = personBasicData
      if (profile_pic) setAvatarSrc(profile_pic)
      getAgent({ subscriberId, agentId }).then((resp) => {
        if (resp.success) {
          const { status, id, profile_pic, ...agent } = resp.data
          setData(agent)
          if (profile_pic) setAvatarSrc(profile_pic)
        }
      })
    }
  }, [])

  const label = useMemo(
    () => (type === 'agents' ? 'Agent' : 'Listing Agent'),
    [type],
  )

  const onChange = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }

  const onPastePhone = (e) => {
    e.preventDefault()
    const value = e.clipboardData.getData('text')
    const digits = value.replace(/\D+/g, '')
    const phone = digits.slice(digits.length - 10)
    setData({ ...data, phone })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setBlocking(true)

    // Convert data into multipart/form-data content type
    const formData = new FormData()
    formData.append('subscriberId', subscriberId)
    if (profilePic) formData.append('profile_pic', profilePic)
    Object.keys(initialValues).forEach((key) => {
      const value = key === 'phone' ? data[key].replace(/\D/g, '') : data[key]
      formData.append(key, value)
    })

    // Get agent full name
    const { first_name: firstname, last_name: lastname } = data
    const agentName = lastname ? `${firstname} ${lastname}`.trim() : firstname
    const personType = type === 'agents' ? 'agent' : 'listing agent'

    if (action === 'edit') {
      // Try to update existing agent
      const agentId = personBasicData.id
      updateAgent({ agentId, formData })
        .then((resp) => {
          if (resp.success) {
            const successMsg = `The ${personType} "${agentName}" has been updated successfully!`
            enqueueSnackbar(successMsg, { variant: 'success' })
            handleSuccess(resp.data) // Update single agent
            setData(initialValues)
            closeModal()
          } else {
            const { error } = resp
            if (error.type === 'BAD_USER_INPUT') setErrors(error.details)
            else enqueueSnackbar(error.message, { variant: 'error' })
          }
        })
        .finally(() => setBlocking(false))
    } else {
      // Try to save new agent
      createAgent(formData)
        .then((resp) => {
          if (resp.success) {
            const successMsg = `The ${personType} "${agentName}" has been added successfully!`
            enqueueSnackbar(successMsg, { variant: 'success' })
            handleSuccess(resp.data) // Push new agent
            setData(initialValues)
            closeModal()
          } else {
            const { error } = resp
            if (error.type === 'BAD_USER_INPUT') setErrors(error.details)
            else enqueueSnackbar(error.message, { variant: 'error' })
          }
        })
        .finally(() => setBlocking(false))
    }
  }

  return (
    <Fragment>
      <Typography
        variant="h6"
        sx={{ pb: 3, textAlign: 'center', lineHeight: 1 }}
      >
        {action === 'add' ? 'New' : 'Edit'} {label}
      </Typography>
      <form noValidate onSubmit={onSubmit}>
        <Stack spacing={3}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 3, sm: 2 }}
          >
            <TextField
              fullWidth
              name="first_name"
              label="First Name"
              size="small"
              value={data.first_name}
              onChange={onChange}
              error={Boolean(errors.first_name)}
              helperText={errors.first_name}
            />
            <TextField
              fullWidth
              name="last_name"
              label="Last Name"
              size="small"
              value={data.last_name}
              onChange={onChange}
              error={Boolean(errors.last_name)}
              helperText={errors.last_name}
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 3, sm: 2 }}
          >
            <TextField
              fullWidth
              type="email"
              name="email"
              label="Email Address"
              size="small"
              value={data.email}
              onChange={onChange}
              error={Boolean(errors.email)}
              helperText={errors.email}
              inputProps={{ inputMode: 'email' }}
            />
            <TextField
              fullWidth
              type="email"
              name="email_cc"
              label="CC Email Address"
              size="small"
              value={data.email_cc}
              onChange={onChange}
              error={Boolean(errors.email_cc)}
              helperText={errors.email_cc}
              inputProps={{ inputMode: 'email' }}
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 3, sm: 2 }}
          >
            <InputMask
              fullWidth
              name="phone"
              label="Phone Number"
              size="small"
              mask="(999) 999-9999"
              maskChar=" "
              alwaysShowMask={false}
              value={data.phone}
              onPaste={onPastePhone}
              onChange={onChange}
              error={Boolean(errors.phone)}
              helperText={errors.phone}
              inputProps={{ inputMode: 'numeric' }}
            >
              {(props) => <TextField {...props} />}
            </InputMask>
            <TextField
              fullWidth
              name="agency_name"
              label="Agency Name"
              size="small"
              value={data.agency_name}
              onChange={onChange}
              error={Boolean(errors.agency_name)}
              helperText={errors.agency_name}
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 3, sm: 2 }}
          >
            <TextField
              fullWidth
              name="address"
              label="Address"
              size="small"
              value={data.address}
              onChange={onChange}
              error={Boolean(errors.address)}
              helperText={errors.address}
            />
            <TextField
              fullWidth
              name="city"
              label="City"
              size="small"
              value={data.city}
              onChange={onChange}
              error={Boolean(errors.city)}
              helperText={errors.city}
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 3, sm: 2 }}
          >
            <TextField
              fullWidth
              name="state"
              label="State/Region"
              size="small"
              value={data.state}
              onChange={onChange}
              error={Boolean(errors.state)}
              helperText={errors.state}
            />
            <InputMask
              fullWidth
              name="zip_code"
              label="Zip Code"
              size="small"
              mask="99999"
              maskChar=" "
              alwaysShowMask={false}
              value={data.zip_code}
              onChange={onChange}
              error={Boolean(errors.zip_code)}
              helperText={errors.zip_code}
              inputProps={{ inputMode: 'numeric' }}
            >
              {(props) => <TextField {...props} />}
            </InputMask>
          </Stack>
        </Stack>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
          <Button type="submit" variant="contained">
            {action === 'add' ? 'Add' : 'Update'} {label}
          </Button>
        </Box>
      </form>
    </Fragment>
  )
}

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  email_cc: '',
  phone: '',
  agency_name: '',
  address: '',
  city: '',
  state: '',
  zip_code: '',
}

AgentForm.defaultProps = {
  setAvatarSrc: () => {},
}

AgentForm.propTypes = {
  type: PropTypes.oneOf(['agents', 'listingAgents']),
  action: PropTypes.oneOf(['add', 'edit']).isRequired,
  profilePic: PropTypes.object,
  setAvatarSrc: PropTypes.func,
  personBasicData: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  setBlocking: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  subscriber: PropTypes.string,
}

export default AgentForm
