/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import {
  Autocomplete,
  Box,
  Card,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core'
import { MobileDateTimePicker } from '@material-ui/lab'
import { Icon } from '@iconify/react'
import CloseOutline from '@iconify/icons-eva/close-outline'
import dayjs from 'dayjs'

const NewEventForm = ({
  index,
  event,
  updateEvent,
  onSelectChange,
  deleteEvent,
  inspectorOptions,
}) => {
  return (
    <Card sx={{ borderBottom: `1px solid #d4d4d4`, p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            fullWidth
            name={`name---${index}`}
            label="Title"
            size="small"
            value={event.name}
            onChange={updateEvent}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            name={`description---${index}`}
            label="Description"
            size="small"
            value={event.description}
            onChange={updateEvent}
          />
        </Grid>
        <Grid item xs={2}>
          <Box sx={{ float: 'right' }}>
            <IconButton onClick={deleteEvent} sx={{ margin: '0' }}>
              <Icon icon={CloseOutline} />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            filterSelectedOptions
            options={inspectorOptions}
            getOptionLabel={(option) =>
              option?.first_name
                ? `${option.first_name} ${option.last_name}`
                : ''
            }
            value={event.inspector}
            onChange={(_event, value) =>
              onSelectChange(value, `inspector---${index}`)
            }
            renderInput={(params) => (
              <TextField {...params} label="Select technician" size="small" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MobileDateTimePicker
            value={dayjs(event.start_date).toDate()}
            onChange={(newDate) => {
              onSelectChange(dayjs(newDate), `start_date---${index}`)
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" fullWidth margin="normal" />
            )}
            disablePast
            minDateTime={dayjs().startOf('day')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MobileDateTimePicker
            value={dayjs(event.end_date).toDate()}
            onChange={(newDate) => {
              onSelectChange(dayjs(newDate), `end_date---${index}`)
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" fullWidth margin="normal" />
            )}
            disablePast
            minDateTime={dayjs(event.start_date).add(1, 'minute').toDate()}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

NewEventForm.propTypes = {
  index: PropTypes.number.isRequired,
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    inspector: PropTypes.object,
    start_date: PropTypes.instanceOf(Date),
    end_date: PropTypes.instanceOf(Date),
  }).isRequired,
  updateEvent: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  inspectorOptions: PropTypes.array.isRequired,
}

export default NewEventForm
