/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import { Box, List, Stack, Typography } from '@material-ui/core'
import { Fragment, useMemo } from 'react'
import ServiceBreakdown from './ServiceBreakdown'

const Cart = ({
  templates,
  agreements,
  fees,
  manualDuration,
  currencySymbol,
}) => {
  const sum = useMemo(
    () =>
      fees.reduce(
        (acc, fee) => {
          const amount = Number(fee.pretax_amount) + Number(fee.tax_amount)
          return {
            total: acc.total + amount,
            duration: acc.duration + Number(fee.duration),
          }
        },
        { total: 0, duration: 0 },
      ),
    [fees],
  )

  return (
    <Fragment>
      <Stack>
        {fees.map((fee) => (
          <ServiceBreakdown
            key={fee.id}
            fee={fee}
            currencySymbol={currencySymbol}
          />
        ))}
      </Stack>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, mb: 2 }}
      >
        <Typography variant="subtitle2">Total</Typography>
        <Typography variant="subtitle2">
          {currencySymbol}
          {sum.total.toFixed(2)}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="subtitle2">Duration</Typography>
        <Typography variant="subtitle2">
          {manualDuration || sum.duration} hrs
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="subtitle2" sx={{ pb: '8px' }}>
          Templates
        </Typography>
        <Typography variant="subtitle2" sx={{ fontWeight: 400, mb: '8px' }}>
          {templates.length > 0 ? (
            <List sx={{ padding: '0', marginLeft: '20px' }}>
              {templates.map(({ value, label }, index) => (
                <li key={value} style={{ lineHeight: 1, marginBottom: '10px' }}>
                  {`${index + 1}) ${label}`}
                </li>
              ))}
            </List>
          ) : (
            <span
              style={{
                position: 'absolute',
                color: '#bcbcbc',
                fontWeight: 500,
                right: 0,
                top: 0,
              }}
            >
              none
            </span>
          )}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="subtitle2" sx={{ pb: '8px' }}>
          Agreements
        </Typography>
        <Typography variant="subtitle2" sx={{ fontWeight: 400, mb: '8px' }}>
          {agreements.length > 0 ? (
            <List sx={{ padding: '0', marginLeft: '20px' }}>
              {agreements.map(({ value, label }, index) => (
                <li key={value} style={{ lineHeight: 1, marginBottom: '10px' }}>
                  {`${index + 1}) ${label}`}
                </li>
              ))}
            </List>
          ) : (
            <span
              style={{
                position: 'absolute',
                color: '#bcbcbc',
                fontWeight: 500,
                right: 0,
                top: 0,
              }}
            >
              none
            </span>
          )}
        </Typography>
      </Box>
    </Fragment>
  )
}

Cart.propTypes = {
  templates: PropTypes.array.isRequired,
  agreements: PropTypes.array.isRequired,
  fees: PropTypes.array.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  manualDuration: PropTypes.string,
}

export default Cart
