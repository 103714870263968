import { useContext, useEffect, useState, Fragment } from 'react'
import { Button, ListItemText, MenuItem, Typography } from '@material-ui/core'
import { Icon } from '@iconify/react'
import PlusFill from '@iconify/icons-eva/plus-fill'
import { useTheme } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { getTeamAppointmentSettings } from '../../../services/inspections.service'
import { fetchUserAppointmentSettings } from '../../../services/user.services'
import AppointmentForm from './MultiStepForm'
import {
  informationInitialState,
  locationInitialState,
  peopleInitialState,
  servicesInitialState,
  settingsInitialState,
} from './utils/initialStates'
import { getAppointmentOpenModal } from '../../../redux/selectors/appointments'
import { setOpenNewAppointment } from '../../../redux/reducers/appointments'
import { AuthContext } from '../../../contexts/AuthContext'

const NewAppointment = ({ date, componentType }) => {
  const { user } = useContext(AuthContext)
  const [showModal, setShowModal] = useState(false)
  const userId = user?.id
  const subscriberId = user?.subscriber_id
  const [canUserSchedule, setCanUserSchedule] = useState(false)
  const [settings, setSettings] = useState(settingsInitialState)
  const [data, setData] = useState(dataInitialState)
  const [stepSlug, setStepSlug] = useState('addLocation')
  const theme = useTheme()
  const dispatch = useDispatch()
  const openModalSelector = useSelector(getAppointmentOpenModal)

  useEffect(() => {
    if (openModalSelector) {
      setShowModal(true)
    }
  }, [openModalSelector])

  useEffect(() => {
    if (userId) {
      setCanUserSchedule(user.can_schedule_new === true)
    }
  }, [userId])

  useEffect(() => {
    if (showModal) {
      getTeamAppointmentSettings(subscriberId).then((appointmentSettings) => {
        fetchUserAppointmentSettings(userId).then((userSettings) => {
          setSettings({
            ...appointmentSettings,
            ...userSettings,
            isUsingIFrame: false,
            isLoaded: true,
          })
        })
      })
    }
  }, [showModal])

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = (_event, reason) => {
    // Don't close if the backdrop is clicked
    if (reason !== 'backdropClick') {
      dispatch(setOpenNewAppointment(false))
      setShowModal(false)
      setData(dataInitialState)
      setSettings(settingsInitialState)
      setStepSlug('addLocation')
    }
  }

  // Current user can't create an appointment
  if (!canUserSchedule) return null

  return (
    <Fragment>
      {(() => {
        switch (componentType) {
          case 'button':
            return (
              <Button
                size="small"
                startIcon={<Icon icon={PlusFill} />}
                onClick={openModal}
                sx={{ Height: 'fit-content', fontSize: 12 }}
              >
                New Appointment
              </Button>
            )

          case 'Menu':
            return (
              <MenuItem sx={{ color: 'text.secondary' }} onClick={openModal}>
                <ListItemText
                  primary="Add Appointment"
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </MenuItem>
            )

          case 'Navbar':
            return (
              <Button
                sx={{
                  Height: 'fit-content',
                  fontSize: 12,
                  '& span': { display: `flex`, alignItems: 'center' },
                  borderRadius: 1,
                  color: theme.palette.primary.main,
                }}
                onClick={openModal}
              >
                <Icon icon={PlusFill} width={20} height={20} />
                <Typography variant="caption" sx={{ ml: 1 }}>
                  Appointment
                </Typography>
              </Button>
            )

          default:
            return null
        }
      })()}
      <AppointmentForm
        open={showModal}
        onClose={closeModal}
        stepSlug={stepSlug}
        setStepSlug={setStepSlug}
        data={data}
        setData={setData}
        settings={settings}
        setSettings={setSettings}
      />
    </Fragment>
  )
}

const dataInitialState = {
  location: locationInitialState,
  services: servicesInitialState,
  dateTime: '',
  people: peopleInitialState,
  information: informationInitialState,
}

NewAppointment.propTypes = {
  date: PropTypes.string, // This was part of the original code (waiting to see if is neccessary)
  componentType: PropTypes.oneOf(['button', 'Menu', 'Navbar']).isRequired,
}

export default NewAppointment
