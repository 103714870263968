import { Box, Container, Typography } from '@material-ui/core'
import { RootStyle } from '../shared/RootStyle'
import { motion } from 'framer-motion'
import { MotionContainer, varBounceIn } from '../../components/animate'
import { PageNotFoundIllustration } from '../../assets'

export default function ErrorPage() {
  return (
    <RootStyle title="404 Page Not Found | Minimal-UI">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                We could not finish the request
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Please try again later or contact us if you think this is a
              mistake.
            </Typography>

            <motion.div variants={varBounceIn}>
              <PageNotFoundIllustration
                sx={{ height: 260, my: { xs: 5, sm: 10 } }}
              />
            </motion.div>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  )
}
