/* eslint-disable react/forbid-prop-types */
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import { Button, Grid, Typography } from '@material-ui/core'
import update from 'immutability-helper'
import NewFeeForm from './NewFeeForm'

const newFeeObject = {
  id: '',
  serviceId: null,
  addOnId: null,
  line_item: '',
  pretax_amount: '',
  tax_amount: '',
  pricing_details: '',
  tax_description: '',
  duration: 0,
  notes_for_client_invoice: '',
}

const ManualFeeForm = ({
  withTitle,
  fees,
  setFees,
  currencySymbol,
  deleteFee,
}) => {
  const addEmptyFee = () => {
    const newFee = { ...newFeeObject, id: uuidv4() }
    setFees([...fees, newFee])
  }

  const updateFee = (event) => {
    const { value, name } = event.target
    const [field, index] = name.split('---')
    setFees(update(fees, { [index]: { $merge: { [field]: value } } }))
  }

  return (
    <Grid container item xs={12} sx={{ mb: '10px' }}>
      {withTitle && (
        <Grid item xs={12} sx={{ mb: '20px' }}>
          <Typography variant="subtitle1">Fees:</Typography>
        </Grid>
      )}
      {fees.map((fee, index) => (
        <NewFeeForm
          key={fee.id}
          index={index}
          currencySymbol={currencySymbol}
          fee={fee}
          updateFee={updateFee}
          deleteFee={deleteFee}
        />
      ))}
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="primary" onClick={addEmptyFee}>
          Add Fee
        </Button>
      </Grid>
    </Grid>
  )
}

ManualFeeForm.propTypes = {
  withTitle: PropTypes.bool,
  fees: PropTypes.array.isRequired,
  setFees: PropTypes.func.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  deleteFee: PropTypes.func.isRequired,
}

ManualFeeForm.defaultProps = {
  withTitle: true,
}

export default ManualFeeForm
