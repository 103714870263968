import PropTypes from 'prop-types'
import { useContext } from 'react'
import { Icon } from '@iconify/react'
import menu2Fill from '@iconify/icons-eva/menu-2-fill'
// material
import {
  alpha,
  experimentalStyled as styled,
  useTheme,
} from '@material-ui/core/styles'
import { AppBar, Box, IconButton, Stack, Toolbar } from '@material-ui/core'
// components
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { AuthContext } from '../../contexts/AuthContext'
import NewAppointment from '../../components/Appointments2/NewAppointment'
import { MHidden } from '../../components/@material-extend'

// redux
import AccountPopover from './AccountPopover'
import SettingFullscreen from '../../components/settings/SettingFullscreen'

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 230
const DRAWER_WIDTH_COMPACT = 95
const APPBAR_MOBILE = 64
const APPBAR_DESKTOP = 64

const ToolbarStyle = styled(Toolbar)(
  ({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
      minHeight: APPBAR_DESKTOP,
      padding: '10px 16px',
    },
  }),
  { index: 999 },
)

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCompact: PropTypes.bool,
}

export default function DashboardNavbar({ onOpenSidebar, isCompact }) {
  const { user } = useContext(AuthContext)
  const isAdmin = false

  const RootStyle = styled(AppBar)(
    ({ theme }) => ({
      boxShadow: 'none',
      backdropFilter: 'blur(6px)',
      WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
      minHeight: 64,
      top: isAdmin && 20,
      backgroundColor: alpha(theme.palette.background.default, 0.72),
      zIndex: theme.zIndex.drawer,
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${
          (isCompact ? DRAWER_WIDTH_COMPACT : DRAWER_WIDTH) + 1
        }px)`,
      },
    }),
    { index: 1 },
  )

  const theme = useTheme()
  const smu = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <>
      <RootStyle>
        <ToolbarStyle>
          <MHidden width="lgUp">
            <IconButton
              onClick={onOpenSidebar}
              sx={{ mr: 1, color: 'text.primary' }}
            >
              <Icon icon={menu2Fill} />
            </IconButton>
          </MHidden>

          <>
            <Box sx={{ flexGrow: 1 }} />
            <Stack direction="row" spacing={{ xs: 0.5, sm: 1 }}>
              {/* ToDo: Check if user can schedule */}
              {user.user_type !== 5 && (
                <NewAppointment componentType={smu ? 'button' : 'Navbar'} />
              )}
              <SettingFullscreen />
              <AccountPopover />
            </Stack>
          </>
        </ToolbarStyle>
      </RootStyle>
    </>
  )
}
