/* eslint-disable react/forbid-prop-types */
import { Box, ListItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import defaultTimeOptions from './times'
import { ListPicker } from './TimePicker.styles'

function TimePickerV2({ time, timeOptions, onChange }) {
  const handleListItemClick = (_event, value) => {
    onChange(value)
  }

  return (
    <>
      <Box style={{ alignItems: 'center' }}>
        <ListPicker component="nav">
          {(timeOptions || defaultTimeOptions).map(({ label }) => (
            <ListItem
              key={label}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: 12,
                cursor: 'pointer',
              }}
              selected={time === label}
              onClick={(event) => handleListItemClick(event, label)}
            >
              {label}
            </ListItem>
          ))}
        </ListPicker>
      </Box>
    </>
  )
}

TimePickerV2.propTypes = {
  time: PropTypes.string.isRequired,
  timeOptions: PropTypes.arrayOf({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onChange: PropTypes.func.isRequired,
}

export default TimePickerV2
