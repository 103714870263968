import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getReport } from '../../services/report.service'

const initialState = {
  isLoading: false,
  dataLoaded: false,
  selectedReport: null,
}
export const fetchSelectedReport = createAsyncThunk(
  'reports/fetchSelectedReport',
  async ({ userId, reportId }) => {
    const res = await getReport({ userId, reportId })
    if (res.success) {
      return res.data
    }
    return Promise.reject()
  },
)

const slice = createSlice({
  name: 'userv2',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSelectedReport.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSelectedReport.fulfilled, (state, action) => {
        state.isLoading = false
        state.dataLoaded = true
        state.selectedReport = action.payload
      })
      .addCase(fetchSelectedReport.rejected, (state) => {
        state.isLoading = false
        state.dataLoaded = false
      })
  },
})

export const {} = slice.actions

export default slice.reducer
