import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  Tab,
  TextField,
} from '@material-ui/core'
import { experimentalStyled as styled } from '@material-ui/core/styles'

export const GreyGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.bgGrey,
  width: '100%',
  borderRadius: '10px',
  marginLeft: '0px',
  marginTop: '0px',
  marginBottom: '12px',
  alignItems: 'center',
  paddingTop: '10px',
  paddingBottom: '10px',
  paddingRight: '16px',
}))

export const Switchlabel = styled(FormControlLabel)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  fontSize: 14,
  '& .MuiFormControlLabel-label': {
    fontSize: 14,
    fontWeight: 700,
  },
}))

export const HelpText = styled(Box)(({ theme }) => ({
  fontSize: '0.75rem',
}))

export const FieldLabel = styled(Box)(({ theme }) => ({
  fontSize: '0.75rem',
}))

export const LinkText = styled(Link)(({ theme }) => ({
  fontSize: '0.75rem',
}))

export const SButton = styled(Button)(({ theme }) => ({
  padding: '5px 18px',
  fontSize: '0.6rem',
}))

export const MBButton = styled(Button)(({ theme }) => ({
  padding: '5px 18px',
  fontSize: '0.6rem',
  marginTop: '5px',
}))

export const TButton = styled(Button)(({ theme }) => ({
  padding: '0px 0px',
  fontSize: '0.6rem',
  borderRadius: '50%',
  display: 'inline-block',
  width: '18px',
  height: '18px',
  lineHeight: '18px',
  textAlign: 'center',
  minWidth: 'auto',
  float: 'right',
}))

export const CustomTextField = styled(TextField)(({ theme }) => ({
  '.MuiInputBase-sizeSmall,.MuiInputLabel-sizeSmall': {
    fontSize: '0.75rem',
    color: '#212B36',
  },
  '.MuiInputBase-sizeSmall': {
    backgroundColor: '#fff',
  },
  /* '.MuiInputBase-sizeSmall:not(.Mui-focused) .MuiOutlinedInput-notchedOutline':{
		borderColor:'#000 !important'
	} */
}))

export const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '0.68rem',
  '@media screen and (max-width:600px)': {
    minHeight: '38px',
  },
}))

export const InspectorKeyTeam = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  img: {
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    marginRight: '15px',
  },
  p: {
    fontSize: '0.72rem',
  },
}))

export const NavigationSortCard = styled(Card)(({ theme }) => ({
  ul: {
    listStyle: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    li: {
      display: 'inline-block',
      backgroundColor: theme.palette.primary.main,
      padding: '6px 12px',
      fontSize: '0.72rem',
      borderRadius: '10px',
      color: '#fff',
      marginRight: '10px',
      marginBottom: '10px',
    },
  },
  '&.inactive-navigation': {
    ul: {
      li: {
        backgroundColor: 'transparent',
        color: theme.palette.common.black,
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  },
}))

export const ThumbImgStyle = styled('img')(({ theme }) => ({
  width: '100%',
  height: 150,
  objectFit: 'cover',
  borderRadius: `12px 12px 0px 12px`,
}))

export const CustomTab = styled(Tab)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  boxShadow: theme.shadows[5],
  minHeight: 'fit-content',
  fontSize: 12,
  borderRadius: 20,
  background: '#efefef',
  margin: 5,
  color: theme.palette.text.primary,
  '&.Mui-selected': {
    background: theme.palette.primary.main,
    color: '#fff',
  },

  [theme.breakpoints.up('lg')]: {
    marginRight: `0 !important`,
    minWidth: '35% !important',
  },
  [theme.breakpoints.down('sm')]: {
    marginRight: `0 !important`,
    minWidth: '100% !important',
  },
}))
