// ----------------------------------------------------------------------

export default function Accordion(theme) {
  const isLight = theme.palette.mode === 'light'
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          position: 'relative',
          '&:before': {
            backgroundColor: theme.palette.common.white,
          },
          '& .MuiAccordionSummary-root': {
            borderRadius: 10,
            // padding: theme.spacing(0, 4),
            padding: '0 32px 0 22px',
            backgroundColor: isLight ? '#ececec' : theme.palette.grey[700],
            width: `100% !important`,
          },
          '&.Mui-expanded': {
            borderRadius: theme.shape.borderRadius,

            '& .MuiAccordionSummary-root': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              borderRadius: 10,
              minHeight: '48px !important',

              '& .MuiAccordionSummary-content': {
                margin: `12px 0 !important`,
              },
            },
            '& .MuiCollapse-root': {
              backgroundColor: 'white',
              color: theme.palette.text.primary,
            },
            '& .MuiAccordionDetails-root': {
              backgroundColor: theme.palette.background.paper,
            },
          },
          '&.Mui-disabled,&.not-present': {
            backgroundColor: 'transparent',
            color: `${theme.palette.action.disabled} !important`,
            borderRadius: 10,
            '& .MuiAccordionSummary-root': {
              backgroundColor: `${theme.palette.action.disabledBackground} !important`,
              width: `100% !important`,
            },
          },
          '& .LinesEllipsis': {
            fontSize: '0.75rem',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(1),
          '&.Mui-disabled': {
            opacity: 1,
            color: theme.palette.action.disabled,
            '& .MuiTypography-root': {
              color: 'inherit',
            },
          },
        },
        expandIconWrapper: {
          color: 'inherit',
        },
      },
    },
  }
}
