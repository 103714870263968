import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { Fragment, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CloseIcon from '@material-ui/icons/Close'
import SearchGoogleMapsPlace from '../../../../../common/SearchGoogleMapsPlace'
import LocationPreview from './LocationPreview'
import { MobileModalHeader } from '../../MobileMultiStep/MobileMultiStep.style'
import { FOUNDATION_OPTIONS } from '../../../../../../constants/foundationOptions'
import { AuthContext } from '../../../../../../contexts/AuthContext'

const AddLocation = ({
  isMobile,
  location,
  setLocation,
  setStepSlug,
  onClose,
  settings,
}) => {
  const { user } = useContext(AuthContext)
  const [coordinates, setCoordinates] = useState([
    settings?.companyAddress?.coordinates?.lat || 0,
    settings?.companyAddress?.coordinates?.lng || 0,
  ])

  const handleManual = () => {
    const searchGoogleEnabled = !location.searchGoogleEnabled
    setLocation({ ...location, searchGoogleEnabled })
  }

  const onInputChange = (e) => {
    const { name, value } = e.target
    setLocation({ ...location, [name]: value })
  }

  const updateOnlyNumbers = (e) => {
    const onlyNumbers = e.target.value.replace(/[^\d.]/g, '')
    e.target.value = onlyNumbers
    onInputChange(e)
  }

  const avoidDecimals = (event) => {
    if (event.key === '.') event.preventDefault()
  }

  const onAdressChange = (_name, address) => {
    const { fullAddress, street, streetNumber, city, state, cp, coordinates } =
      address
    setLocation({
      ...location,
      searchGoogleEnabled: false,
      fullAddress,
      streetAndNumber: streetNumber ? `${streetNumber} ${street}` : street,
      city,
      state,
      zipCode: cp,
      coordinates,
    })
  }

  const nextStep = () => {
    setStepSlug('addServices')
  }

  useEffect(() => {
    let tmpCoordinates = [0, 0]
    if (user && user.companySettings && user.companySettings.coordinates) {
      tmpCoordinates = user.companySettings.coordinates.split(',')
      setCoordinates([
        parseFloat(tmpCoordinates[0]),
        parseFloat(tmpCoordinates[1]),
      ])
    } else if (settings && settings.address_lat_long) {
      tmpCoordinates = settings.address_lat_long.split(',')
      setCoordinates([
        parseFloat(tmpCoordinates[0]),
        parseFloat(tmpCoordinates[1]),
      ])
    }
  }, [user, settings])

  return (
    <Fragment>
      {isMobile && (
        <MobileModalHeader sx={{ mb: '15px' }}>
          <Box>
            <IconButton
              sx={{ backgroundColor: '#eeeeee', marginLeft: '-8px' }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="h6">Add Location</Typography>
          </Box>
          <Box>
            <IconButton
              onClick={nextStep}
              sx={{
                padding: '6px',
                marginRight: '-8px',
                svg: { width: 28, height: 28 },
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          </Box>
        </MobileModalHeader>
      )}
      <Grid container spacing={2} sx={{ ...(isMobile && { marginTop: 0 }) }}>
        <Grid
          item
          xs={12}
          md={7}
          sx={{ ...(isMobile && { paddingTop: '0 !important' }) }}
        >
          <Card
            sx={{
              mx: 'auto',
              overflow: 'visible',
              zIndex: 1, // Very important to show google search place
              ...(isMobile ? { boxShadow: 'none' } : { p: 2 }),
            }}
          >
            <Box
              sx={{
                ...(isMobile
                  ? { display: 'flex', justifyContent: 'center', mb: '10px' }
                  : { mb: '24px' }),
              }}
            >
              {!isMobile && (
                <Typography
                  variant="h6"
                  mr={4}
                  sx={{ display: 'inline-block' }}
                >
                  Location
                </Typography>
              )}
              <Typography
                variant="caption"
                sx={{
                  float: 'right',
                  alignItems: 'center',
                  ...(isMobile ? { display: 'block' } : { display: 'flex' }),
                }}
              >
                {!location.searchGoogleEnabled
                  ? 'Search address'
                  : "Can't find your address?"}
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={handleManual}
                  sx={{
                    fontSize: '0.75rem',
                    ...(isMobile ? { p: '6px 16px' } : { p: '0 16px' }),
                  }}
                >
                  Click Here
                </Button>
              </Typography>
            </Box>
            <Grid container sx={{ justifyContent: 'center' }}>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'block',
                  mb: '16px',
                  ...(isMobile ? { p: 0 } : { p: '0 10px' }),
                }}
              >
                {location.searchGoogleEnabled ? (
                  <Box sx={{ minWidth: '100%' }}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      <SearchGoogleMapsPlace
                        name="location"
                        placeholder="Search location..."
                        value={location.fullAddress}
                        onChange={onAdressChange}
                        latitude={coordinates[0]}
                        longitude={coordinates[1]}
                        radius={
                          +(settings.companyAddress?.service_radius || 100)
                        }
                      />
                    </Box>
                  </Box>
                ) : (
                  <Fragment>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 3, sm: 2 }}
                      sx={{ mb: 2 }}
                    >
                      <TextField
                        name="streetAndNumber"
                        fullWidth
                        label="Address"
                        size="small"
                        value={location.streetAndNumber}
                        onChange={onInputChange}
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 3, sm: 2 }}
                    >
                      <TextField
                        name="city"
                        fullWidth
                        label="City"
                        size="small"
                        value={location.city}
                        onChange={onInputChange}
                      />
                      <TextField
                        name="state"
                        fullWidth
                        label="State"
                        size="small"
                        value={location.state}
                        onChange={onInputChange}
                      />
                      <TextField
                        sx={{ ...(!isMobile && { width: 280 }) }}
                        inputProps={{
                          style: { textAlign: isMobile ? 'left' : 'center' },
                        }}
                        name="zipCode"
                        fullWidth
                        label="Postal Code"
                        size="small"
                        value={location.zipCode}
                        onChange={onInputChange}
                      />
                    </Stack>
                  </Fragment>
                )}
              </Grid>
            </Grid>
            {/* NEW  (start) */}
            <Grid item xs={12} sm={12} sx={{ marginBottom: '16px' }}>
              <Typography variant="h6" sx={{ display: 'inline-block' }}>
                Property
              </Typography>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 2, sm: 2 }}
              >
                <TextField
                  fullWidth
                  name="square_feet"
                  label="Square Feet"
                  size="small"
                  value={location.square_feet || ''}
                  onChange={updateOnlyNumbers}
                />
                <TextField
                  fullWidth
                  name="year_built"
                  label="Year Built"
                  size="small"
                  value={location.year_built || ''}
                  onKeyPress={avoidDecimals}
                  onChange={updateOnlyNumbers}
                />
                <TextField
                  select
                  fullWidth
                  type="text"
                  name="foundation"
                  label="Foundation"
                  size="small"
                  value={location.foundation || ''}
                  onChange={onInputChange}
                >
                  {FOUNDATION_OPTIONS.map((row) => {
                    return (
                      <MenuItem key={row.key} value={row.value}>
                        {row.label}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Stack>
            </Grid>
            {/* NEW  (end) */}
            {!isMobile && (
              <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  sx={{ float: 'right', lineHeight: 1 }}
                  variant="contained"
                  color="inherit"
                  onClick={nextStep}
                >
                  Next
                </Button>
              </Grid>
            )}
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{ ...(isMobile && { paddingTop: '0 !important' }) }}
        >
          <LocationPreview
            isMobile={isMobile}
            fullAddress={location?.fullAddress}
            coordinates={location?.coordinates}
          />
        </Grid>
      </Grid>
    </Fragment>
  )
}

AddLocation.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    searchGoogleEnabled: PropTypes.bool.isRequired,
    fullAddress: PropTypes.string.isRequired,
    streetAndNumber: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    square_feet: PropTypes.string.isRequired,
    year_built: PropTypes.string.isRequired,
    foundation: PropTypes.string.isRequired,
    coordinates: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }),
  }).isRequired,
  setLocation: PropTypes.func.isRequired,
  setStepSlug: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  settings: PropTypes.object.isRequired,
}

export default AddLocation
