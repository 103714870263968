const rule_actions = [
  {
    key: 2,
    label: '— Service or Addon Being Added —',
    event_type: [3, 4],
    options: [
      {
        id: 6,
        label: 'Changed Service is',
        options: [
          {
            id: 6,
            type: 'select',
            value: 'changed_services',
            options: [{ id: '', label: '', options: [] }],
          },
        ],
      },
      {
        id: 7,
        label: 'Changed Service addon is',
        options: [
          {
            id: 7,
            type: 'select',
            value: 'changed_service_addons',
            options: [{ id: '', label: '', options: [] }],
          },
        ],
      },
    ],
  },
  {
    key: 3,
    label: '— Report Attachment Being Added —',
    event_type: [7, 8],
    options: [
      {
        id: 8,
        label: 'Report attachment name contains',
        options: [
          {
            id: 8,
            type: 'text',
            value: 'report_name',
            options: [],
          },
        ],
      },
    ],
  },
  {
    key: 1,
    label: '— Appointment —',
    event_type: [0],
    options: [
      {
        id: 1,
        label: 'Agreements signed',
        options: [],
      },
      {
        id: 2,
        label: 'Invoice paid',
        options: [],
      },
      {
        id: 3,
        label: 'Property detail',
        options: [
          {
            id: 1,
            type: 'select',
            value: 'field',
            options: [
              {
                id: 1,
                label: 'Foundation',
                options: [
                  {
                    id: 1,
                    value: 'operator',
                    type: 'select',
                    options: [
                      {
                        id: 1,
                        label: 'Equal to',
                      },
                      {
                        id: 2,
                        label: 'Not equal to',
                      },
                    ],
                  },
                  {
                    id: 2,
                    value: 'numeric',
                    type: 'select',
                    options: [
                      {
                        id: 1,
                        label: 'Basement',
                      },
                      {
                        id: 3,
                        label: 'Crawlspace',
                      },
                      {
                        id: 2,
                        label: 'Slab',
                      },
                    ],
                  },
                ],
              },
              {
                id: 2,
                label: 'Square Feet',
                options: [
                  {
                    id: 1,
                    value: 'operator',
                    type: 'select',
                    options: [
                      {
                        id: 1,
                        label: 'Greater than or equal to',
                      },
                      {
                        id: 2,
                        label: 'Less than',
                      },
                    ],
                  },
                  {
                    id: 2,
                    value: 'numeric',
                    type: 'number',
                    options: [],
                  },
                ],
              },
              {
                id: 3,
                label: 'Year Built',
                options: [
                  {
                    id: 1,
                    value: 'operator',
                    type: 'select',
                    options: [
                      {
                        id: 1,
                        label: 'Greater than or equal to',
                      },
                      {
                        id: 2,
                        label: 'Less than',
                      },
                    ],
                  },
                  {
                    id: 2,
                    value: 'numeric',
                    type: 'number',
                    options: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 4,
        label: 'Service',
        options: [
          {
            id: 4,
            type: 'select',
            value: 'services',
            options: [{ id: '', label: '', options: [] }],
          },
        ],
      },
      {
        id: 5,
        label: 'Service addon',
        options: [
          {
            id: 4,
            type: 'select',
            value: 'service_addons',
            options: [{ id: '', label: '', options: [] }],
          },
        ],
      },
    ],
  },
]

export default rule_actions
