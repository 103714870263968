/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core'
import { experimentalStyled as styled } from '@material-ui/core/styles'
import { Icon } from '@iconify/react'
import CloseFill from '@iconify/icons-eva/close-fill'
import { varFadeInDown } from './variants'

const AddDialog = styled(Dialog)(({ theme, isUsingIframe = false }) => ({
  '& .MuiBackdrop-root': {
    background: 'transparent',
  },
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
  },
  '& .MuiDialog-paperFullScreen': {
    background: 'rgba(22, 28, 36, 0.6)',
    ...(isUsingIframe && { padding: theme.spacing(8, 0, 3, 0) }),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
  },
}))

const CloseBtn = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
  height: `30px`,
  width: `30px`,
  borderRadius: `100%`,
  background: theme.palette.background.customGrey,
  padding: theme.spacing(0),
  '&:hover': {
    background: theme.palette.background.customGrey,
  },
}))

const DialogAnimate = ({
  isUsingIframe = false,
  open = false,
  animate,
  onClose,
  children,
  ...other
}) => {
  return (
    <AnimatePresence>
      {open && (
        <AddDialog
          fullScreen
          scroll="body"
          open={open}
          onClose={onClose}
          // onBackdropClick="false" <----- Deprecated
          PaperComponent={motion.div}
          PaperProps={{
            sx: {
              borderRadius: 2,
              bgcolor: 'transparent',
            },
            ...(animate || varFadeInDown),
          }}
          {...other}
        >
          <DialogTitle
            style={{ position: 'relative' }}
            sx={{ paddingTop: '32px', paddingBottom: '32px' }}
          >
            <CloseBtn onClick={onClose} sx={{ marginTop: '5px' }}>
              <Icon icon={CloseFill} />
            </CloseBtn>
          </DialogTitle>
          <DialogContent sx={{ padding: '0px 7px 24px 7px' }}>
            {children}
          </DialogContent>
        </AddDialog>
      )}
    </AnimatePresence>
  )
}

DialogAnimate.propTypes = {
  open: PropTypes.bool.isRequired,
  animate: PropTypes.object,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default DialogAnimate
