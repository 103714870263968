export const Gateways = Object.freeze({
  AuthorizeNet: 'authorizeNet',
  Stripe: 'stripe',
  PayPal: 'payPal',
  Square: 'square',
  Fortis: 'fortis',
})

export const PaymentIds = Object.freeze({
  1: null, // Without payments => weird
  2: null, // Select payment? => So weird!
  3: Gateways.AuthorizeNet,
  4: Gateways.Square,
  5: Gateways.Stripe,
  6: Gateways.PayPal,
  7: Gateways.Fortis,
})
