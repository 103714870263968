/* eslint-disable no-restricted-syntax */
import update from 'immutability-helper'

export const addTemplate = (template, current, serviceId = null) => {
  const { id, name, attachments } = template
  const alreadyExists = current.some(({ value }) => value === id)
  if (!alreadyExists) {
    return current.concat({
      keys: id,
      label: name,
      value: id,
      attachments,
      ...(serviceId && { because: [serviceId] }),
    })
  }
  const index = current.findIndex(({ value }) => value === id)
  const updatedTemplates = update(current, {
    [index]: { because: { $push: [serviceId] } },
  })
  return updatedTemplates
}

export const addTemplates = (newTemplates, current, serviceId) => {
  let updatedTemplates = current
  for (const template of newTemplates) {
    updatedTemplates = addTemplate(template, current, serviceId)
  }
  return updatedTemplates
}

export const updateTemplates = (templates, serviceId) => {
  const updatedTemplates = []
  templates.forEach((template) => {
    if (!template.because) updatedTemplates.push(template)
    else {
      const because = template.because.filter((id) => id !== serviceId)
      if (because.length > 0) updatedTemplates.push({ ...template, because })
    }
  })
  return updatedTemplates
}

export const addAgreement = (agreement, current, serviceId = null) => {
  const { id, name, content } = agreement
  const alreadyExists = current.some(({ value }) => value === id)
  if (!alreadyExists) {
    return current.concat({
      keys: id,
      label: name,
      value: id,
      content,
      ...(serviceId && { because: [serviceId] }),
    })
  }
  const index = current.findIndex(({ value }) => value === id)
  const updatedAgreements = update(current, {
    [index]: { because: { $push: [serviceId] } },
  })
  return updatedAgreements
}

export const addAgreements = (newAgreements, current, serviceId) => {
  let updatedAgreements = current
  for (const agreement of newAgreements) {
    updatedAgreements = addAgreement(agreement, current, serviceId)
  }
  return updatedAgreements
}

export const updateAgreements = (agreements, serviceId) => {
  const updatedAgreements = []
  agreements.forEach((agreement) => {
    if (!agreement.because) updatedAgreements.push(agreement)
    else {
      const because = agreement.because.filter((id) => id !== serviceId)
      if (because.length > 0) updatedAgreements.push({ ...agreement, because })
    }
  })
  return updatedAgreements
}
