/* eslint-disable react/forbid-prop-types */
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import update from 'immutability-helper'
import { difference } from 'lodash'
import Cart from './Cart'
import getServiceFee from '../../../../../_utils/getServiceFee'
import getAddonFee from '../../../../../_utils/getAddonFee'
import {
  addAgreements,
  addTemplates,
  updateAgreements,
  updateTemplates,
} from '../../../../../_utils/updateTemplatesAndAgreements'

const SelectServices = ({
  newServices,
  location,
  isMobile,
  serviceOptions,
  discountCode, // TODO: we will use it for discount integration
  currencySymbol,
  updateServiceField,
  onInputChange, // TODO: we will use it for discount integration
  handleManualEdit,
  isUsingIFrame,
}) => {
  const { services, templates, agreements, fees, manualDuration } = newServices

  const servicesWithOutAddOns = useMemo(
    () =>
      serviceOptions.map((service) => {
        // return with no add-ons assigned
        return { ...service, add_ons: [] }
      }),
    [serviceOptions],
  )

  const addService = (service) => {
    const fee = getServiceFee({ service, location, currencySymbol })
    const updatedData = {
      ...newServices,
      services: services.concat(service),
      templates: addTemplates(service.templates, templates, service.id),
      agreements: addAgreements(service.agreements, agreements, service.id),
      fees: newServices.fees.concat(fee),
    }
    updateServiceField('newServices', updatedData)
  }

  const removeService = (service) => {
    const updatedData = {
      ...newServices,
      services: services.filter((s) => s.id !== service.id),
      templates: updateTemplates(templates, service.id),
      agreements: updateAgreements(agreements, service.id),
      fees: fees.filter((f) => f.serviceId !== service.id),
    }
    updateServiceField('newServices', updatedData)
  }

  const updateServices = (newArray) => {
    if (newArray.length > services.length) {
      // Add a service
      const service = difference(newArray, services)[0]
      addService(service)
    } else if (newArray.length < services.length) {
      // Remove a service
      const service = difference(services, newArray)[0]
      removeService(service)
    }
  }

  const addAddOn = (serviceIndex, addOn) => {
    const service = services[serviceIndex]
    const fee = getAddonFee({ service, addOn, currencySymbol })
    const updatedData = {
      ...newServices,
      fees: [...newServices.fees, fee],
      services: update(services, {
        [serviceIndex]: { add_ons: { $push: [addOn] } },
      }),
    }
    updateServiceField('newServices', updatedData)
  }

  const removeAddOn = (serviceIndex, addOn) => {
    const service = services[serviceIndex]
    const addOnIndex = service.add_ons.map((a) => a.id).indexOf(addOn.id)
    const updatedServices = update(services, {
      [serviceIndex]: { add_ons: { $splice: [[addOnIndex, 1]] } },
    })
    const updatedData = {
      ...newServices,
      fees: fees.filter((f) => f.addOnId !== addOn.id),
      services: updatedServices,
    }
    updateServiceField('newServices', updatedData)
  }

  const updateAddOns = (serviceId, newArray) => {
    const serviceIndex = services.map((s) => s.id).indexOf(serviceId)
    const addOns = services[serviceIndex].add_ons
    if (newArray.length > addOns.length) {
      // Add an addon
      const addOn = difference(newArray, addOns)[0]
      addAddOn(serviceIndex, addOn)
    } else if (newArray.length < addOns.length) {
      // Remove an addon
      const addOn = difference(addOns, newArray)[0]
      removeAddOn(serviceIndex, addOn)
    }
  }

  return (
    <Grid container sx={{ width: '100%' }}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ ...(!isMobile && { p: '0 20px 0 0px' }) }}
      >
        <Autocomplete
          sx={{ marginBottom: 2 }}
          multiple
          fullWidth
          label="Select Services"
          options={servicesWithOutAddOns}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={services}
          filterSelectedOptions
          onChange={(_event, newArray) => updateServices(newArray)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Services"
              placeholder={!services.length ? 'Search for' : ''}
              size="small"
            />
          )}
        />

        {/* Service add-ons (for each service selected) */}
        {services.map((service) => {
          // Get service from real list (serviceOptions)
          const serviceData = serviceOptions.find(
            (item) => item.id === service.id,
          )

          const addOnsOptions = serviceData?.add_ons || []

          return !addOnsOptions.length ? null : (
            <Autocomplete
              key={service.id}
              sx={{ marginBottom: 2 }}
              multiple
              fullWidth
              label={`${service.name} Options`}
              options={addOnsOptions}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={service.add_ons}
              filterSelectedOptions
              onChange={(_event, newArray) =>
                updateAddOns(service.id, newArray)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={`add-on-options_${service.id}`}
                  label={`${service.name} Options`}
                  placeholder={!service.add_ons.length ? 'Search for' : ''}
                  size="small"
                />
              )}
            />
          )
        })}

        {/* TODO: is_details_page */}

        {/* <TextField
          fullWidth
          label="Discount code "
          placeholder="Enter code"
          size="small"
          name="discountCode"
          sx={{ my: 2 }}
          value={discountCode}
          onChange={onInputChange}
          onBlur={(e) => {
            // TODO: checkDiscountCode
            console.log(`checkDiscountCode(${e.target.value})`)
          }}
        /> */}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ ...(!isMobile && { p: '0 11px 0 20px' }) }}
      >
        <Stack>
          <Typography variant="subtitle1" sx={{ pb: 1 }}>
            Service Charges
          </Typography>

          {/* **  Cart ** */}
          <Cart
            templates={templates}
            agreements={agreements}
            fees={fees}
            manualDuration={manualDuration}
            currencySymbol={currencySymbol}
          />

          {!isUsingIFrame && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <Button variant="primary" onClick={handleManualEdit}>
                Manual Edit
              </Button>
            </Box>
          )}
        </Stack>
      </Grid>
    </Grid>
  )
}

SelectServices.propTypes = {
  newServices: PropTypes.object.isRequired,
  location: PropTypes.shape({
    searchGoogleEnabled: PropTypes.bool.isRequired,
    fullAddress: PropTypes.string.isRequired,
    streetAndNumber: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    square_feet: PropTypes.string.isRequired,
    year_built: PropTypes.string.isRequired,
    foundation: PropTypes.string.isRequired,
    coordinates: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }),
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  serviceOptions: PropTypes.array.isRequired,
  discountCode: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  updateServiceField: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  handleManualEdit: PropTypes.func.isRequired,
  isUsingIFrame: PropTypes.bool,
}

export default SelectServices
