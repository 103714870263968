/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import {
  Autocomplete,
  Grid,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { emailOptions as EMAIL_OPTIONS } from '../../../../../../../../../constants/action-variables'
import SMS_VARIABLES from '../../../../../../../../../constants/inspection_sms_fields'

const TextDetails = ({ to_address, body_content, updateAction }) => {
  const onChange = (event) => {
    const { name, value } = event.target
    updateAction(name, value)
  }

  const getCursorPos = (input) => {
    input.focus()
    if ('selectionStart' in input) {
      return {
        selectionStart: input.selectionStart,
        selectionEnd: input.selectionEnd,
      }
    }
    // Internet Explorer before version 9
    return { selectionStart: 0, selectionEnd: 0 }
  }

  const onVaribleClicked = (value) => {
    const textarea = document.getElementsByName('body_content')[0]
    const { selectionStart, selectionEnd } = getCursorPos(textarea)
    const string = textarea.value
    const partA = string.substring(0, selectionStart)
    const variable = `{{${value.toUpperCase().split(' ').join('_')}}}`
    const partB = string.substring(selectionEnd, string.length)
    updateAction('body_content', `${partA}${variable}${partB}`)
  }

  return (
    <Stack spacing={2} sx={{ my: 2 }}>
      <Autocomplete
        multiple
        fullWidth
        name="to_address"
        options={EMAIL_OPTIONS}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        value={to_address}
        filterSelectedOptions
        onChange={(_event, newValue) => updateAction('to_address', newValue)}
        // freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label="To"
            placeholder={to_address ? '' : 'type a value or select one'}
          />
        )}
      />
      <Grid container>
        <Grid item xs={12} md={8} style={{ paddingTop: '0px' }}>
          <TextField
            multiline
            fullWidth
            name="body_content"
            type="text"
            size="small"
            value={body_content}
            onChange={onChange}
            sx={{ textarea: { minHeight: 230 } }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{ paddingTop: '0px', paddingLeft: '10px' }}
        >
          <MenuList
            sx={{
              padding: 0,
              height: '247px',
              overflowY: 'auto',
              border: '1px solid #dbe0e4',
              borderRadius: '8px',
            }}
          >
            {SMS_VARIABLES.map((item, i) => {
              return (
                <Tooltip
                  // eslint-disable-next-line react/no-array-index-key
                  key={`email-variable-${i}`}
                  arrow
                  title={item.body}
                  placement="right"
                >
                  <MenuItem
                    sx={{
                      fontSize: '12px',
                      borderRadius: '8px',
                      padding: '7px 15px',
                      justifyContent: 'center',
                    }}
                    onClick={() => onVaribleClicked(item.text)}
                  >
                    {item.text}
                  </MenuItem>
                </Tooltip>
              )
            })}
          </MenuList>
        </Grid>
      </Grid>
    </Stack>
  )
}

TextDetails.propTypes = {
  to_address: PropTypes.array,
  body_content: PropTypes.string,
  updateAction: PropTypes.func,
}

export default TextDetails
