/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import { useContext, useState, useEffect, Fragment } from 'react'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
  IconButton,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import EditIcon from '@mui/icons-material/Edit'
import { searchAgents } from '../../../../../../services/agent.service'
import useDebounce from '../../../../../../hooks/useDebounce'
import { searchClients } from '../../../../../../services/client.service'
import { searchContractors } from '../../../../../../services/contractor.service'
import { searchTechnicians } from '../../../../../../services/technician.service'
import { getAppointmentSelectedTechnician } from '../../../../../../redux/selectors/appointments'
import { getSubscriberId } from '../../../../../../redux/selectors/iframeAppointment'
import { AuthContext } from '../../../../../../contexts/AuthContext'

const SelectPeopleForm2 = ({
  isMobile,
  type,
  typeLabel,
  placeholderLabel,
  handleShowModal,
  selected,
  onChange,
  isUsingIFrame,
  selectPersonBasicData,
}) => {
  const { user } = useContext(AuthContext)
  const [options, setOptions] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([])
  const [rowsPerPage] = useState(isUsingIFrame ? 4 : 15)
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState('')
  const subscriberId = user?.subscriber_id || useSelector(getSubscriberId)

  const debounce = useDebounce({ val: text, delay: 350 })

  const getTechnicianIdAppointmentState = useSelector(
    getAppointmentSelectedTechnician,
  )

  const fetchSearch = async (cursor) => {
    const query = { subscriberId, perPage: rowsPerPage, text: debounce }
    if (cursor) query.cursor = cursor
    let res
    if (type === 'agents' || type === 'listingAgents') {
      res = await searchAgents(query)
    } else if (type === 'clients') {
      res = await searchClients(query)
    } else if (type === 'contractors') {
      res = await searchContractors(query)
    } else if (type === 'technicians') {
      query.perPage = 100
      res = await searchTechnicians(query)
    }
    const { pageData, pageInfo } = res.data
    const { total, endCursor: nextCursor } = pageInfo
    return { total, nextCursor, list: pageData }
  }

  useEffect(() => {
    const searchText = async () => {
      setLoading(true)
      const result = await fetchSearch()
      if (
        !!getTechnicianIdAppointmentState &&
        getTechnicianIdAppointmentState.trim().length > 0
      ) {
        setOptions([
          ...selectedOptions,
          ...result.list.filter(
            (user) => user.id !== getTechnicianIdAppointmentState,
          ),
        ])
      } else if (type !== 'technicians') {
        setOptions([...selectedOptions, ...result.list])
      }
      setLoading(false)
    }
    if (type === 'technicians') {
      searchText()
    }
  }, [])

  useEffect(() => {
    const searchText = async () => {
      setLoading(true)
      const result = debounce ? await fetchSearch() : initialState
      if (
        type === 'technicians' &&
        !!getTechnicianIdAppointmentState &&
        getTechnicianIdAppointmentState.trim().length > 0
      ) {
        setOptions([
          ...selectedOptions,
          ...result.list.filter(
            (user) => user.id !== getTechnicianIdAppointmentState,
          ),
        ])
      } else if (type !== 'technicians') {
        setOptions([...selectedOptions, ...result.list])
      }
      setLoading(false)
    }
    if (type !== 'technicians') {
      searchText()
    }
  }, [debounce])

  const handleOnChange = (value) => {
    setText(value)
  }

  return (
    <Fragment>
      <Typography variant="subtitle2" sx={{ margin: '0 !important' }}>
        {typeLabel}
      </Typography>
      <Grid container style={{ margin: '0 0 12px 0' }}>
        <Grid item xs={12} md={10}>
          <Autocomplete
            multiple
            fullWidth
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name}`
            }
            options={options}
            loading={loading}
            renderOption={(props, option) => (
              <li {...props} key={`${type}--${option.id}`}>
                <Grid container>
                  <Grid item xs={4} md={1} lg={1}>
                    <Avatar
                      alt={`${option.first_name} ${option.last_name}`}
                      src={option.profile_pic}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      sx={{ fontSize: 14, marginBottom: 0 }}
                      color="text.primary"
                      gutterBottom
                    >
                      {`${option.first_name} ${option.last_name}`}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 12 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {`${option.email}`}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={placeholderLabel}
                size="small"
                onChange={(e) => handleOnChange(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
            value={selected}
            onChange={(_event, value) => {
              setSelectedOptions([...value])
              onChange(type, value)
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={`${type}---${option.id}-${index}`}
                  {...getTagProps({ index })}
                  label={
                    <Stack
                      direction={{ xs: 'row' }}
                      spacing={1}
                      sx={{ alignItems: 'center' }}
                    >
                      <Avatar
                        alt={`${option.first_name} ${option.last_name}`}
                        src={option.profile_pic}
                        sx={{ height: 32, width: 32 }}
                      />
                      <Box direction="row" spacing={0} sx={{ lineHeight: 1 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            lineHeight: 1,
                            marginBottom: '2px',
                            fontWeight: 500,
                          }}
                        >
                          {`${option.first_name} ${option.last_name}`}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ lineHeight: 1, fontSize: '11px' }}
                        >
                          {option.email}
                        </Typography>
                      </Box>
                      {type !== 'technicians' && (
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() => selectPersonBasicData(type, option)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Stack>
                  }
                  sx={{ height: 48 }}
                />
              ))
            }
          />
        </Grid>
        {type !== 'technicians' && (
          <Grid
            item
            xs={12}
            md={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              ...(isMobile ? { marginTop: '8px' } : { paddingLeft: '20px' }),
            }}
          >
            <Button
              onClick={(_e) => handleShowModal(type)}
              sx={{
                // minHeight: 'fit-content',
                height: 40,
                fontSize: 12,
                transform: 'scale(1) !important',
                width: '100%',
              }}
            >
              Add New
            </Button>
          </Grid>
        )}
      </Grid>
    </Fragment>
  )
}

SelectPeopleForm2.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  type: PropTypes.oneOf([
    'technicians',
    'clients',
    'agents',
    'listingAgents',
    'contractors',
  ]).isRequired,
  typeLabel: PropTypes.string.isRequired,
  placeholderLabel: PropTypes.string.isRequired,
  newOptions: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  handleShowModal: PropTypes.func.isRequired,
  isUsingIFrame: PropTypes.bool,
  selectPersonBasicData: PropTypes.func,
}
const initialState = { total: 0, nextCursor: null, list: [] }

export default SelectPeopleForm2
