// routes
// components
import { Icon } from '@iconify/react'
// icons
import SearchIcon from '@iconify/icons-eva/search-outline'
import MsgBIcon from '@iconify-icons/akar-icons/envelope'
import discounIcon from '@iconify-icons/akar-icons/tag'
import Dolla from '@iconify/icons-ant-design/dollar-circle-outlined'
import Mail from '@iconify/icons-ic/outline-attach-email'
import People from '@iconify/icons-eva/people-outline'
import List from '@iconify/icons-eva/list-outline'
import clipBoard from '@iconify/icons-eva/clipboard-outline'
import Settings from '@iconify/icons-eva/settings-outline'
import Rocket from '@iconify-icons/radix-icons/rocket'
import Tracking from '@iconify/icons-eva/email-outline'
import SvgIconStyle from '../../components/SvgIconStyle'
import { PATH_ADMIN, PATH_DASHBOARD } from '../../routes/paths'

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: '100%', height: '100%' }}
  />
)

const getIconify = (name) => <Icon icon={name} width="25px" height="25px" />

const ICONS = {
  appointment: getIconify(SearchIcon),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  business: getIcon('ic_business'),
  settings: getIcon('ic_settings'),
  calendar: getIcon('ic_calendar'),
  comms: getIcon('ic_comms'),
  people: getIcon('ic_people'),
  dashboard: getIcon('ic_dashboard'),
  template: getIcon('ic_template'),
  subscription: getIconify(List),
  msgBundle: getIconify(MsgBIcon),
  discounts: getIconify(discounIcon),
  paymentHistory: getIconify(Dolla),
  emailMgmt: getIconify(Mail),
  productUpdate: getIconify(Rocket),
  trackEmail: getIconify(Tracking),
  subs: getIconify(People),
  repo: getIconify(clipBoard),
  sett: getIconify(Settings),
}

const sidebarConfig = [
  {
    name: 'user',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
      },
      {
        title: 'Appointment',
        path: PATH_DASHBOARD.appointments.root,
        icon: ICONS.appointment,
      },
      {
        title: 'Calendar',
        path: PATH_DASHBOARD.calendar,
        icon: ICONS.calendar,
      },
      { title: 'People', path: PATH_DASHBOARD.people.root, icon: ICONS.people },
      {
        title: 'Templates',
        path: PATH_DASHBOARD.templates.root,
        icon: ICONS.template,
      },
      {
        title: 'Business',
        path: PATH_DASHBOARD.business.root,
        icon: ICONS.business,
      },
      {
        title: 'Communication',
        path: PATH_DASHBOARD.communication,
        icon: ICONS.comms,
      },
    ],
  },
  {
    name: 'admin',
    items: [
      {
        title: 'Dashboard',
        path: PATH_ADMIN.dashboard,
        icon: ICONS.dashboard,
      },
      {
        title: 'Subscribers',
        path: PATH_ADMIN.subscribers.root,
        icon: ICONS.subs,
      },
      {
        title: 'Subscription Plans',
        path: PATH_ADMIN.subscriptionPlans.root,
        icon: ICONS.subscription,
      },
      {
        title: 'Message Bundles',
        path: PATH_ADMIN.subscriptionPlans.msgPlans,
        icon: ICONS.msgBundle,
      },
      { title: 'Discounts', path: PATH_ADMIN.discounts, icon: ICONS.discounts },
      {
        title: 'Payment History',
        path: PATH_ADMIN.paymentHistory,
        icon: ICONS.paymentHistory,
      },
      {
        title: 'Email Management',
        path: PATH_ADMIN.emailMgmt.root,
        icon: ICONS.emailMgmt,
      },
      {
        title: 'Product Updates',
        path: PATH_ADMIN.updates.root,
        icon: ICONS.productUpdate,
      },
      {
        title: 'Track Emails',
        path: PATH_ADMIN.trackEmail.root,
        icon: ICONS.trackEmail,
      },

      {
        title: 'Reports',
        path: PATH_ADMIN.reports.root,
        icon: ICONS.repo,
        children: [
          {
            title: 'Subscribers Report',
            path: PATH_ADMIN.reports.subscriberReport,
            icon: ICONS.business,
          },
          {
            title: 'Payment Reports',
            path: PATH_ADMIN.reports.paymentReport,
            icon: ICONS.comms,
          },
          {
            title: 'Subscription Reports',
            path: PATH_ADMIN.reports.subsReport,
            icon: ICONS.comms,
          },
        ],
      },
      { title: 'Settings', path: PATH_ADMIN.settings.root, icon: ICONS.sett },
    ],
  },
]

export default sidebarConfig
