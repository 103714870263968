import { experimentalStyled as styled } from '@material-ui/core/styles'
import { Box, Card, FormControlLabel } from '@material-ui/core'

export const Step = styled(Card)(({ theme }) => ({
  margin: '16px auto',
  maxWidth: 900,
  padding: theme.spacing(2),
  borderRadius: `24px !important`,
  [theme.breakpoints.down('md')]: {
    position: 'relative !important',
  },
}))

export const StepHead = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `0 0 10px 0`,
  borderBottom: `1px solid #e1e1e1`,
}))

export const Switchlabel = styled(FormControlLabel)(({ theme }) => ({
  padding: theme.spacing(1, 1),
  fontSize: 12,
  '& .MuiFormControlLabel-label': {
    fontSize: 12,
    fontWeight: 700,
  },
}))
