import { Avatar, Box, Stack, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { alpha } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { setAppointmentSelectedTechnician } from '../../../../../../redux/reducers/appointments'

const AgendaList = ({
  selectedTimeSlot,
  setDateTime,
  selectedEvent,
  setSelectedEvent,
  nextStep,
}) => {
  if (!selectedTimeSlot) return null
  const [Color, setColor] = useState(selectedTimeSlot.color)
  const dispatch = useDispatch()

  const handleSelectAvailableTimeSlot = (item) => {
    const tmpDate = dayjs(selectedTimeSlot.date)
      .set('hour', selectedTimeSlot.hour)
      .set('minute', selectedTimeSlot.minute)
      .set('second', 0)
    const newDay = tmpDate.format('YYYY/MM/DD')
    const time = tmpDate.format('hh:mm A')
    setDateTime(dayjs(`${newDay} ${time}`).format())

    setSelectedEvent({
      color: selectedTimeSlot.color,
      end: selectedTimeSlot.end,
      start: selectedTimeSlot.start,
      title: selectedTimeSlot.title,
      ...item,
    })
    dispatch(setAppointmentSelectedTechnician(item.user.id))
    nextStep()
  }

  const isSelectedColor = (item) => {
    if (!selectedEvent) return Color
    if (
      dayjs(selectedTimeSlot.start).format('YYYY/MM/DD') ===
        dayjs(selectedEvent.start).format('YYYY/MM/DD') &&
      dayjs(selectedTimeSlot.start).format('hh:mm A') ===
        dayjs(selectedEvent.start).format('hh:mm A') &&
      item.user.id === selectedEvent.user.id
    ) {
      return '#f79023'
    }
    return Color
  }

  return (
    <div>
      <Typography
        align="center"
        paragraph
        style={{ background: '#f7f7f7', padding: '4px' }}
      >
        <b>
          {`${dayjs(selectedTimeSlot.start).format('DD ddd MMMM YYYY')}`}{' '}
          {dayjs(selectedTimeSlot.start).format('HH:mm')}
        </b>
      </Typography>
      <Box
        className="md-custom-event-cont"
        sx={{
          width: '100%',
        }}
      >
        {selectedTimeSlot.values.map((item) => (
          <Stack
            key={
              dayjs(selectedTimeSlot.start).format('DD-ddd-MMMM-YYYY') +
              item.user.id
            }
            direction="row"
            sx={{
              width: '100%',
              flexWrap: 'wrap',
              marginBottom: '8px',
              background: alpha(isSelectedColor(item), 0.3),
              border: `1px solid ${isSelectedColor(item)}`,
              borderLeft: `3px solid ${isSelectedColor(item)}`,
              padding: '3px',
              cursor: 'pointer',
              ':hover': {
                background: alpha(Color, 0.8),
              },
            }}
            onClick={() => handleSelectAvailableTimeSlot({ ...item, hour: item.hour, minute: item.minute })}
          >
            <Stack
              direction="row"
              className="md-cutom-event-img-cont"
              spacing="2px"
            >
              <Avatar
                className="event-people"
                sx={{
                  width: '40px',
                  height: '40px',
                  border: `2px solid  !important`,
                  marginTop: '4px',
                  marginLeft: '4px',
                }}
                src={item.user.profile_pic}
              />
              <Stack sx={{ marginLeft: '16px !important' }}>
                <Typography
                  variant="subtitle2"
                  color="text.primary"
                  className="md-custom-event-title"
                >
                  {`${selectedTimeSlot.title} | ${item.user.first_name} ${item.user.last_name}`}
                </Typography>
                <Typography
                  variant="caption"
                  color="text.primary"
                  className="md-custom-event-title"
                >
                  {/* Company name */}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Box>
    </div>
  )
}

AgendaList.propTypes = {
  selectedTimeSlot: PropTypes.oneOfType([PropTypes.object]),
  setDateTime: PropTypes.func.isRequired,
  selectedEvent: PropTypes.oneOfType([PropTypes.object]),
  setSelectedEvent: PropTypes.func.isRequired,
  nextStep: PropTypes.func,
}

export default AgendaList
