import PropTypes from 'prop-types'
import { useState, useEffect, useMemo, useCallback } from 'react'
import '@mobiscroll/react/dist/css/mobiscroll.min.css'
import './DateTime.css'
import {
  Eventcalendar,
  Page,
  localeEn,
  momentTimezone,
} from '@mobiscroll/react'
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Stack,
  Typography,
} from '@material-ui/core'
import dayjs from 'dayjs'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import BlockUi from 'react-block-ui'
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
import moment from 'moment-timezone'
import AgendaList from './AgendaList'
import Info from '../../../../../info'
import { MobileModalHeader } from '../../MobileMultiStep/MobileMultiStep.style'
import ConditionalWrapper from '../../../../../_new/_common/ConditionalWrapper'
import { Step, StepHead } from '../FormSteps.styles'
import { LabelCalendar } from './SetDateTime.styles'
import { getUserAppointmentSettings } from '../../../../../../redux/selectors/user'
import AddNewTimeSlot from './AddNewTimeSlot'
import {
  getAppointmentLoading,
  getAppointmentTimeSlots,
} from '../../../../../../redux/selectors/appointments'
import { getCalendarSelectedDateTime } from '../../../../../../redux/selectors/calendar'
import { fetchTimeSlotsAppointments } from '../../../../../../redux/reducers/appointments'

momentTimezone.moment = moment

const SetDateTime2 = ({
  setDateTime,
  selectedEvent,
  setSelectedEvent,
  isMobile,
  setStepSlug,
  isUsingIframe,
  subscriberId,
}) => {
  const dispatch = useDispatch()
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null)
  const [showAddNewTimeSlot, setShowAddNewTimeSlot] = useState(false)
  const { timezone: userTimeZone } = useSelector(getUserAppointmentSettings)
  const selectedDateStore = useSelector(getCalendarSelectedDateTime)

  const [myInvalid, setMyInvalid] = useState([
    {
      start: dayjs().endOf('day').subtract(10, 'day').toDate(),
      end: dayjs().endOf('day').toDate(),
    },
  ])

  const [viewSettings, setViewSettings] = useState({
    calendar: {
      type: 'week',
      size: 2,
      labels: true,
    },
  })
  const [datesSelected, setDatesSelected] = useState({
    fromDate: moment()
      .format('YYYY-MM-DD'),
    toDate: moment().endOf('week').add(2, 'week').format('YYYY-MM-DD'),
  })
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY/MM/DD'))
  const [mySelectedDate, setMySelectedDate] = useState(new Date())

  const timeSlotsFromStore = useSelector(getAppointmentTimeSlots)
  const timeSlotsFromStoreLoading = useSelector(getAppointmentLoading)
  const [userTimeZoneState, setUserTimeZoneState] = useState(null)

  const view = useMemo(() => {
    return viewSettings
  }, [viewSettings])

  const renderLabel = useCallback((data, selectedTimeSlot, selectedEvent) => {
    if (
      selectedEvent &&
      dayjs(selectedEvent.start).format('YYYY/MM/DD') ===
        dayjs(data.original.start).format('YYYY/MM/DD') &&
      dayjs(selectedEvent.start).format('hh:mm A') ===
        dayjs(data.original.start).format('hh:mm A')
    ) {
      return (
        <LabelCalendar backgroundColor="#f79023">
          <b>{data.title}</b>
        </LabelCalendar>
      )
    }
    if (
      selectedTimeSlot &&
      dayjs(selectedTimeSlot.start).format('YYYY/MM/DD') ===
        dayjs(data.original.start).format('YYYY/MM/DD') &&
      dayjs(selectedTimeSlot.start).format('hh:mm A') ===
        dayjs(data.original.start).format('hh:mm A')
    ) {
      return (
        <LabelCalendar backgroundColor="#7dbbf5" hoverColor="#fff">
          <b>{data.title}</b>
        </LabelCalendar>
      )
    }
    return (
      <LabelCalendar backgroundColor={data.original.color} hoverColor="#fff">
        <b>{data.title}</b>
      </LabelCalendar>
    )
  }, [])

  const onEventClickSelectTimeSlot = useCallback(async (e) => {
    if ((e.event && e.source === 'calendar') || e.source === 'popover') {
      setSelectedTimeSlot({ ...e.event })
    }

    if (e.source === 'agenda' && e.type === 'onEventClick') {
    }
  }, [])

  const onCellClickSelectTimeSlot = useCallback(async (e) => {
    setSelectedDate(dayjs(e.date).format('YYYY/MM/DD'))
    setMySelectedDate(dayjs(e.date).toDate())
  }, [])

  const handlePageChange = (event, instance) => {
    setDatesSelected({
      fromDate: moment(event.firstDay).startOf('day').format('YYYY-MM-DD'),
      toDate: moment(event.lastDay).endOf('day').format('YYYY-MM-DD'),
    })
  }

  useEffect(() => {
    if (selectedEvent && !selectedEvent.color) {
      setShowAddNewTimeSlot(true)
    }
  }, [])

  useEffect(() => {
    if (timeSlotsFromStore) {
      setData(
        timeSlotsFromStore.map((item) => {
          const tmpDate = dayjs(item.date)
            .hour(item.hour)
            .minute(item.minute)
            .second(0)
            .tz(userTimeZone)
          return {
            ...item,
            start: tmpDate.toDate(),
            end: tmpDate.add(1, 'hour').toDate(),
          }
        }),
      )
    }
  }, [timeSlotsFromStore, userTimeZoneState])

  useEffect(() => {
    setLoading(timeSlotsFromStoreLoading)
  }, [timeSlotsFromStoreLoading])

  const backStep = () => {
    setStepSlug('addServices')
  }

  const nextStep = () => {
    setStepSlug('addPeople')
  }

  useEffect(() => {
    if (!!selectedDateStore) {
      setSelectedDate(dayjs(selectedDateStore).format('YYYY/MM/DD'))
      setShowAddNewTimeSlot(!showAddNewTimeSlot)
    }
  }, [selectedDateStore])

  useEffect(() => {
    if (userTimeZone) {
      setUserTimeZoneState(userTimeZone)
    }
  }, [userTimeZone])

  useEffect(() => {
    dispatch(fetchTimeSlotsAppointments({
      subscriberId,
      fromDate: datesSelected.fromDate,
      toDate: datesSelected.toDate,
    }))
    if (datesSelected && (!dayjs(datesSelected.fromDate).isSame(dayjs(), 'day'))) {
      setMyInvalid([
        {
          start: dayjs(datesSelected.fromDate).startOf('day').toDate(),
          end: dayjs().endOf('day').toDate(),
        },
      ])
    }

    
  }, [datesSelected])

  return (
    <BlockUi blocking={loading}>
      {isMobile && (
        <MobileModalHeader>
          <Box>
            <IconButton
              onClick={backStep}
              sx={{
                padding: '6px',
                marginLeft: '-8px',
                svg: { width: 28, height: 28 },
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="h6">Add Date & Time</Typography>
          </Box>
          <Box>
            <IconButton
              onClick={nextStep}
              sx={{
                padding: '6px',
                marginRight: '-8px',
                svg: { width: 28, height: 28 },
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          </Box>
        </MobileModalHeader>
      )}
      <ConditionalWrapper
        condition={!isMobile}
        wrapper={(children) => (
          <Step sx={{ maxWidth: 1600 }}>
            <StepHead>
              <Typography variant="h6">Book Date & Time</Typography>
              <Info title="Select the Date and Time of booking" />
            </StepHead>
            {children}
          </Step>
        )}
      >
        <Page>
          <div className="mbsc-flex">
            <div className="mbsc-flex-1-1">
              <Eventcalendar
                locale={localeEn}
                theme="ios"
                themeVariant="light"
                clickToCreate={false}
                dragToCreate={false}
                dragToMove={false}
                dragToResize={false}
                eventDelete={false}
                view={view}
                data={data}
                invalid={myInvalid}
                renderLabel={(data) =>
                  renderLabel(data, selectedTimeSlot, selectedEvent)
                }
                onEventClick={onEventClickSelectTimeSlot}
                onCellClick={onCellClickSelectTimeSlot}
                height={300}
                selectedDate={mySelectedDate}
                defaultValue={mySelectedDate}
                displayTimezone={userTimeZone}
                timezonePlugin={momentTimezone}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </Page>
        {selectedTimeSlot && (
          <AgendaList
            selectedTimeSlot={selectedTimeSlot}
            setDateTime={setDateTime}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            nextStep={nextStep}
          />
        )}

        {!showAddNewTimeSlot && !isUsingIframe && (
          <Box flex="column" justifyContent="center" alignContent="center">
            <FormControl fullWidth sx={{ marginTop: 4, marginBottom: 4 }}>
              <Button
                onClick={() => setShowAddNewTimeSlot(!showAddNewTimeSlot)}
              >
                Set a custom time
              </Button>
            </FormControl>
          </Box>
        )}

        {selectedDate && showAddNewTimeSlot && (
          <Box flex="column" justifyContent="center" alignContent="center">
            <div style={{ width: '100%', textAlign: 'center' }}>
              <IconButton
                sx={{
                  marginTop: 2,
                  padding: '6px',
                  svg: { width: 28, height: 28 },
                }}
                onClick={() => setShowAddNewTimeSlot(!showAddNewTimeSlot)}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <AddNewTimeSlot
              selectedDate={selectedDate}
              setDateTime={setDateTime}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
              nextStep={nextStep}
              setMySelectedDate={setMySelectedDate}
            />
          </Box>
        )}

        {!isMobile && (
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Button variant="contained" color="inherit" onClick={backStep}>
              Previous
            </Button>
            <Button variant="contained" color="inherit" onClick={nextStep}>
              Next
            </Button>
          </Stack>
        )}
      </ConditionalWrapper>
    </BlockUi>
  )
}

SetDateTime2.propTypes = {
  setDateTime: PropTypes.func,
  setStepSlug: PropTypes.func.isRequired,
  selectedEvent: PropTypes.oneOfType([PropTypes.object]),
  setSelectedEvent: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isUsingIframe: PropTypes.bool,
  subscriberId: PropTypes.string,
}

export default SetDateTime2
