/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'

const ServiceBreakdown = ({ fee, currencySymbol }) => {
  const taxDescription = fee.tax_description.split(/\r?\n|\r|\n/g)

  return (
    <Grid
      key={fee.id}
      container
      sx={{
        p: 1,
        marginBottom: '8px',
        border: `1px solid #e4e4e4`,
        borderRadius: '8px',
      }}
    >
      <Grid item xs={9}>
        <Typography variant="subtitle2">{fee.line_item}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="subtitle2" sx={{ textAlign: 'right' }}>
          {currencySymbol}
          {(+fee.pretax_amount + +fee.tax_amount).toFixed(2)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">
          <div style={{ lineHeight: 1.2, margin: '9px 0 0 7px' }}>
            {fee.pricing_details}
          </div>

          {taxDescription?.map((description, index) => {
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`${fee.id}--${index}`}
                style={{ lineHeight: 1.2, margin: '9px 0 0 7px' }}
              >
                {description}
              </div>
            )
          })}
        </Typography>
      </Grid>
    </Grid>
  )
}

ServiceBreakdown.propTypes = {
  fee: PropTypes.object.isRequired,
  currencySymbol: PropTypes.string.isRequired,
}

export default ServiceBreakdown
