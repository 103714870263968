export const settingsInitialState = {
  orderId: '',
  serviceOptions: [],
  discountOptions: [],
  agreementOptions: [],
  templateOptions: [],
  agentOptions: [],
  clientOptions: [],
  contractorOptions: [],
  inspectorOptions: [],
  actionGroups: [],
  isLimitReached: false,
  timezone: '',
  currencySymbol: '',
  acceptEvents: false,
  acceptActions: false,
  defaultStartTime: '',
  defaultDuration: 0,
  defaultPrice: 0,
  defaultAgreement: '',
  notifications: {},
  isUsingIFrame: false,
  isLoaded: false,
}

export const locationInitialState = {
  searchGoogleEnabled: true,
  fullAddress: '',
  streetAndNumber: '',
  city: '',
  state: '',
  zipCode: '',
  square_feet: '',
  year_built: '',
  foundation: '',
  coordinates: { lat: 0, lng: 0 },
}

export const servicesInitialState = {
  discountCode: '',
  events: [],
  newServices: {
    services: [],
    templates: [],
    agreements: [],
    fees: [],
    total: '0.0',
    duration: '0',
  },
  isManualEdit: false,
  manualData: {
    templates: [],
    agreements: [],
    fees: [],
    total: '0.0',
    duration: '0',
  },
  autoData: {
    templates: [],
    agreements: [],
    services: [],
    fees: [],
    total: '0.0',
    duration: '0',
  },
  requirePayment: true,
  paymentNotes: '',
  duration: '0',
  templates: [],
  agreements: [],
  services: [],
  fees: [],
}

export const peopleInitialState = {
  technicians: [],
  clients: [],
  agents: [],
  listingAgents: [],
  contractors: [],
}

export const informationInitialState = {
  order_id: '',
  confirm_inspection: true,
  disable_automated_notifications: false,
  occupied: true,
  utilities_on: true,
  action_groups: [],
}
