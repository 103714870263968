import PinIcon from './PinIcon'

export const Pin = (props) => {
  const { style } = props
  return (
    <div
      style={{
        position: 'absolute',
        left: '50%',
        bottom: '100%',
        width: '28.8px',
        height: '38.4px',
        color: '#ea4335',
      }}
    >
      <PinIcon style={style} />
    </div>
  )
}
