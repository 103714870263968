/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-restricted-syntax */
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import isEmpty from '../../../utils/is-empty'

const getServiceFee = ({ service, location, currencySymbol }) => {
  const serviceName = service.name
  const serviceCost = service.base_cost
  let baseCost = serviceCost
  const { square_feet, year_built, foundation } = location
  const taxDescription = []

  // 1) Get service modifiers
  service.modifiers.map((modifier) => {
    const {
      when: modifierType,
      greater_than: greaterThan,
      less_than_or_equal: lessThanOrEqual,
      add_fee: feePrice,
      add_hours: feeDuration,
    } = modifier

    if (!isEmpty(greaterThan) || !isEmpty(lessThanOrEqual)) {
      const price = `${currencySymbol}${feePrice.toFixed(2)}`
      let description = ``

      switch (modifierType) {
        // -- Square feet --
        case '1':
          if (!isEmpty(square_feet)) {
            const squareFeet = parseFloat(square_feet)
            if (greaterThan > 0 && lessThanOrEqual > 0) {
              if (squareFeet > greaterThan && squareFeet <= lessThanOrEqual) {
                description += `(sqft between ${greaterThan} and ${lessThanOrEqual})`
              }
            } else if (greaterThan > 0 && squareFeet > greaterThan) {
              description += `(sqft > ${greaterThan})`
            } else if (lessThanOrEqual > 0 && squareFeet <= lessThanOrEqual) {
              description += `(sqft <= ${lessThanOrEqual})`
            }
          }
          break

        // -- Age of home --
        case '2':
          if (!isEmpty(year_built)) {
            const currentYear = new Date().getFullYear()
            const ageOfHome = parseInt(currentYear - year_built, 10)
            if (ageOfHome > 0) {
              if (greaterThan > 0 && lessThanOrEqual > 0) {
                if (ageOfHome > greaterThan && ageOfHome <= lessThanOrEqual) {
                  description += `(age between ${greaterThan} and ${lessThanOrEqual})`
                }
              } else if (greaterThan > 0 && ageOfHome > greaterThan) {
                description += `(age > ${greaterThan})`
              } else if (lessThanOrEqual > 0 && ageOfHome <= lessThanOrEqual) {
                description += `(age <= ${lessThanOrEqual})`
              }
            } else description = ''
          }
          break

        // -- Year built --
        case '3':
          if (!isEmpty(year_built)) {
            const yearBuilt = parseInt(year_built, 10)
            if (greaterThan > 0 && lessThanOrEqual > 0) {
              if (yearBuilt > greaterThan && yearBuilt <= lessThanOrEqual) {
                description += `(year between ${greaterThan} and ${lessThanOrEqual})`
              }
            } else if (greaterThan > 0 && yearBuilt > greaterThan) {
              description += `(year > ${greaterThan})`
            } else if (lessThanOrEqual > 0 && yearBuilt <= lessThanOrEqual) {
              description += `(year <= ${lessThanOrEqual})`
            }
          }
          break

        // -- Zip code --
        case '4':
          if (!isEmpty(zip_code)) {
            const zipCode = zip_code
            if (zipCode === lessThanOrEqual) {
              description += `(zip code - ${lessThanOrEqual})`
            }
          }
          break

        // -- Foundation --
        case '5':
          if (!isEmpty(foundation)) {
            if (foundation === lessThanOrEqual) {
              description += `(foundation - ${FOUNDATIONS[
                foundation
              ].toLowerCase()})`
            }
          }
          break

        // -- Miles (distance) --
        case '6':
          // TODO: get distance (miles)
          /*
              if (!isEmpty(data.distance)) {
                const miles = parseFloat(data.distance)
                if (greaterThan > 0 && lessThanOrEqual > 0) {
                  if (miles > greaterThan && miles <= lessThanOrEqual) {
                    description += `(miles ${greaterThan} - ${lessThanOrEqual})`
                  }
                } else if (greaterThan > 0 && miles > greaterThan) {
                  description += `(miles > ${greaterThan})`
                } else if (lessThanOrEqual > 0 && miles <= lessThanOrEqual) {
                  description += `(miles <= ${lessThanOrEqual})`
                }
              }
              */
          description = ''
          break

        // -- Kilometers (distance) --
        case '7':
          // TODO: get distance (km)
          /*
              if (!isEmpty(data.distance)) {
              const kilometers = parseFloat(data.distance) * 1.609328
              if (greaterThan > 0 && lessThanOrEqual > 0) {
                if (kilometers > greaterThan && kilometers <= lessThanOrEqual) {
                  description += `(kilometers ${greaterThan} - ${lessThanOrEqual})`
                }
              } else if (greaterThan > 0 && kilometers > greaterThan) {
                description += `(kilometers > ${greaterThan})`
              } else if (lessThanOrEqual > 0 && kilometers <= lessThanOrEqual) {
                description += `(kilometers <= ${lessThanOrEqual})`
              }
            }
            */
          description = ''
          break

        default:
          // * Should never fall here but, just in case a debug helper *
          // eslint-disable-next-line no-console
          console.log(`Unknown modifier type: ${modifierType}`)
          description = ''
      }

      if (description) {
        // Save modifier description
        taxDescription.push(`${price} ${description}`)
        // Update base cost
        baseCost += +feePrice.toFixed(2)
        // Update duration
        // sumDuration += feeDuration || 0
      }
    }

    return true
  })

  // 2) Get service taxes
  let taxAcc = 0
  service.taxes.map((currentTax) => {
    const { add_percent: percentage, name: taxName } = currentTax
    const cost = (baseCost + taxAcc).toFixed(2)
    const tax = (percentage * cost) / 100
    const taxAmount = tax.toFixed(2)
    const percent = percentage.toFixed(1)
    taxAcc += +taxAmount

    let description = `${currencySymbol}${taxAmount} ${taxName} for ${serviceName}`
    description += ` (${percent}% on ${currencySymbol}${cost})`
    taxDescription.push(description)
    return true
  })

  // Return fee
  return {
    id: uuidv4(),
    serviceId: service.id,
    addOnId: null,
    line_item: service.name,
    pretax_amount: baseCost.toFixed(2),
    tax_amount: taxAcc.toString(),
    pricing_details: `${currencySymbol}${serviceCost.toFixed(2)} (Base)`,
    tax_description: taxDescription.join('\n'),
    duration: service.base_duration,
  }
}

// TODO: Move this to constants
const FOUNDATIONS = {
  1: 'Basement',
  2: 'Slab',
  3: 'Crawlspace',
}

getServiceFee.propTypes = {
  service: PropTypes.object,
  location: PropTypes.object,
  currencySymbol: PropTypes.string,
}

export default getServiceFee
