/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@material-ui/core'
import { StaticDatePicker } from '@material-ui/lab'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import LeftArrow from '@iconify-icons/akar-icons/circle-chevron-left'
import RightArrow from '@iconify-icons/akar-icons/circle-chevron-right'
import dayjs from 'dayjs'
import { Fragment } from 'react'
import { Step, StepHead } from './FormSteps.styles'
import Info from '../../../../info'
import CustomTimePicker from '../../../../TimePicker/TimePickerv2'
import isEmpty from '../../../../../utils/is-empty'
import { MobileModalHeader } from '../MobileMultiStep/MobileMultiStep.style'
import ConditionalWrapper from './AddServices/components/ConditionalWrapper'

const SetDateTime = ({ isMobile, dateTime, setDateTime, setStepSlug }) => {
  const onDayChange = (newDate) => {
    const newDay = dayjs(newDate).format('YYYY/MM/DD')
    const time = dateTime
      ? dayjs(dateTime).format('hh:mm A') // same time
      : '08:00 AM' // default start time
    setDateTime(dayjs(`${newDay} ${time}`).format())
  }

  const onTimeChange = (newTime) => {
    const day = dateTime
      ? dayjs(dateTime).format('YYYY/MM/DD') // same day
      : dayjs().format('YYYY/MM/DD') // today
    setDateTime(dayjs(`${day} ${newTime}`).format())
  }

  const backStep = () => {
    setStepSlug('addServices')
  }

  const nextStep = () => {
    setStepSlug('addPeople')
  }

  return (
    <Fragment>
      {isMobile && (
        <MobileModalHeader>
          <Box>
            <IconButton
              onClick={backStep}
              sx={{
                padding: '6px',
                marginLeft: '-8px',
                svg: { width: 28, height: 28 },
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="h6">Add Date & Time</Typography>
          </Box>
          <Box>
            <IconButton
              onClick={nextStep}
              sx={{
                padding: '6px',
                marginRight: '-8px',
                svg: { width: 28, height: 28 },
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          </Box>
        </MobileModalHeader>
      )}
      <ConditionalWrapper
        condition={!isMobile}
        wrapper={(children) => (
          <Step sx={{ maxWidth: 700 }}>
            <StepHead>
              <Typography variant="h6">Book Date & Time</Typography>
              <Info title="Select the Date and Time of booking" />
            </StepHead>
            {children}
          </Step>
        )}
      >
        <Fragment>
          <Grid container sx={{ mb: '16px' }}>
            <Grid item xs={12} md={6}>
              <StaticDatePicker
                openTo="day"
                orientation="portrait"
                showToolbar={false}
                sx={{ '& .Mui-selected': { color: `#fff !important` } }}
                value={dateTime}
                onChange={onDayChange}
                renderInput={() => {}} // is a required prop
                components={{
                  leftArrowIcon: LeftArrow,
                  rightArrowIcon: RightArrow,
                }}
                disablePast
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTimePicker
                time={
                  !isEmpty(dateTime) ? dayjs(dateTime).format('hh:mm A') : ''
                }
                onChange={onTimeChange}
              />
            </Grid>
          </Grid>
          {!isMobile && (
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
              <Button variant="contained" color="inherit" onClick={backStep}>
                Previous
              </Button>
              <Button variant="contained" color="inherit" onClick={nextStep}>
                Next
              </Button>
            </Stack>
          )}
        </Fragment>
      </ConditionalWrapper>
    </Fragment>
  )
}

SetDateTime.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  dateTime: PropTypes.string.isRequired,
  setDateTime: PropTypes.func,
  setStepSlug: PropTypes.func.isRequired,
}

export default SetDateTime
